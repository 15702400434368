import React, {useRef} from "react";
import UnigowInput from "../UnigowInput/UnigowInput";

interface ColorPickerProps {
    color: string;
    setColor: (v: React.SetStateAction<string>)=> void
}

function ColorPicker(props: ColorPickerProps): React.ReactElement {
    const {color, setColor} = props;
    const colorPickerRef = useRef<HTMLInputElement>(null);

    return (
        <div className="flex gap-[10px]">
            <UnigowInput value={color} onChange={(e)=>{
                setColor(e.target.value);
            }} className="w-3/4"
            onClick={()=>{
                colorPickerRef.current?.click();
            }}
            />
            <div onClick={()=>{
                colorPickerRef.current?.click();
            }} className="rounded w-9 h-auto relative border border-solid border-gray-cool-200" style={{backgroundColor:color}}
            >
                <input className="opacity-0" ref={colorPickerRef} type="color" onChange={(e)=>{
                    setColor(e.target.value);
                }}
                />
            </div>
        </div>
    );
}

export default ColorPicker;