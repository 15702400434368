import React, {useState, useEffect} from "react";
import UnigowInput from "../../../components/UnigowInput/UnigowInput";
import UnigowButton from "../../../components/UnigowButton/UnigowButton";
import {useAuth} from "../../../contexts/AuthContext";
import {toast} from "react-toastify";
import {useIframe} from "../contexts/IframeContext";
import {IconButton} from "@mui/material";
import {ArrowBackIosNew, Google} from "@mui/icons-material";
import ForgotPass from "../../ForgotPass/ForgotPass";
import * as Sentry from "@sentry/react";
import {useAuthStore} from "../../../stores/authStore";

function IframeLogin(): React.ReactElement {
    // STORES
    const {setCurrentPage} = useIframe();
    const {loginUser, signInWithGoogle} = useAuth();
    const {userData, loading:authLoading} = useAuthStore();

    // STATES
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [loading, setLoading] = useState(false);

    // FUNCTIONS
    async function handleLogin(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (loading) return;

        setLoading(true);

        if (!email || !pass) {
            toast.error("Debes rellenar el correo y la contraseña");
            setLoading(false);
            return;
        }

        try {
            await loginUser(email, pass);
            if (window.dataLayer) {
                window.dataLayer.push({event: "login", user_type:"user"});
            }
            setLoading(false);
        } catch (err) {
            toast.error("La combinación de correo y contraseña no es correcta");
            setLoading(false);
            setPass("");
        }
    }

    async function handleGoogleLogin(): Promise<void> {
        try {
            await signInWithGoogle();
            if (window.dataLayer) {
                window.dataLayer.push({event: "login", user_type:"user"});
            }
        } catch (err) {
            Sentry.captureException(err);
            toast.error("Se ha producido un error");
        }
    }

    // EFFECTS
    useEffect(()=>{
        if (authLoading) return;

        if (userData) {
            if (userData.additionalInfo) {
                setCurrentPage("chats");
                return;
            }

            setCurrentPage("form");
        }
    }, [userData, setCurrentPage, authLoading]);

    return (
        <div className="w-10/12 mx-auto flex flex-col gap-4">
            <div className="flex gap-8 items-center">
                <IconButton onClick={()=>setCurrentPage("search")}>
                    <ArrowBackIosNew/>
                </IconButton>
                <h3>Inicia sesión para descubrir experiencias reales</h3>
            </div>
            <form className="flex flex-col gap-4" onSubmit={handleLogin}>
                <div className="flex flex-col gap-[10px]">
                    <p className="text-ug-lg font-semibold">Email</p>
                    <UnigowInput required type="email" className="text-ug-base font-light text-gray-cool-400" autoComplete="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                </div>
                <div className="flex flex-col gap-[10px]">
                    <p className="text-ug-lg font-semibold">Contraseña</p>
                    <UnigowInput required type="password" className="text-ug-base font-light text-gray-cool-400" autoComplete="current-password" value={pass} onChange={(e)=>setPass(e.target.value)}/>
                </div>
                <UnigowButton variant="primary">Iniciar Sesión</UnigowButton>
                <UnigowButton onClick={handleGoogleLogin} type="button" className="flex items-center justify-center gap-2 py-1" variant="basic"><Google/> Iniciar sesión con Google</UnigowButton>
            </form>
            <ForgotPass/>
            <button type="button" className="text-ug-sm text-primary-500 hover:underline cursor-pointer bg-transparent border-none text-left w-fit" onClick={()=>setCurrentPage("register")}>No tengo cuenta</button>
        </div>
    );
}

export default IframeLogin;