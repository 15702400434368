import React, {HTMLAttributes} from "react";
import {twMerge} from "tailwind-merge";

export default function CardContainer({className, children, ...divProps}: HTMLAttributes<HTMLDivElement>): React.ReactElement {
    return (
        <div {...divProps} className={twMerge("flex flex-col pb-4", className)}>
            <div className="flex flex-col pb-4 rounded-[10px] shadow-md gap-4">
                {children}
            </div>
        </div>
    );
}