import {Message} from "@twilio/conversations";
import {LoggedUser} from "./user";

export interface TwilioSingleChat {
    _id: string;
    SID: string;
    iframe: string;
    plugin: string;
    user: {
        _id: string,
        profilePic: string,
        name: string,
        surnames: string,
        lastLogin: {
            date: Date
        }
    };
    hasFinished: boolean;
    hasFeedback: boolean;
    position: "orientated" | "orientator",
    unread?: number,
    type: "chat",
    lastMessage?: {
        body: string,
        added: Date | null
    }
}

export interface TwilioGroupChat {
    _id: string;
    SID: string;
    iframe: string;
    plugin: string;
    name: string;
    iconUrl: string;

    type: "group"
    unread?: number,
    lastMessage?: {
        body: string,
        added: Date | null
    }
    accepted: boolean;
    pinnedQuestions: PinnedQuestion[];
}

export type TwilioChat = TwilioSingleChat | TwilioGroupChat;

export interface TwilioChatFull {
    SID: string;
    hasFinished: boolean;
    hasFeedback: boolean;
    mainField: string;
    lastMessage?: {
        body: string,
        added: Date | null
    },
    orientator: {
        name: string,
        email: string
    },
    orientated: {
        name: string,
        email: string
    }
}

export interface WhatsappChatMin {
    _id: string;
    phone: string;
    user: {
        name: string,
        profilePic?: string
    };
    lastMessage?: WhatsappMessage
}

export interface WhatsappChat {
    _id: string;
    phone: string;
    user?: {
        name: string,
        profilePic?: string
    };
    lastMessageDate?: Date
}

export interface WhatsappMessage {
    sender: "unigow" | "user",
    body: string,
    sentDate: Date
}

export interface CreateChat {
    orientator: string;
    // Cliente al que pertenece el orientador
    client: string;
    iframe: string;
    plugin: string;
    mainField: string;
    // Cliente al que pertenece el iframe donde se creó el chat
    iframeClient: string | undefined;
}

export interface CreateAproval {
    orientator: string;
    // Cliente al que pertenece el orientador
    client: string;
    iframe: string;
    mainField: string;
    // Cliente al que pertenece el iframe donde se creó el chat
    iframeClient: string | undefined;
}

export interface AnalyzedChat {
    _id: string;
    chat: {
        orientator?: LoggedUser,
        orientated?: LoggedUser,
        mainField: string,
        startDate: Date
    },
    client: string,
    tags: string[],
    questions: [],
    lastRefresh: Date,
    analyzedMessages: number
}

type CleanComponent = {
    type: "HEADER" | "BODY" | "FOOTER" | "BUTTON",
    text: string,
    var_num: number,
    url?: string
};

export interface WhatsappTemplate {
    name: string,
    components: CleanComponent[],
    language: string,
    status: "APPROVED" | "REJECTED" | "PENDING",
    category: string,
    id: string
}

export type MessageComponent = {
    type: "header" | "body" | "footer",
    parameters: {
        type: "text" | "currency" | "date_time" | "image" | "document" | "video",
        text: string
    }[]
} | {
    type: "button",
    sub_type: "url" | "quick_reply" | "copy_code",
    index: number
    parameters: {
        type: "text" | "payload" | "copupon_code",
        payload?: string | undefined,
        text: string,
        coupon_code?: string | undefined
    }[]
};

export class UnigowMessage {
    public mod: boolean;
    public displayName: string | undefined;
    public answersTo: string | undefined;
    public twilioMsg: Message;

    public constructor(mod: boolean, twilioMsg: Message, answersTo?: string, displayName?: string) {
        this.twilioMsg = twilioMsg;
        this.mod = mod;
        this.displayName = displayName;
        this.answersTo = answersTo;
    }
}

export interface PinnedQuestion {
    _id: string;

    question: string;
    answer: string;
}

export type CreatePinnedQuestion = Omit<PinnedQuestion, "_id">;