import {create} from "zustand";

type UnigowStore = {
    // Estado de carga de la página
    loading: boolean;

    // Función para modificar el estado de carga
    setLoading: (loading: boolean)=> void;

    // Estado del panel de soporte
    support: boolean;

    // Función para abrir el soporte
    openSupport: ()=> void;

    // Función para cerrar el soporte
    closeSupport: ()=> void;
};

const useUnigowStore = create<UnigowStore>((set) => ({
    loading: false,
    setLoading: (loading) => set({loading}),
    support:false,
    openSupport:()=>set({support:true}),
    closeSupport:()=>{
        set({support:false});
    }
}));

export default useUnigowStore;
