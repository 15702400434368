import React, {useEffect} from "react";
import {createRoot} from "react-dom/client";
import "./index.css";
import {App} from "./App";
import {AuthProvider} from "./contexts/AuthContext";
import {BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {
    QueryClient,
    QueryClientProvider
} from "react-query";
import {TwilioProvider} from "./contexts/TwilioContext";
import {ThemeProvider, createTheme} from "@mui/material";
import {CustomLocaleProvider} from "./contexts/CustomLocaleContext";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {extend} from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as Sentry from "@sentry/react";

import "react-toastify/dist/ReactToastify.css";

const root = createRoot(
    document.getElementById("root") as HTMLElement
);

extend(timezone);
extend(utc);
extend(customParseFormat);

const queryClient = new QueryClient({defaultOptions:{
    queries:{
        refetchOnWindowFocus:false
    }
}});

// Get general-font
const style = getComputedStyle(document.body);
const font = style.getPropertyValue("--general-font");

const theme = createTheme({
    typography: {
        fontFamily: [font, "Montserrat", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen",
            "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
            "sans-serif"
        ].join(",")
    }
});

Sentry.init({
    dsn: "https://30d4d7ae37e6090c083a03b966734539@o4504163643883520.ingest.sentry.io/4506061166149632",
    tracesSampleRate: process.env.NODE_ENV === "production" ? 0 : 1.0,
    replaysSessionSampleRate:0,
    replaysOnErrorSampleRate:process.env.NODE_ENV === "production" ? 0 : 0.5,
    environment:process.env.NODE_ENV,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            )
        }),
        new Sentry.Replay()
    ]
});

root.render(
    <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <AuthProvider>
                        <CustomLocaleProvider>
                            <TwilioProvider>
                                <ToastContainer/>
                                <App />
                            </TwilioProvider>
                        </CustomLocaleProvider>
                    </AuthProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </LocalizationProvider>
    </BrowserRouter>
);
