import React, {useEffect} from "react";
import {api} from "../../apis/requests";
import {useSearchParams} from "react-router-dom";
import {useAuthStore} from "../../stores/authStore";
import BasicPage from "@unigow/pages/Functional/BasicPage";

function AcceptAproval(): React.ReactElement {
    const [URLSearchParams] = useSearchParams();
    const {userData} = useAuthStore();

    useEffect(()=>{
        async function acceptAproval(): Promise<void> {
            const aprovalId = URLSearchParams.get("aproval");

            if (aprovalId) window.location.href = "/";

            const answer = URLSearchParams.get("answer");
            await api.post(`aprovals/${aprovalId}`, {answer:answer || "accept"});

            window.location.href = "/";
        }

        if (!userData) return;

        void acceptAproval();
    });

    useEffect(()=>{
        setTimeout(()=>{
            window.location.href = "/";
        }, 10000);
    }, []);

    return (
        <BasicPage>
            <p>Petición aceptada, redirigiendo a tu panel...</p>
        </BasicPage>
    );
}

export default AcceptAproval;