import React from "react";
import {Avatar} from "@mui/material";
import UnigowButton from "../../../components/UnigowButton/UnigowButton";
import {useMediaQuery} from "react-responsive";
import {setCookie} from "../../../helpers/localstorage";
import {twMerge} from "tailwind-merge";
import {PopupType} from "@unigow/unigow-types";
import {PublicOrientator} from "@unigow/types/user";

export interface PopupUserProps {
    popupData?: Pick<PopupType, "style" | "settings"> & {mainField: string};
    orientatorArray: PublicOrientator[];
    length?: number;
    query?: string;
}

export default function SingleUser(props: PopupUserProps): React.ReactElement {
    const {orientatorArray, popupData, query} = props;

    const [orientator] = orientatorArray;

    const isMobile = useMediaQuery({query: "(max-width: 499px)"});

    if (!orientator) return <></>;

    // Search the main field of the orientator in this iframe
    const mainParam = orientator.userInfo.info.info[popupData?.mainField || "main"];

    function getDesktopSizes(): {
        avatar: number,
        padding: string
    } {
        if (!popupData || !popupData.style || !popupData.style.size) return {
            avatar: 50,
            padding: "imd:py-6"
        };

        switch (popupData.style.size) {
            case "normal":
                return {
                    avatar: 100,
                    padding: "imd:py-6"
                };
            case "small":
                return {
                    avatar: 70,
                    padding: "imd:py-2"
                };
            case "big":
                return {
                    avatar: 120,
                    padding: "imd:py-6"
                };
        }
    }

    return (
        <div className={twMerge("p-4 flex imd:gap-7 imd:items-center", getDesktopSizes().padding)}>
            {!isMobile && (
                <Avatar sx={{width: getDesktopSizes().avatar, height:getDesktopSizes().avatar}} className="border-white border-2 border-solid" src={orientator?.userInfo.profilePic}/>
            )}
            <div className="flex flex-col gap-2 w-full">
                <div className="flex flex-col gap-1">
                    <h3 className="font-semibold text-ug-lg imd:text-[18px]">{orientator?.userInfo.name}</h3>
                    <p className="text-ug-sm font-light imd:font-normal imd:text-ug-lg">{mainParam}</p>
                </div>
                <UnigowButton className="py-1 imd:py-2" variant="primary" onClick={()=>{
                    if (!query) return;
                    // Open link on another tab
                    setCookie("query", query, 0.0007);
                    window.open(popupData?.settings.redirectLink || "#", "_blank");
                }}
                >Chatear con {orientator?.userInfo.name}
                </UnigowButton>
            </div>
        </div>
    );
}