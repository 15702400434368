import React, {useEffect} from "react";
import {InteractionsGraph} from "./components/InteractionsGraph";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import {useQuery} from "react-query";
import {api} from "../../../../apis/requests";
import {PieChart} from "./components/PieChart";
import {useNavigate} from "react-router-dom";
import {useDashboardStore} from "../../../../stores/dashboard/dashboardStore";

export default function Stats(): React.ReactElement {
    const {clientInfo, selectedClient} = useDashboardStore();
    const navigate = useNavigate();

    useEffect(()=>{
        if (!clientInfo) return;

        if (!clientInfo.permissions?.manager) {
            navigate("/chats");
        }
    }, [clientInfo, navigate]);

    const {data} = useQuery(["stats", selectedClient, clientInfo], async()=>{
        if (!selectedClient || !clientInfo || !clientInfo.permissions?.manager) return;

        const intData = await api.get<{leads: number, interactions: number}>(`logs/${selectedClient}/stats`);
        const chatData = await api.get<{messages: number}>(`chats/client/${selectedClient}/messages`);
        return {...intData, ...chatData};
    });

    return (
        <PageContainer className="px-4 items-start">
            <div className="flex flex-col gap-[35px] pb-8 w-full">
                <div className="flex flex-col gap-3 items-start">
                    <h1>Estadísticas Generales</h1>
                    <p className="text-ug-lg">Descubre rápidamente los resultados de tu software</p>
                </div>
                <div className="flex gap-8 flex-col lg:flex-row">
                    <div className="bg-white px-6 py-7 gap-6 flex flex-col rounded-lg shadow-ug-base">
                        <div className="px-[30px]">
                            <h3>Resumen de resultados</h3>
                        </div>
                        <div className="flex lg:gap-[52px] flex-col lg:flex-row">
                            <div className="bg-white rounded-xl px-[30px] py-[18px] items-start flex flex-col justify-center">
                                <h3>Registros Únicos</h3>
                                <p className="text-[40px]">{data?.leads}</p>
                            </div>
                            <div className="bg-secondary-org-200 rounded-xl px-[30px] py-[18px] items-start flex flex-col justify-center">
                                <h3>Mensajes</h3>
                                <p className="text-[40px]">{data?.messages}</p>
                            </div>
                            <div className="bg-white rounded-xl px-[30px] py-[18px] items-start flex flex-col justify-center">
                                <h3>Interacciones</h3>
                                <p className="text-[40px]">{data?.interactions}</p>
                            </div>
                        </div>
                        <div className="">
                            <PieChart/>
                        </div>
                    </div>
                    <div className="flex flex-col gap-8 lg:w-2/5">
                        <div className="bg-white p-4 rounded-[10px] shadow-ug-base">
                            <InteractionsGraph/>
                        </div>
                    </div>
                </div>
            </div>
        </PageContainer>
    );
}