import React, {Children, useState} from "react";
import {toast} from "react-toastify";
import {api} from "../../../apis/requests";
import UnigowInput from "../../../components/UnigowInput/UnigowInput";
import {Checkbox, MenuItem, Select, Switch} from "@mui/material";
import UnigowButton from "../../../components/UnigowButton/UnigowButton";
import {CreateManager, CreateOrientator} from "../../../types/user";
import {useAuth} from "../../../contexts/AuthContext";
import {useQuery} from "react-query";
import {ChatsPluginAPI} from "@unigow/apis/iframe";
import {ChatsPluginType} from "@unigow/unigow-types";
import {AuthAPI} from "@unigow/apis/auth";
import {isEmpty, minLength} from "class-validator";

type RegisterFormProps =
     {type: "orientator", masterPassword: string, client: string}
     | {type: "manager", masterPassword: string, client: string};

// Get all props params
export function RegisterForm({type, masterPassword, client}: RegisterFormProps): React.ReactElement {
    // STORES
    const {registerUser} = useAuth();

    // STATES
    const [name, setName] = useState("");
    const [surnames, setSurnames] = useState("");
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [mentorType, setMentorType] = useState<"mentor" | "staff">("mentor");
    const [chatPlugin, setChatPlugin] = useState<ChatsPluginType | undefined>(undefined);
    const [createMentor, setCreateMentor] = useState(type === "orientator");

    // QUERIES
    const {data:clientChatPlugins = []} = useQuery(["chatplugins", client], async()=>{
        const chatPlugins = await ChatsPluginAPI.getClientChatsPlugins({clientId:client});

        if (chatPlugins.length === 1) {
            setChatPlugin(chatPlugins[0]);
        }

        return chatPlugins;
    }, {
        enabled: (type === "orientator" || createMentor) && !!client
    });

    async function getClientFromPass(): Promise<string | null> {
        const response = await AuthAPI.checkRegisterPassword({type, password:masterPassword});

        if (!response.result) {
            if (response.error === "unknown") {
                toast.error("Ha ocurrido un error al verificar la contraseña maestra");
            } else {
                toast.error("La contraseña maestra introducida no es válida");
            }
            return null;
        }

        return response.client;
    }

    // FUNCTIONS
    async function handleRegister(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!minLength(pass, 6)) {
            toast.error("La contraseña debe tener al menos 6 caracteres");
            return;
        }

        if (pass !== confirmPass) {
            toast.error("Las contraseñas no coinciden");
            return;
        }

        if (isEmpty(masterPassword)) {
            toast.error("La contraseña maestra no puede estar vacía");
            return;
        }

        let managerClient: string | null = null;

        if (type === "manager") {
            managerClient = await getClientFromPass();
        }

        const newUser = {
            name,
            surnames,
            email
        };

        const user = await registerUser(newUser, pass).catch((err)=>{
            const error = err as Error;
            if (error.message === "auth/email-already-in-use") {
                toast.error("Ese correo ya está en uso");
            } else {
                toast.error("Ha ocurrido un error al crear el usuario");
            }
        });

        if (!user) {
            toast.error("Ha ocurrido un error al crear el usuario");
            return;
        }

        if (type === "orientator" || createMentor) {
            if (!chatPlugin) return;

            const newOrientator: CreateOrientator = {
                orientatorPassword:masterPassword,
                type:mentorType
            };

            await api.post<CreateOrientator, CreateOrientator>(`pluginchats/${chatPlugin._id}/orientators`, newOrientator);
        }

        if (type === "manager") {
            if (!managerClient) return;

            const newManager: CreateManager = {
                masterPassword:masterPassword
            };

            await api.post<CreateManager, CreateManager>(`clients/${managerClient}/managers`, newManager);
        }
    }

    return (
        <form className="flex flex-col gap-4 xl:w-3/4 px-4" onSubmit={handleRegister}>
            <div className="flex flex-col gap-3">
                <h3>Nombre</h3>
                <UnigowInput required placeholder="Nombre" type="text" autoComplete="name"
                    value={name} onChange={(e)=>setName(e.target.value)}
                />
            </div>
            <div className="flex flex-col gap-3">
                <h3>Apellidos</h3>
                <UnigowInput required placeholder="Apellidos" type="text" autoComplete="name"
                    value={surnames} onChange={(e)=>setSurnames(e.target.value)}
                />
            </div>
            <div className="flex flex-col gap-3">
                <h3>Correo</h3>
                <UnigowInput required placeholder="Correo Electrónico" type="email" autoComplete="email"
                    value={email} onChange={(e)=>setEmail(e.target.value)}
                />
            </div>
            <div className="flex flex-col gap-3">
                <h3>Contraseña</h3>
                <UnigowInput required placeholder="Contraseña" type="password" autoComplete="new-password"
                    value={pass} onChange={(e)=>setPass(e.target.value)}
                />
            </div>
            <div className="flex flex-col gap-3">
                <h3>Repetir Contraseña</h3>
                <UnigowInput required placeholder="Repetir Contraseña" type="password" autoComplete="new-password"
                    value={confirmPass} onChange={(e)=>setConfirmPass(e.target.value)}
                />
            </div>
            {type === "manager" && (
                <div className="flex flex-col gap-3">
                    <h3>Crear perfil de orientador</h3>
                    <div className="flex items-center gap-2">
                        <p className={!createMentor ? "font-bold" : ""}>No</p>
                        <Switch
                            checked={createMentor}
                            onChange={(e)=>{
                                setCreateMentor(e.target.checked);
                            }}
                            sx={{
                                "&.MuiSwitch-root .MuiSwitch-switchBase": {
                                    color: "gray"
                                },
                                ".MuiSwitch-switchBase+.MuiSwitch-track":{
                                    backgroundColor: "gray"
                                },
                                "&.MuiSwitch-root .Mui-checked, .Mui-checked+.MuiSwitch-track": {
                                    color: "blue"
                                },
                                ".Mui-checked+.MuiSwitch-track":{
                                    backgroundColor: "blue"
                                }
                            }}
                        />
                        <p className={createMentor ? "font-bold" : ""}>Si</p>
                    </div>
                </div>
            )}
            {clientChatPlugins.length > 1 && (createMentor || type === "orientator") && (
                <div className="flex flex-col gap-3">
                    <h3>Seleccionar Proyecto</h3>
                    <Select required value={chatPlugin} onChange={(e)=>{
                        const selectedIframe = clientChatPlugins.find((ifr)=>ifr._id.toString() === e.target.value);
                        setChatPlugin(selectedIframe);
                    }} size="small"
                    >
                        <MenuItem value="" disabled>Seleccionar Proyecto</MenuItem>
                        {Children.toArray(clientChatPlugins.map((ifr)=>(
                            <MenuItem value={ifr._id.toString()}>{ifr.alias}</MenuItem>
                        )))}
                    </Select>
                </div>
            )}
            {chatPlugin?.settings.staffTab && (
                <div className="flex flex-col gap-3">
                    <h3>Seleccionar Rol</h3>
                    <div className="flex items-center gap-2">
                        <p className={mentorType === "staff" ? "text-yellow-400 font-bold" : ""}>{chatPlugin.style.staffLabel || "Staff"}</p>
                        <Switch
                            checked={mentorType === "mentor"}
                            onChange={(e)=>{
                                setMentorType(e.target.checked ? "mentor" : "staff");
                            }}
                            sx={{
                                "&.MuiSwitch-root .MuiSwitch-switchBase": {
                                    color: "gold"
                                },
                                ".MuiSwitch-switchBase+.MuiSwitch-track":{
                                    backgroundColor: "gold"
                                },
                                "&.MuiSwitch-root .Mui-checked, .Mui-checked+.MuiSwitch-track": {
                                    color: "blue"
                                },
                                ".Mui-checked+.MuiSwitch-track":{
                                    backgroundColor: "blue"
                                }
                            }}
                        />
                        <p className={mentorType === "mentor" ? "text-primary-500 font-bold" : ""}>{chatPlugin.style.mentorsLabel || "Estudiante"}</p>
                    </div>
                </div>
            )}
            <div className="flex items-center">
                <Checkbox
                    id="privacy"
                    required
                    className="pl-0"
                />
                <label htmlFor="privacy" className="text-ug-sm select-none">
                    Acepto las <a rel="noopener noreferrer" target="_blank" className="underline font-medium text-black" href="/privacy">Políticas de Privacidad</a> de Unigow.
                </label>
            </div>
            <div className="flex justify-between gap-3 flex-col lg:flex-row">
                <UnigowButton className="flex-grow lg:w-1/3 w-full" type="reset" variant="basic">Borrar</UnigowButton>
                <UnigowButton className="w-full flex-shrink flex-grow-0" variant="secondary">Continuar</UnigowButton>
            </div>
            <div className="flex justify-start w-full">
                <a href="/login" className="text-primary-500 text-ug-sm underline font-medium">Ya tengo cuenta, quiero iniciar sesión</a>
            </div>
        </form>
    );
}