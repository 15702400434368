import CardBottom from "@unigow/components/GroupCard/components/CardBottom";
import CardContainer from "@unigow/components/GroupCard/components/CardContainer";
import CardTop from "@unigow/components/GroupCard/components/CardTop";
import {CardType} from "@unigow/unigow-types";
import React, {HTMLAttributes} from "react";

interface GroupCardProps {
    cardInfo: CardType;
    ActionButton: React.ReactElement;
    color: string;
}

export default function CommonCard({cardInfo, ActionButton, color, ...divProps}: GroupCardProps & HTMLAttributes<HTMLDivElement>):
React.ReactElement {

    return (
        <CardContainer {...divProps}>
            <CardTop color={color} name={cardInfo.name} iconUrl={cardInfo.iconUrl} />

            <CardBottom fields={cardInfo.fields}/>

            {!!ActionButton && (
                <div className="px-4">
                    {ActionButton}
                </div>
            )}
        </CardContainer>
    );
}