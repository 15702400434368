import "./styles.css";

import React from "react";
import {Helmet} from "react-helmet";

import {useTwilio} from "@unigow/contexts/TwilioContext";
import {TwilioSingleChat} from "../../types/chat";
import {api} from "../../apis/requests";
import ChatTop from "@unigow/components/ChatRoom/components/ChatTop";
import {useQuery} from "react-query";
import ChatBottom from "@unigow/components/ChatRoom/components/ChatBottom";
import ChatBox from "@unigow/components/ChatRoom/components/ChatBox";
import {useChatStore} from "@unigow/components/ChatRoom/store/chatStore";

export interface ChatRoomProps {
    // Chat's id
    sid: string,
    // Defines where to go back when the user exits
    goBack: ()=> void,
    // Inherited from the dashboard's chat list. Needed for the several components, defines if the user is a mod
    dashboard: boolean
}

export default function ChatRoom({sid, goBack, dashboard}: ChatRoomProps): React.ReactElement {
    // STORES
    const {setConv, setIsDashboard} = useChatStore();
    const {twilioClient} = useTwilio();

    const {data:convInfo, isSuccess:mongoLoaded} = useQuery(["chat", sid], async()=>{
        const data = await api.get<TwilioSingleChat>(`chats/chat/${sid}`);

        return {
            ...data,
            type:"chat" as const
        };
    }, {
        enabled:!!sid
    });

    const {isSuccess:twilioLoaded} = useQuery(["conversation", sid], ()=>{
        return twilioClient?.getConversationBySid(sid);
    }, {
        enabled:!!sid && !!twilioClient,
        onSuccess:(conversation)=>{
            setIsDashboard(dashboard);
            setConv(conversation);
        }
    });

    return (
        <>
            <div className="bg-white w-full rounded-[20px]">
                <Helmet>
                    <title>Unigow - Chat con {`${convInfo?.user.name}`}</title>
                    <meta name="robots" content="noindex" />
                </Helmet>
                {(!mongoLoaded || !twilioLoaded) ? (
                    <ChatTop goBack={goBack} loading={true}/>
                ) : (
                    <ChatTop goBack={goBack} loading={false} name={convInfo.user.name} iconUrl={convInfo.user.profilePic}/>
                )}
                {(!convInfo) ? (
                    <div className="flex flex-col gap-4 overflow-y-scroll p-4 h-[50vh]">

                    </div>
                ) : (
                    <ChatBox chatInfo={convInfo}/>
                )}

                {convInfo && (
                    <ChatBottom chatInfo={convInfo}/>
                )}
            </div>
        </>
    );
}