import React from "react";

export enum ConversationTagColor {
    "financiación" = "#0034BB",
    "alojamiento" = "#FEAF14",
    "titulaciones" = "#6B7280",
    "campus" = "#D11717",
    "ciudad" = "#32940F",
    "profesores" = "#E000AF",
    "empleabilidad" = "#2E8CA1",
    "vida social" = "#D5A11A",
    "soporte al estudiante" = "#109CF1",
    "deportes o asociaciones" = "#010202"
}

interface ConversationTagProps {
    tag: string;
}

export default function ConversationTag(props: ConversationTagProps): React.ReactElement {
    const {tag} = props;

    // The tag must be one of the tags in the enum ConversationTagColor
    const tagKey = tag.toLowerCase() as keyof typeof ConversationTagColor;

    // According to the thag, change the button background and text color
    const color = ConversationTagColor[tagKey];

    return (
        <li className={"border font-medium border-solid bg-opacity-30 w-fit rounded-full px-[10px] py-[2px]"} style={{borderColor:color, backgroundColor:`${color}0D`, color}}>
            <p>{tag}</p>
        </li>
    );
}