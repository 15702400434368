import React, {Children, Fragment} from "react";
import {ChatItem} from "./components/ChatItem";
import {Divider, IconButton, Skeleton} from "@mui/material";
import {TwilioChat, WhatsappChatMin} from "../../types/chat";
import {Star, StarOutline} from "@mui/icons-material";
import {useChatsStore} from "@unigow/pages/Dashboard/pages/ChatsPage/stores/chatsStore";

type ChatsListProps = {
    loading: boolean;
    dashboard?: boolean;
} & ({
    type: "twilio",
    chats: TwilioChat[],
    goToChatPage: (sid: string, type: "chat" | "group")=> void;
} | {
    type: "whatsapp",
    chats: WhatsappChatMin[],
    goToChatPage: (_id: string)=> void;
});

export default function ChatsList({loading, dashboard, type, chats, goToChatPage}: ChatsListProps): React.ReactElement {
    const {isFavoriteChat, addFavoriteChat, removeFavoriteChat} = useChatsStore();

    function renderChats(): React.ReactElement[] {
        if (type === "twilio") {
            return chats.map((chat, i)=>{
                if (chat.type === "group") {
                    return ((
                        <Fragment key={chat.SID}>
                            <ChatItem chatItem={{_id:chat._id, SID:chat.SID, name:chat.name, iconUrl:chat.iconUrl, lastMessage:chat.lastMessage,
                                pending:!chat.accepted, unread:chat.unread}} index={i}
                            onClick={()=>{
                                if (!chat.accepted) return;
                                goToChatPage(`${chat.SID}`, "group");
                            }}
                            />
                            {(i !== chats.length - 1) ? (
                                <Divider className="border-gray-cool-200"/>
                            ) : <></>}
                        </Fragment>
                    ));
                }
                return ((
                    <Fragment key={chat.SID}>
                        <div className="flex justify-between">
                            <ChatItem chatItem={{_id:chat._id, SID:chat.SID, lastMessage:chat.lastMessage, iconUrl:chat.user.profilePic,
                                name:chat.user.name, unread:chat.unread}} index={i} onClick={()=>{
                                goToChatPage(`${chat.SID}`, "chat");
                            }}
                            />
                            {dashboard && (
                                <div className="px-2 flex items-center">
                                    <IconButton className="cursor-pointer" onClick={()=>{
                                        if (isFavoriteChat(chat.SID)) {
                                            removeFavoriteChat(chat.SID);
                                        } else {
                                            addFavoriteChat(chat.SID);
                                        }
                                    }}
                                    >
                                        {isFavoriteChat(chat.SID) ? (
                                            <Star className="text-secondary-org-500"/>
                                        ) : (
                                            <StarOutline className="text-gray-cool-400"/>
                                        )}
                                    </IconButton>
                                </div>
                            )}
                        </div>

                        {(i !== chats.length - 1) ? (
                            <Divider className="border-gray-cool-200"/>
                        ) : <></>}
                    </Fragment>
                ));
            });
        }
        return chats.map((chat, i)=>(
            <Fragment key={chat._id}>
                <ChatItem chatItem={{
                    _id:chat._id,
                    name:chat.user ? chat.user.name : chat.phone,
                    iconUrl:chat.user ? chat.user.profilePic || "" : "",
                    lastMessage:{
                        body:chat.lastMessage?.body || "",
                        added:chat.lastMessage?.sentDate
                    }}} index={i} onClick={()=>{
                    goToChatPage(chat._id);
                }}
                />

                {(i !== chats.length - 1) ? (
                    <Divider className="border-gray-cool-200"/>
                ) : <></>}
            </Fragment>
        ));
    }

    return loading ? (
        <div className="bg-white flex flex-col rounded-b-[20px] w-full">
            {Children.toArray(Array(6).fill(
                <div className="flex relative gap-5 pl-[30px] py-[15px] items-center">
                    <div className="flex-shrink-0 rounded-full overflow-hidden">
                        <Skeleton variant="circular" width={50} height={50}/>
                    </div>
                    <div className="flex flex-col lg:w-1/2">
                        <p className="text-ug-lg font-semibold"><Skeleton variant="text" width="10ch"/></p>
                        <p className="text-ug-lg text-ellipsis line-clamp-1"><Skeleton variant="text"/></p>
                    </div>
                    <div className="absolute top-0 right-0 pr-[30px] pt-[15px] text-gray-cool-400 text-ug-sm">
                        <p><Skeleton variant="text" width="15ch"/></p>
                    </div>
                </div>
            ))}
        </div>
    ) : (
        <ul className="bg-white flex flex-col rounded-sm w-full">
            {renderChats()}
        </ul>
    );
}