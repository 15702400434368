import {Checkbox} from "@mui/material";
import {api} from "@unigow/apis/requests";
import ParameterChooser from "@unigow/pages/Dashboard/components/ParameterChooser/ParameterChooser";
import ParameterFields from "@unigow/pages/Dashboard/components/ParameterChooser/ParameterFields";
import {StepProps} from "@unigow/pages/Dashboard/pages/Iframes/tabs/Groupsplugin/sections/GroupConfig/GroupConfig";
import {useGroupWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/groupWizardStore";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {GroupPage} from "@unigow/types/group";
import React, {FormEvent, useState} from "react";

export default function Step2({setStep, step}: StepProps): React.ReactElement {
    // STORES
    const {clientParams} = useDashboardStore();
    const {groupPageData, modifyGroupPageData} = useGroupWizardStore();

    // STATES
    const [subStep, setSubStep] = useState(0);
    const [phoneNeeded, setPhoneNeeded] = useState(groupPageData?.settings.userNeedsPhone || false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>(groupPageData?.settings.userParams || []);

    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        const groupSettings: Pick<GroupPage["settings"], "userNeedsPhone" | "userParams"> = {
            userParams:selectedOptions,
            userNeedsPhone:phoneNeeded
        };

        if (!groupPageData) {
            setStep(0);
            return;
        }

        const response = await api.patch<Partial<GroupPage>, GroupPage>(`grouppages/${groupPageData._id}`, {settings:groupSettings});

        if (response) {
            modifyGroupPageData("settings", response.settings);
            setSubStep(1);
        }
    }

    return (
        <div className="overflow-hidden">
            {subStep === 0 ? (
                <form className="flex flex-col gap-[15px] py-2" onSubmit={handleSubmit}>
                    {step === 2 && (
                        <h2>2.1 Personaliza el registro de los usuarios</h2>
                    )}
                    <p className="font-light text-ug-base">Es hora de pensar en qué datos quieres pedirle al usuario antes de que pueda interactuar con tus embajadores. El nombre y correo son obligatorios.</p>
                    <p className="text-ug-base font-semibold">Nombre *</p>
                    <p className="text-ug-base font-semibold">Correo electrónico *</p>
                    <div className="flex items-center gap-2">
                        <Checkbox
                            style={{color:"#000"}}
                            id="neededphone"
                            className="p-0"
                            checked={phoneNeeded}
                            onChange={(e)=>setPhoneNeeded(e.target.checked)}
                        />
                        <label htmlFor="neededphone" className="text-ug-base font-semibold">Pedir número de teléfono</label>
                    </div>
                    <ParameterChooser selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}
                        goBack={()=>setStep(0)}
                    />
                </form>
            ) : (
                <div className="flex flex-col gap-[15px] ">
                    {step === 2 && (
                        <h2>2.2 Revisa el formato de cada campo</h2>
                    )}
                    <p className="font-light text-ug-base">El usuario al registrarse deberá seleccionar una opción dentro de cada categoría. Asegúrate de que las opciones son correctas.</p>

                    <ParameterFields selectedOptions={clientParams.filter((x)=>selectedOptions.includes(x.alias))}
                        setSubStep={setSubStep} goNext={()=>{
                            setStep(3);
                        }}
                    />
                </div>
            )}

        </div>
    );
}