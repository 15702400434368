import React, {useEffect} from "react";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import {Outlet, useNavigate} from "react-router-dom";
import {useAuthStore} from "../../../../stores/authStore";
import {Tab, Tabs} from "@mui/material";

type AdminPages = "whatsapp" | "chats";

export default function AdminPage(): React.ReactElement {
    const navigate = useNavigate();

    const {loading, userData} = useAuthStore();

    useEffect(()=>{
        if (!loading && !userData?.admin) {
            window.location.href = "/";
        }
    }, [loading, userData]);

    function changeAdminPage(page: AdminPages): void {
        navigate(`/admin/${page}`);
    }

    function isCurrentPage(): AdminPages {
        // Get page from location
        const pageFromLocation = window.location.pathname.split("/");

        if (pageFromLocation.length === 2) {
            return "whatsapp";
        }

        return pageFromLocation.pop() as AdminPages;
    }

    return (
        <PageContainer className="px-8 overflow-x-hidden h-max flex flex-col gap-8 items-start">
            <Tabs onChange={(e, v)=>{
                changeAdminPage(v as AdminPages);
            }}
            value={isCurrentPage()}
            >
                <Tab label="Whatsapp" value="whatsapp"/>
                <Tab label="Chats" value="chats"/>
            </Tabs>
            <Outlet/>
        </PageContainer>
    );
}