import {SxProps, TextField, TextFieldProps, TextFieldVariants} from "@mui/material";
import React, {forwardRef} from "react";
import {twMerge} from "tailwind-merge";

type UnigowInputProps = {
    variant?: TextFieldVariants;
} & Omit<TextFieldProps, "variant"> & {
    formControlSx?: SxProps,
};

const UnigowInput = forwardRef<HTMLInputElement, UnigowInputProps>(
    ({className, ...inputProps}, ref) => {
        return (
            <TextField {...inputProps} className={twMerge("text-gray-cool-400 text-ug-lg font-light rounded-lg", className)} ref={ref} size="small" InputProps={{...inputProps.InputProps, sx:{borderRadius:"0.5rem", fontWeight:"light"}}}/>
        );
    }
);

export default UnigowInput;