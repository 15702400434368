import {FullGroup} from "@unigow/types/group";

export function groupIsIncomplete(group: FullGroup): boolean {
    return group.pendingFields.length > 0;
}

export function thereIsIncompleteGroup(groups?: FullGroup[]): boolean {
    if (!groups) return false;

    return groups.some(groupIsIncomplete);
}