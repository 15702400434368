import React, {Fragment} from "react";
import {useQuery} from "react-query";
import {WhatsappChatMin} from "../../../../../types/chat";
import {api} from "../../../../../apis/requests";
import {useAuthStore} from "../../../../../stores/authStore";
import ChatsList from "../../../../../components/ChatsList/ChatsList";
import {useNavigate} from "react-router-dom";

export default function AdminWhatsapps(): React.ReactElement {
    const {userData} = useAuthStore();
    const navigate = useNavigate();

    const {data:userChats = [], isLoading, isSuccess} = useQuery(["chats", userData], async()=>{
        const res = await api.get<WhatsappChatMin[]>("chats/conversations");

        return res.sort((a, b)=>{
            if (!a.lastMessage?.sentDate || !b.lastMessage?.sentDate) return 0;
            return new Date(b.lastMessage.sentDate)?.getTime() - new Date(a.lastMessage.sentDate)?.getTime();
        });
    }, {
        enabled:!!userData && userData.admin,
        refetchOnWindowFocus:true,
        refetchInterval:5 * 60 * 1000
    });

    return (
        <Fragment>
            <div className="flex flex-col gap-3 items-start w-full">
                <h1>Whatsapps de usuarios</h1>
            </div>
            <div className="max-w-4xl w-full">
                <ChatsList type="whatsapp" chats={userChats} loading={isLoading || !isSuccess}
                    goToChatPage={(_id: string)=>navigate(`/admin/whatsapp/${_id}`)}
                />
            </div>
        </Fragment>
    );
}