import React, {Children, useEffect} from "react";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import {useQuery} from "react-query";
import {api} from "../../../../apis/requests";
import {Award, Withdrawal} from "../../../../types/award";
import UnigowButton from "../../../../components/UnigowButton/UnigowButton";
import {ReactComponent as Unicoin} from "@unigow/assets/unicoin.svg";
import {toast} from "react-toastify";
import {HttpError} from "../../../../types/error";
import CardsHistory from "./components/CardsHistory";
import * as Sentry from "@sentry/react";
import {useDashboardStore} from "../../../../stores/dashboard/dashboardStore";
import {Skeleton} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function Awards(): React.ReactElement {
    // MISCELLANEOUS
    const navigate = useNavigate();

    // STORES
    const {balance, setBalance, clientInfo} = useDashboardStore();

    // QUERIES
    const {refetch:updateBalance} = useQuery("balance", async()=>{
        return api.get<{netAmount: number}>("movements");
    }, {onSuccess:(data)=>{
        setBalance(data.netAmount);
    }});

    const {data, isLoading} = useQuery("awards", async()=>{
        return api.get<Award[]>("awards");
    });

    // FUNCTIONS
    async function redeemAward(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, awardId: string, price: number): Promise<void> {
        e.preventDefault();

        if (!balance || balance < price) {
            toast.error("No tienes suficientes Unicoins para canjear esta recompensa");
            return;
        }

        const button = e.currentTarget;

        button.disabled = true;
        try {
            await api.post<{award: string}, Withdrawal>("withdrawals", {award:awardId});
            await updateBalance();
            toast.success("Recompensa canjeada con éxito");
        } catch (err) {
            Sentry.captureException(err);
            const error = err as HttpError;
            switch (error.status) {
                case 422:
                    toast.error("No tienes suficientes Unicoins para canjear esta recompensa");
                    break;
                default:
                    toast.error("Ha ocurrido un error al canjear la recompensa");
                    break;
            }
        }

        button.disabled = false;
    }

    // EFFECTS
    useEffect(()=>{
        if (!clientInfo) return;

        if (!clientInfo.incentives) navigate("/chats");
    }, [clientInfo, navigate]);

    return (
        <PageContainer className="gap-[42px] px-4 items-start">
            <div className="flex flex-col gap-3 items-start w-full">
                <h1>Canjea tus Unicoins por premios</h1>
                <p className="text-ug-lg">Acumula tus Unicoins mientras ayudas a estudiantes contándoles tu experiencia. Cuando tengas suficientes podrás canjearlas. </p>
            </div>
            {!isLoading ? (
                <ul className="flex flex-wrap mr-4 w-full gap-10">
                    {data?.map((award)=>(
                        <li key={award._id} className="w-full lg:w-[460px] bg-white flex p-4 pr-6 rounded-[10px] flex-col lg:flex-row gap-4">
                            <div className="w-[50px] h-[50px] lg:w-1/3 lg:h-[130px] flex-shrink-0">
                                <img className="max-w-full h-full rounded-[5px]" src={award.imageUrl} alt={award.name}/>
                            </div>
                            <div className="flex flex-col justify-center gap-3 w-full">
                                <div className="flex flex-col gap-2">
                                    <h3 className="text-ug-lg font-semibold">{award.name}</h3>
                                    <p className="leading-[121%]">{award.description}</p>
                                </div>
                                <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-2">
                                    <div className="flex items-center gap-4 text-ug-lg ">
                                        <p className="font-semibold leading-[133%]">{award.price}</p>
                                        <Unicoin width={30} height={30}/>
                                    </div>
                                    <div>
                                        <UnigowButton type="button" variant="secondary" onClick={(e)=>{
                                            void redeemAward(e, award._id, award.price);
                                        }}
                                        >Canjear Recompensa
                                        </UnigowButton>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
            // Repeat the skeleton 3 times
                <div className="flex flex-wrap mr-4 w-full gap-10">
                    {Children.toArray(Array(3).fill(
                        <div className="w-[460px] bg-white flex p-4 pr-6 rounded-[10px] flex-col lg:flex-row gap-4">
                            <div className="w-[50px] h-[50px] lg:w-1/3 lg:h-[130px] flex-shrink-0">
                                <Skeleton variant="rounded" className="w-[50px] h-[50px] lg:w-full lg:h-[130px]"/>
                            </div>
                            <div className="flex flex-col justify-center gap-3 w-full">
                                <div className="flex flex-col gap-2">
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                </div>
                                <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-2">
                                    <div className="flex items-center gap-4 text-ug-lg ">
                                        <Skeleton variant="text" width="5ch"/>
                                    </div>
                                    <div>
                                        <UnigowButton type="button" variant="secondary"><Skeleton variant="text" width="18ch"/>
                                        </UnigowButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <CardsHistory/>
        </PageContainer>
    );
}