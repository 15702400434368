import React, {useEffect, useState} from "react";
import {ReactComponent as UnigowLogo} from "../../assets/unigow-black.svg";
import {useNavigate, useSearchParams} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import {useAuthStore} from "../../stores/authStore";
import {RegisterForm} from "./components/RegisterForm";
import {PasswordForm} from "./components/PasswordForm";
import {api} from "@unigow/apis/requests";

interface RegisterProps {
    userType: "manager" | "orientator"
}

function Register({userType}: RegisterProps): React.ReactElement {
    // MISCELLANEOUS
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // STORES
    const {userData} = useAuthStore();

    // STATES
    const [client, setClient] = useState("");
    const [masterPassword, setMasterPassword] = useState("");
    const [progress, setProgress] = useState(5);

    // EFFECTS
    useEffect(()=>{
        if (userData) {
            if (userType === "manager") {
                setProgress(100);

                // Wait half a second
                setTimeout(()=>{
                    navigate("/iframes");
                }, 500);
            } else {
                setProgress(66);
                if (searchParams.get("group")) {
                    void api.post(`groups/${searchParams.get("group")}/autoinvite`, {});
                }

                // Wait half a second
                setTimeout(()=>{
                    navigate("/account");
                }, 500);
            }
        }
    }, [userData, navigate, userType, searchParams]);

    useEffect(()=>{
        if (client) setProgress(33);
    }, [client]);

    return (
        <div className="absolute inset-0 bg-gradient-to-b from-black from-[65%] to-primary-500">
            <div className="md:absolute max-h-[85%] md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 bg-white lg:w-1/3 md:w-2/3 w-full md:rounded-2xl py-8 lg:px-28 flex flex-col gap-4 shadow-ug-xl items-center overflow-y-auto">
                <div className="flex justify-center">
                    <UnigowLogo />
                </div>
                <div className="flex justify-center">
                    <h2>Regístrate como {userType === "manager" ? "Administrador" : "Embajador"}</h2>
                </div>
                <div className="w-full">
                    <LinearProgress value={progress} variant="determinate"
                        className="h-[6px] rounded-full w-full"
                        sx={{
                            background: "#E0EDFF",
                            "> span": {background: "linear-gradient(107deg, #8CD2FD 0%, #394DE7 33.85%, #0028C6 58.33%, #6AC0F4 100%)", borderRadius:"9999px"}
                        }}
                    />
                </div>
                {client === "" ? (
                    <PasswordForm setMasterPassword={setMasterPassword} setTargetClient={setClient} type={userType}/>
                ) : userType === "orientator" ? (
                    <RegisterForm type={userType} masterPassword={masterPassword} client={client}/>
                ) : (
                    <RegisterForm type="manager" masterPassword={masterPassword} client={client}/>
                )}
            </div>
        </div>
    );
}

export default Register;