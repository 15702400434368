import {UsersIcon} from "@heroicons/react/24/outline";
import React, {LiHTMLAttributes} from "react";
import {twMerge} from "tailwind-merge";
import {getCookie} from "../../../helpers/localstorage";

interface SidebarItemProps extends LiHTMLAttributes<HTMLLIElement> {
    folded?: boolean;
    Icon: React.FC<Parameters<typeof UsersIcon>[0]> | React.FC<React.SVGProps<SVGSVGElement>>
    label: string;
    active?: boolean;
    secondary?: boolean;
    newFeature?: boolean;
}

function SidebarItem({folded, Icon, label, active, className, secondary, newFeature, ...liProps}: SidebarItemProps): React.ReactElement {
    // Search for features array in cookie
    const rawFeatures = getCookie("viewedFeatures");
    const features = rawFeatures ? JSON.parse(rawFeatures) as string[] : [];

    return (
        <li {...liProps} className={twMerge("flex items-center rounded-full cursor-pointer gap-2 px-2 py-1",
            active ? "bg-gradient-to-r from-secondary-blue-500 via-primary-500 to-secondary-blue-500 text-white" : "text-primary-500",
            folded ? "p-1" : secondary ? "ml-2" : "", className)}
        >
            <Icon width={25} height={25} className={active ? "text-white" : "text-gray-cool-300"}/>
            {!folded && (
                <p className={twMerge("text-ug-lg font-semibold", secondary ? "text-ug-base" : "")}>{label}</p>
            )}
            {newFeature && !folded && !features.includes(label) && !active && (
                <div className="border-black border-solid border rounded-full text-[9px] text-black px-1 py-[2px] font-semibold flex items-center">
                    New
                </div>
            )}
        </li>
    );
}

export default SidebarItem;