import {FormControl, InputLabel, Select, SelectProps, SxProps} from "@mui/material";
import React from "react";
import {twMerge} from "tailwind-merge";

type UnigowSelectProps = SelectProps<string> & {
    formControlSx?: SxProps
};

export default function UnigowSelect({className, children, formControlSx, ...inputProps}: UnigowSelectProps): React.ReactElement {
    return (
        <FormControl variant="outlined" size="small" className={twMerge("text-gray-cool-400 text-ug-lg rounded-lg", className)} sx={formControlSx}>
            <InputLabel>{inputProps.label}</InputLabel>
            <Select {...inputProps}  className={twMerge("text-gray-cool-400 text-ug-lg font-light rounded-lg")}>
                {children}
            </Select>
        </FormControl>
    );
}