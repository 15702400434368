import React, {Children, useState} from "react";
import UnigowButton from "../../../../../components/UnigowButton/UnigowButton";
import {useAnswersStore} from "../stores/answersStore";
import {Alert, IconButton, Snackbar} from "@mui/material";
import {Close} from "@mui/icons-material";
import {ReactComponent as ClipboardCopy} from "@unigow/assets/clipboardCopy.svg";

export function MentorAnswers(): React.ReactElement {
    const {answers, setChosenAnswer, removeAnswer} = useAnswersStore();

    const [open, setOpen] = useState(false);

    return (
        <div className="border-gray-cool-300 border-2 rounded-[20px] border-solid flex-grow">
            <div className="border-0 border-b-2 border-solid border-gray-cool-300 text-center py-5 flex justify-center">
                <h3 className="flex items-center gap-2">Mis respuestas guardadas</h3>
            </div>
            <ul className="flex flex-col gap-8 items-center py-4 h-[55vh] overflow-y-auto">
                {Children.toArray(answers.map((answer, i)=>(
                    <li className="bg-white flex justify-center w-10/12 rounded-lg relative pb-4">
                        <IconButton className="absolute -top-0 right-0" onClick={()=>{
                            removeAnswer(i);
                        }} size="small"
                        ><Close className="w-5 h-5"/>
                        </IconButton>
                        <p className="px-4 py-2">{answer}</p>
                        <UnigowButton variant="basic" className="absolute -bottom-2 py-0 border-black hover:bg-black hover:text-white transition-colors flex items-center text-xs px-2" onClick={()=>{
                            setOpen(true);
                            setChosenAnswer(answer);
                        }}
                        >Utilizar esta respuesta
                            <ClipboardCopy className="w-5 h-5 ml-1"/>
                        </UnigowButton>
                    </li>
                )))}
            </ul>
            <Snackbar open={open} autoHideDuration={3000} onClose={()=>setOpen(false)} anchorOrigin={{vertical:"bottom", horizontal:"left"}}>
                <Alert onClose={()=>setOpen(false)} severity="success" sx={{width:"100%"}}>Respuesta copiada al chat</Alert>
            </Snackbar>
        </div>
    );
}