import React, {HTMLAttributes} from "react";
import {twMerge} from "tailwind-merge";

interface PageContainerProps extends HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode
}

export default function PageContainer({children, className, ...divProps}: PageContainerProps): React.ReactElement {
    return (
        <div {...divProps} className={twMerge("flex flex-col items-center px-4 lg:px-8 py-6", className)}>
            {children}
        </div>
    );
}