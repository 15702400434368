import React, {useState, FormEvent} from "react";
import {IconButton, MenuItem} from "@mui/material";
import {DeleteOutlineOutlined, Visibility, VisibilityOff} from "@mui/icons-material";
import {toast} from "react-toastify";
import {useChatsPluginStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/chatsPlugin.store";
import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";
import UnigowSelect from "@unigow/components/UnigowSelect/UnigowSelect";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {ChatsPluginAPI} from "@unigow/apis/iframe";
import {UpdateChatsPluginReq} from "@unigow/unigow-types";

function Step3(): React.ReactElement {
    // MISCELLANEOUS
    const {t} = useCustomLocale();

    // STORES
    const {setStep, step, pluginData, modifyPluginData} = useChatsPluginStore();

    // STATES
    const [filterParams, setFilterParams] = useState(pluginData?.settings.filterParams);
    const [visibleParams, setVisibleParams] = useState(pluginData?.settings.visibleParams?.length === 0 ?
        pluginData.settings.mentorParams : pluginData?.settings.visibleParams);

    // FUNCTIONS
    async function changeFilterParams(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        if (!pluginData) return;

        const updateIframe: UpdateChatsPluginReq = {
            settings:{
                filterParams:filterParams,
                visibleParams:visibleParams
            }
        };

        const response = await ChatsPluginAPI.updateChatsPlugin({pluginId:pluginData._id.toString(), data:updateIframe});

        if (response) {
            modifyPluginData("settings", response.settings);
            setStep(4);
        }
    }

    function addToFilterParams(param: string): void {
        // Add only if it's not already in the array
        if (!filterParams?.includes(param)) {
            setFilterParams((prev = [])=>[...prev, param]);

            // Add also to visible params if it's not there
            if (!visibleParams?.includes(param)) {
                setVisibleParams((prev = [])=>[...prev, param]);
            }
        }
    }

    function removeFromFilterParams(param: string): void {
        setFilterParams((prev = [])=>prev.filter((x)=>x !== param));
    }

    function addToVisibleParams(param: string): void {
        // Add only if it's not already in the array
        if (!visibleParams?.includes(param)) {
            setVisibleParams((prev = [])=>[...prev, param]);
        }
    }

    function removeFromVisibleParams(param: string): void {
        if (param === pluginData?.settings.mainField) {
            toast.error("No puedes ocultar el campo principal");
            return;
        }

        setVisibleParams((prev = [])=>prev.filter((x)=>x !== param));

        // Remove from filter params if it's there
        setFilterParams((prev = [])=>prev.filter((x)=>x !== param));
    }

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-[10px]">
                {step === 3 && (
                    <h2>3. Define los filtros que verán los usuarios</h2>
                )}
                <p className="font-light text-ug-base">Elige que categorías de los embajadores podrán filtrar los usuarios al realizar su búsqueda. <span className="text-primary-500">Recomendado: 2 filtros.</span></p>

            </div>
            <form className="flex flex-col gap-[15px]" onSubmit={changeFilterParams}>
                {pluginData?.settings.mentorParams?.map((param)=>(
                    <li className="flex gap-[10px] xl:items-center flex-col xl:flex-row lg:justify-between" key={param}>
                        <div className="flex gap-4 shrink-0">
                            {visibleParams?.includes(param) ? (
                                <IconButton className="p-0" onClick={()=>removeFromVisibleParams(param)}>
                                    <Visibility className="text-gray-cool-400"/>
                                </IconButton>
                            ) : (
                                <IconButton className="p-0" onClick={()=>addToVisibleParams(param)}>
                                    <VisibilityOff className="text-gray-cool-400"/>
                                </IconButton>
                            )}
                            <p className="flex-shrink-0 font-semibold text-ug-base">{param === "main" ? pluginData.settings.mainField : t(param)}</p>
                        </div>
                        {filterParams?.includes(param) ? (
                            <div className="flex gap-1 w-full justify-end">
                                <UnigowSelect disabled className="rounded-full w-full max-w-[300px]" label={`Filtrar por ${t(param)}`} formControlSx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "9999px!important"
                                    }
                                }}
                                >
                                    <MenuItem disabled value="">Elige {param === "main" ? pluginData.settings.mainField : t(param)}</MenuItem>
                                </UnigowSelect>
                                <IconButton onClick={()=>removeFromFilterParams(param)}>
                                    <DeleteOutlineOutlined color="error"/>
                                </IconButton>
                            </div>
                        ) : (
                            <UnigowButton variant="primary" onClick={()=>addToFilterParams(param)}>Convertir en filtro
                            </UnigowButton>
                        )}
                    </li>
                ))}
                <div className="flex gap-[10px] flex-col lg:flex-row">
                    <UnigowButton variant="basic" onClick={()=>setStep(0)}>Cancelar</UnigowButton>
                    <UnigowButton variant="secondary" type="submit">Continuar al siguiente paso</UnigowButton>
                </div>
            </form>
        </div>
    );
}

export default Step3;