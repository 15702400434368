import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";
import {DisplayGroup} from "@unigow/types/group";
import React, {Children, SetStateAction} from "react";

interface EditableCardBottomProps {
    modifyField?: (field: string, value: string)=> void,
    setDescription?: (v: SetStateAction<string>)=> void,
    timetable?: Record<string, {start: string, end: string}[]>
}

export default function CardBottom({fields, description, modifyField, setDescription, timetable}: EditableCardBottomProps & Partial<Pick<DisplayGroup, "fields" | "description">>): React.ReactElement {
    const {t} = useCustomLocale();

    const chipParams: {
        name: string,
        value: string
    }[] = [];

    const defaultParams: {
        name: string,
        value: string,
    }[] = [];

    (fields?.filter((x)=>x.name !== "") || []).forEach((x)=>{
        if (x.type === "shorttext") {
            chipParams.push({
                name: x.name,
                value: x.value
            });
        } else {
            defaultParams.push({
                name: x.name,
                value: x.value
            });
        }
    });

    return (
        <div className="flex flex-col mx-4 gap-3 justify-between h-full">
            {/* Propiedades generales */}
            <div className="flex flex-col gap-3">
                {chipParams.length > 0 && (
                    <ul className="flex flex-wrap gap-1">
                        {Children.toArray(chipParams.map((param)=>{
                            if (modifyField) {
                                // INPUT THAT LOOKS LIKE TEXT BUT IS EDITABLE
                                return (
                                    <li className="bg-gray-cool-100 rounded-full text-ug-base px-2">
                                        <input
                                            required
                                            className="bg-transparent border-none focus:outline-none"
                                            value={param.value}
                                            onChange={(e)=>modifyField(param.name, e.target.value)}
                                            placeholder={`Escribe el valor de ${param.name}`}
                                        />
                                    </li>
                                );
                            }
                            return (
                                <li className="bg-gray-cool-100 rounded-full text-ug-base px-2">{param.value}</li>
                            );
                        }))}
                    </ul>
                )}

                <ul className="flex flex-col gap-3">
                    {description && (
                        <li className="flex flex-col gap-1">
                            <p className="uppercase text-ug-sm text-gray-cool-500">Descripción del grupo</p>
                            {setDescription ? (
                                <input
                                    required
                                    className="bg-transparent border-none focus:outline-none"
                                    value={description}
                                    onChange={(e)=>setDescription(e.target.value)}
                                    placeholder={"Escribe la descripción de este grupo"}
                                />
                            ) : (
                                <p className="text-ug-base">{description}</p>
                            )}
                        </li>
                    )}
                    {Children.toArray(defaultParams.map((param)=>{
                        if (modifyField) {
                            return (
                                <li className="flex flex-col gap-1">
                                    <p className="uppercase text-ug-sm text-gray-cool-500">{param.name}</p>
                                    <input
                                        required
                                        className="bg-transparent border-none focus:outline-none"
                                        value={param.value}
                                        onChange={(e)=>modifyField(param.name, e.target.value)}
                                        placeholder={`Escribe el valor de ${param.name}`}
                                    />
                                </li>
                            );
                        }
                        return (
                            (
                                <li className="flex flex-col gap-1">
                                    <p className="uppercase text-ug-sm text-gray-cool-500">{param.name}</p>
                                    <p className="text-ug-base">{param.value}</p>
                                </li>
                            )
                        );
                    }))}
                    {timetable && (
                        <li>
                            <p className="uppercase text-ug-sm text-gray-cool-500">Horario</p>
                            <ul>
                                {Children.toArray(Object.keys(timetable).map((day)=>{
                                    if (timetable[day].length === 0) return null;
                                    return (
                                        <li className="flex gap-2">
                                            <p>{t(day)}:</p>
                                            <ul className="flex flex-col text-sm mt-[2px]">
                                                {Children.toArray(timetable[day].map((slot)=>(
                                                    <li>
                                                        <p>{slot.start} - {slot.end}</p>
                                                    </li>
                                                )))}
                                            </ul>
                                        </li>
                                    );
                                }))}
                            </ul>
                        </li>
                    )}
                </ul >
            </div>
        </div>
    );
}