import {api} from "@unigow/apis/requests";
import ParamQuestions from "@unigow/components/ParamQuestions/ParamQuestions";
import {UnigowPhoneInput} from "@unigow/components/UnigowInput/UnigowPhoneInput";
import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";
import {userAnsweredQuestions} from "@unigow/helpers/chat";
import {validateField} from "@unigow/helpers/helper";
import {useAuthStore} from "@unigow/stores/authStore";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {useIframeStore} from "@unigow/stores/iframeStore";
import {ParameterType} from "@unigow/unigow-types";
import {isPhoneNumber} from "class-validator";
import React, {useState, FormEvent, FormHTMLAttributes, useEffect} from "react";
import {useQuery} from "react-query";
import {toast} from "react-toastify";

interface UserFormProps extends FormHTMLAttributes<HTMLFormElement> {
    SubmitButton: React.ReactNode;
    registerPage?: boolean;
    shadows?: boolean;
    requirePhone?: boolean;
    neededParams: string[];
}

export default function UserForm({SubmitButton, registerPage, shadows, requirePhone, neededParams, ...formProps}: UserFormProps): React.ReactElement {
    // STORES
    const {getRequiredParams} = useCustomLocale();
    const {userData, modifyUserData} = useAuthStore();
    const {iframeData} = useIframeStore();
    const {pluginInfo} = useDashboardStore();

    // STATES
    const [formData, setFormData] = useState<Record<string, string | string[]>>({});
    const [phone, setPhone] = useState("");

    const choosenIframe = iframeData ? iframeData : pluginInfo;
    const choosenParams = neededParams;
    const choosenClient = iframeData ? iframeData.client : pluginInfo?.client.toString();

    // QUERIES
    const {data:params = [], isLoading, isSuccess} = useQuery(["parameters", choosenIframe], async()=>{
        const url = choosenIframe ? `parameters/${choosenClient}/parameterInfo` : "parameters/parameterInfo";

        const auxParams = choosenIframe ? choosenParams :
            Object.keys(userData?.additionalInfo || {});

        const clientParams = await api.get<ParameterType[]>(url);

        return clientParams.filter((param)=>auxParams.includes(param.alias));
    }, {enabled:!!choosenIframe});

    // EFFECTS
    useEffect(()=>{
        if (userData) {
            if (userData.phone) {
                setPhone(userData.phone);
            }

            if (userData.additionalInfo) {
                setFormData(userData.additionalInfo);
            }
        }
    }, [userData]);

    // FUNCTIONS
    const handleFieldChange = (fieldName: string, value: string | string[] | undefined): void => {
        if (!value) {
            // Remove the field from the formData
            const newFormData = {...formData};
            delete newFormData[fieldName];
            setFormData(newFormData);
            return;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value
        }));
    };

    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        if (!userData) return;

        e.preventDefault();

        const createUserInfo: {
            info: Record<string, string | string[]>
        } = {
            info:formData
        };

        const unansweredQuestions = userAnsweredQuestions(getRequiredParams, userData.minor, choosenParams, formData);

        if (unansweredQuestions.length > 0) {
            const errorMsg = `Los siguientes campos no han sido rellenados: ${unansweredQuestions.map((x)=>params.find((p)=>p.alias === x)?.name).join(", ")}`;
            toast.error(errorMsg);
            return;
        }

        // Si se está en un iframe y el iframe exige que se ponga un número de teléfono, se comprueba que se haya puesto
        if ((requirePhone && (!phone || phone === "" || !isPhoneNumber(phone)))) {
            toast.error("El número de teléfono debe estar en formato internacional +34XXXXXXXXX");
            return;
        }

        createUserInfo.info.phone = phone.trim().replace(/ /g, "");

        // Search for all the elements in formData, search those whose param has property validation and
        // validate with regex the expressión in that field
        // Travel formData Record with key and value
        try {
            validateField(formData, params);
        } catch (err) {
            const error = err as Error;
            toast.error(error.message);
            return;
        }

        const res = await api.post<{info: Record<string, string | string[]>}, {info: Record<string, string>}>("userinfo", createUserInfo);

        if (res) {
            modifyUserData("phone", phone);
            modifyUserData("additionalInfo", res.info);
            toast.success("Cambios guardados con éxito");
        }
    }

    return (
        <form {...formProps} className={!shadows ? "flex flex-col gap-4" : "flex flex-col gap-4 shadow-md rounded-[10px] px-10 py-4"} onSubmit={handleSubmit}>
            <ParamQuestions className="flex flex-col gap-4" params={params} handleFieldChange={handleFieldChange} formData={formData}/>
            {!isLoading && registerPage && (requirePhone || pluginInfo) && (
                <div className="flex flex-col gap-[10px]">
                    <p className="text-ug-lg font-semibold">Teléfono<sup>*</sup></p>
                    <UnigowPhoneInput value={phone}
                        onChange={(v)=>{
                            setPhone(`+${v}`);
                        }}
                        inputProps={{required:true}}
                    />
                </div>
            )}
            {!isLoading && isSuccess && SubmitButton}
        </form>
    );
}