import {Close, Delete, Mic, PlayArrow, Send, Stop} from "@mui/icons-material";
import {IconButton, TextareaAutosize} from "@mui/material";
import {useTwilio} from "@unigow/contexts/TwilioContext";
import {useAnswersStore} from "@unigow/pages/Dashboard/pages/ChatsPage/stores/answersStore";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {CreatePinnedQuestion, TwilioChat} from "@unigow/types/chat";
import dayjs from "dayjs";
import React, {FormEvent, KeyboardEvent, useEffect, useRef, useState} from "react";
import {RecordRTCPromisesHandler} from "recordrtc";
import * as Sentry from "@sentry/react";
import {replaceStringVars} from "@unigow/helpers/helper";
import {useIframeStore} from "@unigow/stores/iframeStore";
import {useAuthStore} from "@unigow/stores/authStore";
import {useChatStore} from "@unigow/components/ChatRoom/store/chatStore";
import {twMerge} from "tailwind-merge";
import {toast} from "react-toastify";
import {api} from "@unigow/apis/requests";
import {UnigowHttpError} from "@unigow/types/error";
import {getPluginData} from "@unigow/helpers/iframe";
import {ChatsPluginType} from "@unigow/unigow-types";

interface ChatBottomProps {
    chatInfo: TwilioChat
}

export default function ChatBottom({chatInfo}: ChatBottomProps): React.ReactElement {
    const {conv, setCurrentMessage, currentMessage, isDashboard, questionToPin, setQuestionToPin} = useChatStore();

    const {twilioClient} = useTwilio();
    const {chosenAnswer, clearChosenAnswer} = useAnswersStore();
    const {iframeData} = useIframeStore();
    const {clientInfo} = useDashboardStore();
    const {userData} = useAuthStore();

    const [recorder, setRecorder] = useState<RecordRTCPromisesHandler>();
    const [stream, setStream] = useState<MediaStream>();
    const [recording, setRecording] = useState(false);
    const [duration, setDuration] = useState(0);
    const [fileUploaded, setFileUploaded] = useState<File>();

    const answerBox = useRef<HTMLTextAreaElement>(null);

    async function startRecording(): Promise<void> {
        const auxStream = await navigator.mediaDevices.getUserMedia({audio: true});
        setStream(auxStream);
        const auxRecorder = new RecordRTCPromisesHandler(auxStream, {
            type: "video"
        });
        setRecorder(auxRecorder);
        if (auxRecorder) {
            await auxRecorder.startRecording();
            setRecording(true);
        }
    }

    async function stopRecording(): Promise<void> {
        if (recorder) {
            await recorder.stopRecording();
            setRecording(false);
            const blob = await recorder.getBlob();
            const buffer = await blob.arrayBuffer();
            const audioContext = new (window.AudioContext)();
            await audioContext.decodeAudioData(buffer, (buf)=>{
                setDuration(buf.duration);
            });
            const file = new File([blob], `${dayjs()}.mp3`);
            setFileUploaded(file);
            stream?.getTracks()[0].stop();
        }
    }


    async function sendMessage(e: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLTextAreaElement>): Promise<void> {
        e.preventDefault();

        if (!conv || (!iframeData && !clientInfo)) return;

        const messageToSend = currentMessage.trim();
        setCurrentMessage("");

        if (chatInfo.type === "chat" && chatInfo.position === "orientator") {
            // Get time since last message not sent by the orientator
            const {lastMessage} = conv;

            if (lastMessage) {
                const lastMessageTime = lastMessage?.dateCreated;
                const time = lastMessageTime ? dayjs().diff(dayjs(lastMessageTime), "seconds") : 0;

                const clientName = iframeData ? iframeData.clientInfo.name : clientInfo?.name;

                if (window.dataLayer && time > 0) {
                    window.dataLayer.push({event:"ambassador_response_time", client:clientName, amount:time});
                }
            }
        }

        if (twilioClient) {
            if (questionToPin) {
                const pinnedQuestion: CreatePinnedQuestion = {
                    question: questionToPin,
                    answer: currentMessage
                };
                try {
                    await api.post<CreatePinnedQuestion, {status: string}>(`groups/${chatInfo._id}/pins`, pinnedQuestion);

                    setQuestionToPin(undefined);
                } catch (err) {
                    const error = err as UnigowHttpError;

                    if (error.errorCode === "group/max-pins") {
                        if (!confirm("Se ha alcanzado el límite de preguntas destacadas. ¿Quieres mandar la respuesta en modo normal?")) {
                            setQuestionToPin(undefined);
                            return;
                        }
                    }
                }
            }

            try {
                if (messageToSend !== "") {
                    const preparedMessage = conv.prepareMessage();
                    preparedMessage?.setBody(messageToSend);
                    preparedMessage.setAttributes({
                        displayName: `${userData?.name}`,
                        mod: !!isDashboard,
                        answersTo: questionToPin || null
                    });
                    await preparedMessage?.build().send();
                    setCurrentMessage("");

                } else if (fileUploaded) {
                    const preparedMessage = conv.prepareMessage();
                    preparedMessage?.setBody("Cargando Audio...");
                    const formData = new FormData();
                    formData.append(fileUploaded?.name, fileUploaded);
                    preparedMessage?.addMedia(formData);
                    await preparedMessage?.build().send();
                    setFileUploaded(undefined);
                }

            } catch (err) {
                Sentry.captureException(err);
            }
        }
    }

    useEffect(()=>{
        if (chosenAnswer) {
            setCurrentMessage(chosenAnswer);
            clearChosenAnswer();

            if (answerBox.current) {
                answerBox.current.focus();
            }
        }
    }, [chosenAnswer, clearChosenAnswer, setCurrentMessage]);

    useEffect(()=>{
        if (!iframeData) return;

        const plugindata = getPluginData(chatInfo.plugin, iframeData.pluginData);

        if (!plugindata) return;

        // El mensaje inicial solo funciona en chats individuales
        if (chatInfo.type !== "chat" || !conv) return;

        const chatPluginData = plugindata as ChatsPluginType;

        const isFirstMessage = !conv.lastMessage;

        if (isFirstMessage) {
            const replacements = {
                orientador:chatInfo.user.name || "",
                orientado:userData?.name || ""
            };
            setCurrentMessage(replaceStringVars(chatPluginData.style.initialMessage || "", replacements));
        }

        return ()=>{
            setCurrentMessage("");
        };
    }, [chatInfo, iframeData, userData, conv, setCurrentMessage]);

    useEffect(()=>{
        const answerInput = answerBox.current;

        if (!answerInput) return;

        if (questionToPin) {
            answerInput.focus();
        }
    }, [questionToPin]);

    return (
        <form className="flex gap-4 py-4 px-4 items-center" onSubmit={sendMessage}>
            <>
                {recording ? (
                    <div id="recording-icon" className="w-full bg-gray-200 border-0 rounded-lg py-3 focus:ring-1 focus:ring-gray-400">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                ) : (
                    <>
                        {fileUploaded ? (
                            <div className="flex w-full justify-center bg-gray-200 border-0 rounded-lg py-3 focus:ring-1 focus:ring-gray-400">
                                <div className="flex items-center justify-between w-full max-w-sm px-4">
                                    <IconButton onClick={()=>{
                                        setFileUploaded(undefined);
                                    }}
                                    >
                                        <Delete className="text-red-600" width="30px" height="30px"/>
                                    </IconButton>
                                    <p className="text-gray-400 text-ug-base">{Math.round(duration / 60).toLocaleString("es-ES", {minimumIntegerDigits:2})}:{Math.round(duration % 60).toLocaleString("es-ES", {minimumIntegerDigits:2})}</p>
                                    <div className="flex gap-4 items-center">
                                        <IconButton onClick={()=>{
                                            const audio = new Audio(URL.createObjectURL(fileUploaded));
                                            void audio.play();
                                        }}
                                        >
                                            <PlayArrow className="text-primary-500"/>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <TextareaAutosize ref={answerBox} disabled={fileUploaded !== undefined} name="message" className={twMerge("w-full bg-gray-cool-100 rounded-xl border-none text-gray-cool-500 text-ug-lg", questionToPin ? "border-primary-500 border-2 border-solid animate-pulse" : "")}
                                value={currentMessage} placeholder="Escribe tu texto" onChange={(e)=>{
                                    if (!conv) return;
                                    setCurrentMessage(e.target.value);
                                    void conv.typing();
                                }}
                                maxRows={4}
                                onKeyDown={(e)=>{
                                    if (e.key === "Enter" && !e.shiftKey) {
                                        e.preventDefault();
                                        void sendMessage(e);
                                    }
                                }}
                            />
                        )}
                    </>
                )}

                {currentMessage.length > 0 || fileUploaded ? (
                    <IconButton type="submit" className="flex justify-center p-3 rounded-full border border-primary-500 bg-primary-500 text-white">
                        <Send/>
                    </IconButton>
                ) : questionToPin ? (
                    <IconButton className="flex justify-center p-3 rounded-full border border-primary-500 bg-primary-500 text-white" onClick={()=>{
                        toast.info(<b>Modo de respuesta destacada desactivado</b>);
                        setQuestionToPin(undefined);
                    }}
                    >
                        <Close/>
                    </IconButton>
                ) : (
                    <>
                        {recording ? (
                            <IconButton className="flex justify-center p-3 rounded-full border border-primary-500 bg-primary-500 text-white" onClick={stopRecording}>
                                <Stop/>
                            </IconButton>
                        ) : (
                            <IconButton className="flex justify-center p-3 rounded-full border border-primary-500 bg-primary-500 text-white" onClick={startRecording}>
                                <Mic className="text-primary"/>
                            </IconButton>
                        )}
                    </>
                )}
            </>
        </form>
    );
}