import {
    ArcElement, BarElement, CategoryScale, Chart as ChartJS, ChartData, ChartOptions, Filler,
    Legend, LinearScale, LineElement, PointElement, TimeScale, Title, Tooltip, ChartArea
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, {useRef} from "react";
import {Chart} from "react-chartjs-2";

import {useQuery} from "react-query";
import {api} from "../../../../../apis/requests";
import {Divider} from "@mui/material";
import {useDashboardStore} from "../../../../../stores/dashboard/dashboardStore";

ChartJS.register(ArcElement, Title, Tooltip, Filler, Legend, ChartDataLabels,
    TimeScale, LinearScale, CategoryScale, PointElement, LineElement, BarElement);

function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea): CanvasGradient {
    const colorStart = "#FFF";
    const colorEnd = "#109CF1";

    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(1, colorEnd);

    return gradient;
}

const options: ChartOptions = {
    scales:{
        y:{
            beginAtZero:true,
            grace:"20%"
        }
    },
    elements:{
        line:{
            tension:0.4
        }
    },
    hover:{intersect:false},
    plugins:{datalabels:{display:false}}
};

export function InteractionsGraph(): React.ReactElement {
    const {selectedClient} = useDashboardStore();
    const chartRef = useRef<ChartJS>(null);

    const {data:chartData = {datasets:[]}} = useQuery(["interaction-graph", selectedClient], async()=>{
        const chart = chartRef.current;

        if (!chart) {
            return;
        }

        if (selectedClient) {
            const response = await api.get<{interval: number, interactions: number}[]>(`logs/${selectedClient}/interactions`);
            let labels: string[] = [];

            let interactionsData: number[] = [];

            if (response.length === 12) {
                labels = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
                interactionsData = response.map((x)=>x.interactions);
            } else {
                const sortedDays = response.sort((x, b)=>{
                    return x.interval < b.interval ? 1 : -1;
                });
                labels = Array.from({length: sortedDays[0].interval}, (_, index) => `${index + 1}`); // Always generate labels for all months

                const interactionsByInterval = new Map(response.map((item) => [item.interval, item.interactions]));

                interactionsData = labels.map((monthIndex) => {
                    const interactions = interactionsByInterval.get(parseInt(monthIndex || "0")) || 0;
                    return interactions;
                });
            }
            const newData: ChartData<"line"> = {
                labels:labels,
                datasets: [
                    {
                        label: "Interacciones",
                        data: interactionsData,
                        fill:true,
                        borderColor:"#109CF1",
                        pointRadius:0,
                        borderWidth:1,
                        backgroundColor: createGradient(chart.ctx, chart.chartArea)
                    }
                ]
            };
            return newData;
        }
    });

    return (
        <>
            <div className="mb-4 flex justify-between lg:flex-row items-center flex-col">
                <h3>Interacciones mensuales</h3>
                {/* <div className="text-xs flex items-center gap-2">
                    <p>Rango: </p>
                    <FormControl variant="standard">
                        <Select value={range} onChange={(e)=>setRange(e.target.value)} sx={{fontSize:"0.75rem", color:"#109CF1"}}>
                            <MenuItem value="monthly">Este Mes</MenuItem>
                            <MenuItem value="anual">Este Año</MenuItem>
                        </Select>
                    </FormControl>
                </div> */}
            </div>
            <Divider className="w-full"/>
            <Chart type="line" data={chartData} ref={chartRef} options={options}/>
        </>
    );
}