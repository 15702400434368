import React, {FormEvent, useState, useEffect, Children} from "react";
import PageContainer from "@unigow/components/PageContainer/PageContainer";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {api} from "@unigow/apis/requests";
import {toast} from "react-toastify";
import {LoggedUser} from "@unigow/types/user";
// import OrientatorForm from "../../components/OrientatorForm";
import ImageUploader from "@unigow/components/ImageUploader/ImageUploader";
import PopupWindow from "@unigow/components/PopupWindow/PopupWindow";
import MandatoryProfilePic from "./components/MandatoryProfilePic";
import {isPhoneNumber} from "class-validator";
import {AddAPhoto} from "@mui/icons-material";
import {UnigowPhoneInput} from "@unigow/components/UnigowInput/UnigowPhoneInput";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {useAuthStore} from "@unigow/stores/authStore";
import {Autocomplete, Divider, MenuItem, Select} from "@mui/material";
import ClientApi from "@unigow/pages/Dashboard/pages/Profile/components/ClientApi";
import UserForm from "@unigow/pages/Iframe/pages/Form/UserForm";

function Profile(): React.ReactElement {
    // STORES
    const {clientInfo, selectedClient, availableChatPlugins, selectPlugin,
        selectedPlugin, selectClient, setPluginInfo, modifyClientInfo} = useDashboardStore();
    const {userData, modifyUserData} = useAuthStore();

    // STATES
    const [masterPass, setMasterPass] = useState("");
    const [confirmMasterPass, setConfirmMasterPass] = useState("");
    const [orientatorPass, setOrientatorPass] = useState("");
    const [confirmOrientatorPass, setConfirmOrientatorPass] = useState("");
    const [name, setName] = useState("");
    const [surnames, setSurnames] = useState("");
    const [profilePic, setProfilePic] = useState("");
    const [phone, setPhone] = useState("");
    const [privacy, setPrivacy] = useState("");
    const [logo, setLogo] = useState("");
    const [emails, setEmails] = useState<string[]>([]);

    // FUNCTIONS
    async function handleUserSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (phone !== "" && !isPhoneNumber(phone)) {
            toast.error("El número de teléfono debe estar en formato internacional +34XXXXXXXXX");
            return;
        }

        if (name === userData?.name && surnames === userData?.surnames && phone === userData.phone) return;

        const body: Record<string, string> = {
            name,
            surnames,
            phone: phone.trim().replace(/ /g, "")
        };

        if (profilePic) {
            body.profilePic = profilePic;
        }

        const res = await api.patch<Record<string, unknown>, LoggedUser>("users/updateProfile", body);

        if (res) {
            toast.success("Cambios guardados con éxito");
        }
    }

    async function handlePassSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!clientInfo?.permissions?.manager) return;

        const changes: Record<string, unknown> = {};

        if (masterPass === confirmMasterPass) {
            changes.masterPassword = masterPass;
        } else if (confirmMasterPass) {
            toast.error("Las claves maestras de administradores no coinciden");
            return;
        }

        if (orientatorPass === confirmOrientatorPass) {
            changes.orientatorPassword = orientatorPass;
        } else if (confirmOrientatorPass) {
            toast.error("Las claves maestras de embajadores no coinciden");
            return;
        }

        changes.privacy = privacy;

        if (emails.length > 0) {
            changes.notifyEmails = emails;
        }

        if (!changes.orientatorPassword && !changes.masterPassword && privacy === "") return;

        const res = await api.patch<Record<string, unknown>, {masterPassword: string, orientatorPassword: string}>(`clients/${selectedClient}`, changes);

        if (res) {
            toast.success("Cambios guardados con éxito");
        }
    }

    // EFFECTS
    useEffect(()=>{
        if (userData) {
            setName(userData.name);
            setSurnames(userData.surnames);
            if (userData.phone) {
                setPhone(userData.phone);
            }
        }
    }, [userData]);

    useEffect(()=>{
        if (!clientInfo) return;

        if (clientInfo.permissions?.manager) {
            setMasterPass(clientInfo.masterPassword || "");
            setConfirmMasterPass("");
            setOrientatorPass(clientInfo.orientatorPassword || "");
            setConfirmOrientatorPass("");
            setEmails(clientInfo.notifyEmails || []);
        }

        setPrivacy(clientInfo.privacy || "");
    }, [clientInfo]);

    useEffect(()=>{
        async function uploadPic(): Promise<void> {
            if (profilePic && profilePic !== "") {
                const body = {
                    profilePic: profilePic
                };

                const res = await api.patch<Record<string, unknown>, LoggedUser>("users/updateProfile", body);

                if (res) {
                    modifyUserData("profilePic", profilePic);
                }
            }
        }
        void uploadPic();
    }, [profilePic, modifyUserData]);

    useEffect(()=>{
        if (!clientInfo?.permissions?.manager) return;
        async function uploadPic(): Promise<void> {
            if (logo && logo !== "") {
                const body = {
                    logo: logo
                };

                await api.patch<Record<string, unknown>, {logo: string}>(`clients/${selectedClient}`, body);
                modifyClientInfo("logo", logo);
                setLogo("");
            }
        }
        void uploadPic();
    }, [logo, selectedClient, clientInfo, modifyClientInfo]);

    return (
        <PageContainer className="px-4 items-start" style={{alignItems:"unset"}}>
            {clientInfo?.permissions?.orientator && (
                <PopupWindow title="Foto de perfil" open={!!userData && !userData.profilePic}>
                    <MandatoryProfilePic profPicSetter={setProfilePic}/>
                </PopupWindow>
            )}

            <div className="flex flex-col gap-[35px] pb-8 w-full">
                <div className="flex flex-col gap-3 items-start w-full">
                    <h1>Mi Perfil</h1>
                </div>
                {userData && (
                    <form className="bg-white p-[30px] gap-[30px] flex flex-col lg:w-5/6 rounded-[10px]" onSubmit={handleUserSubmit}>
                        {clientInfo?.permissions?.manager && (<h2 className="text-primary-500">Información del Administrador</h2>)}
                        <ImageUploader variableSetter={setProfilePic} buttonText="Guardar foto de perfil"
                            firebaseFolder="profPics" firebaseSufix="profPic" aspectRatio={1} rounded
                            name={userData?.email}
                        >
                            <div className="cursor-pointer overflow-hidden w-fit">
                                {userData?.profilePic ? (
                                    <img className="w-[100px] h-[100px] rounded-full" src={userData?.profilePic} alt=""/>
                                ) : (
                                    <div className="border border-solid border-gray-cool-300 h-[100px] w-[100px] rounded-full flex items-center justify-center">
                                        <AddAPhoto className="text-gray-cool-400 w-7 h-7"/>
                                    </div>
                                )}
                            </div>
                        </ImageUploader>
                        <div className="lg:grid lg:grid-cols-2 gap-4 lg:gap-8 flex-col flex">
                            <div className="flex flex-col gap-[10px]">
                                <p className="text-ug-base font-semibold">Nombre</p>
                                <UnigowInput required placeholder="Nombre" value={name} onChange={(e)=>{
                                    setName(e.target.value);
                                }}
                                />
                            </div>
                            <div className="flex flex-col gap-[10px]">
                                <p className="text-ug-base font-semibold">Apellidos</p>
                                <UnigowInput required placeholder="Apellidos" value={surnames} onChange={(e)=>{
                                    setSurnames(e.target.value);
                                }}
                                />
                            </div>
                            <div className="flex flex-col gap-[10px]">
                                <p className="text-ug-base font-semibold">Email</p>
                                <UnigowInput  inputProps={
                                    {readOnly:true}
                                } type="email" placeholder="¿Cuál es tu email?" value={userData?.email}
                                />
                            </div>
                            <div className="flex flex-col gap-[10px]">
                                <p className="text-ug-base font-semibold">Teléfono</p>
                                <UnigowPhoneInput value={phone}
                                    onChange={(v)=>{
                                        setPhone(`+${v}`);
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <UnigowButton type="submit" variant="secondary">Guardar Cambios</UnigowButton>
                        </div>
                    </form>
                )}
                {clientInfo && clientInfo?.permissions?.manager && (
                    <div className="flex flex-col bg-white p-[30px] lg:w-5/6 rounded-[10px] gap-4">
                        <form className=" gap-[30px] flex flex-col" onSubmit={handlePassSubmit}>
                            <h2 className="text-primary-500">Información de la Organización</h2>
                            <div className="flex flex-col gap-[10px]">
                                <p className="text-ug-base font-semibold">Logotipo de la Organización</p>
                                <ImageUploader variableSetter={setLogo} buttonText="Guardar logo"
                                    firebaseFolder="logos" firebaseSufix="logo"
                                    name={clientInfo._id.toString()}
                                >
                                    <div className="cursor-pointer overflow-hidden w-fit">
                                        {clientInfo.logo ? (
                                            <img className="h-[100px] w-auto" src={clientInfo.logo} alt=""/>
                                        ) : (
                                            <div className="border border-solid border-gray-cool-300 h-[62px] w-[180px] px-2 flex items-center justify-center">
                                                <AddAPhoto className="text-gray-cool-400 w-7 h-7"/>
                                            </div>
                                        )}
                                    </div>
                                </ImageUploader>
                            </div>
                            <div className="lg:grid lg:grid-cols-2 gap-2 lg:gap-8 flex-col flex">
                                <div className="flex flex-col gap-[10px]">
                                    <p className="text-ug-base font-semibold">Clave Maestra Administradores</p>
                                    <UnigowInput value={masterPass} onChange={(e)=>setMasterPass(e.target.value)}/>
                                </div>
                                <div className="flex flex-col gap-[10px]">
                                    <p className="text-ug-base font-semibold">Confirmar Clave Maestra Administadores</p>
                                    <UnigowInput value={confirmMasterPass} onChange={(e)=>setConfirmMasterPass(e.target.value)}/>
                                </div>
                            </div>
                            <div className="lg:grid lg:grid-cols-2 gap-2 lg:gap-8 flex-col flex">
                                <div className="flex flex-col gap-[10px] ">
                                    <p className="text-ug-base font-semibold">Clave Maestra Embajadores</p>
                                    <UnigowInput value={orientatorPass} onChange={(e)=>setOrientatorPass(e.target.value)}/>
                                </div>
                                <div className="flex flex-col gap-[10px] ">
                                    <p className="text-ug-base font-semibold">Confirmar Clave Maestra Embajadores</p>
                                    <UnigowInput value={confirmOrientatorPass} onChange={(e)=>setConfirmOrientatorPass(e.target.value)}/>
                                </div>
                            </div>
                            <div className="w-full flex flex-col gap-[10px]">
                                <p className="text-ug-base font-semibold">Link a la Política de Privacidad</p>
                                <UnigowInput className="" value={privacy} onChange={(e)=>setPrivacy(e.target.value)}/>
                            </div>
                            <div className="w-full flex flex-col gap-[10px]">
                                <p className="text-ug-base font-semibold">Correos de contacto</p>
                                <Autocomplete onChange={(e, v)=>setEmails(v)} value={emails} fullWidth freeSolo multiple autoSelect
                                    renderInput={(params)=>(
                                        <UnigowInput type="email" variant="outlined" {...params} placeholder="Añade correos para que reciban notificaciones"/>
                                    )}
                                    options={[]}
                                />
                            </div>
                            <div>
                                <UnigowButton type="submit" variant="secondary">Guardar Cambios</UnigowButton>
                            </div>
                        </form>
                        <Divider/>
                        <ClientApi/>
                    </div>
                )}
                {!!selectedPlugin && (
                    <div className="bg-white p-[30px] gap-[30px] flex flex-col lg:w-5/6 rounded-[10px]">
                        <h2 className="text-primary-500">Información como embajador</h2>
                        {availableChatPlugins.length > 1 && (
                            <div className="flex flex-col gap-2">
                                <p className="font-semibold">Perfil seleccionado</p>
                                <Select value={selectedPlugin} onChange={(e)=>{
                                    selectPlugin(e.target.value);
                                    const foundIframe = availableChatPlugins.find((plugin)=>plugin._id.toString() === e.target.value);

                                    if (!foundIframe) return;

                                    setPluginInfo(foundIframe);

                                    if (foundIframe.client.toString() !== selectedPlugin) {
                                        selectClient(foundIframe?.client.toString());
                                    }
                                }} size="small" className="max-w-[30ch]"
                                >
                                    {Children.toArray(availableChatPlugins.map((plugin)=>(
                                        <MenuItem value={plugin._id.toString()}>{plugin.alias}</MenuItem>
                                    )))}
                                </Select>
                            </div>
                        )}
                        <UserForm neededParams={availableChatPlugins.map(
                            (plugin) => plugin.settings.mentorParams).flat().filter(
                            (value, index, self) => self.indexOf(value) === index)}
                        SubmitButton={(
                            <div>
                                <UnigowButton type="submit" variant="secondary">Guardar Cambios</UnigowButton>
                            </div>
                        )}
                        />
                    </div>
                )}
            </div>
        </PageContainer>
    );
}

export default Profile;