import React, {HTMLAttributes} from "react";
import UnigowInput from "../UnigowInput/UnigowInput";
import UnigowMultipleSelect from "../UnigowMultipleSelect/UnigowMultipleSelect";
import {sortText} from "../../helpers/helper";
import {Checkbox, MenuItem} from "@mui/material";
import UnigowSelect from "../UnigowSelect/UnigowSelect";
import {ParameterType} from "@unigow/unigow-types";
import FileInput from "@unigow/components/ParamQuestions/components/FileInput";
import {DatePicker} from "@mui/x-date-pickers";
import {useAuthStore} from "@unigow/stores/authStore";
import dayjs from "dayjs";
import {UnigowAutocomplete} from "@unigow/components/UnigowAutocomplete/UnigowAutocomplete";

interface ParamQuestionsProps extends HTMLAttributes<HTMLDivElement> {
    params?: ParameterType[],
    handleFieldChange: (fieldName: string, value: string | string[] | undefined)=> void,
    formData: Record<string, string | string[]>,
    mainField?: string
}

export default function ParamQuestions({params, handleFieldChange, formData, mainField, className}: ParamQuestionsProps): React.ReactElement {
    const {userData} = useAuthStore();

    function renderQuestion(question: ParameterType): React.ReactNode {
        // Parámetros de tipo texto sin restricciones
        if (question.type === "free") {
            return (
                <div className="flex flex-col gap-2">
                    {question.description && <p className="text-ug-sm">{question.description}</p>}
                    <UnigowInput required={question.required} type="text" value={formData[question.alias] || ""}
                        onChange={(e) => handleFieldChange(question.alias, e.target.value)}
                        placeholder={question.placeholder && question.placeholder !== "" ? question.placeholder : question.name}
                    />
                </div>
            );
        }

        // Parámetros de tipo texto con opciones a elegir
        if (question.inputType === "checkbox" && question.options) {
            // Put the options of the param as checkboxes
            // Set the value as an array of the selected options
            return (
                <div className="flex flex-col gap-2">
                    {question.description && <p className="text-ug-sm">{question.description}</p>}
                    <UnigowMultipleSelect
                        required={question.required}
                        options={question.options}
                        value={formData[question.alias] as string[] | undefined || []}
                        handleFieldChange={(e) => handleFieldChange(question.alias, e.target.value)}
                        placeholder={question.placeholder && question.placeholder !== "" ? question.placeholder : question.name}
                    />
                </div>
            );
        }

        // Parámetro de tipo documento
        if (question.inputType === "file") {
            return (
                <div className="flex flex-col gap-2">
                    {question.description && <p className="text-ug-sm">{question.description}</p>}
                    <FileInput handleFieldChange={handleFieldChange} fieldName={question.alias}
                        fileUrl={formData[question.alias] as string | undefined}
                    />
                </div>
            );
        }

        // Parámetros de tipo texto con una opción a elegir de forma libre
        if (question.inputType === "selectfree") {
            return (
                <div className="flex flex-col gap-2">
                    {question.description && <p className="text-ug-sm">{question.description}</p>}
                    <UnigowAutocomplete
                        required={question.required}
                        options={question.options || []}
                        value={formData[question.alias] as string || ""}
                        onChange={(_, v)=>{
                            if (v) {
                                handleFieldChange(question.alias, v);
                            }
                        }}
                        placeholder={question.placeholder && question.placeholder !== "" ? question.placeholder : "Selecciona una opción"}
                    />
                </div>
            );
        }

        // Parámetros de tipo fecha
        if (question.inputType === "date") {
            return (
                <div className="flex flex-col gap-2">
                    {question.description && <p className="text-ug-sm">{question.description}</p>}
                    <DatePicker className="rounded-xl" format="DD/MM/YYYY"
                        sx={{
                            "& input": {
                                padding:"10px 10px"
                            }
                        }}
                        slotProps={{textField:{required:question.required}}}
                        value={formData[question.alias] ? dayjs(formData[question.alias] as string) : null}
                        onChange={(v)=>handleFieldChange(question.alias, v?.toISOString() || "")}
                    />
                </div>
            );
        }

        // Parámetros de tipo policy son checkboxs obligatorios
        if (question.inputType === "policy") {
            return (
                <div className="flex gap-2 items-center">
                    <Checkbox id={question.alias}  required={question.required}/>
                    <label htmlFor={question.alias} className="text-ug-sm">{question.description}</label>
                </div>
            );
        }

        // Resto de parámetros con una opción a elegir de entre una lista
        return (
            <div className="flex flex-col gap-2">
                {question.description && <p className="text-ug-sm">{question.description}</p>}
                <UnigowSelect
                    required={question.required}
                    value={formData[question.alias] as string || ""}
                    onChange={(e) => handleFieldChange(question.alias, e.target.value)}
                    displayEmpty={true}
                    renderValue={(v)=>{
                        if (!v || v === "") {
                            return <span className="text-gray-cool-400 text-ug-lg font-light">{question.placeholder && question.placeholder !== "" ? question.placeholder : question.name}</span>;
                        }
                        return v;
                    }}
                >
                    {sortText(question.options)?.map((option)=>(
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                </UnigowSelect>
            </div>
        );
    }

    if (!userData) return <p>Cargando...</p>;

    if (userData && userData.minor) {
        const minorParams = params?.filter((param)=>param.minors);
        const adultParams = params?.filter((param)=>!param.minors);

        return (
            <div className="flex flex-col gap-4">
                <p><span className="font-semibold">IMPORTANTE:</span> Este registro debe ser rellenado por uno de los representantes legales del menor</p>
                {minorParams && (
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl">Datos extra por registro de un menor</h3>
                        <div className={className}>
                            {minorParams.map((question)=>(
                                <div className="flex flex-col gap-[10px]" key={question._id.toString()}>
                                    <p className="text-ug-lg font-semibold">{question.name === "main" ? mainField : question.name}{question.required && <sup>*</sup>}</p>
                                    {renderQuestion(question)}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {adultParams && (
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl">Datos del participante</h3>
                        <div className={className}>
                            {adultParams.map((question)=>(
                                <div className="flex flex-col gap-[10px]" key={question._id.toString()}>
                                    <p className="text-ug-lg font-semibold">{question.name === "main" ? mainField : question.name}{question.required && <sup>*</sup>}</p>
                                    {renderQuestion(question)}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className={className}>
            {params?.filter((param)=>!param.minors).map((question)=>(
                <div className="flex flex-col gap-[10px]" key={question._id.toString()}>
                    <p className="text-ug-lg font-semibold">{question.name === "main" ? mainField : question.name}{question.required && <sup>*</sup>}</p>
                    {renderQuestion(question)}
                </div>
            ))}
        </div>
    );
}