import React from "react";
import {PopupUserProps} from "./SingleUser";
import {Avatar, AvatarGroup} from "@mui/material";
import UnigowButton from "../../../components/UnigowButton/UnigowButton";
import {useMediaQuery} from "react-responsive";
import {twMerge} from "tailwind-merge";

export default function MultipleUsers(props: PopupUserProps): React.ReactElement {
    const {orientatorArray, popupData, length} = props;

    const isMobile = useMediaQuery({query: "(max-width: 499px)"});

    function getDesktopSizes(): {
        avatar: number,
        padding: string
    } {
        if (!popupData || !popupData.style || !popupData.style.size) return {
            avatar: 50,
            padding: "imd:py-6"
        };

        switch (popupData.style.size) {
            case "normal":
                return {
                    avatar: 50,
                    padding: "imd:py-6"
                };
            case "small":
                return {
                    avatar: 40,
                    padding: "imd:py-2"
                };
            case "big":
                return {
                    avatar: 60,
                    padding: "imd:py-6"
                };
        }
    }

    return (
        <div className={twMerge("p-4 flex gap-2 imd:gap-5 items-center flex-col", getDesktopSizes().padding)}>
            <AvatarGroup max={5} total={length} sx={{
                "& .MuiAvatar-root": {width: isMobile ? 50 : getDesktopSizes().avatar, height: isMobile ? 50 : getDesktopSizes().avatar, fontSize: 15, border:"2px solid white"}
            }}
            >
                {orientatorArray.map((orientator)=>(
                    <Avatar key={orientator.userInfo._id} className="border-white border-2 border-solid" src={orientator?.userInfo.profilePic}/>
                ))}
            </AvatarGroup>
            <UnigowButton className="py-1 imd:py-2" variant="primary" onClick={()=>{
                window.open(popupData?.settings.redirectLink || "#", "_blank");
            }}
            >Chatear con estudiantes
            </UnigowButton>
        </div>
    );
}