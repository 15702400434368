import React, {useState, useEffect} from "react";
import {ReactComponent as UnigowLogo} from "../../assets/unigow-black.svg";
import UnigowButton from "../../components/UnigowButton/UnigowButton";
import UnigowInput from "../../components/UnigowInput/UnigowInput";
import {useAuth} from "../../contexts/AuthContext";
import {toast} from "react-toastify";
import {useNavigate, useSearchParams} from "react-router-dom";
import ForgotPass from "../ForgotPass/ForgotPass";
import {useAuthStore} from "../../stores/authStore";

function Login(): React.ReactElement {
    // MISCELANEOUS
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // STORES
    const {loginUser} = useAuth();
    const {userData} = useAuthStore();

    // STATES
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [loading, setLoading] = useState(false);

    // FUNCTIONS
    async function handleLogin(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (loading) return;

        setLoading(true);

        if (!email || !pass) {
            toast.error("Debes rellenar el correo y la contraseña");
            setLoading(false);
            return;
        }

        try {
            await loginUser(email, pass);
            setLoading(false);
        } catch {
            toast.error("La combinación de correo y contraseña no es correcta");
            setLoading(false);
            setPass("");
        }
    }

    // EFFECTS
    useEffect(()=>{
        if (searchParams.get("error")) {
            toast.error("Tu cuenta no tiene los permisos necesarios para acceder al Dashboard");
        }
    }, [searchParams]);

    useEffect(()=>{
        if (userData) {
            navigate("/");
        }
    }, [userData, navigate]);

    return (
        <div className="absolute inset-0 bg-gradient-to-b from-black from-[65%] to-primary-500">
            <div className="flex flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white lg:w-1/3 lg:rounded-2xl py-8 lg:px-28 shadow-ug-xl gap-4 w-full">
                <form className="flex flex-col items-center gap-4 px-8 lg:px-0" onSubmit={handleLogin} onKeyDown={(e)=>{
                    if (e.key === "Enter") {
                        e.preventDefault();
                        void handleLogin(e);
                    }
                }}
                >
                    <div className="flex justify-center">
                        <UnigowLogo />
                    </div>
                    <div className="flex justify-center">
                        <h2>Inicia sesión en Unigow</h2>
                    </div>
                    <div className="flex flex-col gap-4 w-full xl:w-3/4">
                        <div className="flex flex-col gap-3">
                            <h3>Correo</h3>
                            <UnigowInput required placeholder="Correo Electrónico" type="email" autoComplete="email"
                                value={email} onChange={(e)=>{
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex flex-col gap-3">
                            <h3>Contraseña</h3>
                            <UnigowInput required placeholder="Contraseña" type="password" autoComplete="current-password"
                                value={pass} onChange={(e)=>{
                                    setPass(e.target.value);
                                }}
                            />
                        </div>
                        <UnigowButton disabled={loading} type="submit" variant="secondary">Iniciar Sesión</UnigowButton>
                    </div>
                </form>
                <ForgotPass/>
            </div>
        </div>
    );
}

export default Login;