import React, {Children} from "react";
import PageContainer from "@unigow/components/PageContainer/PageContainer";
import {RetoObject} from "../../../../types/retos";
import {Reto} from "./components/Reto";
import {useQuery} from "react-query";
import {api} from "../../../../apis/requests";
import {useDashboardStore} from "../../../../stores/dashboard/dashboardStore";

export default function Retos(): React.ReactElement {
    const {clientInfo} = useDashboardStore();

    const {data:retos = []} = useQuery("retos", async()=>{
        const results = await api.get<RetoObject[]>(`retos/client/${clientInfo?._id}`);

        // First unanswered retos sort
        return results.sort((a, b)=>Number(a.answered) - Number(b.answered));
    }, {
        enabled:!!clientInfo
    });

    return (
        <PageContainer className="px-4 items-start" style={{alignItems:"unset"}}>

            <div className="flex flex-col gap-[35px] pb-8 w-full">
                <div className="flex flex-col gap-3 items-start w-full">
                    <h1>Mi opinión</h1>
                    <p>Bienvenid@ a la sección en la que puedes dar tu opinión a traves de cuestionarios. <br />
                        Este contenido será utilizado para crear contenido educativo <br />
                        <b>Importante</b>: Una vez rellenado un cuestionario ya no lo podrás editar
                    </p>
                </div>
                {retos.length > 0 ?
                    (
                        <ul className="flex flex-col gap-4">
                            {Children.toArray(retos.map((reto, i)=>(
                                <li>
                                    <Reto reto={reto} defaultOpen={i === 0 && !reto.answered}/>
                                </li>
                            )))}
                        </ul>
                    ) : (
                        <p>No hay retos disponibles</p>
                    )}
            </div>
        </PageContainer>
    );
}