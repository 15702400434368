import React from "react";

import UnigowButton, {UnigowButtonProps} from "@unigow/components/UnigowButton/UnigowButton";
import {useIframe} from "../../contexts/IframeContext";
import {createAprovalHelper, userAnsweredQuestions} from "@unigow/helpers/chat";
import {CreateAproval} from "@unigow/types/chat";
import {twMerge} from "tailwind-merge";
import {useAuthStore} from "@unigow/stores/authStore";
import {useIframeStore} from "@unigow/stores/iframeStore";
import {printArray} from "@unigow/helpers/helper";
import {PublicOrientator} from "@unigow/types/orientators";
import {ChatsPluginWithClient} from "@unigow/types/iframe";
import {getLocalStorageObj} from "@unigow/helpers/localstorage";
import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";

interface ChatButtonProps {
    orientatorInfo: PublicOrientator,
    pluginData: ChatsPluginWithClient
}

function AprovalButton({orientatorInfo, className, pluginData, ...buttonProps}: ChatButtonProps & UnigowButtonProps): React.ReactElement {
    // STORES
    const {getRequiredParams} = useCustomLocale();
    const {setCurrentPage} = useIframe();
    const {userData} = useAuthStore();
    const {setPendingAction} = useIframeStore();

    // FUNCTIONS
    async function createAproval(): Promise<void> {
        // Search the value of the field defined as main in the iframe in the orientator's additionalInfo
        const mainField = orientatorInfo.userInfo.info.info[pluginData.settings.mainField || ""];

        if (!userData) {
            setCurrentPage("register");
            return;
        }

        const newAproval: CreateAproval = {
            orientator:orientatorInfo.userInfo._id,
            // Si existe un cliente alternativo, usar su Id. Si no usar el id del propio iframe
            client:orientatorInfo.realClient?._id.toString() || pluginData.client.toString(),
            iframe:pluginData._id.toString(),
            // TODO PONER CAMPO PRINCIPAL BIEN
            mainField:printArray(mainField),
            // Si existe un cliente alternativo, añadir propiedad extra con el id del cliente del iframe
            iframeClient:orientatorInfo.realClient?._id ? pluginData.client.toString() : undefined
        };

        if (!userData) {
            setPendingAction({
                action:"aproval",
                data:newAproval,
                needsPhone:pluginData.settings.userNeedsPhone,
                userParams:pluginData.settings.userParams,
                privacy:orientatorInfo.realClient ? {
                    privacyLink:orientatorInfo.realClient.privacy || "",
                    clientName:orientatorInfo.realClient.name,
                    clientId:orientatorInfo.realClient._id.toString()
                } : undefined});

            localStorage.setItem("redirectTo", "form");
            setCurrentPage("register");
            return;
        }

        const privacyAcceptedRaw = getLocalStorageObj<string[]>("privacyAccepted");

        const unansweredQuestions = userAnsweredQuestions(getRequiredParams, userData.minor, pluginData.settings.userParams,
            userData?.additionalInfo);

        // Si el usuario no está logueado o no ha respondido las preguntas del iframe, guardar el aproval para luego
        if (unansweredQuestions.length > 0 || !userData || (pluginData.settings.userNeedsPhone && (!userData.phone || userData.phone === "")) || (orientatorInfo.realClient && (!privacyAcceptedRaw ||
            !privacyAcceptedRaw.includes(orientatorInfo.realClient._id.toString())))) {
            setPendingAction({
                action:"aproval",
                data:newAproval,
                needsPhone:pluginData.settings.userNeedsPhone,
                userParams:pluginData.settings.userParams,
                privacy:orientatorInfo.realClient ? {
                    privacyLink:orientatorInfo.realClient.privacy || "",
                    clientName:orientatorInfo.realClient.name,
                    clientId:orientatorInfo.realClient._id.toString()
                } : undefined});

            setCurrentPage("form");
            return;
        }

        await createAprovalHelper(newAproval, ()=>setPendingAction(undefined));
    }

    return (
        <UnigowButton className={twMerge(className)} {...buttonProps}
            onClick={()=>createAproval()}
        >{buttonProps.children}
        </UnigowButton>
    );
}

export default AprovalButton;