import {Close} from "@mui/icons-material";
import {Avatar, IconButton, Theme, ThemeProvider, createTheme} from "@mui/material";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {ReactComponent as Dialog} from "@unigow/assets/dialog.svg";
import {ReactComponent as ChatIcon} from "@unigow/assets/chatIcon.svg";
import {CSSTransition} from "react-transition-group";

import "./styles.css";
import {api} from "../../apis/requests";
import {useQuery} from "react-query";
import {UnigowPopupMessage} from "../../types/iframe";
import SingleUser from "./components/SingleUser";
import MultipleUsers from "./components/MultipleUsers";
import {getCookie, setCookie} from "../../helpers/localstorage";
import {twMerge} from "tailwind-merge";
import {iframeReady, resizeIframe} from "@unigow/helpers/iframe";
import {PopupType} from "@unigow/unigow-types";
import {PublicOrientator} from "@unigow/types/user";

export function getPopupSize(popupData: Pick<PopupType, "style">, pageWidth: number): {
    mobile: {
        width: number;
    };
    desktop: {
        width: number;
    }
} {
    if (!popupData || !popupData.style || !popupData.style.size) {
        return {
            mobile:{
                width:pageWidth * 0.95
            },
            desktop:{
                width:500
            }
        };
    }

    switch (popupData.style.size) {
        case "small":return {
            mobile:{
                width:pageWidth * 0.85
            },
            desktop:{
                width:300
            }
        };
        case "big":return {
            mobile:{
                width:pageWidth * 0.95
            },
            desktop:{
                width:500
            }
        };
        default:return {
            mobile:{
                width:pageWidth * 0.95
            },
            desktop:{
                width:400
            }
        };
    }
}

// Hay dos tipos de popup, el general que no tiene parámetros en la url y el específico
// que tiene un parámetro de búsqueda para el main del iframe
export default function PopupIframe(): React.ReactElement {
    const [URLSearchParams] = useSearchParams();
    const [show, setShow] = useState(true);
    const [showIframe, setShowIframe] = useState(true);
    const {client, popup, popupId} = useParams();
    const [orientators, setOrientators] = useState<PublicOrientator[]>([]);
    const [total, setTotal] = useState(0);
    const [theme, setTheme] = useState<Theme | undefined>();
    const [pageWidth, setPageWidth] = useState<number>(window.innerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
    const [reresize, setReresize] = useState(true);

    const hideIframe = getCookie(encodeURIComponent(window.location.href)) || undefined;

    const iframeRef = useRef<HTMLDivElement>(null);
    const iframeContainerRef = useRef<HTMLDivElement>(null);

    const {data:popupData} = useQuery(["clientpopup", popup], async()=>{
        const url = popupId ? `popups/${popupId}` : `popups/${client}/popup/${popup}`;

        const res = await api.get<PopupType & {mainField: string}>(url);

        const auxTheme = createTheme({
            palette:{
                primary:{
                    main:res?.style?.mainColor || "#000"
                },
                secondary:{
                    main:"#FFF"
                },
                error:{
                    main:"#ED6C4D"
                }
            }
        });

        setTheme(auxTheme);

        document.documentElement.style.setProperty("--secondary-color", "#FFF");
        if (res?.style?.mainColor) {
            document.documentElement.style.setProperty("--primary-color", res?.style.mainColor);
        }
        if (res?.style?.fontFamily) {
            document.documentElement.style.setProperty("--general-font", res?.style.fontFamily);
        }

        return res;
    }, {refetchOnWindowFocus:false});

    const queryUrl = URLSearchParams.get("main");

    const query: string | null = queryUrl
        ? atob(queryUrl)
        : null;

    useEffect(()=>{
        // Ask the parent for its width
        window.parent.postMessage({action:"getPageWidth"}, "*");

        // Listen for the parent's width
        const listener = (event: MessageEvent<UnigowPopupMessage>): void => {
            if (event.data.action === "pageWidth") {
                const parentWidth = event.data.data.width as number;

                setPageWidth(parentWidth);
                setIsMobile(parentWidth < 500);
                setReresize(true);
            }
        };

        window.addEventListener("message", listener);

        return ()=>{
            window.removeEventListener("message", listener);
        };
    }, []);

    useLayoutEffect(()=>{
        if (show && showIframe && !!popupData && !!orientators && !hideIframe) {
            iframeReady();
        }
    }, [show, showIframe, popupData, orientators, hideIframe]);

    useLayoutEffect(()=>{
        if (!pageWidth || !popupData || !reresize) return;

        const {desktop, mobile} = getPopupSize(popupData, pageWidth);

        setShowIframe(true);

        const iframeEl = iframeRef.current as HTMLDivElement;

        if (!iframeEl) return;

        if (!show) {
            void resizeIframe({height:90, width:90, animate:true});
            return;
        }

        const width = isMobile ? mobile.width : desktop.width;

        void resizeIframe({height:iframeEl.clientHeight + 30, width});
        setReresize(false);

    }, [pageWidth, popupData, isMobile, show, iframeRef, reresize, orientators]);

    useEffect(()=>{
        async function fetchData(): Promise<void> {
            if (!popupData || hideIframe) return;
            let url = `pluginchats/${popupData.plugin}/orientators`;

            if (query && query !== "all") {
                url += `?${popupData.mainField}=${query}`;
            }

            const data = await api.get<PublicOrientator[]>(url);

            const withPhoto = data.filter((orientator)=>!!orientator.userInfo.profilePic);

            const randomOrientators: PublicOrientator[] = [];

            if (!query) {
                setTotal(data.length);
                // Choose a maximum of 4 random orientators
                let counter = 0;

                withPhoto.sort(()=>Math.random() - 0.5).forEach((orientator)=>{
                    if (counter >= 4) return;
                    if (!randomOrientators.includes(orientator)) {
                        randomOrientators.push(orientator);
                        counter++;
                    }
                });
            } else {
                // Choose one random orientator
                randomOrientators.push(withPhoto[Math.floor(Math.random() * withPhoto.length)]);
            }

            setOrientators(randomOrientators);
        }
        void fetchData();
    }, [query, popupData, hideIframe]);

    if (!popupData)  return <div/>;

    function closeIframe(): void {
        setShowIframe(false);

        setCookie(encodeURIComponent(window.location.href), "hidden", 0.042);
    }

    return (
        <ThemeProvider theme={theme || {}}>
            <CSSTransition nodeRef={iframeContainerRef} classNames="iframe" timeout={300} in={showIframe && !!popupData && orientators.length > 0 && !hideIframe} unmountOnExit>
                <div ref={iframeContainerRef} className={twMerge("fixed bottom-4 flex w-full justify-end imd:right-0")}>
                    <CSSTransition classNames="popup" timeout={300} in={show}>
                        <div className={twMerge("flex absolute right-0 bottom-0", isMobile ? "w-full" : "")} ref={iframeRef}>
                            <div className="flex items-start mt-2 px-2 imd:hidden w-6">
                                <IconButton size="small" className="bg-white rounded-imd drop-shadow-lg" onClick={closeIframe}>
                                    <Close/>
                                </IconButton>
                            </div>
                            <div className={twMerge("ml-2", isMobile ? "w-[calc(100%-140px)]" : "px-4")} style={{filter:"drop-shadow(3px 3px 6px rgb(0 0 0 / .3))"}}>
                                <div className="rounded-xl bg-white imd:rounded-3xl imd:rounded-br-none">
                                    <div className="px-4 py-2 imd:py-4 flex gap-2 justify-between">
                                        {!isMobile && popupData.style?.size === "big" && (
                                            <div className="flex items-center text-primary-500 border border-gray-cool-200 border-solid rounded-[9px] p-1 mx-2">
                                                <ChatIcon className="rotate-6"/>
                                            </div>
                                        )}
                                        {isMobile ? (
                                            <p className={twMerge("italic", popupData.style?.size === "big" ? "text-ug-base" : "text-ug-sm")}>{popupData?.style?.shortText}</p>
                                        ) : (
                                            <p className={twMerge("font-medium", popupData.style?.size === "small" ? "text-ug-sm" : "text-ug-base ")}>{popupData?.style?.longText}</p>
                                        )}
                                        {!isMobile && (
                                            <div className="flex items-start">
                                                <IconButton className="p-1 px-2" onClick={closeIframe}>
                                                    <Close className="w-4"/>
                                                </IconButton>
                                            </div>
                                        )}
                                    </div>
                                    <div className="bg-gray-cool-200 h-[1px] w-full"/>
                                    {!query ? (
                                        <MultipleUsers popupData={popupData} orientatorArray={orientators} length={total}/>
                                    ) : (
                                        <SingleUser popupData={popupData} orientatorArray={orientators} query={query}/>
                                    )}

                                </div>
                            </div>
                            {isMobile && (
                                <Dialog width={20} className="self-end mb-4 -translate-x-1"/>
                            )}
                        </div>
                    </CSSTransition>
                    {isMobile && (
                        <div className="flex items-end drop-shadow-lg px-2">
                            <IconButton className="p-0 bg-white" onClick={()=>{
                                setShow((prev)=>!prev);
                                setReresize(true);
                            }}
                            >
                                <Avatar className="w-12 h-12 border-white border-2 border-solid" src={orientators[0]?.userInfo.profilePic}/>
                            </IconButton>
                        </div>
                    )}
                </div>
            </CSSTransition>
        </ThemeProvider>
    );
}

