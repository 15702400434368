import React, {FormEvent, useState} from "react";
import {Checkbox} from "@mui/material";
import {ChatIframe} from "@unigow/types/iframe";
import {useChatsPluginStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/chatsPlugin.store";
import ParameterChooser from "@unigow/pages/Dashboard/components/ParameterChooser/ParameterChooser";
import ParameterFields from "@unigow/pages/Dashboard/components/ParameterChooser/ParameterFields";
import {ChatsPluginAPI} from "@unigow/apis/iframe";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";

function Step4(): React.ReactElement {
    // STORES
    const {clientParams} = useDashboardStore();
    const {setStep, step, pluginData, modifyPluginData} = useChatsPluginStore();

    // STATES
    const [subStep, setSubStep] = useState(0);
    const [phoneNeeded, setPhoneNeeded] = useState(pluginData?.settings.userNeedsPhone || false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>(pluginData?.settings.userParams || []);

    // FUNCTIONS
    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        const iframe: Partial<ChatIframe> = {
            settings:{
                userParams:selectedOptions,
                userNeedsPhone:phoneNeeded
            }
        };

        if (!pluginData) {
            setStep(0);
            return;
        }

        const response = await ChatsPluginAPI.updateChatsPlugin({pluginId:pluginData._id.toString(), data:iframe});

        if (response) {
            modifyPluginData("settings", response.settings);
            setSubStep(1);
        }
    }

    return (
        <div className="overflow-hidden">
            {subStep === 0 ? (
                <form className="flex flex-col gap-[15px] " onSubmit={handleSubmit}>
                    {step === 4 && (
                        <h2>4.1 Personaliza el registro de los usuarios</h2>
                    )}
                    <p className="font-light text-ug-base">Es hora de pensar en qué datos quieres pedirle al usuario antes de que pueda interactuar con tus embajadores. El nombre y correo son obligatorios.</p>
                    <p className="text-ug-base font-semibold">Nombre *</p>
                    <p className="text-ug-base font-semibold">Correo electrónico *</p>
                    <div className="flex items-center gap-2">
                        <Checkbox
                            style={{color:"#000"}}
                            id="neededphone"
                            className="p-0"
                            checked={phoneNeeded}
                            onChange={(e)=>setPhoneNeeded(e.target.checked)}
                        />
                        <label htmlFor="neededphone" className="text-ug-base font-semibold">Pedir número de teléfono</label>
                    </div>
                    <ParameterChooser selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}
                        goBack={()=>setStep(0)}
                    />
                </form>
            ) : (
                <div className="flex flex-col gap-[15px] ">
                    {step === 4 && (
                        <h2>4.2 Revisa el formato de cada campo</h2>
                    )}
                    <p className="font-light text-ug-base">El usuario al registrarse deberá seleccionar una opción dentro de cada categoría. Asegúrate de que las opciones son correctas.</p>

                    <ParameterFields selectedOptions={clientParams.filter((x)=>selectedOptions.includes(x.alias))}
                        setSubStep={setSubStep} goNext={()=>{
                            setStep(5);
                        }}
                    />
                </div>
            )}

        </div>
    );
}

export default Step4;