import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Loading} from "../../pages/Loading/Loading";
import {useAuthStore} from "../../stores/authStore";

interface ProtectedRouteProps {
    children: React.JSX.Element
}

function ProtectedRoute({children}: ProtectedRouteProps): React.ReactElement {
    // MISCELANEOUS
    const navigate = useNavigate();

    // STORES
    const {userData, loading} = useAuthStore();

    useEffect(()=>{
        if (!userData && !loading) {
            // El usuario no ha iniciado sesión

            window.location.href = "/login";
        }
    }, [loading, userData, navigate]);

    if (loading || !userData) return <Loading/>;

    return children;
}

export default ProtectedRoute;