import {
    ArcElement, BarElement, CategoryScale, Filler, Legend, LinearScale, LineElement,
    PointElement, TimeScale, Title, Tooltip
} from "chart.js";
import ChartJS from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, {useState} from "react";
import {Chart} from "react-chartjs-2";

import {useQuery} from "react-query";
import {api} from "@unigow/apis/requests";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {FullOrientator} from "@unigow/types/user";

ChartJS.register(ArcElement, Title, Tooltip, Filler, Legend, ChartDataLabels,
    TimeScale, LinearScale, CategoryScale, PointElement, LineElement, BarElement);

export function PieChart(): React.ReactElement {
    const {selectedClient} = useDashboardStore();
    const [total, setTotal] = useState(1);

    const {data:clientMentors = []} = useQuery(["mentors", selectedClient], async()=>{
        return api.get<FullOrientator[]>(`orientators/filter/client/${selectedClient}`);
    }, {enabled:!!selectedClient});

    const {data:chartData = {datasets:[]}} = useQuery(["ranking", selectedClient], async()=>{
        const res = await api.get<{_id: string, total: number}[]>(`chats/client/${selectedClient}/ranking`);

        let totalAux = 0;
        res.forEach((item)=>totalAux += item.total);
        setTotal(totalAux);

        res.sort((a, b)=>a.total < b.total ? 1 : -1);

        const colors = ["#00237E", "#034BB3", "#39CEF3", "#B1F0FF", "#D6E9EE"];
        const newData = {
            labels:res.map((y)=>{
                const found = clientMentors.find((x)=>x.userInfo._id === y._id)?.userInfo;
                return `${found?.name} ${found?.surnames}`;
            }),
            datasets: [
                {
                    label: "Interacciones",
                    data: res.map((y)=>y.total),
                    backgroundColor:colors,
                    borderWidth:2,
                    borderRadius:10,
                    offset: 10
                }
            ]
        };

        return newData;
    }, {enabled:clientMentors.length > 0});

    const options = {responsive:true, plugins:{datalabels:{color:"black", backgroundColor:"white", borderRadius:10, formatter(value: number) {
        return `${Math.round(value / total * 100)} %`;
    }}}};

    return (
        <div className="lg:p-5 overflow-visible lg:max-w-lg mx-auto">
            {chartData.datasets.length === 0 ? (
                <p className="text-center">No hay datos para mostrar</p>
            ) : (
                <Chart className="overflow-visible" type="doughnut" data={chartData}
                    options={options} plugins={[ChartDataLabels]}
                />
            )}
        </div>
    );
}