import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";
import {joinActivityHelper} from "@unigow/helpers/cards";
import {userAnsweredQuestions} from "@unigow/helpers/chat";
import {getPluginData} from "@unigow/helpers/iframe";
import {useIframe} from "@unigow/pages/Iframe/contexts/IframeContext";
import {useAuthStore} from "@unigow/stores/authStore";
import {useIframeStore} from "@unigow/stores/iframeStore";
import {CardsPluginType, CardType} from "@unigow/unigow-types";
import React from "react";
import {toast} from "react-toastify";

interface JoinCardButtonProps {
    currCard: CardType & {participantNumber: number};
}

export default function JoinActivityButton({currCard}: JoinCardButtonProps): React.ReactElement {
    const {getRequiredParams} = useCustomLocale();
    const {iframeData, setPendingAction} = useIframeStore();
    const {userData} = useAuthStore();
    const {setCurrentPage} = useIframe();

    if (!iframeData) return <></>;

    const isFull = currCard.participantNumber >= currCard.maxParticipants;

    const pluginData = getPluginData(currCard.plugin.toString(), iframeData.pluginData) as CardsPluginType;

    async function joinCard(): Promise<void> {
        if (!iframeData) return;

        if (isFull) {
            toast.error("This activity is full");
            return;
        }

        setPendingAction({
            action:"card",
            data:currCard,
            needsPhone:currCard.settings.userNeedsPhone,
            userParams:currCard.settings.userParams
        });

        if (!userData) {
            localStorage.setItem("redirectTo", "form");
            setCurrentPage("register");
            return;
        }

        const unansweredQuestions = userAnsweredQuestions(getRequiredParams, userData.minor,  currCard.settings.userParams, userData?.additionalInfo);

        if (unansweredQuestions.length > 0 || (currCard.settings.userNeedsPhone && (!userData.phone || userData.phone === ""))) {
            setCurrentPage("form");
            return;
        }

        // Function to join activity
        await joinActivityHelper(currCard, setCurrentPage, ()=>setPendingAction(undefined));
    }

    return (
        <UnigowButton disabled={isFull} className="w-full" variant="primary" onClick={joinCard}>{isFull ? "Actividad completa" : pluginData?.style.buttonText}
        </UnigowButton>
    );
}