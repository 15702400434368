import {PopupType} from "@unigow/unigow-types";
import {create} from "zustand";

type PopupStore = {
    // Datos del plugin de chats
    popupData: PopupType | undefined

    // Función para actualizar el plugin de chats
    setPopupData: (data: PopupType)=> void;

    // Función para modificar un parámetro de la información del plugin seleccion
    modifyPopupData: <K extends keyof PopupType>(property: K, value: PopupType[K])=> void;

    // Paso actual del wizard
    step: number;

    // Función para actualizar el paso del wizard
    setStep: (step: number)=> void;

    resetPopupData: ()=> void;
};

export const usePopupStore = create<PopupStore>((set, get) => ({
    popupData: undefined,
    setPopupData:(data)=>{
        set({popupData:data});
    },
    modifyPopupData:(property, value)=>{
        const {popupData} = get();
        if (!popupData) return;

        const updatedPopupData = {
            ...popupData,
            [property]:value
        };

        set({popupData:updatedPopupData});
    },
    step: 0,
    setStep:(step)=>{
        if (step === 1) {
            set({step:1});
            return;
        }

        const {popupData} = get();
        if (!popupData) return;

        if (popupData.style.mainColor === undefined || popupData.style.mainColor.length === 0) {
            return;
        }

        set({step});
    },
    resetPopupData:()=>{
        set({popupData:undefined, step:0});
    }
}));