import {api} from "@unigow/apis/requests";
import CommonCard from "@unigow/components/GroupCard/CommonCard";
import JoinActivityButton from "@unigow/pages/Iframe/components/components/JoinActivityButton";
import {CardsPluginType, CardType, IframeType} from "@unigow/unigow-types";
import React, {Children} from "react";
import {useQuery} from "react-query";

interface CardsSearchProps {
    pluginData: CardsPluginType,
    iframeData: IframeType
}

export default function CardsSearch({pluginData, iframeData}: CardsSearchProps): React.ReactElement {
    const {data = []} = useQuery(["cards", pluginData._id], ()=>{
        return api.get<(CardType & {participantNumber: number})[]>(`plugincards/${pluginData._id}/cards`);
    }, {enabled:!!pluginData._id, refetchOnMount:true});

    return (
        <div className="flex flex-col items-center w-11/12 lg:w-full mx-auto gap-7">
            <ul className="flex flex-wrap gap-4 w-full">
                {Children.toArray((data).map((card)=>(
                    <li className="w-full md:w-[calc(50%-1rem)] relative">
                        <CommonCard color={iframeData.style.mainColor} cardInfo={card} ActionButton={(
                            <JoinActivityButton currCard={card}/>
                        )}
                        />
                    </li>
                )
                ))}
            </ul>
        </div>
    );
}