import {Spinner} from "flowbite-react";
import React, {useEffect, useState} from "react";

import {MenuItem, TableCell, TableRow} from "@mui/material";
import {UnigowTable} from "@unigow/components/UnigowTable/UnigowTable";
import {api} from "../../../../apis/requests";
import {Log} from "../../../../types/log";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import {twMerge} from "tailwind-merge";
import UnigowInput from "../../../../components/UnigowInput/UnigowInput";
import DateFilter from "../../../../components/DateFilter/DateFilter";
import {Dayjs} from "dayjs";
import {ReactComponent as Zoom} from "@unigow/assets/search.svg";
import UnigowSelect from "../../../../components/UnigowSelect/UnigowSelect";
import {useNavigate} from "react-router-dom";
import {generateCsv} from "../../../../helpers/helper";
import UnigowButton from "../../../../components/UnigowButton/UnigowButton";
import {Download} from "@mui/icons-material";
import {useDashboardStore} from "../../../../stores/dashboard/dashboardStore";
import {useCustomQuery} from "../../../../hooks/useCustomQuery";

export default function Logs(): React.ReactElement {
    const {clientInfo, selectedClient} = useDashboardStore();
    const [email, setEmail] = useState("");
    const [start, setStart] = useState<Dayjs | null>(null);
    const [end, setEnd] = useState<Dayjs | null>(null);
    const [type, setType] = useState<string>("");
    const navigate = useNavigate();

    useEffect(()=>{
        if (!clientInfo) return;

        if (!clientInfo?.permissions?.manager) {
            navigate("/chats");
        }
    }, [clientInfo, navigate]);

    const {data:clientLogs, isLoading:loading} = useCustomQuery(["logs", selectedClient, email, start, end, type], async()=>{
        let url = `logs/${selectedClient}?`;

        if (email) {
            url += `email=${email}&`;
        }

        if (start) {
            url += `start=${start.unix()}&`;
        }

        if (end) {
            url += `end=${end.endOf("day").unix()}` + "&";
        }

        if (type) {
            url += `type=${type}&`;
        }

        return api.get<Log[]>(url);
    }, {debounce:300, enabled:!!selectedClient && clientInfo?.permissions?.manager});

    function paintLog(logType: string): string {
        if (["chatCreated", "userRegistered", "aprovalCreated"].includes(logType)) {
            return "bg-secondary-org-200";
        }
        return "bg-secondary-blue-200";
    }

    function downloadInteractions(): void {
        // Saves the data from the interaction array in a csv file and then downloads it
        const headers = ["Autor del Evento", "Email del Autor", "Descripción", "Fecha y Hora"];
        const data = clientLogs?.map((interaction)=>[interaction.author ? interaction.author.name : "Sistema", interaction.author ? interaction.author.email : "", interaction.description, new Date(interaction.timestamp || 0).toLocaleString("es", {year:"numeric", month:"long", day:"numeric"})]) || [];

        generateCsv(headers, data, "logs.csv");
    }

    return (
        <PageContainer className="px-8 overflow-x-hidden h-max">
            <div className="flex flex-col gap-3 items-start w-full">
                <h1>Actividad</h1>
                <p className="text-ug-lg">Observa las interacciones exactas que están sucediendo en tu Chat peer-to-peer</p>
            </div>
            <div className="my-4 flex w-full items-center justify-between flex-wrap gap-2">
                <div className="relative">
                    <UnigowInput className="border-none bg-white" value={email} onChange={(e)=>{
                        setEmail(e.target.value);
                    }} placeholder="Busca por un correo"
                    InputProps={{className:"pl-8"}}
                    />
                    <div className="absolute left-0 flex justify-center items-center w-[40px] h-[40px] top-0">
                        <Zoom className="text-primary-500"/>
                    </div>
                </div>
                <div className="flex items-start lg:items-center gap-4 flex-col lg:flex-row">
                    <DateFilter start={start} setStart={setStart} end={end} setEnd={setEnd}/>
                    <UnigowSelect id="interactions" value={type} onChange={(e)=>{
                        setType(e.target.value);
                    }}
                    className="text-gray-cool-500 w-52 bg-white"
                    label={"Filtrar por tipo"}
                    >
                        <MenuItem value="">Filtrar por Actividad</MenuItem>
                        <MenuItem value="aprovalAccepted">Petición aprobada</MenuItem>
                        <MenuItem value="aprovalRefused">Petición rechazada</MenuItem>
                        <MenuItem value="aprovalCreated">Petición creada</MenuItem>
                        <MenuItem value="iframeUpdated">Chat peer-to-peer actualizado</MenuItem>
                        <MenuItem value="managerCreated">Manager creado</MenuItem>
                        <MenuItem value="orientatorCreated">Embajador creado</MenuItem>
                        <MenuItem value="userInfoCreated">Lead registrado</MenuItem>
                        <MenuItem value="chatCreated">Chat creado</MenuItem>
                    </UnigowSelect>
                    <UnigowButton className="flex items-center gap-2 py-1 shrink-0" variant="primary" onClick={downloadInteractions}>Exportar a CSV<Download/></UnigowButton>
                </div>
            </div>
            <div className="bg-white shadow-lg rounded-[20px] w-[calc(100vw-3rem)] lg:w-full">
                {loading ? (
                    <div className="p-10">
                        <Spinner/>
                    </div>
                ) : (
                    <UnigowTable headerTitles={["Autor del Evento", "Email del Autor", "Descripción", "Fecha y Hora"]} elementCount={clientLogs?.length || 0}>
                        {clientLogs?.map((interaction)=>(
                            <TableRow key={interaction._id}>
                                <TableCell>
                                    <p className="text-ug-base text-primary-500 underline cursor-pointer">{interaction.author ? interaction.author.name : "Sistema"}</p>
                                </TableCell>
                                <TableCell>
                                    <p className="text-ug-base text-gray-cool-500">{interaction.author ? interaction.author.email : ""}</p>
                                </TableCell>
                                <TableCell>
                                    <p className={twMerge("text-ug-base px-2 py-1 rounded-lg pl-2 text-black w-fit", paintLog(interaction.action))}>{interaction.description}</p>
                                </TableCell>
                                <TableCell>
                                    <p className="text-ug-base text-gray-cool-500">{new Date(interaction.timestamp || 0).toLocaleString("es", {year:"numeric", month:"long", day:"numeric"})}</p>
                                </TableCell>
                            </TableRow>
                        ))}
                    </UnigowTable>
                )}
            </div>
        </PageContainer>
    );
}