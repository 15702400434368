import React, {Children, useState} from "react";
import {useCustomQuery} from "../../../../../hooks/useCustomQuery";
import {api} from "../../../../../apis/requests";
import {TwilioChatFull} from "../../../../../types/chat";
import {Pagination, TextField} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import ChatRoom from "../../../../../components/ChatRoom/ChatRoom";

export default function AdminChats(): React.ReactElement {
    const [searchParams, setSearchParams] = useSearchParams();

    const chatId = searchParams.get("chat");

    const [page, setPage] = useState(1);
    const [email, setEmail] = useState("");

    const {data} = useCustomQuery(["chats", "unigow", page, email], async()=>{
        let url = `chats/totalchats?page=${page}`;

        if (email) {
            url += `&email=${email}`;
        }

        return api.get<{chats: TwilioChatFull[], pages: number}>(url);
    }, {
        debounce:500
    });

    async function joinChat(chat: string): Promise<void> {
        const res = await api.post<void, {status: string}>(`chats/join/${chat}`);

        if (res) {
            setSearchParams({chat:chat});
        }
    }

    return (
        <div className="flex flex-col gap-4 w-[800px]">
            <div className="flex flex-col gap-3 items-start w-full">
                <h1>Chats de usuarios</h1>
            </div>
            <div className="flex flex-col gap-4">
                {!chatId ? (
                    <div className="flex flex-col gap-4">
                        <TextField className="bg-white" label="Buscar por email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                        {!!data && (
                            <ul className="flex flex-col gap-4 py-4">
                                {Children.toArray(data.chats.map((chat)=>(
                                    <li className="flex relative gap-5 px-[30px] py-[15px] items-center cursor-pointer bg-white rounded-md" onClick={()=>joinChat(chat.SID)}>
                                        <p>Chat de {chat.orientated.name} con {chat.orientator.name} sobre {chat.mainField}</p>
                                    </li>
                                )))}
                            </ul>
                        )}
                        {!!data && (
                            <Pagination count={data.pages} page={page} onChange={(_, newPage)=>setPage(newPage)}/>
                        )}
                    </div>
                ) : (
                    <div>
                        <ChatRoom sid={chatId} goBack={()=>setSearchParams({})} dashboard={false}/>
                    </div>
                )}
            </div>
        </div>
    );
}