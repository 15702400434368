import {useState, useEffect} from "react";
import {useQuery, QueryFunction, QueryKey, UseQueryResult, UseQueryOptions} from "react-query";

interface UseCustomQueryOptions<D, E> extends UseQueryOptions<D, E> {
    debounce?: number;
}

export const useCustomQuery = <TData, TError>(
    params: QueryKey,
    request: QueryFunction<TData, QueryKey>,
    {debounce, ...options}: UseCustomQueryOptions<TData, TError> = {}
): UseQueryResult<TData, TError> => {
    const [newParams, setNewParams] = useState(params);

    const stringify = (obj: unknown): string => JSON.stringify(obj);

    useEffect(() => {
        if (stringify(params) !== stringify(newParams)) {
            const timerId = setTimeout(
                () => setNewParams(params),
                debounce
            );
            return () => clearTimeout(timerId);
        }
    }, [params, newParams, debounce]);

    return useQuery<TData, TError>(newParams, request, options);
};
