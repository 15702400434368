
import React, {Fragment, MouseEvent, useEffect, useState} from "react";

import {Divider, IconButton, Menu, MenuItem, Slider} from "@mui/material";
import {Check, DoneAll, KeyboardArrowDown, Pause, PlayArrow, Reply} from "@mui/icons-material";

import {formatTime} from "@unigow/helpers/helper";
import {twMerge} from "tailwind-merge";
import {toast} from "react-toastify";
import {useAnswersStore} from "../../../pages/Dashboard/pages/ChatsPage/stores/answersStore";
import UnigowButton from "../../UnigowButton/UnigowButton";
import {UnigowMessage} from "@unigow/types/chat";
import {api} from "@unigow/apis/requests";
import {useChatStore} from "@unigow/components/ChatRoom/store/chatStore";

interface MessageItemProps {
    unigowMessage: UnigowMessage;
    userId: string;
    read?: boolean;
    unigowMsg?: boolean;
    isGroup?: boolean;
}

export default function MessageItem({unigowMessage, userId, read, unigowMsg, isGroup}: MessageItemProps): React.ReactElement {
    const {twilioMsg:message, ...messageProps} = unigowMessage;
    const {setQuestionToPin, isDashboard, selectPinnedQuestion} = useChatStore();
    const {isAnswerSaved, addAnswer} = useAnswersStore();
    const [isPlaying, setIsPlaying] = useState(false);
    const [attachedAudio, setAttachedAudio] = useState<HTMLAudioElement | undefined>(undefined);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [deleted, setDeleted] = useState(message.body === "");

    const [openMenu, setOpenMenu] = useState<HTMLElement | null>(null);
    const open = Boolean(openMenu);
    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
        setOpenMenu(event.currentTarget);
    };
    const handleClose = (): void => {
        setOpenMenu(null);
    };

    useEffect(()=>{
        async function getData(): Promise<void> {
            if (!message.attachedMedia || message.attachedMedia.length < 1) return;
            const downloadUrl = await message.attachedMedia[0].getContentTemporaryUrl();
            if (!downloadUrl) return;
            const audio = new Audio(downloadUrl);
            // Load the audio to the end (infinity) in order to know the correct duration.
            audio.currentTime = 1e101;
            audio.addEventListener("loadedmetadata", ()=>{
                if (audio.duration === Infinity) {
                    audio.currentTime = 1e101;
                    audio.addEventListener("timeupdate", ()=>{
                        setCurrentTime(audio.currentTime);
                        setDuration(audio.duration);
                    });
                }
            });
            setAttachedAudio(audio);
        }
        void getData();
    }, [message]);

    async function playAudio(): Promise<void> {
        if (!message.attachedMedia || !attachedAudio) return;
        setIsPlaying(true);
        if (attachedAudio.currentTime === attachedAudio.duration) {
            attachedAudio.currentTime = 0;
        }
        attachedAudio.addEventListener("ended", ()=>{
            setIsPlaying(false);
        });
        try {
            await attachedAudio.play();
        } catch {
            toast.error("Este navegador no soporta la reproducción de audio.");
            setIsPlaying(false);
        }
    }

    function pauseAudio(): void {
        if (!message.attachedMedia || !attachedAudio) return;
        setIsPlaying(false);
        attachedAudio.pause();
    }

    const positionClass = (userId === message.author || unigowMsg) ? "justify-end" : "";
    let designClass = userId === message.author ? "bg-primary-transparent-10 rounded-l-xl shadow-primary-200" : "bg-gray-cool-100 rounded-r-xl";

    if (userId !== message.author && (unigowMsg || messageProps.mod)) {
        designClass = "bg-secondary-org-200 rounded-l-xl shadow-secondary-org-200";
    }

    async function deleteMessage(): Promise<void> {
        if (!isDashboard && message.author !== userId) return;

        if (!confirm("¿Estás seguro de que deseas eliminar este mensaje?")) return;

        await message.updateBody("");

        setDeleted(true);
        handleClose();
    }

    async function kickUser(): Promise<void> {
        if (!isDashboard || !message.author) return;

        if (confirm("¿Estás seguro de que deseas expulsar a este usuario?")) {
            await api.post<{userId: string}, {status: string}>(`groups/kick/${unigowMessage.twilioMsg.conversation.sid}`, {userId: message.author});
        }

        handleClose();
    }

    useEffect(()=>{
        if (message.body === "") {
            setDeleted(true);
        }
    }, [message.body]);

    return (
        <li className={twMerge("w-full flex relative group", positionClass, userId === message.author ? "" : "flex-row-reverse justify-end")}>
            {!deleted && (userId === message.author || (isDashboard && !unigowMessage.mod)) && (
                <div className={twMerge("group-hover:block hidden", open ? "block" : "")}>
                    <IconButton size="small" onClick={handleClick}
                        aria-controls={open ? "message-settings" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                    >
                        <KeyboardArrowDown/>
                    </IconButton>
                    <Menu open={open} onClose={handleClose}
                        id="message-settings"
                        anchorEl={openMenu}
                        MenuListProps={{
                            "aria-labelledby": "message-settings"
                        }}
                    >
                        <MenuItem onClick={deleteMessage}>Eliminar mensaje</MenuItem>
                        {isDashboard && isGroup && userId !== message.author && message.body && (
                            <Fragment>
                                <MenuItem onClick={()=>{
                                    toast.info(
                                        <p>
                                            <b>Modo de respuesta destacada activado.</b>
                                            <br/>
                                            Al enviar el mensaje se guardará automáticamente en la sección de mensajes destacados del grupo.
                                        </p>
                                        , {autoClose: 10000});
                                    setQuestionToPin(message.body!);
                                    handleClose();
                                }}
                                >Crear respuesta destacada
                                </MenuItem>
                                <MenuItem onClick={kickUser}>Expulsar usuario</MenuItem>
                            </Fragment>
                        )}
                    </Menu>
                </div>
            )}
            <div className="flex flex-col gap-2">
                {messageProps.displayName && userId !== message.author && (
                    <p className="text-gray-cool-400 text-xs lg:text-ug-sm">{messageProps.displayName} {messageProps.mod && messageProps.displayName !== "Unigow" ? "- Embajador" : ""}</p>
                )}
                <div className={twMerge("px-[37px] py-[15px] rounded-t-xl text-left leading-6 w-fit max-w-[50vw] break-words gap-3 flex flex-col", designClass, messageProps.answersTo ? "shadow-md" : "")}>
                    {attachedAudio ? (
                        <div className="flex flex-col justify-between mb-2">
                            <div className="flex items-center gap-4">
                                {isPlaying ? <Pause className="text-gray-500 cursor-pointer" onClick={pauseAudio}/> : <PlayArrow className="text-gray-500 cursor-pointer" onClick={playAudio}/>}
                                <Slider size="small" sx={{thumb:{height:10, width:10}}} value={currentTime * 100 / duration % 100 || 0} max={100} step={1} onChange={(e, value)=>{
                                    attachedAudio.currentTime = value as number * duration / 100;
                                }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col gap-2">
                            {messageProps.answersTo && (
                                <Fragment>
                                    <p className="text-sm text-gray-cool-500 flex items-center gap-2 line-clamp-2 cursor-pointer hover:text-primary-400" onClick={()=>selectPinnedQuestion(messageProps.answersTo!)}><Reply/>{messageProps.answersTo}</p>
                                    <Divider/>
                                </Fragment>
                            )}
                            <p className={twMerge("text-ug-lg text-black", message.author === userId ? "text-right" : "text-left", deleted ? "text-gray-600" : "")}>{!deleted ? message.body : "Este mensaje ha sido eliminado"}</p>
                        </div>
                    )}
                    {!deleted && (
                        <div className="w-full flex justify-between gap-4">
                            {message.attachedMedia && message.attachedMedia?.length > 0 && (
                                <p className="text-gray-400 text-sm lg:text-xs">{Math.round(duration / 60).toLocaleString("es-ES", {minimumIntegerDigits:2})}:{Math.round(duration % 60).toLocaleString("es-ES", {minimumIntegerDigits:2})}</p>
                            )}
                            {userId === message.author && (
                                <div className="flex justify-end w-full gap-1">
                                    {!!message.body && message.body !== "" && (!message.attachedMedia || message.attachedMedia.length === 0) && isDashboard && !isGroup && !isAnswerSaved(message.body) && (
                                        <UnigowButton className="py-0 text-xs bg-transparent border-black" variant="basic" onClick={()=>addAnswer(message.body!)}>Guardar respuesta</UnigowButton>
                                    )}
                                    <p className="text-gray-cool-400 text-ug-base flex items-center gap-1">{read ? <DoneAll className="text-primary-500"/> : <Check className="text-gray-500"/>} {formatTime(message.dateCreated || new Date(), "short")}</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </li>
    );
}