import {
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    getAdditionalUserInfo,
    onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword,
    signInWithPopup,
    signOut
} from "firebase/auth";
import React, {createContext, useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";

import {auth} from "@unigow/firebase";
import {
    setLocalStorage
} from "@unigow/helpers/localstorage";
import {CreateUser, LoggedUser} from "../types/user";
import {api} from "../apis/requests";
import * as Sentry from "@sentry/react";
import {useQuery} from "react-query";
import {useAuthStore} from "../stores/authStore";

interface AuthContextProps {
    children: React.ReactNode;
}

export type AuthContextType = {
    registerUser: (userInfo: CreateUser, password: string)=> Promise<void | LoggedUser>;
    loginUser: (email: string, password: string)=> Promise<void>;
    forgotPass: (email: string)=> Promise<void>;
    logoutUser: ({error, redirect}: {error?: boolean, redirect: string})=> Promise<void>;
    loading: boolean;
    resetPassword: (email: string)=> Promise<void>,
    signInWithGoogle: ()=> Promise<void>;
};

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);
export function useAuth(): AuthContextType {
    return useContext(AuthContext);
}

export function AuthProvider({children}: AuthContextProps): React.ReactElement {
    const provider = new GoogleAuthProvider();
    auth.languageCode = "es";

    const {setUserData, userData, setLoading, loading} = useAuthStore();
    const [token, setToken] = useState<string | null>(null);
    const [waiting, setWaiting] = useState(false);

    useQuery("user-data", async()=>{
        const userLoggedData = await api.get<LoggedUser>("users/me");
        const userInfo = await api.get<{info: Record<string, string | string[]>}>("userinfo");

        return {...userLoggedData, additionalInfo:userInfo.info};
    }, {
        enabled:!!token && !waiting,
        onSuccess:(data)=>{
            if (!data) return;

            setUserData(data);
            setLoading(false);

            if (window.dataLayer) {
                window.dataLayer.push({event:"user_data", logged:true});
            }
        },
        onError:(err)=>{
            Sentry.captureException(err);
            setUserData(null);

            setLoading(false);

            if (window.dataLayer) {
                window.dataLayer.push({event:"user_data", logged:false});
            }
        }, refetchOnWindowFocus:false});

    async function addUserToMongo(user: CreateUser): Promise<LoggedUser> {
        return api.post<CreateUser, LoggedUser>("users", user);
    }

    useEffect(()=>{
        if (userData) {
            Sentry.setTag("context", "logged");
            Sentry.setUser({email:userData.email, id:userData._id});
        } else {
            Sentry.setTag("context", "anonymous");
        }
    }, [userData]);

    useEffect(()=>{
        const unsuscribe = onAuthStateChanged(auth, async(user)=>{
            if (waiting) return;
            if (user) {
                const userToken = await user.getIdToken(true);
                setToken(userToken);

                setLocalStorage("firebase_token", userToken);
            } else {
                if (window.dataLayer) {
                    window.dataLayer.push({logged:false});
                }
                setUserData(null);
                setLoading(false);
            }
        });

        return ()=>{
            if (unsuscribe) unsuscribe();
        };
    }, [setUserData, setLoading, waiting]);

    async function resetPassword(email: string): Promise<void> {
        await sendPasswordResetEmail(auth, email);

        toast.success("Correo enviado con éxito, revisa la bandeja de entrada para restablecer tu contraseña");
    }

    async function logoutUser({error, redirect}: {error?: boolean, redirect: string}): Promise<void> {
        setUserData(null);

        if (window.dataLayer) {
            window.dataLayer.push({logged:false});
        }

        await signOut(auth);

        if (redirect === "#") {
            window.location.reload();
            return;
        }

        window.location.href = `${redirect}${error ? "?error=true" : ""}`;
        return;
    }

    async function registerUser(userInfo: CreateUser, password: string): Promise<void | LoggedUser> {
        const result = await createUserWithEmailAndPassword(auth, userInfo.email, password);

        setWaiting(true);

        const {user} = result;
        const userToken = await user.getIdToken();

        //we save firebase token on localStorage
        setLocalStorage("firebase_token", userToken);
        setToken(userToken);

        const newUser = await addUserToMongo(userInfo);

        setWaiting(false);

        if (window.dataLayer) {
            window.dataLayer.push({event: "sign_up", user_type: "user"});
        }
        return newUser;

    }

    async function forgotPass(email: string): Promise<void> {
        try {
            await sendPasswordResetEmail(auth, email);
            toast.success("El email para restablecer la contraseña ha sido enviado con éxito. \nSi no lo encuentras revisa la carpeta de Spam.", {className:"unigow-toast"});
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
            toast.error("Ha habido un error inesperado, inténtalo más tarde", {className:"unigow-toast"});
        }
    }

    async function loginUser(email: string, password: string): Promise<void> {
        const {user} = await signInWithEmailAndPassword(auth, email, password);

        const userToken = await user.getIdToken(true);

        setToken(userToken);

        setLocalStorage("firebase_token", userToken);
    }

    async function signInWithGoogle(): Promise<void> {
        setWaiting(true);
        const userCreds = await signInWithPopup(auth, provider);
        const additionalInfo = getAdditionalUserInfo(userCreds);
        const {user} = userCreds;

        if (!additionalInfo) return;

        const userToken = await user.getIdToken(true);

        setToken(userToken);

        setLocalStorage("firebase_token", userToken);

        const {isNewUser} = additionalInfo;

        if (isNewUser) {
            // Usuario se está registrando
            const name = user.displayName?.split(" ")[0] || "";
            const surnames = user.displayName?.split(" ").slice(1).join(" ") || "";

            const response = await addUserToMongo({email:user.email as string, name, surnames,
                profilePic:user.photoURL as string});
            setUserData(response);
            setLoading(false);

            if (window.dataLayer) {
                window.dataLayer.push({logged:true});
            }
        }
        setWaiting(false);
    }

    return (
        <AuthContext.Provider value={{registerUser, loginUser, forgotPass, logoutUser, loading, resetPassword,
            signInWithGoogle}}
        >
            {children}
        </AuthContext.Provider>
    );
}