import {Checkbox, FormControlLabel} from "@mui/material";
import React, {useState} from "react";
import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import {Dayjs} from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";

interface DateFilterProps {
    start: Dayjs | null,
    setStart: (v: React.SetStateAction<Dayjs | null>)=> void,
    end: Dayjs | null,
    setEnd: (v: React.SetStateAction<Dayjs | null>)=> void
}

function DateFilter(props: DateFilterProps): React.ReactElement {
    const {start, setStart, end, setEnd} = props;
    const [open, setOpen] = useState(false);

    return (
        <div className="flex px-[10px] rounded-[10px] bg-white text-gray-cool-500 text-ug-base flex-col lg:flex-row py-2 lg:py-[1px] shrink-0 border border-gray-cool-300 border-solid">
            <FormControlLabel value="female" control={(
                <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    onChange={(e, c)=>{
                        setOpen(c);
                        if (!c) {
                            setStart(null);
                            setEnd(null);
                        }
                    }}
                    size="small"
                    value={open}
                />
            )} label={open ? "Fecha entre" : "Filtrar por fecha"}
            />
            {open && (
                <div className="flex items-center text-gray-cool-500 text-ug-base gap-2 flex-col lg:flex-row">
                    <DatePicker value={start} onChange={(v)=>{
                        setStart(v);
                    }} format="DD/MM/YYYY"
                    sx={{
                        "&":{
                            width:"180px"
                        },
                        "& input": {
                            padding:"5px 10px"
                        }
                    }}
                    disableFuture
                    />
                    <p className="text-gray-cool-500 text-ug-base">y</p>
                    <DatePicker value={end} onChange={(v)=>{
                        setEnd(v);
                    }} format="DD/MM/YYYY"
                    sx={{
                        "&":{
                            width:"180px"
                        },
                        "& input": {
                            padding:"5px 10px"
                        }
                    }}
                    disableFuture
                    />
                </div>
            )}
        </div>
    );
}

export default DateFilter;