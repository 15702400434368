import React, {useState} from "react";
import UnigowButton from "../../../../components/UnigowButton/UnigowButton";
import ParameterOptions from "./ParameterOptions";
import {ParameterType} from "@unigow/unigow-types";
import {useChatsPluginStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/chatsPlugin.store";

interface ParameterFieldsProps {
    selectedOptions: ParameterType[];
    setSubStep: (v: React.SetStateAction<number>)=> void;
    goNext: ()=> void;
}

export default function ParameterFields({selectedOptions, setSubStep, goNext}: ParameterFieldsProps): React.ReactElement {
    // STORES
    const {pluginData} = useChatsPluginStore();

    // STATES
    const [selectedParam, setSelectedParam] = useState<ParameterType | undefined>(undefined);

    // FUNCTIONS
    function renderOptions(param: ParameterType): React.ReactElement {
        if (param.type === "free") {
            return <p className="text-ug-base italic text-gray-cool-400">Texto libre</p>;
        }

        if (param.type === "specific" && (!param.options || param.options.length === 0)) {
            return <UnigowButton variant="primary" onClick={()=>setSelectedParam(param)}>Añadir opciones</UnigowButton>;
        }

        const text = param.options?.join(", ");

        return (
            <div className="flex flex-col gap-[2px]">
                <p className="text-ug-base italic line-clamp-1">{text}</p>
                {((text && text.length > 25) || (param.type === "specific")) && (
                    <p className="text-ug-sm text-primary-500 underline cursor-pointer w-fit" onClick={()=>setSelectedParam(param)}>{param.type === "specific" ? "Editar" : "Ver"} opciones del campo</p>
                )}
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-4">
            {selectedParam && (
                <ParameterOptions param={selectedParam} clearParam={()=>setSelectedParam(undefined)}/>
            )}
            <ul className="flex flex-col gap-4">
                {selectedOptions.filter((o)=>["text", "selectfree"].includes(o.inputType))?.map((param)=>(
                    <li key={param._id.toString()} className="flex items-center gap-2">
                        <p className="text-ug-base font-semibold w-1/3 flex-shrink-0">{param.name === "main" ? pluginData?.settings.mainField : param.name}</p>
                        {renderOptions(param)}
                    </li>
                ))}
            </ul>
            <div className="flex gap-[10px] flex-col lg:flex-row">
                <UnigowButton variant="basic" onClick={()=>setSubStep(0)}>Cancelar</UnigowButton>
                <UnigowButton variant="secondary" type="submit" onClick={goNext}>Continuar al siguiente paso</UnigowButton>
            </div>
        </div>
    );
}