import {api} from "@unigow/apis/requests";
import {CheckPasswordRequest, CheckPasswordResponse} from "@unigow/unigow-types";

export const AuthAPI = {
    checkRegisterPassword: async function({type, password}: {type: string, password: string}): Promise<CheckPasswordResponse> {
        try {
            return await api.post<CheckPasswordRequest, CheckPasswordResponse>("clients/checkpass", {type, password});
        } catch {
            return {result:false, error:"unknown"};
        }
    }
};