import {api} from "@unigow/apis/requests";
import PopupWindow from "@unigow/components/PopupWindow/PopupWindow";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import React, {Fragment, useState} from "react";
import {useQuery} from "react-query";
import {toast} from "react-toastify";

export default function ClientApi(): React.ReactElement {
    const {clientInfo} = useDashboardStore();
    const [hasApi, setHasApi] = useState(false);
    const [generatedApiKey, setGeneratedApiKey] = useState<string | null>(null);

    const {isLoading} = useQuery(["api-key", clientInfo!._id], async()=>{
        return api.get<{hasApiKey: boolean}>(`auth/${clientInfo!._id}`);
    }, {enabled: !!clientInfo, onSuccess: (data)=>setHasApi(data.hasApiKey)});

    async function generateApiKey(): Promise<void> {
        const newApi = await api.post<null, {apiKey: string}>(`auth/${clientInfo!._id}/apikey`);

        setGeneratedApiKey(newApi.apiKey);
        setHasApi(true);
    }

    return (
        <Fragment>
            <PopupWindow open={!!generatedApiKey} title="Tu nueva clave de Api">
                <div className="flex flex-col gap-[15px]">
                    <h2>Clave de Api generada</h2>
                    <p>Esta es tu clave de Api, <span className="font-semibold">guárdala en un lugar seguro</span>. No podrás verla de nuevo. Haz click para copiarla.</p>
                    <div className="px-2 py-1 border border-solid border-gray-cool-300 rounded-[5px] flex-shrink-0 flex justify-between items-center blur-sm hover:blur-none transition-all">
                        <input readOnly className="text-ug-base text-cool-500 border-0 w-full cursor-pointer" value={generatedApiKey!}
                            onClick={async()=>{
                                await navigator.clipboard.writeText(generatedApiKey!);
                                toast.success("Clave copiada con éxito al portapapeles");
                            }}
                        />
                    </div>
                    <UnigowButton variant="secondary" onClick={()=>{
                        setGeneratedApiKey(null);
                    }}
                    >Ya he copiado la clave
                    </UnigowButton>
                </div>
            </PopupWindow>

            {!isLoading && (
                <div className="flex flex-col gap-4" id="api">
                    <h2>Clave de Api</h2>
                    <p>Si deseas conectar Unigow a aplicaciones externas como puede ser Zapier, necesitas una clave de API que identifique a tu
                        institución.<br/> <br />
                        Puedes generarla pulsando el botón a continuación:
                    </p>
                    <div>
                        <UnigowButton variant="basic" onClick={()=>{
                            let confirm = true;
                            if (hasApi) {
                                confirm = window.confirm("¿Estás seguro de que quieres generar una nueva clave de Api? La anterior dejará de funcionar.");
                            }

                            if (confirm) {
                                void generateApiKey();
                            }

                        }}
                        >{hasApi ? "Volver a g" : "G"}enerar clave de Api
                        </UnigowButton>
                    </div>
                </div>
            )}
        </Fragment>
    );
}