import {TableCell, TableRow} from "@mui/material";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {UnigowTable} from "@unigow/components/UnigowTable/UnigowTable";
import {generateCsv} from "@unigow/helpers/helper";
import {UserType} from "@unigow/unigow-types";
import React, {Children} from "react";

interface MembersTableProp {
    participants: (Pick<UserType, "name" | "email" | "phone"> & {
        timetable?: {
            start: Date;
            end: Date;
        }
    })[];

    type: "default" | "reservations";
}

export default function MembersTable({participants, type}: MembersTableProp): React.ReactElement {

    function getHeaders(): string[] {
        if (type === "reservations") return ["Horario", "Nombre", "Email", "Teléfono"];
        return ["Nombre", "Email", "Teléfono"];
    }

    function downloadParticipants(): void {
        const headers = getHeaders();

        const data = participants.map((participant)=>(participant.timetable ? [new Date(participant.timetable.start).toLocaleString("es-ES", {weekday:"short", day:"2-digit", month:"short", hour:"2-digit", minute:"2-digit"}), participant.name, participant.email, participant.phone || ""] : [participant.name, participant.email, participant.phone || ""]));

        generateCsv(headers, data, "participants.csv");
    }

    return (
        <div className="">
            <div className="flex justify-end">
                <UnigowButton variant="secondary" onClick={downloadParticipants}>Descargar CSV</UnigowButton>
            </div>
            <UnigowTable headerTitles={getHeaders()} elementCount={participants.length}>
                {Children.toArray(participants.map((participant)=>(
                    <TableRow>
                        {participant.timetable && (
                            <TableCell>Inicio: {new Date(participant.timetable.start).toLocaleString("es-ES", {weekday:"short", day:"2-digit", month:"short", hour:"2-digit", minute:"2-digit"})} <br /> Fin: {new Date(participant.timetable.end).toLocaleString("es-ES", {weekday:"short", day:"2-digit", month:"short", hour:"2-digit", minute:"2-digit"})}
                            </TableCell>
                        )}
                        <TableCell>{participant.name}</TableCell>
                        <TableCell>{participant.email}</TableCell>
                        <TableCell>{participant.phone}</TableCell>
                    </TableRow>
                )))}
            </UnigowTable>
        </div>
    );
}