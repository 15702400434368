import React, {useState} from "react";
import {ReactComponent as Verified} from "@unigow/assets/verified.svg";
import {twMerge} from "tailwind-merge";
import {useIframe} from "../../contexts/IframeContext";
import {useIframeStore} from "../../../../stores/iframeStore";
import {useIframeWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/iframeWizard.store";

function VerifiedBadge(): React.ReactElement {
    const {searchType} = useIframe();
    const {iframeData:dashboardData} = useIframeWizardStore();
    const {iframeData} = useIframeStore();
    const [show, setShow] = useState<"left" | "right" | null>(null);

    return (
        <div className="relative flex items-center">
            {show && (
                <div className={twMerge("absolute bg-white border border-solid border-primary-500 rounded-[10px] py-[6px] px-4 z-40",
                    show === "left" ? "right-6" : "left-6")}
                >
                    {(searchType !== "staff" || !!dashboardData) ? (
                        <p className="w-[28ch]">
                            <span className="font-semibold">Persona real</span> que comparte su experiencia, verificado por <span className="text-primary-500 font-semibold">Unigow</span>
                        </p>
                    ) : (
                        <p className="w-[18ch]">
                            <span className="font-semibold">Staff oficial</span> de <span className="text-primary-500 font-semibold">{iframeData?.clientInfo.name}</span>
                        </p>
                    )}
                </div>
            )}
            <Verified className="cursor-help" onMouseEnter={(e)=>{
                const distanceToRight = window.innerWidth - e.clientX;

                if (distanceToRight > window.innerWidth / 2) {
                    setShow("right");
                } else {
                    setShow("left");
                }
            }} onMouseLeave={()=>setShow(null)}
            />
        </div>
    );
}

export default VerifiedBadge;