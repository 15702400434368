import {Breadcrumbs, Link, Tab, Tabs} from "@mui/material";
import {useIframeWizardStore} from "./stores/iframeWizard.store";
import React, {Fragment, useEffect} from "react";
import {Outlet, useNavigate, useParams, Link as RouterLink} from "react-router-dom";
import {Dashboard, GroupsOutlined,  QuestionAnswerOutlined} from "@mui/icons-material";

export function getTitleSubtitle(mode?: string): {title: string, subtitle: string, name: string, icon?: React.ReactElement} {
    switch (mode) {
        case "chats":
            return {
                title: "Configura el Chat Peer-to-Peer",
                subtitle: "En esta página puedes configurar el Chat peer-to-peer para que tus usuarios puedan comunicarse con tus embajadores",
                name: "Chat Peer-to-Peer",
                icon: <QuestionAnswerOutlined/>
            };
        case "groups":
            return {
                title: "Maximiza la interacción con los grupos",
                subtitle: "En esta página puedes crear grupos a medida para integrarlos en tu web y conectar a personas que tengan intereses o dudas similares",
                name: "Chat Grupal",
                icon: <GroupsOutlined/>
            };
        case "landings":
            return {
                title: "Crea una Landing Page",
                subtitle: "No esperes a que tu equipo de tecnología integre el código en tu página. Crea una landing page en esta pestaña, registra a tus embajadores y empieza a hacer campañas de tráfico sin límites. Elige el Chat peer-to-peer que quieres utilizar para crear la landing",
                name: "Landing Page"
            };
        case "cards":
            return {
                title:"Configura las tarjetas de tu herramienta",
                subtitle:"En esta página puedes configurar las tarjetas de tu herramienta",
                name:"Tarjetas Interactivas",
                icon: <Dashboard/>
            };
        default:
            return {
                title: "Realiza la configuración básica de tu herramienta",
                subtitle: "Empieza a configurar los plugins de tu herramienta",
                name: "Configuración Básica"
            };
    }
}

export default function IframeWizard(): React.ReactElement {
    // Url will be /iframes/:iframeAlias/:configmode. Get the config mode from the url
    const currentUrl = window.location.pathname.split("/");
    const currentMode = currentUrl.length > 3 ? currentUrl.pop() : "";

    const navigate = useNavigate();
    const {iframeAlias} = useParams();
    const {clientIframes, setIframeData, iframeData} = useIframeWizardStore();

    // Get currentpage from last element of the url

    useEffect(()=>{
        function getIframeData(): void {
            if (!clientIframes || iframeAlias === "create") {
                return;
            }

            const iframe = clientIframes.find((ifr)=>ifr.alias === iframeAlias);

            if (!iframe) {
                return;
            }

            setIframeData(iframe);
        }

        getIframeData();

        return ()=>{
            setIframeData(undefined);
        };
    }
    , [clientIframes, iframeAlias, setIframeData]);

    return (
        <Fragment>
            <div className="flex flex-col gap-2 px-4 lg:px-8">
                <Breadcrumbs className="text-xs" separator=">" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/iframes" component={RouterLink}>
                        Mis herramientas
                    </Link>
                    <Link underline="hover" color="inherit" to={`/iframes/${iframeAlias}`} component={RouterLink}>
                        {iframeData?.name || "Nueva herramienta"}
                    </Link>
                    <Link underline="none" color="inherit">
                        {getTitleSubtitle(currentMode).name}
                    </Link>
                </Breadcrumbs>
                <div className="flex flex-col gap-3 items-start">
                    <h1>{getTitleSubtitle(currentMode).title}</h1>
                    <p className="text-ug-lg break-words">{getTitleSubtitle(currentMode).subtitle}</p>
                </div>
            </div>
            <div className="w-full border-gray-cool-400 border-0 border-t border-solid flex flex-col sm:flex-row">
                <div className="sm:h-full sm:w-1/5 shrink-0 grow-0">
                    <Tabs value={currentMode} orientation="vertical" className="bg-white" onChange={(_, v)=>{
                        // Get base url, which is iframes/:iframeAlias from the current url.
                        let baseUrl = window.location.pathname;

                        if (baseUrl.split("/").length > 3) {
                            baseUrl = baseUrl.split("/").slice(0, 3).join("/");
                        }

                        navigate(`${baseUrl}/${v}`);
                    }}
                    >
                        <Tab value="" className="max-w-none text-left items-start border border-gray-cool-400 text-black border-solid disabled:text-gray-cool-400 border-r-0 border-t-0" label="Configuración Básica"/>
                        <Tab value="chats" className="max-w-none text-left items-start border border-gray-cool-400 text-black border-solid disabled:text-gray-cool-400 border-r-0" label="Chat Peer-to-Peer" disabled={!iframeData || !iframeData.plugins.includes("chats")}/>
                        <Tab value="groups" className="max-w-none text-left items-start border border-gray-cool-400 text-black border-solid disabled:text-gray-cool-400 border-r-0" label="Grupos" disabled={!iframeData || !iframeData.plugins.includes("groups")}/>
                        <Tab value="cards" className="max-w-none text-left items-start border border-gray-cool-400 text-black border-solid disabled:text-gray-cool-400" label="Tarjetas Interactivas" disabled={!iframeData || !iframeData.plugins.includes("cards")}/>
                        <Tab value="landings" className="max-w-none text-left items-start border border-gray-cool-400 text-black border-solid disabled:text-gray-cool-400 border-r-0" label="Landing Page" disabled={!iframeData || iframeData.plugins.length === 0}/>
                    </Tabs>
                </div>
                <div className="flex-grow bg-white px-8 py-4 border-b border-l border-0 border-gray-cool-400 border-solid rounded-bl-md">
                    <Outlet/>
                </div>
            </div>
        </Fragment>
    );
}