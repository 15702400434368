import {AddAPhoto} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import ImageUploader from "@unigow/components/ImageUploader/ImageUploader";
import {DisplayGroup} from "@unigow/types/group";
import React from "react";

interface EditableCardTopProps {
    setIconUrl?: React.Dispatch<React.SetStateAction<string>>,
    setName?: React.Dispatch<React.SetStateAction<string>>
    color?: string,
    subtitle?: string
}

export default function CardTop({iconUrl, name, subtitle, setIconUrl, setName, color}: EditableCardTopProps & Pick<DisplayGroup, "iconUrl" | "name">): React.ReactElement {
    return (
        <div className="flex flex-row gap-4 py-[9px] px-4 items-center rounded-t-[10px]" style={{backgroundColor:`${color}0F`}}>
            {setIconUrl ? (
                <ImageUploader firebaseFolder="groupIcons" firebaseSufix="gropIcon" buttonText="Guardar Logo"
                    variableSetter={setIconUrl} aspectRatio={1}
                >
                    <IconButton className="w-[50px] h-[50px] flex flex-shrink-0 rounded-full bg-white">
                        {iconUrl ? (
                            <img className="rounded-full w-full" src={iconUrl} alt="" />
                        ) : (
                            <AddAPhoto className="border border-solid border-gray-cool-400 rounded-full p-[1rem]"/>
                        )}
                    </IconButton>
                </ImageUploader>
            ) : (
                <div className="w-[50px] h-[50px] flex flex-shrink-0 justify-center items-center rounded-full bg-white">
                    {iconUrl ? (
                        <img className="rounded-full w-full" src={iconUrl} alt="" />
                    ) : (
                        <AddAPhoto/>
                    )}
                </div>
            )}
            <div className="flex flex-col justify-center shrink-0" style={{minHeight:"3lh", width:"calc(100% - 50px)"}}>
                <div className="flex gap-1 flex-col pl-4">
                    {setName ? (
                        <input
                            required
                            className="text-ug-lg font-semibold w-full bg-transparent border-none focus:outline-none p-0"
                            value={name}
                            onChange={(e)=>setName(e.target.value)}
                            placeholder="Escribe el título"
                        />
                    ) : (
                        <p className="text-ug-lg font-semibold">{name}</p>
                    )}
                    <p className="text-gray-cool-500 text-xs font-light">{subtitle}</p>
                </div>
            </div>
        </div>
    );
}