import {Chip, IconButton} from "@mui/material";
import {useIframeWizardStore} from "./stores/iframeWizard.store";
import React, {Children, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {AddBoxOutlined} from "@mui/icons-material";
import {getTitleSubtitle} from "@unigow/pages/Dashboard/pages/Iframes/IframeWizard";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";

function calculateTextColor(hex: string): string {
    // If the color is dark, return white, else return black

    // Get the rgb values
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);

    // Calculate the luminance
    const luminance = ((0.299 * r) + (0.587 * g) + (0.114 * b)) / 255;

    return luminance > 0.5 ? "#000000" : "#ffffff";
}

export default function IframeSelector(): React.ReactElement {
    const {clientInfo} = useDashboardStore();
    const {clientIframes} = useIframeWizardStore();
    const navigate = useNavigate();

    useEffect(()=>{
        if (!clientInfo) return;

        if (!clientInfo.permissions.manager) {
            navigate("/chats");
        }
    }, [clientInfo, navigate]);

    return (
        <div className="flex flex-col gap-4 px-4 lg:px-8 w-full">
            <div className="flex flex-col gap-3 items-start">
                <h1>¡Bienvenido a Unigow!</h1>
                <p className="text-ug-lg break-words">Comienza a configurar las diferentes herramientas que te ayudarán a ser más relevante para los jóvenes</p>
            </div>
            <ul className="flex flex-wrap gap-2 pb-8">
                {Children.toArray(clientIframes?.map((iframe)=>(
                    <li className="lg:w-[calc(33%-1.5rem)] md:w-[calc(50%-1.5rem)] w-[calc(100%-1.5rem)] flex justify-between flex-col rounded-[10px] overflow-hidden h-[260px] bg-white">
                        <div className="flex flex-col">
                            <div className="w-full h-4" style={{backgroundColor:iframe.style.mainColor}}></div>
                            <div className="p-4 flex flex-col gap-4 pb-0">
                                <p className="line-clamp-2 text-ug-lg font-semibold">{iframe.name}</p>
                                <p className="text-ug-base">#{iframe.alias}</p>
                                <p className="font-semibold text-ug-base">Plugins activos</p>
                                <ul className="flex gap-2 flex-wrap max-h-[60px] overflow-y-auto">
                                    {Children.toArray(iframe.plugins.map((plugin)=>(
                                        <Chip size="small" label={getTitleSubtitle(plugin).name} color="default" />
                                    )))}
                                </ul>
                            </div>
                        </div>
                        <div className="p-4">
                            <UnigowButton style={{backgroundColor: iframe.style.mainColor, color:calculateTextColor(iframe.style.mainColor)}} className="w-full" variant="basic" onClick={()=>navigate(`${iframe.alias}`)}>Editar herramienta</UnigowButton>
                        </div>
                    </li>
                )))}
                <li className="lg:w-[calc(33%-1.5rem)] md:w-[calc(50%-1.5rem)] w-[calc(100%-1.5rem)] flex justify-center items-center rounded-[10px] overflow-hidden h-[260px] bg-white" onClick={()=>navigate("create")}>
                    <IconButton>
                        <AddBoxOutlined/>
                    </IconButton>
                </li>
            </ul>
        </div>
    );
}