import React from "react";
import StubPopup from "./StubPopup";
import {usePopupStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/popup.store";
import {useChatsPluginStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/chatsPlugin.store";

function PopupPreview(): React.ReactElement {
    const {popupData} = usePopupStore();
    const {pluginData} = useChatsPluginStore();

    return (
        <div className="flex items-center text-left flex-col">
            <div className="flex justify-between w-full items-center mb-2">
                <p className="text-xs font-semibold">Vista previa</p>
            </div>
            <div className="">
                {popupData ? (
                    <StubPopup popupData={{...popupData, mainField:pluginData?.settings.mainField || ""}}/>
                ) : (
                    <StubPopup popupData={{
                        style:{
                            mainColor:"#0034BB",
                            size:"normal",
                            fontFamily:"Arial",
                            longText:"Descubre las experiencias reales de estudiantes. Resolverán todas tus dudas ;)",
                            shortText:"Chatea con estudiantes reales"
                        },
                        settings:{
                            redirectLink:"https://unigow.com"
                        },
                        mainField:"Info"
                    }}
                    />
                )}
            </div>
        </div>
    );
}

export default PopupPreview;