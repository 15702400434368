import {useQuery} from "react-query";
import {useIframeWizardStore} from "../../stores/iframeWizard.store";
import React from "react";
import {useCardWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/cardsPlugin.store";
import {CardsPluginAPI} from "@unigow/apis/iframe";
import PageContainer from "@unigow/components/PageContainer/PageContainer";
import {Tab, Tabs} from "@mui/material";
import Cards from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/Cards";
import CardConfig from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CardPluginConfig/CardPluginConfig";

export default function CardsPluginWizard(): React.ReactElement {
    const {iframeData} = useIframeWizardStore();
    const {setConfigPage, setCardPageData, selectCardPage, cardPageData, configPage} = useCardWizardStore();

    useQuery(["plugincards", iframeData], async()=>{
        return CardsPluginAPI.getIframeCardsPlugin({iframeId:iframeData!._id.toString()});
    }, {
        enabled: !!iframeData,
        onSuccess:(res)=>{
            if (res.length === 0) return;

            const [firstPlugin] = res;

            setCardPageData(firstPlugin);
            selectCardPage(firstPlugin._id.toString());
        }
    });

    function isOptionEnabled(option: number): boolean {
        if (option >= 1) {
            return !!cardPageData?.alias;
        }
        return true;
    }

    return (
        <div className="flex flex-col gap-4">
            <PageContainer className="gap-[20px] items-start select-none w-fill p-0 lg:p-0">
                <div className="bg-white w-[calc(90%-2rem)] rounded-r-xl lg:px-8 py-4 px-4 flex gap-4 flex-col">
                    <Tabs value={configPage} onChange={(_, v)=>setConfigPage(v)}>
                        <Tab value="config" label="Configuración"/>
                        <Tab value="cards" label="Activación de tarjetas" disabled={!isOptionEnabled(1)}/>
                    </Tabs>
                    <div className=" flex gap-8 flex-col lg:flex-row">
                        {configPage === "config" && (
                            <CardConfig/>
                        )}
                        {configPage === "cards" && (
                            <Cards/>
                        )}
                    </div>
                </div>
            </PageContainer>
        </div>
    );
}