import React, {useState} from "react";
import {api} from "../../../../apis/requests";
import PopupWindow from "../../../../components/PopupWindow/PopupWindow";
import {IconButton} from "@mui/material";
import {DeleteOutlineOutlined} from "@mui/icons-material";
import UnigowButton from "../../../../components/UnigowButton/UnigowButton";
import EditableText from "../../../../components/EditableText/EditableText";
import {useDashboardStore} from "../../../../stores/dashboard/dashboardStore";
import {ParameterType} from "@unigow/unigow-types";

interface ParameterOptionsProps {
    param: ParameterType;
    clearParam: ()=> void;
}

function ParameterOptions({param, clearParam}: ParameterOptionsProps): React.ReactElement | null {
    // STORES
    const {selectedClient, modifyClientParamOptions} = useDashboardStore();

    // STATES
    const [options, setOptions] = useState<string[]>(param.options || []);
    const [newOpt, setNewOpt] = useState("");

    // FUNCTIONS
    async function updateParams(opts: string[]): Promise<void> {
        if (!param || JSON.stringify(opts) === JSON.stringify(options)) return;

        const res = await api.post<{alias: string, options: string[]}, {options: string[]}>(`parameters/${selectedClient}`, {alias:param.alias, options:opts});

        if (res) {
            setOptions(res.options);
            modifyClientParamOptions(param.alias, res.options);
            return;
        }
    }

    if (!param) {
        return null;
    }

    return (
        <PopupWindow title="Selector de opciones" open={param !== undefined} onClose={clearParam} closePopup={clearParam}>
            <div className="flex flex-col gap-[15px] lg:min-w-[500px]">
                {param.type === "general" ? (
                    <div className="flex flex-col gap-[5px] text-center">
                        <h3>Visualiza todas las opciones de la categoría</h3>
                        <p className="text-ug-sm">Este popup tiene como objetivo que puedas ver todas las opciones disponibles de la categoría</p>
                    </div>
                ) : (
                    <div className="flex flex-col gap-[5px] text-center">
                        <h3>Añade las opciones de la categoría</h3>
                    </div>
                )}
                <div className="flex gap-2 flex-col lg:flex-row">
                    <p className="text-ug-base font-semibold lg:w-1/6 shrink-0 text-center">{param.name}</p>
                    <ul className="flex flex-wrap gap-[3px] ">
                        {options?.map((opt)=>(
                            <li key={opt} className="rounded-[10px] bg-gray-cool-100 px-[10px] flex items-center">
                                {param.type === "specific" ? (
                                    <EditableText value={opt} auxValue={newOpt} setAuxValue={setNewOpt} onEnter={(v)=>{
                                        const value = v.trim().normalize();
                                        setNewOpt("");
                                        if (!options.includes(value)) {
                                            void updateParams(options.map((x)=>{
                                                return x === opt ? value : x;
                                            }));
                                        } else {
                                            void updateParams(options.filter((x)=>x !== "Nueva opción"));
                                        }
                                    }}
                                    />
                                ) : (
                                    <p>{opt}</p>
                                )}
                                {param.type === "specific" && (
                                    <IconButton size="small" onClick={()=>{
                                        void updateParams(options.filter((x)=>x !== opt));
                                    }}
                                    >
                                        <DeleteOutlineOutlined color="error"/>
                                    </IconButton>
                                )}
                            </li>
                        ))}
                        {param.type === "specific" && (
                            <div className="">
                                <UnigowButton variant="primary" disabled={newOpt !== "" || options.includes("Nueva opción")} onClick={()=>{
                                    setOptions((prev)=>{
                                        if (!prev.includes("Nueva opción")) {
                                            return [...prev, "Nueva opción"];
                                        }
                                        return prev;
                                    });
                                }}
                                >Añadir opción
                                </UnigowButton>
                            </div>
                        )}
                    </ul>
                </div>
            </div>
        </PopupWindow>
    );
}

export default ParameterOptions;