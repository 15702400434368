import React, {useState} from "react";
import UnigowInput from "../../../components/UnigowInput/UnigowInput";
import UnigowButton from "../../../components/UnigowButton/UnigowButton";
import {AuthAPI} from "@unigow/apis/auth";
import {toast} from "react-toastify";
import {isEmpty} from "class-validator";

interface PasswordFormProps {
    setMasterPassword: React.Dispatch<React.SetStateAction<string>>;
    setTargetClient: React.Dispatch<React.SetStateAction<string>>;
    type: "manager" | "orientator";
}

export function PasswordForm({setTargetClient, setMasterPassword, type}: PasswordFormProps): React.ReactElement {
    const [password, setPassword] = useState("");

    async function handlePasswordCheck(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (isEmpty(password)) {
            toast.error("La contraseña maestra no puede estar vacía");
            return;
        }

        const response = await AuthAPI.checkRegisterPassword({type, password});

        if (!response.result) {
            if (response.error === "unknown") {
                toast.error("Ha ocurrido un error al verificar la contraseña maestra");
            } else {
                toast.error("La contraseña maestra introducida no es válida");
            }
            return;
        }

        setMasterPassword(password);
        setTargetClient(response.client);
    }

    return (
        <form className="flex flex-col gap-4 xl:w-3/4 px-4" onSubmit={handlePasswordCheck}>
            <div className="flex flex-col gap-3">
                <h3 className="text-primary-500">Contraseña Maestra</h3>
                <input hidden type="text" name="username" autoComplete="username"/>
                <UnigowInput required placeholder="Contraseña Maestra" type="password" autoComplete="current-password"
                    value={password} onChange={(e)=>setPassword(e.target.value)}
                />
            </div>
            <div className="flex justify-between gap-3">
                <UnigowButton className="w-full" variant="secondary">Continuar</UnigowButton>
            </div>
            <div className="flex justify-start w-full">
                <a href="/login" className="text-primary-500 text-ug-sm underline font-medium">Ya tengo cuenta, quiero iniciar sesión</a>
            </div>
        </form>
    );
}