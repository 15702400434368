
import {Spinner} from "flowbite-react";
import React, {useEffect, useState} from "react";

import {Autocomplete, IconButton, Switch, TableCell, TableRow} from "@mui/material";
import {UnigowTable} from "@unigow/components/UnigowTable/UnigowTable";
import {api} from "../../../../apis/requests";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import {twMerge} from "tailwind-merge";
import {FullOrientator} from "../../../../types/user";
import {DeleteOutlineOutlined} from "@mui/icons-material";
import {toast} from "react-toastify";
import MentorsInvite from "./components/MentorsInvite";
import {ReactComponent as Avatar} from "@unigow/assets/avatarGrey.svg";
import UnigowInput from "../../../../components/UnigowInput/UnigowInput";
import {useCustomQuery} from "../../../../hooks/useCustomQuery";
import {ReactComponent as Zoom} from "@unigow/assets/search.svg";
import {useNavigate} from "react-router-dom";
import {useDashboardStore} from "../../../../stores/dashboard/dashboardStore";
import {useQuery} from "react-query";
import {useCustomLocale} from "../../../../contexts/CustomLocaleContext";
import {ChatsPluginAPI} from "@unigow/apis/iframe";
import {ChatsPluginType} from "@unigow/unigow-types";

export default function Mentors(): React.ReactElement {
    // MISCELLANEOUS
    const navigate = useNavigate();

    // STORES
    const {clientInfo} = useDashboardStore();
    const {t} = useCustomLocale();

    // STATES
    const [email, setEmail] = useState("");
    const [chatPlugin, setChatPlugin] = useState<ChatsPluginType | null>(null);
    const [mentors, setMentors] = useState<FullOrientator[]>([]);
    const [loadingMentors, setLoadingMentors] = useState(true);

    // QUERIES
    const {isLoading, data:chatPlugins = []} = useQuery(["chatplugins", clientInfo], async()=>{
        return ChatsPluginAPI.getClientChatsPlugins({clientId:clientInfo!._id.toString()});
    }, {
        enabled: (clientInfo?.permissions.manager) && !!clientInfo,
        onSuccess:(data)=>{
            if (data.length > 0) {
                setChatPlugin(data[0]);
            }
        }
    });

    useCustomQuery(["mentors", chatPlugin, email], async()=>{
        setLoadingMentors(true);
        return api.get<FullOrientator[]>(`pluginchats/${chatPlugin?._id}/orientators/all${email ? `?email=${email}` : ""}`);
    }, {debounce:300, enabled:clientInfo?.permissions?.manager && !!chatPlugin,
        onSuccess:(data)=>{
            setMentors(data);
            setLoadingMentors(false);
        }
    });

    useEffect(()=>{
        setLoadingMentors(true);
        setMentors([]);
    }, [chatPlugin]);

    // EFFECTS
    useEffect(()=>{
        setLoadingMentors(true);
        setChatPlugin(null);
        setMentors([]);

        if (!clientInfo) return;

        if (!clientInfo.permissions?.manager) {
            navigate("/chats");
        }
    }, [clientInfo, navigate]);

    function deleteOrientator(): void {
        alert("En mantenimiento");
    }

    async function toggleMentor(mentor: string, action: "enable" | "disable"): Promise<void> {
        const res = await api.patch<{action: string}, {message: string}>(`orientators/${mentor}/toggle`, {action});

        if (res) {
            toast.success("Embajador actualizado con éxito");

            setMentors((prevMentors)=>prevMentors.map((prevMentor)=>prevMentor._id === mentor ? {...prevMentor, enabled:action === "enable"} : prevMentor));
        }
    }

    function renderTable(): React.ReactElement {
        if (!mentors || mentors.length === 0) {
            return (
                <div className="p-10">
                    <p className="text-ug-base text-gray-cool-500">No se han encontrado embajadores</p>
                </div>
            );
        }

        return (
            <div className="bg-white shadow-lg rounded-[20px] w-[calc(100vw-3rem)] lg:w-full">
                <UnigowTable headerTitles={clientInfo!.partner ? ["¿Activado?", "Nombre", t(chatPlugin?.settings.mainField || ""), "Interacciones", "Tiempo", "Cliente", "Echar"] : ["¿Activado?", "Nombre", "Email", "Interacciones", "Tiempo", "Echar"]} elementCount={mentors.length}>
                    {mentors?.map((orientator)=>(
                        <TableRow key={orientator._id}>
                            <TableCell>
                                <Switch checked={orientator.enabled} onChange={()=>toggleMentor(orientator._id, orientator.enabled ? "disable" : "enable")}/>
                            </TableCell>
                            <TableCell>
                                <div className="flex items-center gap-4">
                                    {orientator.userInfo?.profilePic ? (
                                        <img className="w-[24px] h-[24px] rounded-full flex-shrink-0" src={orientator.userInfo?.profilePic} alt=""/>
                                    ) : (
                                        <Avatar className="w-[24px] h-[24px] flex-shrink-0"/>
                                    )}
                                    <p className="text-ug-base text-black">{orientator.userInfo.name} {orientator.userInfo.surnames}</p>
                                </div>
                            </TableCell>
                            {clientInfo!.partner ? (
                                <TableCell>
                                    <p className="text-ug-base text-gray-cool-500">{orientator.userInfo.info.info[chatPlugin?.settings.mainField || ""]}</p>
                                </TableCell>
                            ) : (
                                <TableCell>
                                    <p className="text-ug-base text-gray-cool-500">{orientator.userInfo.email}</p>
                                </TableCell>
                            )}
                            <TableCell>
                                <p className={twMerge("text-ug-base px-2 py-1 rounded-full text-black w-fit")}>{orientator.chatCount} chats</p>
                            </TableCell>
                            <TableCell>
                                <p className={twMerge("text-ug-base px-2 py-1 rounded-full text-black w-fit")}>{Math.floor((orientator.messageCount || 0) * 0.33)} minutos</p>
                            </TableCell>
                            {clientInfo!.partner && (
                                <TableCell>
                                    <p className="text-ug-base text-gray-cool-500">{orientator.realClient?.name || clientInfo!.name}</p>
                                </TableCell>
                            )}
                            <TableCell>
                                <IconButton onClick={()=>deleteOrientator()}>
                                    <DeleteOutlineOutlined color="error"/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </UnigowTable>
            </div>
        );
    }

    return (
        <PageContainer className="px-8 overflow-x-hidden h-max">
            <div className="flex justify-between items-start gap-4 lg:items-center w-full flex-col lg:flex-row">
                <div className="flex flex-col gap-3 items-start">
                    <h1>Embajadores</h1>
                    <p className="text-ug-lg">En esta pestaña puedes administrar a tus embajadores y ver cuánto interactúa cada uno</p>
                </div>
                <div className="">
                    <MentorsInvite/>
                </div>
            </div>
            <div className="my-4 flex w-full gap-4">
                <div className="relative">
                    <UnigowInput className="border-none bg-white" value={email} onChange={(e)=>{
                        setEmail(e.target.value);
                    }} placeholder="Busca por un correo"
                    InputProps={{className:"pl-8"}}
                    />
                    <div className="absolute left-0 flex justify-center items-center w-[40px] h-[40px] top-0">
                        <Zoom className="text-primary-500"/>
                    </div>
                </div>
                {!!chatPlugins && chatPlugins.length > 0 && (
                    <Autocomplete
                        className="w-[20ch] bg-white rounded-lg"
                        options={chatPlugins}
                        getOptionLabel={(option)=>option.alias}
                        renderInput={(params)=>(
                            <UnigowInput {...params} placeholder="Filtrar por iframe" className="border-none"/>
                        )}
                        value={chatPlugin}
                        onChange={(_, value)=>{
                            setChatPlugin(value);
                        }}
                        groupBy={(option)=>typeof option === "object" ? "Proyectos" : ""}
                    />
                )}
            </div>
            <div className="bg-white mx-10 shadow-lg overflow-x-auto max-h-[700px] w-full rounded-[20px]">
                {!clientInfo || loadingMentors || isLoading ? (
                    <div className="p-10">
                        <Spinner/>
                    </div>
                ) : renderTable()}
            </div>
        </PageContainer>
    );
}