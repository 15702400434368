import {toast} from "react-toastify";
import {api} from "../apis/requests";
import {TwilioChat, CreateAproval, CreateChat} from "../types/chat";
import {removeLocalStorage} from "./localstorage";
import * as Sentry from "@sentry/react";
import {UnigowError} from "@unigow/types/error";
import {DisplayGroup} from "@unigow/types/group";

export async function createChatHelper(createChatDto: CreateChat, goToChat: (v: string)=> void, resetPendingAction: ()=> void): Promise<void> {
    try {
        resetPendingAction();
        const res = await api.post<CreateChat, TwilioChat>("chats", createChatDto);

        // Send GTM event
        if (window.dataLayer && createChatDto) {
            window.dataLayer.push({event: "new_chat", main_category: createChatDto.mainField, client: createChatDto.client});
        }

        goToChat(res.SID);
    } catch (err) {
        Sentry.captureException(err);
        toast.error("Ya tienes un chat con ese embajador");
    }
}

export async function joinGroupHelper(group: DisplayGroup, goToGroup: (v: string)=> void): Promise<void> {
    removeLocalStorage("pendingGroup");

    const response = await api.post<void, {status: string}>(`groups/join/${group._id}`).catch((e)=>{
        const error = e as UnigowError;

        switch (error.errorCode) {
            case "group/banned": {
                toast.error("No puedes unirte a este grupo porque fuiste baneado");
                return;
            }
            case "group/max":{
                toast.error("Solo puedes estar en 5 grupos a la vez");
                return;
            }
            case "group/full":{
                toast.error("El grupo está lleno");
                return;
            }
            default:{
                toast.error("Hubo un error al unirte al grupo");
                return;
            }
        }
    });

    if (response && response.status === "ok") {
        goToGroup(group.SID);
    }
}

export async function createAprovalHelper(createAprovalDto: CreateAproval, resetPendingAction: ()=> void): Promise<void> {
    try {
        resetPendingAction();

        await api.post<CreateAproval, TwilioChat>("aprovals", createAprovalDto);
    } catch (err) {
        Sentry.captureException(err);
        toast.error("Ya has hecho una solicitud a este embajador");
    }
}

export function orientatorAnsweredQuestions(iframeParams?: string[], userParams?: Record<string, string | string[]>): boolean {
    if (!iframeParams || iframeParams.length === 0) return true;

    if (!userParams) return false;

    const userKeys = Object.keys(userParams || {});
    const iframeKeys = iframeParams.sort((a, b)=>{
        if (a < b) {
            return 1;
        }
        return -1;
    });

    for (const key of userKeys) {
        if (userParams[key].length === 0) {
            return false;
        }
    }

    return iframeKeys.every((x)=>userKeys.includes(x));
}

export function userAnsweredQuestions(requirizator: (v: string[], minor: boolean)=> string[], userIsMinor: boolean, iframeParams?: string[],
    userParams?: Record<string, string | string[]>): string[] {
    // Añado la función para comprobar que solo los campos requeridos están en el objeto para garantizar que esto se está haciendo
    const onlyRequiredFields = requirizator(iframeParams || [], userIsMinor);

    // Si no existen userParams, que la función no moleste
    if (!onlyRequiredFields || onlyRequiredFields.length === 0) return [];

    // Si existen userParams pero el usuario no tiene datos, no molestarse en seguir
    if (!userParams) return onlyRequiredFields;

    const userKeys = Object.keys(userParams || {});
    const iframeKeys = onlyRequiredFields.sort((a, b)=>{
        if (a < b) {
            return 1;
        }
        return -1;
    });

    const missingFields = [];

    for (const key of userKeys) {
        if (userParams[key].length === 0) {
            missingFields.push(key);
        }
    }

    return iframeKeys.filter((x)=>!userKeys.includes(x)).concat(missingFields);
}