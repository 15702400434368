import React, {Children, Fragment} from "react";

import {useIframe} from "../contexts/IframeContext";
import {BuildingLibraryIcon, ChatBubbleLeftRightIcon, SparklesIcon, UserGroupIcon, UserIcon} from "@heroicons/react/24/outline";
import {twMerge} from "tailwind-merge";
import {useAuthStore} from "../../../stores/authStore";
import {setLocalStorage} from "../../../helpers/localstorage";
import {IframeWithPlugins} from "@unigow/types/iframe";

interface IframeHeaderProps {
    backgroundColor?: string;
    iframeData: IframeWithPlugins
}

export default function IframeHeader({backgroundColor, iframeData}: IframeHeaderProps): React.ReactElement {
    // STORES
    const {userData} = useAuthStore();
    const {setCurrentPage, currentPage, setSearchType, searchType} = useIframe();

    return (
        <div className="flex justify-around pt-4 pb-2 items-baseline" style={{backgroundColor:backgroundColor || "white"}}>
            {Children.toArray(iframeData.pluginData.map((pluginData)=>{

                switch (pluginData.type) {
                    case "chat":
                        return (
                            <Fragment>
                                <button onClick={()=>{
                                    setSearchType("mentor");
                                    setCurrentPage(`plugin-${pluginData._id}`);
                                }} className={twMerge("flex flex-col items-center gap-1 bg-transparent border-none cursor-pointer", (currentPage.includes(pluginData._id.toString()) && searchType === "mentor") ? "text-primary-500" : "")}
                                >
                                    <UserGroupIcon width={35}/>
                                    <p className={`${currentPage === "home" ? "text-primary" : ""} text-sm`}>{pluginData.style.mentorsLabel || "Estudiantes"}</p>
                                </button>
                                {pluginData.settings.staffTab && (
                                    <button onClick={()=>{
                                        setSearchType("staff");
                                        setCurrentPage(`plugin-${pluginData._id}`);
                                    }} className={twMerge("flex flex-col items-center gap-1 bg-transparent border-none cursor-pointer", (currentPage.includes(pluginData._id.toString()) && searchType === "staff") ? "text-primary-500" : "")}
                                    >
                                        <BuildingLibraryIcon width={35}/>
                                        <p className={`${currentPage === "home" ? "text-primary" : ""} text-sm`}>{pluginData.style.staffLabel || "Staff"}</p>
                                    </button>
                                )}
                            </Fragment>
                        );
                    case "group":
                        return (
                            <button onClick={()=>{
                                setCurrentPage(`plugin-${pluginData._id}`);
                            }} className={twMerge("flex flex-col items-center gap-1 bg-transparent border-none cursor-pointer", currentPage.includes(pluginData._id.toString()) ? "text-primary-500" : "")}
                            >
                                <UserGroupIcon width={35}/>
                                <p className={`${currentPage === "home" ? "text-primary" : ""} text-sm"`}>Grupos</p>
                            </button>
                        );
                    case "cards":
                        return (
                            <button onClick={()=>{
                                setCurrentPage(`plugin-${pluginData._id}`);
                            }} className={twMerge("flex flex-col items-center gap-1 bg-transparent border-none cursor-pointer", currentPage.includes(pluginData._id.toString()) ? "text-primary-500" : "")}
                            >
                                <SparklesIcon width={35}/>
                                <p className={`${currentPage === "home" ? "text-primary" : ""} text-sm"`}>Actividades</p>
                            </button>
                        );
                    default:
                        return null;
                }
            }))}

            {/* Solo mostrar la pestaña de chats si hay un plugin de chats o grupos */}
            {iframeData.plugins.some((a)=>["chats", "groups"].includes(a)) && (
                <button onClick={()=>{
                    if (userData) {
                        setCurrentPage("chats");
                        return;
                    }
                    setLocalStorage("redirectTo", "chats");
                    setCurrentPage("login");
                }} className={twMerge("flex flex-col items-center gap-1 bg-transparent border-none cursor-pointer", currentPage && (currentPage.includes("chat") || currentPage.includes("group")) ? "text-primary-500" : "")}
                >
                    <ChatBubbleLeftRightIcon width={35}/>
                    <p className={`${currentPage === "chats" ? "text-primary" : ""} text-sm`}>Buzón</p>
                </button>
            )}
            <button onClick={()=>{
                if (userData) {
                    setCurrentPage("profile");
                    return;
                }
                setLocalStorage("redirectTo", "profile");
                setCurrentPage("login");
            }} className={twMerge("flex flex-col items-center gap-1 bg-transparent border-none cursor-pointer", currentPage === "profile" ? "text-primary-500" : "")}
            >
                <UserIcon width={35}/>
                <p className={`${currentPage === "profile" ? "text-primary" : ""} text-sm`}>Perfil</p>
            </button>
        </div>
    );
}