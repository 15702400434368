import {Spinner} from "flowbite-react";
import React, {useEffect, useState} from "react";

import {Chip, TableCell, TableRow} from "@mui/material";
import {UnigowTable} from "@unigow/components/UnigowTable/UnigowTable";
import {api} from "../../../../apis/requests";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import {Lead} from "../../../../types/log";
import {useCustomLocale} from "../../../../contexts/CustomLocaleContext";
import UnigowInput from "../../../../components/UnigowInput/UnigowInput";
import {ReactComponent as Zoom} from "@unigow/assets/search.svg";
import {useCustomQuery} from "../../../../hooks/useCustomQuery";
import DateFilter from "../../../../components/DateFilter/DateFilter";
import {Dayjs} from "dayjs";
import {useNavigate} from "react-router-dom";
import {generateCsv, printAdditionalInfo} from "../../../../helpers/helper";
import UnigowButton from "../../../../components/UnigowButton/UnigowButton";
import {Download} from "@mui/icons-material";
import {useDashboardStore} from "../../../../stores/dashboard/dashboardStore";
import {UserHistory} from "./components/UserHistory";

export default function Leads(): React.ReactElement {
    const {selectedClient, clientInfo} = useDashboardStore();
    const {t} = useCustomLocale();
    const [email, setEmail] = useState("");
    const [start, setStart] = useState<Dayjs | null>(null);
    const [end, setEnd] = useState<Dayjs | null>(null);
    const [selectedUser, setSelectedUser] = useState<Lead["user"] | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(()=>{
        if (!clientInfo) return;

        if (!clientInfo?.permissions?.manager) {
            navigate("/chats");
        }
    }, [clientInfo, navigate]);

    const {data:leads, isLoading:loading} = useCustomQuery(["leads", selectedClient, email, start, end], async()=>{
        let url = `logs/${selectedClient}/leads?`;

        if (email) {
            url += `email=${email}&`;
        }

        if (start) {
            url += `start=${start.unix()}&`;
        }

        if (end) {
            url += `end=${end.endOf("day").unix()}` + "&";
        }

        return api.get<Lead[]>(url);

    }, {debounce:300, enabled:!!selectedClient && clientInfo?.permissions?.manager});

    function getAllLeadKeys(): string[] {
        const keys: string[] = [];

        leads?.forEach((lead)=>{
            Object.keys(lead.user.info || {}).forEach((key)=>{
                if (!keys.includes(key)) {
                    keys.push(key);
                }
            });
        });

        return keys;
    }

    function downloadLeads(): void {
        const allKeys = getAllLeadKeys();

        // Saves the data from the leads array in a csv file and then downloads it
        const headers = ["Nombre", "Email", "Teléfono", "Interés del lead"].concat(leads && leads.length > 0 ? allKeys : []).concat(["Fecha de registro", "Fuente"]);

        const leadsInfo: Record<string, string[]> = {};
        // Fill all the info values, if empty fill with empty string
        leads?.forEach((lead)=>{
            leadsInfo[lead._id] = allKeys.map((key)=>printAdditionalInfo(lead.user.info?.[key]) || "");
        });

        const data = leads?.filter((x)=>!x.blurred)?.map((lead)=>[lead.user.name, lead.user.email, lead.user.phone || "", lead.user.history.join(",")].concat(leadsInfo[lead._id]).concat([new Date(lead.timestamp || 0).toLocaleString("es", {year:"numeric", month:"long", day:"numeric"}), lead.iframe?.alias || "Grupo"])) || [];

        generateCsv(headers, data, "leads.csv");
    }

    return (
        <PageContainer className="px-8 overflow-x-hidden h-max">
            <UserHistory user={selectedUser} clearUser={()=>setSelectedUser(undefined)}/>
            <div className="flex flex-col gap-3 items-start w-full">
                <h1>Leads Únicos</h1>
                <p className="text-ug-lg">Analiza los leads obtenidos con tu herramienta. Si quieres saber más información sobre cada uno haz click en el nombre</p>
            </div>
            <div className="my-4 flex w-full items-start lg:items-center justify-between flex-col lg:flex-row gap-4">
                <div className="relative">
                    <UnigowInput className="border-none bg-white" value={email} onChange={(e)=>{
                        setEmail(e.target.value);
                    }} placeholder="Busca por un correo"
                    InputProps={{className:"pl-8"}}
                    />
                    <div className="absolute left-0 flex justify-center items-center w-[40px] h-[40px] top-0">
                        <Zoom className="text-primary-500"/>
                    </div>
                </div>
                <div className="flex items-start lg:items-center gap-4 flex-col lg:flex-row">
                    <DateFilter start={start} setStart={setStart} end={end} setEnd={setEnd}/>
                    <UnigowButton className="flex items-center gap-2 py-1" variant="primary" onClick={downloadLeads}>Exportar a CSV<Download/></UnigowButton>
                </div>
            </div>
            <div className="bg-white shadow-lg rounded-[20px] w-[calc(100vw-3rem)] lg:w-full">
                {loading ? (
                    <div className="p-10">
                        <Spinner/>
                    </div>
                ) : (
                    <div className="">
                        <UnigowTable headerTitles={["Nombre", "Email", "Teléfono"].concat(getAllLeadKeys().map((x)=>t(x))).concat(["Fecha de registro", "Fuente"])} elementCount={leads?.length || 0}>
                            {leads?.map((lead)=>(
                                <TableRow key={lead._id} className={lead.blurred ? "blur-sm pointer-events-none select-none" : ""}>
                                    <TableCell>
                                        <button className="text-ug-base text-primary-500 underline cursor-pointer bg-transparent border-none" onClick={()=>{
                                            if (!lead.user) return;

                                            setSelectedUser(lead.user);
                                        }}
                                        >{lead.user.fullName || lead.user.name}
                                        </button>
                                    </TableCell>
                                    <TableCell>
                                        <p className="text-ug-base text-gray-cool-500">{lead.user.email}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p className="text-ug-base text-gray-cool-500">{lead.user.phone}</p>
                                    </TableCell>
                                    {getAllLeadKeys().map((key)=>(
                                        <TableCell key={key}>
                                            <p className="text-ug-base text-gray-cool-500">{printAdditionalInfo(lead.user.info?.[key])}</p>
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                        <p className="text-ug-base text-gray-cool-500">{new Date(lead.timestamp || 0).toLocaleString("es", {year:"numeric", month:"long", day:"numeric"})}</p>
                                    </TableCell>
                                    <TableCell>
                                        <div className="flex">
                                            <div className="text-ug-base text-gray-cool-500">
                                                <Chip className="w-24" label={lead.iframe?.alias || "Grupo"} color={lead.iframe?.client === selectedClient ? "primary" : "success"}/>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </UnigowTable>
                    </div>
                )}
            </div>
        </PageContainer>
    );
}