import React from "react";
import {useQuery} from "react-query";
import {useAuthStore} from "../../../stores/authStore";
import {useTwilio} from "../../../contexts/TwilioContext";
import {api} from "../../../apis/requests";

import * as Sentry from "@sentry/react";
import {TwilioChat} from "../../../types/chat";
import ChatsList from "../../../components/ChatsList/ChatsList";
import {useIframe} from "../contexts/IframeContext";
import {useParams} from "react-router-dom";

export default function Chats(): React.ReactElement {
    const {iframeId} = useParams();

    const {userData} = useAuthStore();
    const {twilioClient} = useTwilio();
    const {setCurrentPage} = useIframe();

    const {data:userChats, isLoading, isSuccess} = useQuery(["chats", iframeId, userData, twilioClient?.connectionState], async()=>{
        // GET CHATS AND GROUPS DEPENDING ON PLUGINS
        const chatsRes = (await api.get<TwilioChat[]>("chats")).map((chat)=>{
            return {...chat, type:"chat"};
        });

        const groupsRes = (await api.get<TwilioChat[]>("groups")).map((group)=>{
            return {...group, type:"group"};
        });

        const res = [...chatsRes, ...groupsRes] as TwilioChat[];

        await Promise.all(res.map(async(chat)=>{
            // Set a timeout in case the conversation is not found
            try {
                const conversation = await twilioClient!.getConversationBySid(chat.SID);
                const unread = await conversation.getUnreadMessagesCount();
                chat.unread = unread || 0;
                const lastMessage = await conversation?.getMessages(1);
                if (lastMessage.items.length > 0) {
                    chat.lastMessage = {
                        body:lastMessage?.items[0].body || "",
                        added:lastMessage.items[0].dateCreated
                    };
                }
            } catch (err) {
                Sentry.captureException(err);
                // Delete chat from array
                res.splice(res.indexOf(chat), 1);
            }
        }));

        return res.sort((a, b)=>{
            if (!a.lastMessage?.added || !b.lastMessage?.added) return 0;
            return b.lastMessage.added?.getTime() - a.lastMessage.added?.getTime();
        });
    }, {
        enabled:!!userData && !!twilioClient,
        refetchOnWindowFocus:true,
        refetchInterval:5 * 60 * 1000
    });

    return (
        <ChatsList loading={isLoading || !isSuccess} type="twilio" chats={userChats || []}
            goToChatPage={(sid: string, type: "chat" | "group")=>setCurrentPage(`${type}-${sid}`)}
        />
    );
}