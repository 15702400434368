import {api} from "@unigow/apis/requests";
import {useCardWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/cardsPlugin.store";
import CardsPluginWizard from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/CardspluginWizard";
import {CardWithParticipants} from "@unigow/types/card";
import React from "react";
import {useQuery} from "react-query";

export default function CardsPlugins(): React.ReactElement {
    const {setCardPageCards, selectedCardPage, cardPageData} = useCardWizardStore();

    useQuery(["cards", selectedCardPage], ()=>{
        return api.get<CardWithParticipants[]>(`plugincards/${selectedCardPage}/cards`);
    }, {
        enabled: !!cardPageData,
        onSuccess:(data)=>{
            setCardPageCards(data);
        }
    });

    return (
        <CardsPluginWizard/>
    );
}