import React, {DetailedHTMLProps, useEffect, useRef, useState} from "react";
import {IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import {ReactComponent as Dialog} from "@unigow/assets/dialog.svg";
import {ReactComponent as DialogBottom} from "@unigow/assets/dialogDown.svg";
import {ReactComponent as ChatIcon} from "@unigow/assets/chatIcon.svg";
import {generateRandomOrientatorData} from "@unigow/helpers/helper";
import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";
import SingleUser from "@unigow/pages/PopupIframe/components/SingleUser";
import {useMediaQuery} from "react-responsive";
import {PopupType} from "@unigow/unigow-types";
import {useChatsPluginStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/chatsPlugin.store";
import {PublicOrientator} from "@unigow/types/user";

interface StubPopupProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    popupData?: Omit<PopupType, "_id" | "client" | "iframe" | "plugin"> & {mainField: string};
}

export default function StubPopup({popupData, ...divProps}: StubPopupProps): React.ReactElement {
    const {pluginData} = useChatsPluginStore();

    const previewRef = useRef<HTMLDivElement>(null);
    const {t} = useCustomLocale();
    const isMobile = useMediaQuery({maxWidth: 499});

    const [orientators, setOrientators] = useState<PublicOrientator[]>([]);

    useEffect(()=>{
        if (previewRef.current) {
            if (popupData && popupData.style && popupData?.style.mainColor) {
                previewRef.current.style.setProperty("--primary-color", popupData?.style.mainColor);
                previewRef.current.style.fontFamily = popupData?.style.fontFamily;
            }

            const testOrientator: PublicOrientator = {
                _id:pluginData!._id,
                user:pluginData!._id,
                client:pluginData!._id,
                iframe:pluginData!._id,
                plugin:pluginData!._id,
                type:"mentor",
                userInfo:{
                    _id:"1",
                    name:"María",
                    profilePic:"https://firebasestorage.googleapis.com/v0/b/unigow-v2.appspot.com/o/uploadedImages%2F31.png?alt=media&token=f1306319-c8be-441d-af76-b13cdd376b83",
                    info:{info:generateRandomOrientatorData(pluginData?.settings.mentorParams || ["Info"], t)}
                },
                enabled:true
            };

            const ors: PublicOrientator[] = [testOrientator];

            setOrientators(ors);
        }
    }, [previewRef, pluginData, popupData, t]);

    return (
        <div {...divProps} className={"flex w-full justify-end"} ref={previewRef}>
            <div className="flex items-start mt-2 px-2 imd:hidden">
                <IconButton className="p-0 bg-white rounded-imd drop-shadow-lg">
                    <Close/>
                </IconButton>
            </div>
            <div className="flex items-end imd:flex-col p-4" style={{filter:"drop-shadow(3px 3px 6px rgb(0 0 0 / .3))"}}>
                <div className="rounded-xl bg-white imd:rounded-3xl imd:rounded-br-none w-fill">
                    <div className="px-4 py-2 imd:py-4 flex gap-2 justify-between">
                        {!isMobile && popupData?.style?.size !== "small" && (
                            <div className="flex items-center text-primary-500 border border-gray-cool-200 border-solid rounded-[9px] p-1 mx-2">
                                <ChatIcon className="rotate-6"/>
                            </div>
                        )}
                        {isMobile ? (
                            <p className="text-ug-sm italic">{popupData?.style?.shortText}</p>
                        ) : (
                            <p className="text-ug-base font-medium w-[300px]">{popupData?.style?.longText}</p>
                        )}

                        {!isMobile && (
                            <div className="flex items-start">
                                <IconButton className="p-1 px-2">
                                    <Close className="w-4"/>
                                </IconButton>
                            </div>
                        )}
                    </div>
                    <div className="bg-gray-cool-200 h-[1px] w-full"/>
                    {orientators.length > 0 && (
                        <SingleUser popupData={popupData} orientatorArray={orientators} />
                    )}
                </div>

                {isMobile ? (
                    <Dialog width={20} className="mb-3 -translate-x-1"/>
                ) : (
                    <DialogBottom width={50} className=""/>
                )}
            </div>
        </div>
    );
}