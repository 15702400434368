import React, {Children, FormEvent, useEffect, useState} from "react";
import {MessageComponent, WhatsappChat, WhatsappTemplate} from "@unigow/types/chat";
import {Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import UnigowButton from "../../../../../components/UnigowButton/UnigowButton";
import {api} from "../../../../../apis/requests";
import {toast} from "react-toastify";

interface SendTemplateProps {
    template: WhatsappTemplate,
    chat: WhatsappChat,
    closeModal: ()=> void
}

export function SendTemplate({template, closeModal, chat}: SendTemplateProps): React.ReactElement {
    const [headerVars, setHeaderVars] = useState<string[]>([]);
    const [bodyVars, setBodyVars] = useState<string[]>([]);
    const [footerVars, setFooterVars] = useState<string[]>([]);
    const [buttonVars, setButtonVars] = useState<string[]>([]);

    const header = template.components.find((c)=>c.type === "HEADER");

    const body = template.components.find((c)=>c.type === "BODY");

    const footer = template.components.find((c)=>c.type === "FOOTER");

    const button = template.components.find((c)=>c.type === "BUTTON");

    useEffect(()=>{
        // Populate the header vars with empty strings
        if (header) {
            setHeaderVars(Array(header.var_num).fill(""));
        }

        // Populate the body vars with empty strings
        if (body) {
            setBodyVars(Array(body.var_num).fill(""));
        }

        // Populate the footer vars with empty strings
        if (footer) {
            setFooterVars(Array(footer.var_num).fill(""));
        }

        // Populate the button vars with empty strings
        if (button) {
            setButtonVars(Array(button.var_num).fill(""));
        }
    }, [header, body, footer, button]);

    async function sendTemplate(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        const templateComponents: MessageComponent[] = [];

        // Add header vars
        if (headerVars.length > 0) {
            const params: {
                type: "video" | "image" | "text" | "currency" | "date_time" | "document";
                text: string;
            }[] = [];
            headerVars.forEach((v)=>{
                params.push({
                    type:"text",
                    text:v
                });
            });

            templateComponents.push({
                type:"header",
                parameters:params
            });
        }

        // Add body vars
        if (bodyVars.length > 0) {
            const params: {
                type: "video" | "image" | "text" | "currency" | "date_time" | "document";
                text: string;
            }[] = [];
            bodyVars.forEach((v)=>{
                params.push({
                    type:"text",
                    text:v
                });
            });

            templateComponents.push({
                type:"body",
                parameters:params
            });
        }

        // Add footer vars
        if (footerVars.length > 0) {
            const params: {
                type: "video" | "image" | "text" | "currency" | "date_time" | "document";
                text: string;
            }[] = [];
            footerVars.forEach((v)=>{
                params.push({
                    type:"text",
                    text:v
                });
            });

            templateComponents.push({
                type:"footer",
                parameters:params
            });
        }

        // Add button vars
        if (buttonVars.length > 0) {
            const params: {
                type: "text" | "payload" | "copupon_code";
                text: string;
                payload?: string;
                coupon_code?: string;
            }[] = [];
            buttonVars.forEach((v)=>{
                params.push({
                    type:"text",
                    text:v
                });
            });

            templateComponents.push({
                type:"button",
                sub_type:"url",
                index:0,
                parameters:params
            });
        }

        const reqBody = {
            template:template.name,
            parameters:templateComponents
        };

        const result = await api.post<{template: string, parameters: MessageComponent[]}, {status: string}>(`chats/conversations/${chat._id}/templates`, reqBody);

        if (result.status === "ok") {
            toast.success("Mensaje enviado correctamente");
            closeModal();
            return;
        }
        toast.error("Error al enviar el mensaje");
    }

    return (
        <Dialog open={true} onClose={closeModal}>
            <DialogTitle>Enviar plantilla a {chat.phone}</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-4">
                    <div>
                        <p className="text-lg font-semibold">Mensaje que se va a enviar</p>
                        <div className="font-mono text-sm p-2 border-dotted border-gray-400 rounded-lg flex flex-col gap-2">
                            {header && (
                                <div className="block">
                                    {/* Replace {{x}} by headerVars */}
                                    {header.text.replace(/{{\d+}}/g, (match)=>headerVars[parseInt(match.match(/\d+/)![0]) - 1] || "[VARIABLE]")}
                                </div>
                            )}
                            {body && (
                                <div className="block">
                                    {/* Replace {{x}} by bodyVars */}
                                    {body.text.replace(/{{\d+}}/g, (match)=>bodyVars[parseInt(match.match(/\d+/)![0]) - 1] || "[VARIABLE]")}
                                </div>
                            )}
                            {footer && (
                                <div className="block">
                                    {/* Replace {{x}} by footerVars */}
                                    {footer.text.replace(/{{\d+}}/g, (match)=>footerVars[parseInt(match.match(/\d+/)![0]) - 1] || "[VARIABLE]")}
                                </div>
                            )}
                            {button && (
                                <div className="flex flex-col gap-2">
                                    <div className="flex flex-col gap-2 border border-solid border-gray-400 rounded-lg p-2">
                                        <p className="font-semibold">{button.text}</p>
                                        <div className="block">
                                            {/* Replace {{x}} by buttonVars */}
                                            {button.url?.replace(/{{\d+}}/g, (match)=>buttonVars[parseInt(match.match(/\d+/)![0]) - 1] || "[VARIABLE]")}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <form className="flex flex-col gap-2" onSubmit={sendTemplate}>
                        {headerVars.length > 0 && (
                            <div className="">
                                <p className="font-semibold">Variables del header</p>
                                {/* Inputs */}
                                {Children.toArray(headerVars.map((v, i)=>(
                                    <TextField required variant="standard" type="text" value={v} onChange={(e)=>{
                                        setHeaderVars((prev)=>{
                                            const copy = [...prev];
                                            copy[i] = e.target.value;
                                            return copy;
                                        });
                                    }}
                                    placeholder={`Variable ${i + 1} del header`}
                                    />
                                )))}
                            </div>
                        )}
                        {bodyVars.length > 0 && (
                            <div className="">
                                <p className="font-semibold">Variables del body</p>
                                {/* Inputs */}
                                <div className="flex gap-2">
                                    {Children.toArray(bodyVars.map((v, i)=>(
                                        <TextField required variant="standard" type="text" value={v} onChange={(e)=>{
                                            setBodyVars((prev)=>{
                                                const copy = [...prev];
                                                copy[i] = e.target.value;
                                                return copy;
                                            });
                                        }}
                                        placeholder={`Variable ${i + 1} del body`}
                                        />
                                    )))}
                                </div>
                            </div>
                        )}
                        {footerVars.length > 0 && (
                            <div className="">
                                <p className="font-semibold">Variables del footer</p>
                                {/* Inputs */}
                                {Children.toArray(footerVars.map((v, i)=>(
                                    <TextField required variant="standard" type="text" value={v} onChange={(e)=>{
                                        setFooterVars((prev)=>{
                                            const copy = [...prev];
                                            copy[i] = e.target.value;
                                            return copy;
                                        });
                                    }}
                                    placeholder={`Variable ${i + 1} del footer`}
                                    />
                                )))}
                            </div>
                        )}
                        {buttonVars.length > 0 && (
                            <div className="">
                                <p className="font-semibold">Variables del botón</p>
                                {/* Inputs */
                                    Children.toArray(buttonVars.map((v, i)=>(
                                        <TextField required variant="standard" type="text" value={v} onChange={(e)=>{
                                            setButtonVars((prev)=>{
                                                const copy = [...prev];
                                                copy[i] = e.target.value;
                                                return copy;
                                            });
                                        }}
                                        placeholder={`Variable ${i + 1} del botón`}
                                        />
                                    )))
                                }
                            </div>
                        )}
                        <UnigowButton variant="primary">Enviar mensaje</UnigowButton>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
    );
}