import React, {createContext, useContext, useEffect, useState} from "react";
import {Client} from "@twilio/conversations";
import {getLocalStorage, removeLocalStorage, setLocalStorage} from "../helpers/localstorage";
import {api} from "../apis/requests";
import {useAuthStore} from "../stores/authStore";

interface TwilioContextProps {
    children: React.ReactNode;
}

export type TwilioContextType = {
    twilioClient: Client | undefined;
    newMessages: string;
    readMessages: ()=> void;
};

export const TwilioContext = createContext<TwilioContextType>({} as TwilioContextType);

export function useTwilio(): TwilioContextType {
    return useContext(TwilioContext);
}

export function TwilioProvider({children}: TwilioContextProps): React.ReactElement {
    // STORES
    const {userData} = useAuthStore();

    // STATES
    const [twilioClient, setTwilioClient] = useState<Client>();
    const [newMessages, setNewMessages] = useState(getLocalStorage("unreadMessages") || "0");
    const [twilioToken, setTwilioToken] = useState("");

    // FUNCTIONS
    function readMessages(): void {
        setNewMessages("0");
        removeLocalStorage("unreadMessages");
    }

    // EFFECTS
    useEffect(()=>{
        async function initConversations(): Promise<void> {
            if (!userData) return;
            const response = await api.get<{token: string}>("chats/token");

            setTwilioToken(response.token);
        }

        void initConversations();
    }, [userData]);

    useEffect(()=>{
        if (twilioToken !== "") {
            // Set up twilio client with timeout
            const client = new Client(twilioToken, {logLevel: "warn"});

            client.on("connectionStateChanged", async(status)=>{
                if (status === "connected") {
                    const userConvos = await client.getSubscribedConversations();
                    let totalUnread = 0;
                    userConvos.items.forEach((element) => {
                        let lastMessageIndex = element.lastMessage?.index || 0;
                        if (element.lastReadMessageIndex === undefined) {
                            lastMessageIndex = 1;
                        }
                        totalUnread += lastMessageIndex - (element.lastReadMessageIndex || 0);
                    });
                    setLocalStorage("unreadMessages", `${totalUnread}`);
                    if (totalUnread < 100) {
                        setNewMessages(`${totalUnread}`);
                    } else {
                        setNewMessages("+99");
                    }
                    setTwilioClient(client);
                } else {
                    setTwilioClient(undefined);
                }
            });
            return ()=>{
                client.off("connectionStateChanged", (status)=>{
                    if (status === "connected") {
                        setTwilioClient(client);
                    } else {
                        setTwilioClient(undefined);
                    }
                });
            };
        }
    }, [twilioToken]);

    useEffect(()=>{
        function incrementMessageCount(): void {
            // Function for setting the unread message count in the header/footer
            if (!window.location.pathname.includes("rooms")) {
                const currentMessages = parseInt(getLocalStorage("unreadMessages") || "0");
                setLocalStorage("unreadMessages", `${currentMessages + 1}`);
                if (currentMessages < 100) {
                    setNewMessages(`${currentMessages + 1}`);
                } else {
                    setNewMessages("+99");
                }
            }
        }
        let event: Client | undefined = undefined;
        if (twilioClient) {
            event = twilioClient.on("messageAdded", incrementMessageCount);
        }
        return ()=>{
            event?.removeListener("messageAdded", incrementMessageCount);
        };
    }, [twilioClient, newMessages]);

    return <TwilioContext.Provider value={{twilioClient, newMessages, readMessages}}>{children}</TwilioContext.Provider>;
}