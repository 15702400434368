import React, {LiHTMLAttributes, useRef} from "react";
import {useCustomLocale} from "../../../../contexts/CustomLocaleContext";
import {ReadMore} from "../../../../components/ReadMore/ReadMore";

interface FreeFieldProps {
    param: {
        alias: string,
        value: string
    },
    lines?: number
}

export default function FreeField(props: FreeFieldProps & LiHTMLAttributes<HTMLLIElement>): React.ReactElement {
    const {param, lines, ...liAtrs} = props;
    const {t} = useCustomLocale();
    const divRef = useRef<HTMLDivElement>(null);

    return (
        <li {...liAtrs} className="flex flex-col gap-1 justify-center">
            {param.alias !== "main" && (
                <p className="uppercase text-ug-sm text-gray-cool-500">{t(param.alias)}</p>
            )}
            <div ref={divRef} className="relative flex items-start" style={{wordBreak:"break-all"}}>
                <ReadMore
                    className="text-ug-base"
                    text={param.value}
                    characters={lines ? 36 * lines : 36}
                />
            </div>
        </li>
    );
}