import {Autocomplete, AutocompleteProps, createFilterOptions, SxProps} from "@mui/material";
import React from "react";
import {twMerge} from "tailwind-merge";
import UnigowInput from "../UnigowInput/UnigowInput";

type UnigowAutocompleteProps<Multiple extends boolean> = {
    formControlSx?: SxProps,
    placeholder?: string,
    required?: boolean,
} & Omit<AutocompleteProps<string, Multiple, false, true>, "renderInput">;

const filter = createFilterOptions<string>();

export function UnigowAutocomplete({className, placeholder, required, ...inputProps}: UnigowAutocompleteProps<false>): React.ReactElement {
    return (
        <Autocomplete {...inputProps}  className={twMerge("text-gray-cool-400 text-ug-lg font-light rounded-lg")} freeSolo
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const {inputValue} = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue.toLowerCase() === option.toLowerCase());
                if (inputValue !== "" && !isExisting) {
                    filtered.push(`${inputValue}`);
                }

                return filtered;
            }}
            renderInput={(params)=>(
                <UnigowInput {...params} placeholder={placeholder} className={twMerge("w-fill", className)} required={required}/>
            )}
            autoSelect
            autoHighlight
        />
    );
}

export function UnigowMultipleAutocomplete({className, placeholder, required, ...inputProps}: UnigowAutocompleteProps<true>): React.ReactElement {
    return (
        <Autocomplete {...inputProps}  className={twMerge("text-gray-cool-400 text-ug-lg font-light rounded-lg")}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                return filtered;
            }}
            renderInput={(params)=>(
                <UnigowInput {...params} placeholder={placeholder} className={twMerge("w-fill", className)} required={required}/>
            )}
            multiple
        />
    );
}