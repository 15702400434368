import {Rating, TextareaAutosize} from "@mui/material";
import {api} from "@unigow/apis/requests";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import BasicPage from "@unigow/pages/Functional/BasicPage";
import React, {FormEvent, useState} from "react";
import {useSearchParams} from "react-router-dom";

export default function Feedback(): React.ReactElement {
    const [searchParams] = useSearchParams();

    const activityId = searchParams.get("activity");
    const userId = searchParams.get("user");
    const activityName = searchParams.get("name");
    const feedbackToken = searchParams.get("token");

    const [result, setResult] = useState<"success" | "error" | "cancelled" | null>(null);
    const [cardRating, setCardRating] = useState<number | null>(null);
    const [inscriptionRating, setInscriptionRating] = useState<number | null>(null);
    const [comment, setComment] = useState<string | undefined>(undefined);

    async function addFeedback(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        const body = {
            feedbackToken,
            user: userId,
            cardRating,
            inscriptionRating,
            comment
        };

        await api.post<typeof body, {message: string}>(`cards/${activityId}/feedback`, body).catch(()=>{
            setResult("error");
        }).then((d)=>{
            if (!d) return;
            setResult("success");
        });
    }

    function renderMessage(): string {
        switch (result!) {
            case "success": return "Tu opinión ha sido enviada con éxito";
            case "error": return "Ha ocurrido un error al enviar el formulario";
            case "cancelled": return "Acción cancelada";
        }
    }

    return (
        <BasicPage>
            {!result ? (
                <form className="flex flex-col rounded-md p-6 border border-solid border-gray-cool-200 w-10/12 sm:w-[480px] gap-4" onSubmit={addFeedback}>
                    <div>
                        <h3 className="text-lg font-semibold">Envíanos tu opinión sobre: <br/> {activityName}</h3>
                    </div>

                    <div className="flex flex-col gap-1">
                        <label htmlFor="cardrating">Califica del 1 al 5 tu experiencia en la actividad</label>
                        <Rating id="cardrating" value={cardRating} onChange={(_, v)=>setCardRating(v)} />
                    </div>

                    <div className="flex flex-col gap-1">
                        <label htmlFor="inscriptionrating">Califica del 1 al 5 el proceso de inscripción</label>
                        <Rating id="inscriptionrating" value={inscriptionRating} onChange={(_, v)=>setInscriptionRating(v)} />
                    </div>

                    <TextareaAutosize className="border-gray-cool-200 rounded-md resize-none" maxRows={5} placeholder="Escribe un comentario adicional (opcional)" value={comment} onChange={(e)=>setComment(e.target.value)} />

                    <div className="gap-2 md:gap-4 flex flex-col-reverse md:grid grid-cols-4">
                        <UnigowButton className="col-span-1" variant="basic">Cancelar</UnigowButton>
                        <UnigowButton className="col-span-3" variant="primary">Enviar mi opinión</UnigowButton>
                    </div>
                </form>
            ) : (
                <p><span className="font-semibold">{renderMessage()}</span>, puedes cerrar esta pestaña</p>
            )}
        </BasicPage>
    );
}