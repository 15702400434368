import React, {useEffect, useState} from "react";
import PageContainer from "@unigow/components/PageContainer/PageContainer";
import {useQuery} from "react-query";
import {api} from "@unigow/apis/requests";
import {twMerge} from "tailwind-merge";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import EditLogo from "./components/EditLogo";
import EditBanner from "./components/EditBanner";
import EditBackground from "./components/EditBackground";
import EditTitle from "./components/EditTitle";
import EditSubtitle from "./components/EditSubtitle";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useIframeWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/iframeWizard.store";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {LandingType} from "@unigow/unigow-types";

export interface LandingEditorProps {
    landingInfo?: LandingType;
    modifyLandingData: <K extends keyof LandingType>(property: K, value: LandingType[K])=> void;
}

export default function LandingCreator(): React.ReactElement {
    const {clientInfo} = useDashboardStore();
    const {iframeData} = useIframeWizardStore();

    const [editMode, setEditMode] = useState(false);
    const [landingData, setLandingData] = useState<LandingType | undefined>(undefined);
    const [retry, setRetry] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        if (!clientInfo) return;

        if (!clientInfo?.permissions?.manager) {
            navigate("/chats");
        }
    }, [clientInfo, navigate]);

    useQuery(["landings", iframeData, retry], async()=>{
        const results = await api.get<LandingType[]>(`iframes/${iframeData!._id}/plugins/landings`);

        return results;
    }, {
        enabled:!!iframeData && !!clientInfo && clientInfo?.permissions?.manager,
        onSuccess:async(data)=>{
            if (data.length === 0) {
                setLandingData(undefined);

                const res = await api.post<void, LandingType>(`iframes/${iframeData!._id}/plugins/landings`);

                if (res) {
                    toast.success("Landing creada con éxito");
                    setRetry(!retry);
                }
            }

            setLandingData(data[0]);
        }
    });

    let landingUrl = "";

    if (clientInfo) {
        landingUrl = `${window.location.href.split("/iframes")[0]}/landing/${clientInfo.alias}/${landingData?.alias}
    `;
    }

    function modifyLandingData<K extends keyof LandingType>(property: K, value: LandingType[K]): void {
        setLandingData((prev)=>{
            if (!prev) return prev;

            return {
                ...prev,
                [property]:value
            };
        });
    }

    function getLogoSize(): number {
        if (landingData?.logoSize === "small") return 75;
        if (landingData?.logoSize === "big") return 200;

        return 100;
    }

    return (
        <div className="flex flex-col gap-4">
            <PageContainer className="gap-[20px] items-start select-none w-fill p-0 lg:p-0">
                <div className="bg-white w-[calc(90%-2rem)] rounded-r-xl lg:px-8 px-4 flex gap-4 flex-col">
                    {!!landingData && (
                        <div className="flex flex-col gap-4 w-full">
                            <div className={twMerge("flex w-full", editMode ? "justify-end" : "")}>
                                <UnigowButton variant="secondary" onClick={()=>{
                                    setEditMode((prev)=>!prev);
                                }}
                                >{editMode ? "Guardar cambios" : "Editar landing page"}
                                </UnigowButton>
                            </div>
                            <div className={twMerge("w-full h-[600px] border-1 border-solid border-gray-cool-400 rounded-[10px] overflow-y-auto", landingData ? "" : "blur-sm")} style={{backgroundColor:landingData?.background || "#FFF"}}>
                                {/* HEADER */}
                                <div className="border-b border-t-0 border-x-0 border-solid border-gray-cool-200 py-4 px-24 flex justify-between" style={{backgroundColor:landingData?.banner || "#E0E0E0"}}>
                                    <div className="flex gap-4 items-center">
                                        <div className={twMerge("rounded-sm flex items-center overflow-hidden", landingData?.logo ? "" : "bg-white")}>
                                            {landingData?.logo ? (
                                                <img style={{width:getLogoSize()}} src={landingData.logo} alt="" />
                                            ) : (
                                                <p className="px-4 py-1">LOGO</p>
                                            )}
                                        </div>
                                        {editMode && (
                                            <div className="">
                                                <EditLogo landingInfo={landingData} modifyLandingData={modifyLandingData}/>
                                            </div>
                                        )}
                                    </div>
                                    {editMode && (
                                        <div className="flex items-center">
                                            <EditBanner landingInfo={landingData} modifyLandingData={modifyLandingData}/>
                                        </div>
                                    )}
                                </div>

                                {/* BODY */}
                                <div className="relative">
                                    <div className="flex flex-col justify-center text-center pt-10 px-4 mx-auto gap-2">
                                        <div className="flex items-center gap-4 justify-center">
                                            <h1>{landingData?.title || "Descubre las experiencias reales de nuestros estudiantes"}</h1>
                                            {editMode && (
                                                <EditTitle landingInfo={landingData} modifyLandingData={modifyLandingData}/>
                                            )}
                                        </div>
                                        {landingData?.description && (
                                            <div className="flex items-center gap-4 justify-center">
                                                <h3>{landingData.description}</h3>
                                                {editMode && (
                                                    <div className="flex">
                                                        <EditSubtitle landingInfo={landingData} modifyLandingData={modifyLandingData}/>
                                                        {/* <UnigowButton className="py-0 px-3 flex items-center gap-2 bg-red-600 rounded-l-none"
                                            variant="primary">
                                            <DeleteOutlineOutlined className="w-[18px]"/>
                                        </UnigowButton> */}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex justify-center py-4">
                                        <div className="w-[95%] h-[500px] bg-gray-cool-100 text-white text-3xl flex items-center justify-center">Herramienta</div>
                                    </div>
                                    {editMode && (
                                        <div className="flex absolute bottom-4 right-4">
                                            <EditBackground landingInfo={landingData} modifyLandingData={modifyLandingData}/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={twMerge("w-full flex flex-col rounded-[10px] border border-solid border-gray-cool-400 py-5 gap-[15px] mb-8")}>
                        <div className="px-7 flex justify-between items-center flex-col lg:flex-row gap-2">
                            <div className="flex flex-col gap-1">
                                <h3 className="text-black">Aquí tienes el link de la página: </h3>
                                <div className="flex flex-col gap-[10px]">
                                    <p className="font-mono text-ug-base font-light break-all select-text">
                                        {landingUrl}
                                    </p>
                                </div>
                            </div>
                            <UnigowButton variant="primary" onClick={async()=>{
                                await navigator.clipboard.writeText(landingUrl);
                                toast.success("Enlace copiado al portapapeles");
                            }}
                            >Copiar el enlace
                            </UnigowButton>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    );
}