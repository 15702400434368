import {create} from "zustand";
import {CreateAproval, CreateChat} from "@unigow/types/chat";
import {DisplayGroup} from "@unigow/types/group";
import {IframeWithPlugins} from "@unigow/types/iframe";
import {CardType} from "@unigow/unigow-types";

type ActionType =  ({
    action: "chat",
    data: CreateChat
} | {
    action: "aproval",
    data: CreateAproval
} | {
    action: "group",
    data: DisplayGroup
} | {
    action: "card",
    data: CardType
}) & {
    userParams: string[],
    needsPhone: boolean,
    privacy?: {
        privacyLink: string,
        clientName: string,
        clientId: string
    }
};

type IframeStore = {
    // Información sobre el iframe
    iframeData: IframeWithPlugins | undefined,

    // Función para modificar la información del iframe
    setIframeData: (iframeData: IframeWithPlugins | undefined)=> void,

    // El iframe está cargando
    loading: boolean

    // Función para modificar el estado de carga
    setLoading: (loading: boolean)=> void;

    pendingAction: ActionType | undefined

    setPendingAction: (pendingAction: ActionType | undefined)=> void
};

export const useIframeStore = create<IframeStore>((set) => ({
    iframeData:undefined,
    setIframeData:(iframeData)=>{
        set({iframeData});
    },
    loading:false,
    setLoading:(loading)=>set({loading}),
    pendingAction: undefined,
    setPendingAction:(pendingAction)=>set({pendingAction})
}));