import React, {Children, useEffect, useState} from "react";
import {CSSTransition} from "react-transition-group";

import "./styles.css";
import "@unigow/styles/stepsstyles.css";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import UnigowSelect from "@unigow/components/UnigowSelect/UnigowSelect";
import {MenuItem} from "@mui/material";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {toast} from "react-toastify";
import PopupPreview from "./components/PopupPreview";
import {twMerge} from "tailwind-merge";
import {usePopupStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/popup.store";
import {ParameterType, PopupType} from "@unigow/unigow-types";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {useChatsPluginStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/chatsPlugin.store";
import {useQuery} from "react-query";
import {api} from "@unigow/apis/requests";

export default function PopupConfig(): React.ReactElement {
    // STORES
    const {clientParams} = useDashboardStore();
    const {pluginData} = useChatsPluginStore();
    const {setStep, step, popupData, setPopupData} = usePopupStore();

    // STATES
    const [popupType, setPopupType] = useState("");
    const [mainParam, setMainParam] = useState<ParameterType | undefined>(undefined);

    useQuery(["popup", pluginData?._id], async()=>{
        return api.get<PopupType>(`iframes/${pluginData?.iframe}/plugins/${pluginData?._id}/popups`);
    }, {
        enabled: !!pluginData,
        onSuccess:(res)=>{
            if (!res) return;

            setPopupData(res);
        }
    });

    // EFFECTS
    useEffect(()=>{
        if (!clientParams || !pluginData) return;

        const main = clientParams.find((param)=>param.alias === pluginData.settings.mainField);

        if (main) {
            setMainParam(main);
        }
    }, [clientParams, popupData, pluginData]);

    // FUNCTIONS
    let iframeText: string[] = [];

    if (popupData) {
        iframeText = [
            `<script>
            window.unigowConfig = {
                "popupId": "${popupData?._id}"${popupType ? `,
                "mainQuery": "${popupType}"` : ""}
                ${process.env.REACT_APP_NODE_ENV === "development" ? `,
                "overrideUrl": "http://localhost:3000"` : process.env.REACT_APP_NODE_ENV === "staging" ? `,
                "overrideUrl": "https://testing.unigow.com"` : ""}
            }
        </script>`,
            `<script src="${window.location.origin}/vendor/popup-min-2.0.0.js"></script>`
        ];
    }

    return (
        <div className="flex flex-wrap gap-8">
            <div className="flex flex-col lg:w-[calc(55%-3rem)] w-full shrink-0 gap-[14px] pr-4">
                <div className={twMerge("flex flex-col rounded-[10px] border border-solid border-gray-cool-400 px-[26px] py-2 gap-1", step !== 1 ? "pb-2 cursor-pointer" : "pb-4")}>
                    <h2 className={step !== 1 ? "text-gray-cool-500" : ""} onClick={()=>setStep(1)}>1. Configuración Básica</h2>
                    <CSSTransition classNames="wizardstep" timeout={500} in={step === 1} unmountOnExit>
                        <Step1/>
                    </CSSTransition>
                </div>
                <div className={twMerge("flex flex-col rounded-[10px] border border-solid border-gray-cool-400 px-[26px] py-2 gap-1", step !== 2 ? "pb-2 cursor-pointer" : "pb-4")}>
                    <h2 className={step !== 2 ? "text-gray-cool-500" : ""} onClick={()=>setStep(2)}>2. Personalizar el diseño del Reminder</h2>
                    <CSSTransition classNames="wizardstep" timeout={500} in={step === 2} unmountOnExit>
                        <Step2/>
                    </CSSTransition>
                </div>
                <CSSTransition classNames="wizardstep" timeout={500} in={popupData?.style && popupData?.style.mainColor !== undefined && popupData.style.mainColor.length > 0} unmountOnExit>
                    <div className={twMerge("flex flex-col rounded-[10px] border border-solid border-secondary-blue-400 px-[26px] py-2 gap-[15px] pb-4")}>
                        <div className="flex flex-col  ">
                            <h2 className="text-black" onClick={()=>setStep(2)}>¡Enhorabuena! Ya has terminado</h2>
                            <p className="font-light text-ug-base">Ya está todo listo para que lo puedas integrar en tu web. Solo tienes que  copiar y pegar el siguiente código. Revisa el vídeo si tienes cualquier duda.</p>
                        </div>
                        {!!mainParam && !!mainParam.options && (
                            <div className="flex flex-col gap-[10px]">
                                <p className="text-ug-base font-semibold">Tipo de Popup</p>
                                <UnigowSelect onChange={(e)=>{
                                    setPopupType(e.target.value);
                                }} value={popupType}
                                label="Tipo de popup"
                                >
                                    <MenuItem value="">Elige una de las opciones</MenuItem>
                                    <MenuItem value="all">General</MenuItem>
                                    {/* Mostrar todas las opciones del parámetro principal del iframe */}
                                    {Children.toArray(mainParam.options.map((option)=>(
                                        <MenuItem value={option}>{option}</MenuItem>
                                    )))}
                                </UnigowSelect>
                            </div>
                        )}
                        <div className="flex flex-col gap-[10px]">
                            <p className="text-ug-base font-semibold">Código a copiar en la web</p>

                            <h3>Final del Body</h3>
                            <div className="border-gray-cool-200 border-2 border-dashed p-2 font-mono">
                                {Children.toArray(iframeText.map((text)=>(
                                    <p className="font-mono text-ug-base font-light break-all select-text">
                                        {text}
                                    </p>
                                )))}
                            </div>
                            <UnigowButton variant="primary" onClick={async()=>{
                                await navigator.clipboard.writeText(iframeText.join("\n"));
                                toast.success("Código copiado al portapapeles");
                            }}
                            >Copiar el código
                            </UnigowButton>
                        </div>
                    </div>
                </CSSTransition>
            </div>
            <div className="lg:w-[calc(45%-2rem)] w-full p-4">
                <PopupPreview/>
            </div>
        </div>
    );
}