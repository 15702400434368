import React, {FormEvent, Fragment, useEffect, useState} from "react";
import PopupWindow from "@unigow/components/PopupWindow/PopupWindow";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {Edit} from "@mui/icons-material";
import {LandingEditorProps} from "../LandingCreator";
import {api} from "@unigow/apis/requests";
import {toast} from "react-toastify";
import ColorPicker from "@unigow/components/ColorPicker/ColorPicker";
import {LandingType} from "@unigow/unigow-types";

function EditBanner(props: LandingEditorProps): React.ReactElement {
    const {landingInfo, modifyLandingData} = props;
    const [open, setOpen] = useState(false);
    const [banner, setBanner] = useState("");

    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!landingInfo) return;

        const res = await api.patch<Partial<LandingType>, LandingType>(`landing/${landingInfo._id}`, {banner});

        if (res) {
            toast.success("Cambios guardados con éxito");
            modifyLandingData("banner", banner);
            setOpen(false);
        }
    }

    useEffect(()=>{
        if (landingInfo && landingInfo.banner) {
            setBanner(landingInfo.banner);
        }
    }, [landingInfo]);

    return (
        <Fragment>
            <UnigowButton className="py-0 px-3 flex items-center gap-2" variant="primary" onClick={()=>setOpen(true)}>
                <p className="text-ug-base font-semibold">Editar fondo</p>
                <Edit className="w-[18px]"/>
            </UnigowButton>
            <PopupWindow open={open} closePopup={()=>setOpen(false)} title="Elige el color de fondo del header">
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className="flex flex-col gap-[10px]">
                        <p className="text-ug-base font-semibold">Elige un color</p>
                        <ColorPicker color={banner} setColor={setBanner}/>
                    </div>
                    <UnigowButton disabled={banner === ""} variant="secondary">Guardar color de fondo del header</UnigowButton>
                </form>
            </PopupWindow>
        </Fragment>
    );
}

export default EditBanner;