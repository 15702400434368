import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

type AnswersStore = {
    // Saved answers
    answers: string[];

    // Function to add an answer
    addAnswer: (answer: string)=> void;

    // Function to remove an answer
    removeAnswer: (index: number)=> void;

    // Function to check if an answer is already saved
    isAnswerSaved: (answer: string)=> boolean;

    // Chosen answer
    chosenAnswer: string | undefined;

    // Function to set the chosen answer
    setChosenAnswer: (answer: string)=> void;

    // Function to clear the chosen answer
    clearChosenAnswer: ()=> void;
};

export const useAnswersStore = create(
    persist<AnswersStore>(
        (set, get) => ({
            answers: [],
            addAnswer: (answer) => set((state) => ({answers: [...state.answers, answer]})),
            removeAnswer: (index) => set((state) => ({answers: state.answers.filter((_, i) => i !== index)})),
            isAnswerSaved: (answer) => {
                return get().answers.includes(answer);
            },
            chosenAnswer: undefined,
            setChosenAnswer: (answer) => set({chosenAnswer: answer}),
            clearChosenAnswer: () => set({chosenAnswer: undefined})
        }),
        {
            name:"answers-store",
            storage:createJSONStorage(()=>localStorage)
        }
    )
);