import React, {createContext, useContext, useEffect, useState} from "react";
import {Theme, ThemeProvider, createTheme} from "@mui/material";
import {useIframeStore} from "../../../stores/iframeStore";

interface IframeContextProps {
    children: React.ReactNode;
}

export type IframeContextType = {
    currentPage: string;
    setCurrentPage: (v: React.SetStateAction<string>)=> void;
    searchType?: string;
    setSearchType: (v: React.SetStateAction<string>)=> void;
};

export const IframeContext = createContext<IframeContextType>({} as IframeContextType);
export function useIframe(): IframeContextType {
    return useContext(IframeContext);
}

export function IframeProvider({children}: IframeContextProps): React.ReactElement {
    // STORES
    const {iframeData} = useIframeStore();

    // STATES
    const [currentPage, setCurrentPage] = useState("");
    const [searchType, setSearchType] = useState("mentor");
    const [theme, setTheme] = useState<Theme>(createTheme({}));

    useEffect(()=>{
        if (!iframeData || !!currentPage) return;

        const [firstPlugin] = iframeData.pluginData;

        if (firstPlugin) {
            setCurrentPage(`plugin-${firstPlugin._id}`);
        }
    }, [iframeData, currentPage]);

    useEffect(()=>{
        if (!iframeData) return;

        const auxTheme = createTheme({
            palette:{
                primary:{
                    main:iframeData?.style.mainColor || "#000"
                },
                secondary:{
                    main:"#FFF"
                },
                error:{
                    main:"#ED6C4D"
                }
            }
        });

        setTheme(auxTheme);

        document.documentElement.style.setProperty("--secondary-color", "#FFF");
        if (iframeData?.style.mainColor) {
            document.documentElement.style.setProperty("--primary-color", iframeData?.style.mainColor);
        }
        if (iframeData?.style.fontFamily) {
            document.documentElement.style.setProperty("--general-font", iframeData?.style.fontFamily);
        }
    }, [iframeData]);

    return (
        <IframeContext.Provider value={{currentPage, setCurrentPage, searchType, setSearchType}}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </IframeContext.Provider>
    );
}