import React from "react";
import PhoneInput, {PhoneInputProps} from "react-phone-input-2";
import {twMerge} from "tailwind-merge";

import "react-phone-input-2/lib/style.css";

interface UnigowPhoneInputProps {
    className?: string;
}

const inputClassname: string =
    "py-2 px-3 text-gray-cool-400 text-ug-lg font-light border border-solid border-gray-cool-300 rounded-lg";

export function UnigowPhoneInput({className, value, onChange, inputProps, ...phoneInputProps}: UnigowPhoneInputProps &
PhoneInputProps): React.ReactElement {

    return (
        <PhoneInput value={value}
            disableSearchIcon enableSearch country="es"
            onChange={onChange} inputProps={{className:twMerge(inputClassname, "w-full text-right text-ug-lg font-main", className), ...inputProps}}
            containerStyle={{width:"auto"}} searchPlaceholder="Buscar país" placeholder="+34 XXX XXX XXX" prefix="+" {...phoneInputProps}
            countryCodeEditable={false} buttonStyle={{borderTopLeftRadius:"0.5rem", borderBottomLeftRadius:"0.5rem"}}
        />
    );
}