import {useChatsPluginStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/chatsPlugin.store";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import React, {useRef} from "react";
import {CSSTransition} from "react-transition-group";

import "./styles.css";
import {twMerge} from "tailwind-merge";
import UserCard from "@unigow/pages/Iframe/components/UserCard";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {generateRandomOrientatorData} from "@unigow/helpers/helper";
import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";

export default function ChatPluginConfig(): React.ReactElement {
    const {t} = useCustomLocale();

    const {clientInfo} = useDashboardStore();
    const {pluginData} = useChatsPluginStore();
    const {step, setStep} = useChatsPluginStore();

    // REFS
    const step1Ref = useRef<HTMLDivElement>(null);
    const step2Ref = useRef<HTMLDivElement>(null);
    const step3Ref = useRef<HTMLDivElement>(null);
    const step4Ref = useRef<HTMLDivElement>(null);

    return (
        <div className="flex gap-8 flex-col lg:flex-row w-full">
            <div className="flex flex-col lg:w-[55%] w-full shrink-0 gap-[14px] pr-4">
                <div className={twMerge("flex flex-col rounded-[10px] border border-solid border-gray-cool-400 px-[26px] py-2 gap-1", step !== 1 ? "pb-2 cursor-pointer" : "pb-4")}>
                    <h2 className={step !== 1 ? "text-gray-cool-500" : ""} onClick={()=>setStep(1)}>1. Elige el tipo de Chat peer-to-peer que quieres</h2>
                    <CSSTransition nodeRef={step1Ref} classNames="wizardstep" timeout={500} in={step === 1} unmountOnExit>
                        <div ref={step1Ref}>
                            <Step1/>
                        </div>
                    </CSSTransition>
                </div>
                <div className={twMerge("flex flex-col rounded-[10px] border border-solid border-gray-cool-400 px-[26px] py-2 gap-1", step !== 2 ? "pb-2 cursor-pointer" : "pb-4")}>
                    {step !== 2 && (
                        <h2 className={step !== 2 ? "text-gray-cool-500" : ""} onClick={()=>setStep(2)}>2. Configurar categorías del embajador</h2>
                    )}
                    <CSSTransition nodeRef={step2Ref} classNames="wizardstep" timeout={500} in={step === 2} unmountOnExit>
                        <div ref={step2Ref}>
                            <Step2/>
                        </div>
                    </CSSTransition>
                </div>
                <div className={twMerge("flex flex-col rounded-[10px] border border-solid border-gray-cool-400 px-[26px] py-2 gap-1", step !== 3 ? "pb-2 cursor-pointer" : "pb-4")}>
                    {step !== 3 && (
                        <h2 className={step !== 3 ? "text-gray-cool-500" : ""} onClick={()=>setStep(3)}>3. Definir los filtros que ve el usuario</h2>
                    )}
                    <CSSTransition nodeRef={step3Ref} classNames="wizardstep" timeout={500} in={step === 3} unmountOnExit>
                        <div ref={step3Ref}>
                            <Step3/>
                        </div>
                    </CSSTransition>
                </div>
                <div className={twMerge("flex flex-col rounded-[10px] border border-solid border-gray-cool-400 px-[26px] py-2 gap-1", step !== 4 ? "pb-2 cursor-pointer" : "pb-4")}>
                    {step !== 4 && (
                        <h2 className={step !== 4 ? "text-gray-cool-500" : ""} onClick={()=>setStep(4)}>4. Definir los datos que se piden al usuario</h2>
                    )}
                    <CSSTransition nodeRef={step4Ref} classNames="wizardstep" timeout={500} in={step === 4} unmountOnExit>
                        <div ref={step4Ref}>
                            <Step4/>
                        </div>
                    </CSSTransition>
                </div>
            </div>
            <div className="flex items-center text-left flex-grow flex-col">
                <div className="flex justify-between w-full items-center mb-2">
                    <p className="text-xs font-semibold">Vista previa</p>
                </div>
                {!!clientInfo && (
                    <div className="w-full">
                        {pluginData ? (
                            <UserCard pluginData={{...pluginData, clientInfo:clientInfo}} orientatorInfo={{
                                _id:clientInfo._id,
                                client:clientInfo._id,
                                iframe:clientInfo._id,
                                plugin:clientInfo._id,
                                user:clientInfo._id,
                                userInfo:{
                                    _id:"test",
                                    name:"Marta",
                                    profilePic:undefined,
                                    info:{
                                        _id:"test",
                                        info:generateRandomOrientatorData(pluginData?.settings.mentorParams || [], t)
                                    }
                                },
                                realClient:null,
                                enabled:true
                            }}
                            />
                        ) : (
                            <UserCard pluginData={{
                                _id:clientInfo._id,
                                alias:"Chat",
                                iframe:clientInfo._id,
                                client:clientInfo._id,
                                settings:{
                                    contact:"chat",
                                    confirmationNeeded:true,
                                    userNeedsPhone:true,
                                    private:false,
                                    staffTab:false,
                                    mentorParams:[],
                                    userParams:[],
                                    filterParams:[],
                                    visibleParams:[]
                                },
                                clientInfo:{
                                    _id:clientInfo._id,
                                    alias:"test",
                                    incentives:true,
                                    partner:true,
                                    questionary:true,
                                    blur:{},
                                    name:"Test",
                                    logo:"https://www.google.com"
                                },
                                style:{
                                    showLogos:false,
                                    initialMessage:"test",
                                    mentorsLabel:"Mentors",
                                    staffLabel:"Staff",
                                    randomOrder:true,
                                    priorityArray:[]
                                }
                            }} orientatorInfo={{
                                _id:clientInfo._id,
                                client:clientInfo._id,
                                iframe:clientInfo._id,
                                plugin:clientInfo._id,
                                user:clientInfo._id,
                                userInfo:{
                                    _id:"test",
                                    name:"Marta",
                                    profilePic:undefined,
                                    info:{
                                        _id:"test",
                                        info:{
                                            main:"mainField"
                                        }
                                    }
                                },
                                realClient:null,
                                enabled:true
                            }}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}