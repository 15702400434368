import {api} from "@unigow/apis/requests";
import GroupPageWizard from "@unigow/pages/Dashboard/pages/Iframes/tabs/Groupsplugin/GroupPageWizard";
import {useGroupWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/groupWizardStore";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {FullGroup} from "@unigow/types/group";
import React from "react";
import {useQuery} from "react-query";

export default function GroupPages(): React.ReactElement {
    const {clientInfo} = useDashboardStore();
    const {selectGroupPage, setGroupPageGroups, selectedGroupPage, groupPageData} = useGroupWizardStore();

    useQuery(["grouppages", clientInfo?._id], ()=>{
        return api.get<{_id: string}[]>(`grouppages/client/${clientInfo!._id}`);
    }, {
        enabled:!!clientInfo,
        onSuccess:(data)=>{
            if (data.length === 0) {
                selectGroupPage(undefined);
                setGroupPageGroups([]);
                return;
            }

            // TEMP, get first group
            selectGroupPage(data[0]._id);

            setGroupPageGroups([]);
        }
    });

    useQuery(["groups", selectedGroupPage], ()=>{
        return api.get<FullGroup[]>(`grouppages/${selectedGroupPage}/groups`);
    }, {
        enabled: !!groupPageData,
        onSuccess:(data)=>{
            // Check every group and see if it has all the fields in the groupPageData

            // If not, add the field to the pendingFields array

            const dataWithFields = data.map((group)=>{
                const missingFields = groupPageData?.fields.filter((field)=>{
                    return !group.fields.some((groupField)=>{
                        return groupField.name === field.name;
                    });
                }) || [];

                return {
                    ...group,
                    pendingFields: missingFields.map((field)=>{
                        return {
                            ...field,
                            value: ""
                        };
                    })
                };
            });

            setGroupPageGroups(dataWithFields);
        }
    });

    return (
        <GroupPageWizard/>
    );
}