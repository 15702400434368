import {ParameterType} from "@unigow/unigow-types";

/** Function for always having two digit numbers (usefull for showing minutes, hours, etc...) */
function no(num: number): string {
    return num > 9 ? `${  num}` : `0${  num}`;
}

/** Function for getting the different in seconds between two dates */
export function getTimeDifferenceInSecs(date1: Date, date2: Date = new Date()): number {
    return Math.abs((date2.getTime() - date1.getTime()) / 1000);
}

/** Function for formating a date with different params */
export function formatTime(date?: Date | null, type?: string): string {
    if (!date) {
        return "";
    }
    if (type === "short") {
        return date.toLocaleString("es-es", {year:"2-digit", month:"numeric", day:"2-digit", hour:"2-digit", minute:"2-digit"});
    }
    return date.toLocaleString("es-es", {weekday:"long", year:"2-digit", month:"long", day:"2-digit", hour:"2-digit", minute:"2-digit"});
}

/** Short function for addings days to a date */
export function addDaysToDate(date: Date, days: number): Date {
    const timestamp = date.getTime();
    const daysTime = days * 24 * 3600 * 1000;
    return new Date(timestamp + daysTime);
}

/** Function for formating a date taking now as a reference (ex. x hours ago) */
export function formatTimeRelative(date: Date): string {
    // This function is responsible of formatting a date with respect of the current time (3 minutes ago, 6 hours ago...)
    const now = new Date();
    const then = new Date(date);

    const diff = getTimeDifferenceInSecs(now, then);

    if (diff > 24 * 3600 || then.getDate() !== now.getDate()) {
        // If the time is longer than 1 day or the date is different, display the full date
        return `${no(then.getDate())}/${no(then.getMonth() + 1)}/${then.getFullYear() - 2000} ${no(then.getHours())}:${no(then.getMinutes())}`;
    } else if (diff > 3600) {
        // if the time is from the current date but longer than 1 hour display the hours
        const hoursDiff = Math.round(diff / 3600);
        if (hoursDiff === 1) {
            return "Hace 1 hora";
        }
        return `Hace ${hoursDiff} horas`;
    } else if (diff > 60) {
        // If the time is longer than 1 minute display the minutes
        const minsDiff = Math.round(diff / 60);
        if (minsDiff === 1) {
            return "Hace 1 minuto";
        }
        return `Hace ${minsDiff} minutos`;
    }
    // If the time is shorter than 1 minute, display "recently"
    return "Hace unos instantes";
}

export function generateRandomOrientatorData(params: string[], translateFunction: (v: string)=> string): Record<string, string> {
    const orientatorParams: Record<string, string> = {};

    for (const clave of params) {
        orientatorParams[clave] = `${translateFunction(clave)} del usuario`;
    }

    return orientatorParams;
}

export function formatBytes(bytes: number): string {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let l = 0;
    let n = bytes;

    while (n >= 1024 && ++l) {
        n = n / 1024;
    }

    return (`${n.toFixed(n < 10 && l > 0 ? 1 : 0)  } ${  units[l]}`);
}

export function sortText(text: string[] = [], orientator: "asc" | "desc" = "asc"): string[] {
    return text.sort((a, b)=>{
        if (a.toLowerCase() < b.toLowerCase()) {
            return orientator === "asc" ? -1 : 1;
        }
        return orientator === "asc" ? 1 : -1;

    });
}

// Function for downloading a file to the browser
export function downloadFile(data: BlobPart, filename: string, type: string = "text/plain"): void {
    const blob = new Blob([data], {type});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}

export function validateField(newData: Record<string, unknown>, params: ParameterType[]): void {
    Object.entries(newData).forEach(([key, value]) => {
        // Search for the param in params array
        const uniParam = params?.find((param) => param.alias === key);
        // If the param has validation property
        if (uniParam?.validation && uniParam.validation !== "") {
            // Create a regex with the validation property
            const regex = new RegExp(uniParam.validation);
            // If the value doesn't match the regex, show an error
            // If the value is not a string, return
            if (typeof value !== "string") {
                return;
            }
            if (!regex.test(value)) {
                throw new Error(uniParam.validationError || `El campo ${key} no cumple con el formato requerido`);
            }
        }
    });
}

export function replaceStringVars(text: string, vars: Record<string, string>): string {
    let auxText = text;
    Object.entries(vars).forEach(([key, value])=>{
        auxText = auxText.replace(new RegExp(`{${  key  }}`, "g"), value);
    });
    return auxText;
}

export function printAdditionalInfo(value: string | string[] | undefined): string {
    if (!value) {
        return "";
    }

    if (typeof value === "string") {
        return value;
    }
    return value.map((x) => `#${x}`).join(" ");
}

export function convertAdditionalInfo(additionalInfo: Record<string, string | string[]> | undefined): Record<string, string> {
    if (!additionalInfo) return {};

    const result: Record<string, string> = {};
    Object.entries(additionalInfo).forEach(([key, value])=>{
        result[key] = printAdditionalInfo(value);
    });
    return result;
}

// Function that turns a string or string[] into a string
export function printArray(value: string | string[] | undefined): string {
    if (!value) {
        return "";
    }

    if (typeof value === "string") {
        return value;
    }
    return value.join(", ");
}

export function generateCsv(headers: string[], data: string[][], fileName: string): void {
    // Saves the data from the mentors array in a csv file and then downloads it
    const headersText = headers.join(";");

    const dataText = data.map((row) => row.join(";")).join("\n");

    downloadFile([headersText, dataText].join("\n"), fileName, "text/csv");
}