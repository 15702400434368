import React, {useState} from "react";
import {useQuery} from "react-query";
import {api} from "../../../../apis/requests";
import {useParams} from "react-router-dom";
import {twMerge} from "tailwind-merge";
import {LandingType} from "@unigow/unigow-types";

function LandingPage(): React.ReactElement {
    const {alias, client} = useParams();

    const [landingData, setLandingData] = useState<LandingType | undefined>(undefined);

    useQuery(["landing", alias], async()=>{
        return api.get<LandingType>(`landing/${client}/${alias}`);
    }, {
        enabled:!!alias && !!client,
        onSuccess:(data)=>{
            setLandingData(data);
        }
    });

    function getLogoSize(): number {
        if (landingData?.logoSize === "small") return 75;
        if (landingData?.logoSize === "big") return 200;

        return 100;
    }

    return (
        <div className="min-h-screen" style={{backgroundColor:landingData?.background || "#FFF"}}>
            {/* HEADER */}
            <div className="border-b border-t-0 border-x-0 border-solid border-gray-cool-200 py-4 px-24 flex justify-between" style={{backgroundColor:landingData?.banner || "#AAA"}}>
                <div className="flex gap-4 items-center justify-center lg:justify-start w-full">
                    <div className={twMerge("rounded-sm flex items-center overflow-hidden", landingData?.logo ? "" : "bg-white")}>
                        {landingData?.logo ? (
                            <img style={{width:getLogoSize()}} src={landingData.logo} alt="" />
                        ) : (
                            <p className="px-4 py-1">LOGO</p>
                        )}
                    </div>
                </div>
            </div>

            {/* BODY */}
            <div className="relative">
                <div className="flex flex-col justify-center text-center pt-10 px-4 mx-auto gap-2">
                    <div className="flex items-center gap-4 justify-center">
                        <h1>{landingData?.title || "Descubre las experiencias reales de nuestros estudiantes"}</h1>
                    </div>
                    {landingData?.description && (
                        <div className="flex items-center gap-4 justify-center">
                            <h3>{landingData.description}</h3>
                        </div>
                    )}
                </div>
                {!!landingData && (
                    <div className="flex justify-center py-4"><iframe className="border-none" src={`/iframe/${landingData.iframe}`} sandbox="allow-same-origin allow-scripts allow-forms allow-popups" width="95%" height="500px"></iframe></div>
                )}
            </div>
        </div>
    );
}

export default LandingPage;