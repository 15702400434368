import React, {useEffect, useState} from "react";

import {CircularProgress} from "@mui/material";
import UnigowLogo from "@unigow/assets/unigow-small.svg";
import {twMerge} from "tailwind-merge";

interface LoadingProps {
    logo?: boolean,
    color?: string,
    iframe?: boolean
}

export function Loading(props: LoadingProps): React.ReactElement {
    const [isSlow, setIsSlow] = useState(false);
    const {logo, color, iframe} = props;

    useEffect(()=>{
        const handleSlow = setTimeout(()=>{
            setIsSlow(true);
        }, 8000);
        return ()=>{
            clearTimeout(handleSlow);
        };
    }, []);

    return (
        <div className={twMerge("bg-white fixed w-full h-full top-0 flex justify-center items-center z-50 flex-col", iframe ? "relative" : "")}>
            <div className="relative w-32">
                {logo && (
                    <div className="absolute left-0 right-0 w-full h-full flex justify-center items-center">
                        <img loading="lazy" src={UnigowLogo} alt="Unigow" className="w-24"/>
                    </div>
                )}
                <CircularProgress size="8rem" className={twMerge("absolute left-0 z-10 text-primary-500", color)}/>
                <figure className="w-32 h-32 rounded-full border-8 border-primary opacity-40 flex justify-center items-center transition-colors duration-300" style={{transform:"scale(0.9)"}}/>
            </div>
            <p className="font-bold">Cargando...</p>
            {isSlow && <p>Parece que el volumen de usuarios está poniendo a prueba nuestros servidores, espera un momento o vuelve más tarde...</p>}
        </div>
    );
}