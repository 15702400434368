import React from "react";
import {useQuery} from "react-query";
import {api} from "../../../../../apis/requests";
import {Withdrawal} from "../../../../../types/award";
import {TableCell, TableRow} from "@mui/material";
import {UnigowTable} from "../../../../../components/UnigowTable/UnigowTable";
import {CardCode} from "./CardCode";
import AmazonCard from "@unigow/assets/amazoncard.webp";

export default function CardsHistory(): React.ReactElement {
    const {data = []} = useQuery("cards", async()=>{
        return api.get<Withdrawal[]>("withdrawals");
    });

    if (data.length === 0) return <></>;

    return (
        <div className="w-full">
            <p className="text-ug-lg mb-4">Pulsa en el texto borroso para obtener tu código</p>
            <div className="bg-white shadow-lg overflow-x-auto max-h-[700px] rounded-[20px]">
                <UnigowTable headerTitles={["Código de la tarjeta", "Cantidad", "Fecha"]} elementCount={data.length}>
                    {data?.map((card)=>(
                        <TableRow key={card._id}>
                            <TableCell>
                                <div className="flex items-center gap-8">
                                    <img height={25} src={AmazonCard} alt="Tarjeta Amazon" />
                                    <CardCode cardId={card._id}/>
                                </div>
                            </TableCell>
                            <TableCell>
                                <p className="text-ug-base font-semibold">{card.awardInfo?.value}€</p>
                            </TableCell>
                            <TableCell>
                                <p className="text-ug-base text-gray-cool-500">{new Date(card.createdAt).toLocaleDateString()}</p>
                            </TableCell>
                        </TableRow>
                    ))}
                </UnigowTable>
            </div>
        </div>
    );
}