import {Conversation} from "@twilio/conversations";
import {PinnedQuestion} from "@unigow/types/chat";
import {create} from "zustand";

type ChatStore = {
    conv: Conversation | undefined;
    setConv: (conv: Conversation | undefined)=> void;

    currentMessage: string;
    setCurrentMessage: (message: string)=> void;

    isDashboard: boolean;
    setIsDashboard: (dashboard: boolean)=> void;

    questionToPin: string | undefined;
    setQuestionToPin: (question: string | undefined)=> void;

    pinnedQuestions: PinnedQuestion[];
    setPinnedQuestions: (pinnedQuestions: PinnedQuestion[])=> void;
    showPinnedQuestions: PinnedQuestion[];
    showAllPinnedQuestions: ()=> void;
    hidePinnedQuestions: ()=> void;
    selectPinnedQuestion: (question: string)=> void;
};

export const useChatStore = create<ChatStore>((set, get)=>({
    conv: undefined,
    setConv: (conv)=> set({conv}),

    currentMessage: "",
    setCurrentMessage: (message)=> set({currentMessage: message}),

    isDashboard: false,
    setIsDashboard: (dashboard)=> set({isDashboard: dashboard}),

    questionToPin: undefined,
    setQuestionToPin: (question)=> set({questionToPin: question}),

    pinnedQuestions:[],
    setPinnedQuestions: (pinnedQuestions)=> set({pinnedQuestions}),
    showPinnedQuestions: [],
    showAllPinnedQuestions: ()=> set({showPinnedQuestions: get().pinnedQuestions}),
    hidePinnedQuestions: ()=> set({showPinnedQuestions: []}),
    selectPinnedQuestion: (question)=> {
        const selectedQuestion = get().pinnedQuestions.find((q)=> q.question === question);
        if (selectedQuestion) {
            set({showPinnedQuestions: [selectedQuestion]});
        }
    }
}));