import React, {FormEvent, useState, useEffect} from "react";
import UnigowInput from "../../../components/UnigowInput/UnigowInput";
import UnigowButton from "../../../components/UnigowButton/UnigowButton";
import {useAuth} from "../../../contexts/AuthContext";
import {api} from "../../../apis/requests";
import {toast} from "react-toastify";
import {LoggedUser} from "../../../types/user";
import {ReactComponent as Logout} from "@unigow/assets/logout.svg";
import {isPhoneNumber} from "class-validator";
import {useAuthStore} from "@unigow/stores/authStore";
import {UnigowPhoneInput} from "@unigow/components/UnigowInput/UnigowPhoneInput";
import {useIframeStore} from "@unigow/stores/iframeStore";
import UserForm from "@unigow/pages/Iframe/pages/Form/UserForm";

export default function Profile(): React.ReactElement {
    // STORES
    const {logoutUser} = useAuth();
    const {userData} = useAuthStore();
    const {iframeData} = useIframeStore();

    // STATES
    const [addParams, setAddParams] = useState<string[]>([]);
    const [name, setName] = useState("");
    const [surnames, setSurnames] = useState("");
    const [phone, setPhone] = useState<string>();

    // HANDLERS
    async function handleUserSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (phone && phone !== "" && !isPhoneNumber(phone)) {
            toast.error("El número de teléfono debe estar en formato internacional +34XXXXXXXXX");
            return;
        }

        if (name === userData?.name && surnames === userData?.surnames && phone === userData.phone) return;

        const body: Record<string, string> = {
            name,
            surnames
        };

        if (phone) {
            body.phone = phone.trim().replace(/ /g, "");
        }

        const res = await api.patch<Record<string, string>, LoggedUser>("users/updateProfile", body);

        if (res) {
            toast.success("Cambios guardados con éxito");
        }
    }

    // EFFECTS
    useEffect(()=>{
        if (userData) {
            setName(userData.name);
            setSurnames(userData.surnames);
            if (userData.phone) setPhone(userData.phone);
        }
    }, [userData]);

    useEffect(()=>{
        if (!iframeData) return;

        const params: string[] = [];

        iframeData.pluginData.forEach((plugin)=>{
            if (!("settings" in plugin)) return;

            plugin.settings.userParams.forEach((param)=>{
                if (params.includes(param)) return;

                params.push(param);
            });
        });

        setAddParams(params);
    }, [iframeData]);


    return (
        <div className="flex flex-col w-10/12 mx-auto gap-8">
            {userData && (
                <form className="flex flex-col gap-4" onSubmit={handleUserSubmit}>
                    <h3>Tu información básica</h3>
                    <div className="w-full flex flex-col shadow-md rounded-[10px] bg-white">
                        <div className="flex flex-col lg:flex-row px-[30px] justify-between lg:items-center py-4 gap-2">
                            <p className="text-ug-lg font-semibold">Nombre</p>
                            <UnigowInput value={name} onChange={(e)=>setName(e.target.value)}/>
                        </div>
                        <div className="flex flex-col lg:flex-row  px-[30px] justify-between lg:items-center py-4 gap-2">
                            <p className="text-ug-lg font-semibold">Apellidos</p>
                            <UnigowInput value={surnames} onChange={(e)=>setSurnames(e.target.value)}/>
                        </div>
                        <div className="flex flex-col lg:flex-row  px-[30px] justify-between lg:items-center py-4 gap-2">
                            <p className="text-ug-lg font-semibold">Correo Electrónico</p>
                            <UnigowInput type="email"  inputProps={
                                {readOnly:true}
                            } value={userData?.email}
                            />
                        </div>
                        <div className="flex flex-col lg:flex-row  px-[30px] justify-between lg:items-center py-4 gap-2">
                            <p className="text-ug-lg font-semibold">Número de teléfono</p>
                            <UnigowPhoneInput value={phone}
                                onChange={(v)=>{
                                    setPhone(`+${v}`);
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <UnigowButton type="submit" variant="secondary" className="px-16">Guardar Cambios</UnigowButton>
                    </div>
                </form>
            )}
            {addParams && addParams.length > 0 && (
                <div className="flex flex-col gap-4">
                    <h3>Tu información como usuario</h3>
                    <UserForm neededParams={addParams} shadows className="flex flex-col shadow-md rounded-[10px] p-4 gap-4" SubmitButton={(
                        <div>
                            <UnigowButton type="submit" variant="secondary" className="px-16">Guardar Cambios</UnigowButton>
                        </div>
                    )}
                    />
                </div>
            )}
            <div>
                <UnigowButton onClick={()=>logoutUser({redirect:"#"})} className="py-1" variant="basic"><div className="flex items-center gap-4"><p>Cerrar sesión</p> <Logout/></div></UnigowButton>
            </div>
        </div>
    );
}