import {Checkbox, MenuItem} from "@mui/material";
import {IframeAPI} from "@unigow/apis/iframe";
import PageContainer from "@unigow/components/PageContainer/PageContainer";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import UnigowSelect from "@unigow/components/UnigowSelect/UnigowSelect";
import {getTitleSubtitle} from "@unigow/pages/Dashboard/pages/Iframes/IframeWizard";
import {useIframeWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/iframeWizard.store";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {CreateIframeReq, IframeType} from "@unigow/unigow-types";
import React, {Children, FormEvent, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {twMerge} from "tailwind-merge";



export default function BasicConfig(): React.ReactElement {
    const navigate = useNavigate();

    const {selectedClient} = useDashboardStore();
    const {iframeData, setIframeData, addClientIframe, setClientIframe} = useIframeWizardStore();

    let iframeText = "";

    if (iframeData) {
        iframeText = `
    <div><iframe src="${window.location.href.split("/iframes")[0]}/iframe/${iframeData._id}" sandbox="allow-same-origin allow-scripts allow-forms allow-popups" width="100%" height="900px" frameBorder="0"></iframe></div>
    `;
    }

    // STATES
    const [name, setName] = useState("");
    const [alias, setAlias] = useState("");
    const [color, setColor] = useState((iframeData && iframeData.style) ? iframeData?.style.mainColor || "#0034BB" : "#0034BB");
    const [font, setFont] = useState((iframeData && iframeData.style) ? iframeData?.style.fontFamily || "Montserrat" : "Montserrat");
    const [backgroundColor, setBackgroundColor] = useState((iframeData && iframeData.style) ? iframeData?.style.backgroundColor || "#FFFFFF" : "#FFFFFF");
    const [plugins, setPlugins] = useState<IframeType["plugins"]>([]);
    const [siteUrl, setSiteUrl] = useState("");
    const [minorsAllowed, setMinorsAllowed] = useState(false);

    const pluginsList: IframeType["plugins"] = ["chats", "groups", "cards"];

    // REFS
    const colorPickerRef = useRef<HTMLInputElement>(null);
    const bgColorPickerRef = useRef<HTMLInputElement>(null);

    useEffect(()=>{
        if (!iframeData) return;

        setName(iframeData.name);
        setAlias(iframeData.alias);
        setColor(iframeData.style.mainColor);
        setFont(iframeData.style.fontFamily);
        setPlugins(iframeData.plugins);
        setBackgroundColor(iframeData.style.backgroundColor);
        setSiteUrl(iframeData.siteUrl || "");
        setMinorsAllowed(iframeData.settings.minorsAllowed);
    }, [iframeData]);

    async function saveChanges(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (iframeData) {
            // Existing iframe
            // Send only the properties that have changed
            const iframe: Partial<IframeType> = {
                name,
                alias,
                plugins,
                style:{
                    mainColor:color,
                    fontFamily:font,
                    backgroundColor
                },
                siteUrl: siteUrl !== "" ? siteUrl : undefined,
                settings:{
                    minorsAllowed,
                    private:false
                }
            };

            const response = await IframeAPI.updateIframe({iframeId:iframeData._id.toString(), data:iframe});

            if (response) {
                toast.success("Cambios guardados correctamente");
                setClientIframe(response);
                if (alias !== iframeData.alias) {
                    // If the alias has changed, redirect to the new alias
                    navigate(`/iframes/${iframe.alias}`);
                } else {
                    setIframeData(response);
                }
            }

            return;
        }

        // New iframe
        const newIframe: CreateIframeReq = {
            name,
            client:selectedClient,
            plugins,
            alias,
            style:{
                mainColor:color,
                fontFamily:font
            },
            settings:{
                private:false
            },
            siteUrl: siteUrl !== "" ? siteUrl : undefined
        };

        const response = await IframeAPI.createIframe(newIframe);

        if (response) {
            addClientIframe({_id:response.iframeId, ...newIframe});
            setIframeData({_id:response.iframeId, ...newIframe});

            // Redirect to the correct config page
            navigate(`/iframes/${alias}`);
        }
    }

    return (
        <div className="flex flex-col gap-4">
            <PageContainer className="gap-[20px] items-start select-none w-fill p-0 lg:p-0">
                <div className="bg-white w-[calc(90%-2rem)] rounded-r-xl lg:px-8 py-4 px-4 flex gap-4 flex-col">
                    <form className="flex flex-wrap gap-4" onSubmit={saveChanges}>
                        <div className="flex flex-col gap-[10px] w-full md:w-[calc(50%-.5rem)]">
                            <p className="text-ug-base font-semibold">Escribe un título para esta herramienta</p>
                            <UnigowInput required placeholder="Nombre de la herramienta" value={name} onChange={(e)=>{
                                setName(e.target.value);
                            }}
                            />
                        </div>
                        <div className="flex flex-col gap-[10px] w-full md:w-[calc(50%-.5rem)]">
                            <p className="text-ug-base font-semibold">Escribe un alias para esta herramienta</p>
                            <UnigowInput required placeholder="Alias de la herramienta" value={alias} onChange={(e)=>{
                                setAlias(e.target.value);
                            }}
                            />
                        </div>
                        <div className="flex flex-col gap-[10px] w-full">
                            <p className="text-ug-base font-semibold">URL de la página donde se va a encontrar la herramienta (opcional)</p>
                            <UnigowInput className="w-full" placeholder="https://www.ejemplo.com" value={siteUrl} onChange={(e)=>{
                                setSiteUrl(e.target.value);
                            }}
                            />
                        </div>
                        <div className="flex flex-col gap-[10px] w-full">
                            <p className="text-ug-base font-semibold">¿Permitir el acceso a menores de edad?</p>
                            <div className="flex gap-4">
                                <label htmlFor="minors-allowed" className="cursor-pointer">
                                    <Checkbox id="minors-allowed" checked={minorsAllowed} onChange={(e)=>{
                                        setMinorsAllowed(e.target.checked);
                                    }}
                                    />
                                    Permitir el acceso a menores de 14 años
                                </label>
                            </div>
                        </div>
                        <div className="w-full flex flex-col gap-4">
                            <p className="text-ug-base font-semibold">Elige todos los plugins que quieras incluir en tu herramienta</p>
                            <div className="flex-wrap flex gap-4">
                                {Children.toArray(pluginsList.map((plugin)=>(
                                    <label htmlFor={plugin} className={twMerge("px-2 py-2 border-2 border-solid cursor-pointer border-gray-cool-300 rounded-full text-gray-cool-500 font-semibold", plugins.includes(plugin) ? "border-secondary-blue-500 bg-secondary-blue-200/35 text-black" : "")}>
                                        <div className="flex items-center gap-2 px-2">
                                            <input className="opacity-0 w-0 m-0" id={plugin} type="checkbox" checked={plugins.includes(plugin)} onChange={(e)=>{
                                                if (e.target.checked) {
                                                    setPlugins([...plugins, plugin]);
                                                } else {
                                                    setPlugins(plugins.filter((p)=>p !== plugin));
                                                }
                                            }}
                                            />
                                            {getTitleSubtitle(plugin).icon}
                                            {getTitleSubtitle(plugin).name}
                                        </div>
                                    </label>
                                )))}
                            </div>
                        </div>
                        <div className="flex flex-col gap-[10px] w-full md:w-[calc(50%-.5rem)]">
                            <p className="text-ug-base font-semibold">Color Principal</p>
                            <div className="flex gap-[10px]">
                                <UnigowInput required value={color} onChange={(e)=>{
                                    setColor(e.target.value);
                                }} className="md:w-3/4 w-full"
                                onClick={()=>{
                                    colorPickerRef.current?.click();
                                }}
                                />
                                <div onClick={()=>{
                                    colorPickerRef.current?.click();
                                }} className="rounded w-9 h-auto relative border border-solid border-gray-cool-200" style={{backgroundColor:color}}
                                >
                                    <input className="opacity-0" ref={colorPickerRef} type="color" onChange={(e)=>{
                                        setColor(e.target.value);
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[10px] w-full md:w-[calc(50%-.5rem)]">
                            <p className="text-ug-base font-semibold">Elige la fuente de texto</p>
                            <UnigowSelect required value={font} style={{fontFamily:font}} onChange={(e)=>{
                                setFont(e.target.value);
                            }}
                            label="Tipo de fuente"
                            >
                                <MenuItem value="monospace" style={{fontFamily:"monospace"}}>Monospace</MenuItem>
                                <MenuItem value="Montserrat" style={{fontFamily:"Montserrat"}}>Montserrat</MenuItem>
                                <MenuItem value="Arial" style={{fontFamily:"Arial"}}>Arial</MenuItem>
                                <MenuItem value="Calibri" style={{fontFamily:"Calibri"}}>Calibri</MenuItem>ç
                                <MenuItem value={"\"Courier New\""} style={{fontFamily:"\"Courier New\""}}>Courier New</MenuItem>
                                <MenuItem value="Georgia" style={{fontFamily:"Georgia"}}>Georgia</MenuItem>
                                <MenuItem value={"\"Times New Roman\""} style={{fontFamily:"\"Times New Roman\""}}>Times New Roman</MenuItem>
                                <MenuItem value="Verdana" style={{fontFamily:"Verdana"}}>Verdana</MenuItem>
                                <MenuItem value="Noto Sans HK" style={{fontFamily:"Noto Sans HK"}}>Noto Sans HK</MenuItem>
                            </UnigowSelect>
                        </div>
                        <div className="flex flex-col gap-[10px] lg:w-1/2 wr">
                            <p className="text-ug-base font-semibold">Color de Fondo</p>
                            <div className="flex gap-[10px]">
                                <UnigowInput value={backgroundColor} onChange={(e)=>{
                                    setBackgroundColor(e.target.value);
                                }} className="lg:w-3/4 w-full"
                                onClick={()=>{
                                    bgColorPickerRef.current?.click();
                                }}
                                />
                                <div onClick={()=>{
                                    bgColorPickerRef.current?.click();
                                }} className="rounded w-9 h-auto relative border border-solid border-gray-cool-200" style={{backgroundColor:backgroundColor}}
                                >
                                    <input className="opacity-0" ref={bgColorPickerRef} type="color" onChange={(e)=>{
                                        setBackgroundColor(e.target.value);
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="basis-[100%]">
                            <UnigowButton className="px-10" variant="business">Guardar cambios</UnigowButton>
                        </div>
                    </form>
                    {!!iframeData && (
                        <div className={twMerge("flex flex-col rounded-[10px] border border-solid border-secondary-blue-400 px-[26px] py-2 gap-[15px] pb-4")}>
                            <div className="flex flex-col gap-[10px]">
                                <p className="text-ug-base font-semibold">Código a copiar en la web</p>
                                <div className="border-gray-cool-200 border-2 border-dashed p-2 font-mono">
                                    <p className="font-mono text-ug-base font-light break-all select-text">
                                        {iframeText}
                                    </p>
                                </div>
                            </div>
                            <UnigowButton variant="primary" onClick={async()=>{
                                await navigator.clipboard.writeText(iframeText);
                                toast.success("Código copiado al portapapeles");
                            }}
                            >Copiar el código
                            </UnigowButton>
                        </div>
                    )}
                </div>
            </PageContainer>
        </div>
    );
}