import React, {useState} from "react";
import {api} from "../../../../../apis/requests";
import {toast} from "react-toastify";
import {twMerge} from "tailwind-merge";
import * as Sentry from "@sentry/react";

interface CardCodeProps {
    cardId: string
}

export function CardCode(props: CardCodeProps): React.ReactElement {
    const {cardId} = props;
    const [code, setCode] = useState("CANJEA-TU-CODIGO");

    async function claimCode(): Promise<void> {
        if (code !== "CANJEA-TU-CODIGO") return;
        const loadingToast = toast.info("Generando código...", {autoClose:false});
        try {
            const res = await api.get<{code: string}>(`withdrawals/${cardId}/claim`);
            if (!res.code) {
                toast.error("Ha ocurrido un error al canjear la recompensa");
                return;
            }
            setCode(res.code);
            toast.dismiss(loadingToast);
        } catch (err) {
            Sentry.captureException(err);
            toast.error("Ha ocurrido un error al canjear la recompensa");
            toast.dismiss(loadingToast);
        }
    }

    return (
        <p className={twMerge("font-semibold text-ug-base", "cursor-pointer transition-all",
            code === "CANJEA-TU-CODIGO" ? "blur-sm hover:scale-110 hover:blur-[2px]" : "")}
        onClick={claimCode}
        >{code}
        </p>
    );
}