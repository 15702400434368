import {Button, styled} from "@mui/material";
import React, {InputHTMLAttributes, useEffect, useState} from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {getDownloadURL, getMetadata, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "@unigow/firebase";

interface FileInputProps {
    handleFieldChange: (fieldName: string, value: string | string[])=> void,
    fieldName: string;
    fileUrl?: string | undefined;
}

export default function FileInput({handleFieldChange, fieldName, fileUrl, inputProps}: (FileInputProps &
{inputProps?: Partial<InputHTMLAttributes<HTMLInputElement>>})): React.ReactElement {
    const [fileMetadata, setFileMetadata] = useState<{
        name: string,
        size: number
    } | null>(null);

    function handleNewFile(files: FileList): void {
        const firstFile = files.item(0);

        if (!firstFile) return;

        // Upload the file to Firebase Storage
        const storageRef = ref(storage, `paramfiles/${firstFile.name}`);

        const uploadTask = uploadBytesResumable(storageRef, firstFile);

        uploadTask.on("state_changed", (snapshot)=>{
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
        }, (error)=>{
            console.error(error);
        }, async()=>{
            await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL)=>{
                handleFieldChange(fieldName, downloadURL);
            });
            setFileMetadata({
                name: firstFile.name,
                size: firstFile.size
            });
        });
    }

    useEffect(()=>{
        if (!fileUrl || !!fileMetadata) return;

        getMetadata(ref(storage, fileUrl)).then((metadata)=>{
            setFileMetadata({
                name: metadata.name,
                size: metadata.size
            });
        })
            .catch((error)=>{
                console.error(error);
            });
    }, [fileUrl, fileMetadata, setFileMetadata]);

    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1
    });

    return (
        <div className="flex gap-4 flex-col">
            {fileMetadata && (
                <p>{fileMetadata.name} ({(fileMetadata.size / 1024).toFixed(2)} KB)</p>
            )}
            <Button
                variant="contained"
                component="label"
                role={undefined}
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
            >
                {fileMetadata ? "Reemplazar" : "Subir"} archivo
                <VisuallyHiddenInput
                    type="file"
                    {...inputProps}
                    onChange={(e)=>{
                        if (e.target.files) {
                            handleNewFile(e.target.files);
                        }
                    }}
                />
            </Button>
        </div>
    );
}