import React, {Fragment, useState, FormEvent} from "react";
import PopupWindow from "@unigow/components/PopupWindow/PopupWindow";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {CopyAll, Visibility, VisibilityOff} from "@mui/icons-material";
import {Autocomplete, IconButton} from "@mui/material";
import {toast} from "react-toastify";
import {api} from "@unigow/apis/requests";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import MentorSelector from "./MentorsSelector";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";

interface MentorsInviteProps {
    group?: string;
}

function MentorsInvite({group}: MentorsInviteProps): React.ReactElement {
    const {selectedClient, clientInfo} = useDashboardStore();
    const [open, setOpen] = useState(false);
    const [mentorSelector, setMentorSelector] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [emails, setEmails] = useState<string[]>([]);

    async function sendInvitations(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        if (emails.length === 0) return;

        const body = {
            emails,
            group
        };

        const res = await api.post<{emails: string[]}, {status: number}>(`clients/${selectedClient}/invite`, body);

        if (res) {
            toast.success("Invitaciones enviadas con éxito");
        }
    }

    return (
        <Fragment>
            <div className="">
                <UnigowButton type="button" variant={group ? "basic" : "secondary"} onClick={()=>setOpen(true)}>{!group ? "Invita a embajadores" : "Invitar embajadores no registrados"}</UnigowButton>
            </div>
            {clientInfo && (
                <PopupWindow title="Invitar a embajadores" open={open} onClose={()=>setOpen(false)} closePopup={()=>setOpen(false)}>
                    {clientInfo.partner && (
                        <MentorSelector open={mentorSelector} setOpen={setMentorSelector}/>
                    )}
                    <div className="flex flex-col gap-[15px]">
                        {clientInfo.partner && (
                            <div className="">
                                <UnigowButton variant="secondary" className="py-1" onClick={()=>setMentorSelector(true)}>
                                    Invitar embajadores existentes en Unigow
                                </UnigowButton>
                            </div>
                        )}
                        <div className="flex flex-col gap-[5px]">
                            <h3 className="text-primary-500">Invita de forma manual con las claves de acceso</h3>
                            <p className="text-ug-sm">Copia el Link y la Contraseña Maestra que necesitan para registrarse</p>
                        </div>
                        <div className="flex lg:items-center gap-4 justify-between flex-col lg:flex-row">
                            <p className="text-ug-lg font-semibold">Link de registro</p>
                            <div className="px-2 py-1 border border-solid border-gray-cool-300 rounded-[5px] lg:w-3/5 flex-shrink-0 flex justify-between items-center">
                                <input readOnly className="text-ug-base text-cool-500 border-0 w-full cursor-pointer" value={`${process.env.REACT_APP_DOMAIN}/register/ambassador${group ? `?group=${group}` : ""}`}
                                    onClick={async()=>{
                                        await navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN}/register/ambassador${group ? `?group=${group}` : ""}`);
                                        toast.success("Copiado con éxito al portapapeles");
                                    }}
                                />
                                <IconButton onClick={async()=>{
                                    await navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN}/register/ambassador${group ? `?group=${group}` : ""}`);
                                    toast.success("Copiado con éxito al portapapeles");
                                }}
                                >
                                    <CopyAll/>
                                </IconButton>
                            </div>
                        </div>
                        <div className="flex lg:items-center gap-4 justify-between flex-col lg:flex-row">
                            <p className="text-ug-lg font-semibold">Contraseña Maestra de Embajadores</p>
                            <div className="px-2 py-1 border border-solid border-gray-cool-300 rounded-[5px] lg:w-3/5 flex-shrink-0 flex justify-between items-center">
                                <input readOnly type={showPass ? "text" : "password"} className="text-ug-base text-cool-500 border-0 w-full cursor-pointer" value={clientInfo.orientatorPassword}
                                    onClick={async()=>{
                                        if (!clientInfo.orientatorPassword) return;
                                        await navigator.clipboard.writeText(clientInfo.orientatorPassword);
                                        toast.success("Copiado con éxito al portapapeles");
                                    }}
                                />
                                <div className="flex shrink-0">
                                    <IconButton onClick={()=>{
                                        setShowPass((prev)=>!prev);
                                    }}
                                    >
                                        {showPass ? (
                                            <Visibility/>
                                        ) : (
                                            <VisibilityOff/>
                                        )}
                                    </IconButton>
                                    <IconButton onClick={async()=>{
                                        if (!clientInfo.orientatorPassword) return;
                                        await navigator.clipboard.writeText(clientInfo.orientatorPassword);
                                        toast.success("Copiado con éxito al portapapeles");
                                    }}
                                    >
                                        <CopyAll/>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={sendInvitations} className="flex flex-col gap-[15px]">
                            <div className="flex flex-col gap-5">
                                <div className="flex flex-col gap-[5px]">
                                    <h3 className="text-primary-500">Invita por correo de forma automática</h3>
                                    <p className="text-ug-sm">Escribe los correos de los Embajadores a los que quieres invitar separados por una coma.
                                        Recibirán una invitación con su link de registro y contraseña maestra
                                    </p>
                                </div>
                                <Autocomplete onChange={(e, v)=>setEmails(v)} value={emails} fullWidth freeSolo multiple
                                    renderInput={(params)=>(
                                        <UnigowInput type="email" variant="outlined" {...params}/>
                                    )}
                                    options={[]}
                                />
                            </div>
                            <div>
                                <UnigowButton type="submit" disabled={emails.length === 0} className="px-8" variant="secondary">Enviar invitación automática por correo</UnigowButton>
                            </div>
                        </form>
                    </div>
                </PopupWindow>
            )}
        </Fragment>
    );
}

export default MentorsInvite;
