import React from "react";

import UnigowButton, {UnigowButtonProps} from "@unigow/components/UnigowButton/UnigowButton";
import {twMerge} from "tailwind-merge";
import {useIframe} from "../../contexts/IframeContext";
import {CreateChat} from "@unigow/types/chat";
import {createChatHelper, userAnsweredQuestions} from "@unigow/helpers/chat";
import {useAuthStore} from "@unigow/stores/authStore";
import {printArray} from "@unigow/helpers/helper";
import {useIframeStore} from "@unigow/stores/iframeStore";
import {getPluginData} from "@unigow/helpers/iframe";
import {PublicOrientator} from "@unigow/types/orientators";
import {ChatsPluginType} from "@unigow/unigow-types";
import {getLocalStorageObj} from "@unigow/helpers/localstorage";
import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";

interface ChatButtonProps {
    orientatorInfo: PublicOrientator
}

function ChatButton({orientatorInfo, className, ...buttonProps}: ChatButtonProps & UnigowButtonProps): React.ReactElement {
    // STORES
    const {getRequiredParams} = useCustomLocale();
    const {setCurrentPage} = useIframe();
    const {userData} = useAuthStore();
    const {setLoading, setPendingAction, iframeData} = useIframeStore();

    // FUNCTIONS
    async function createChat(): Promise<void> {
        if (!iframeData) return;

        const pluginData = getPluginData(orientatorInfo.plugin.toString(), iframeData.pluginData) as ChatsPluginType;

        if (!pluginData) return;

        // Search the value of the field defined as main in the iframe in the orientator's additionalInfo
        const mainField = orientatorInfo.userInfo.info.info[pluginData.settings.mainField || ""];

        // Send GTM event
        if (window.dataLayer) {
            window.dataLayer.push({event: "button_click_chat", main_category: mainField, client: iframeData.clientInfo.name});
        }

        const newChat: CreateChat = {
            orientator:orientatorInfo.userInfo._id,
            // Si existe un cliente alternativo, usar su Id. Si no usar el id del propio iframe
            client:orientatorInfo.realClient?._id.toString() || pluginData.client.toString(),
            iframe:pluginData.iframe.toString(),
            plugin:pluginData._id.toString(),
            mainField:printArray(mainField),
            // Si existe un cliente alternativo, añadir propiedad extra con el id del cliente del iframe
            iframeClient:orientatorInfo.realClient?._id ? pluginData.client.toString() : undefined
        };

        if (!userData) {
            setPendingAction({
                action:"chat",
                data:newChat,
                needsPhone:pluginData.settings.userNeedsPhone,
                userParams:pluginData.settings.userParams,
                privacy:orientatorInfo.realClient ? {
                    privacyLink:orientatorInfo.realClient.privacy || "",
                    clientName:orientatorInfo.realClient.name,
                    clientId:orientatorInfo.realClient._id.toString()
                } : undefined
            });

            localStorage.setItem("redirectTo", "form");
            setCurrentPage("register");
            return;
        }

        const unansweredQuestions = userAnsweredQuestions(getRequiredParams, userData.minor,  pluginData.settings.userParams,
            userData.additionalInfo);

        const privacyAcceptedRaw = getLocalStorageObj<string[]>("privacyAccepted");

        // Si el usuario no está logueado o no ha respondido las preguntas del iframe, guardar el chat para luego
        if (unansweredQuestions.length > 0 || (pluginData.settings.userNeedsPhone && (!userData.phone || userData.phone === "")) || (orientatorInfo.realClient && (!privacyAcceptedRaw || !privacyAcceptedRaw.includes(orientatorInfo.realClient._id.toString())))) {
            setPendingAction({
                action:"chat",
                data:newChat,
                needsPhone:pluginData.settings.userNeedsPhone,
                userParams:pluginData.settings.userParams,
                privacy:orientatorInfo.realClient ? {
                    privacyLink:orientatorInfo.realClient.privacy || "",
                    clientName:orientatorInfo.realClient.name,
                    clientId:orientatorInfo.realClient._id.toString()
                } : undefined
            });

            setCurrentPage("form");
            return;
        }

        setLoading(true);
        // Si cumple ambos requisitos crear el chat en el momento
        await createChatHelper(newChat, (sid: string)=>setCurrentPage(`chat-${sid}`), ()=>setPendingAction(undefined));
        setLoading(false);
    }

    return (
        <UnigowButton type="button" className={twMerge(className)} {...buttonProps}
            onClick={createChat}
        >{buttonProps.children}
        </UnigowButton>
    );
}

export default ChatButton;