import React, {useEffect, useState} from "react";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import UnigowButton from "../../../../components/UnigowButton/UnigowButton";
import {Download} from "@mui/icons-material";
import {Spinner} from "flowbite-react";
import {UnigowTable} from "../../../../components/UnigowTable/UnigowTable";
import {TableCell, TableRow} from "@mui/material";
import {useCustomQuery} from "../../../../hooks/useCustomQuery";
import {AnalyzedChat} from "../../../../types/chat";
import {api} from "../../../../apis/requests";
import {ReactComponent as StarsIcon} from "@unigow/assets/stars.svg";
import {toast} from "react-toastify";
import ConversationTag, {ConversationTagColor} from "./components/ConversationTag";
import {generateCsv} from "../../../../helpers/helper";
import {getCookie, setCookie} from "../../../../helpers/localstorage";
import {useDashboardStore} from "../../../../stores/dashboard/dashboardStore";

export default function Analysis(): React.ReactElement {
    const {selectedClient, clientInfo} = useDashboardStore();
    const [selectedConv, setSelectedConv] = useState<AnalyzedChat | null>(null);
    const [lastRefresh, setLastRefresh] = useState<Date | null>(null);

    // Add Conversaciones to the viewed features array
    useEffect(()=>{
        const rawFeatures = getCookie("viewedFeatures");
        const features = rawFeatures ? JSON.parse(rawFeatures) as string[] : [];

        if (!features.includes("Conversaciones")) {
            features.push("Conversaciones");
            setCookie("viewedFeatures", JSON.stringify(features), 180);
        }
    }, []);

    const {data:conversations, isLoading:loading, refetch} = useCustomQuery(["conversations", selectedClient], async()=>{
        const url = `chatinfo/${selectedClient}`;

        const result = await api.get<AnalyzedChat[]>(url);

        return result.filter((x)=>!!x.chat);
    }, {debounce:300, enabled:!!selectedClient && clientInfo?.permissions?.manager});

    useEffect(()=>{
        if (conversations && conversations.length > 0) {
            conversations.sort((a, b)=>new Date(b.lastRefresh).getTime() - new Date(a.lastRefresh).getTime());
            setLastRefresh(new Date(conversations[0].lastRefresh));
        }
    }, [conversations]);

    function canRefreshAgain(): boolean {
        // 1 hour cooldown
        if (!conversations || conversations?.length === 0 || !lastRefresh) return true;

        const now = new Date();

        const diff = now.getTime() - lastRefresh.getTime();

        return diff > 3600000;
    }

    async function refreshConversations(): Promise<void> {
        if (!selectedClient || !clientInfo?.permissions?.manager) return;

        if (!canRefreshAgain()) return;

        const url = `chatinfo/${selectedClient}/scan`;

        const res = await api.get<{message: "No changes detected" | "Scheduled succesfully"}>(url);

        if (res.message === "No changes detected") {
            toast.info("No se han detectado cambios en las conversaciones");
            return;
        }

        toast.success(`Conversaciones actualizadas a fecha ${  new Date().toLocaleString("es", {year:"numeric", month:"long", day:"numeric"})}`);

        setLastRefresh(new Date());

        // Refresh the conversations
        await refetch();
    }

    function downloadAnalysis(): void {
        // Saves the data from the mentors array in a csv file and then downloads it
        const headers = ["Nombre Embajador", "Campo principal", "Nombre Lead", "Preguntas destacadas", "Temáticas tratadas", "Fecha de la conversación"];

        const data = conversations?.map((conversation)=>{
            if (!conversation.chat) return null;

            return [`${conversation.chat.orientator?.name} ${conversation.chat.orientator?.surnames}`, conversation.chat.mainField, `${conversation.chat.orientated?.name} ${conversation.chat.orientated?.surnames}`, conversation.questions.join(","), conversation.tags.join(","), new Date(conversation.chat.startDate).toUTCString()];
        }).filter((x)=>!!x) || [];

        generateCsv(headers, data, "conversaciones.csv");
    }

    return (
        <PageContainer className="px-8 overflow-x-hidden h-max">
            <div className="flex flex-col gap-3 items-start w-full">
                <h1>Análisis de Conversaciones </h1>
                <p className="text-ug-lg">Analiza las conversaciones de tus prospectos para poder entender mejor tu proceso de captación y las necesidades de cada uno de los  interesados en tu institución. Para actualizar los datos de la página debes pulsar en “Cargar datos sobre las últimas interacciones”</p>
            </div>
            <div className="my-4 flex w-full items-start lg:items-center justify-between flex-col lg:flex-row gap-4">
                <div className="relative">
                    <UnigowButton className="flex items-center gap-2 py-2" variant="secondary" onClick={refreshConversations}
                        disabled={!canRefreshAgain()}
                    >
                        {canRefreshAgain() ? "Cargar datos sobre las últimas interacciones" : `Disponible a las ${lastRefresh ? new Date(lastRefresh.getTime() + 3600000).toLocaleTimeString("es") : ""}`}
                    </UnigowButton>
                </div>
                <div className="flex items-start lg:items-center gap-4 flex-col lg:flex-row">
                    <UnigowButton className="flex items-center gap-2 py-1" variant="primary" onClick={downloadAnalysis}>Exportar a CSV<Download/></UnigowButton>
                </div>
            </div>
            <div className="bg-white shadow-lg w-[calc(100vw-3rem)] lg:w-full rounded-[20px] flex flex-col lg:flex-row">
                {loading ? (
                    <div className="p-10">
                        <Spinner/>
                    </div>
                ) : (
                    <div className="w-full border-0 border-r border-solid border-gray-cool-300 h-max">
                        <UnigowTable headerTitles={["Nombre Embajador", "Campo principal", "Nombre Lead", ""]} elementCount={conversations?.length || 0}>
                            {conversations?.map((conversation)=>{
                                if (!conversation.chat) return null;

                                return (
                                    <TableRow key={conversation._id}>
                                        <TableCell>
                                            <p className="text-ug-base cursor-pointer">{conversation.chat.orientator?.name} {conversation.chat.orientator?.surnames}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p className="text-ug-base cursor-pointer">{conversation.chat.mainField}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p className="text-ug-base cursor-pointer">{conversation.chat.orientated?.name} {conversation.chat.orientated?.surnames}</p>
                                        </TableCell>
                                        <TableCell>
                                            <UnigowButton className="whitespace-nowrap" variant="primary" onClick={()=>{
                                                setSelectedConv(conversation);
                                            }} disabled={selectedConv?._id === conversation._id}
                                            >{selectedConv?._id === conversation._id ? "Seleccionado" : "Ver análisis"}
                                            </UnigowButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </UnigowTable>
                    </div>
                )}
                {selectedConv && (
                    <div className="shrink-0 w-[270px] relative border-0 border-l border-solid border-gray-cool-300">
                        <div className="sticky w-[inherit]">
                            <div className="flex gap-2 items-center border-0 border-b border-gray-cool-300 border-solid p-4 text-[0.875rem]">
                                <StarsIcon width={13} height={13}/>
                                <p className="font-semibold">Análisis de la conversación</p>
                            </div>
                            <div className="py-[22px] px-[26px] text-ug-sm flex flex-col gap-4">
                                <div className="flex flex-col gap-2">
                                    <p className="font-semibold">Preguntas destacadas</p>
                                    <ul className="flex flex-col gap-2">
                                        {selectedConv?.questions.map((question)=>(
                                            <li key={question}>{question}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <p className="font-semibold">Temáticas tratadas</p>
                                    <ul className="flex flex-wrap gap-2">
                                        {selectedConv?.tags.map((topic)=>(
                                        // If the tag is not a key in the enum, we don't show it
                                            Object.keys(ConversationTagColor).includes(topic.toLowerCase()) &&
                                            <ConversationTag tag={topic as keyof typeof ConversationTagColor} key={topic}/>
                                        ))}
                                    </ul>
                                </div>
                                <p>La conversación se inició el {new Date(selectedConv.chat?.startDate).toLocaleDateString("es")}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </PageContainer>
    );
}