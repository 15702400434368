import {Add, Warning} from "@mui/icons-material";
import {Tooltip} from "@mui/material";
import CardBottom from "@unigow/components/GroupCard/components/CardBottom";
import CardContainer from "@unigow/components/GroupCard/components/CardContainer";
import CardTop from "@unigow/components/GroupCard/components/CardTop";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {groupIsIncomplete, thereIsIncompleteGroup} from "@unigow/pages/Dashboard/pages/Iframes/tabs/Groupsplugin/helpers/groupHelper";
import EditGroup from "@unigow/pages/Dashboard/pages/Iframes/tabs/Groupsplugin/sections/CreateGroups/EditGroup";
import {useGroupWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/groupWizardStore";
import {GroupPage, NewGroup} from "@unigow/types/group";
import React, {Children, useEffect} from "react";
import {useIframeWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/iframeWizard.store";

function newGroupTemplate(fields: GroupPage["fields"]): NewGroup {
    return {
        name:"",
        description:"",
        iconUrl:"",
        fields:fields.map((field)=>{
            return {
                ...field,
                value:""
            };
        }),
        maxParticipants:100
    };
}

export default function Groups(): React.ReactElement {
    const {iframeData} = useIframeWizardStore();
    const {groupPageGroups, groupPageData, selectedGroup, setSelectedGroup, setConfigPage} = useGroupWizardStore();

    useEffect(()=>{
        if (!groupPageGroups || !groupPageData) return;

        if (groupPageGroups.length === 0) {
            setSelectedGroup(newGroupTemplate(groupPageData.fields));
        }

        return ()=>{
            // If we leave the page, deselect the group
            setSelectedGroup(undefined);
        };
    }, [groupPageGroups, groupPageData, setSelectedGroup]);

    if (!groupPageGroups) return (<div>Loading...</div>);

    return (
        <div className="w-full flex flex-col gap-4">
            <div className="">
                <h2>Crea tantos grupos como quieras</h2>
            </div>
            <div className="">
                {selectedGroup ? (
                    <EditGroup group={selectedGroup}/>
                ) : (
                    <ul className="flex flex-wrap gap-4">
                        {Children.toArray((groupPageGroups).map((group)=>(
                            <li className="w-full lg:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] relative">
                                {groupIsIncomplete(group) && (
                                    <div className="">
                                        <Tooltip title="Faltan datos por rellenar">
                                            <Warning color="warning" className="absolute top-2 right-2"/>
                                        </Tooltip>
                                    </div>
                                )}
                                <CardContainer>
                                    <CardTop color={iframeData?.style.mainColor} name={group.name}
                                        iconUrl={group.iconUrl} subtitle="Número de participantes"
                                    />

                                    <CardBottom description={group.description} fields={group.fields}/>

                                    <div className="px-4">
                                        <UnigowButton buttonType="button" ref={(node)=>{
                                            if (node) {
                                                node.style.setProperty("background-color", iframeData?.style.mainColor || "", "important");
                                            }
                                        }}
                                        onClick={()=>setSelectedGroup(group)}
                                        className={"w-full"} variant="primary"
                                        >Editar grupo
                                        </UnigowButton>
                                    </div>
                                </CardContainer>
                            </li>
                        ))
                        )}
                    </ul>
                )}
            </div>
            {groupPageData && !selectedGroup && (
                <div className="flex gap-4">
                    <UnigowButton variant="basic" className="flex items-center gap-2" onClick={()=>{
                        setSelectedGroup(newGroupTemplate(groupPageData.fields));
                    }}
                    >Crear otro grupo <Add className="w-3 h-3 border border-solid rounded-full"/>
                    </UnigowButton>
                    <UnigowButton disabled={thereIsIncompleteGroup(groupPageGroups)} variant="secondary" onClick={()=>{
                        setConfigPage("config");
                    }}
                    >Terminar la activación grupos
                    </UnigowButton>
                </div>
            )}
        </div>
    );
}