import {TwilioChat} from "@unigow/types/chat";
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

type ChatsStore = {
    chats: TwilioChat[];
    setChats: (chats: TwilioChat[])=> void;

    getChats: ()=> TwilioChat[];
    getUnreadChats: ()=> TwilioChat[];
    getGroupChats: ()=> TwilioChat[];
    getFavoriteChats: ()=> TwilioChat[];

    favoriteChats: TwilioChat["SID"][]
    isFavoriteChat: (chatId: TwilioChat["SID"])=> boolean;
    addFavoriteChat: (chatId: TwilioChat["SID"])=> void;
    removeFavoriteChat: (chatId: TwilioChat["SID"])=> void;
};

export const useChatsStore = create(
    persist<ChatsStore>(
        (set, get)=>({
            chats: [],
            setChats: (chats)=> set({chats}),

            getUnreadChats: ()=> get().getChats().filter((chat)=> chat.unread && chat.unread > 0),

            // Groups first
            getChats: ()=> get().chats.sort((a, b)=> {
                // First priority groups 1 chats 2. Second priority last message date
                if (a.type === "group" && b.type === "chat") return -1;

                if (a.type === "chat" && b.type === "group") return 1;

                if (!a.lastMessage?.added || !b.lastMessage?.added) return 0;

                return new Date(b.lastMessage.added).getTime() - new Date(a.lastMessage.added).getTime();

            }),
            getGroupChats: ()=> get().chats.filter((chat)=> {
                return  chat.type === "group";
            }),

            favoriteChats: [],
            getFavoriteChats: ()=> get().getChats().filter((chat)=> get().favoriteChats.includes(chat.SID)),
            isFavoriteChat: (chatId)=> get().favoriteChats.includes(chatId),
            addFavoriteChat: (chatId)=> set({favoriteChats: [...get().favoriteChats, chatId]}),
            removeFavoriteChat: (chatId)=> set({favoriteChats: get().favoriteChats.filter((id)=> id !== chatId)})
        }),
        {
            name:"chats-store",
            storage: createJSONStorage(()=>localStorage)
        }
    )
);