import React, {InputHTMLAttributes, useState, useRef, useEffect} from "react";

interface EditableTextProps extends InputHTMLAttributes<HTMLInputElement> {
    onEnter: (v: string)=> void,
    auxValue: string,
    setAuxValue: (v: React.SetStateAction<string>)=> void
}

function EditableText(props: EditableTextProps): React.ReactElement {
    const {onEnter, value, auxValue, setAuxValue, ...inputProps} = props;
    const inputRef = useRef<HTMLInputElement>(null);

    const [clicked, setClicked] = useState(false);

    useEffect(()=>{
        if (clicked && inputRef.current) {
            inputRef.current?.focus();
            inputRef.current.setSelectionRange(0, inputRef.current.value.length);
        }
    }, [clicked]);

    if (clicked) {
        return (
            <input size={auxValue.length} {...inputProps} type="text" onKeyDown={(e)=>{
                if (e.key === "Enter") {
                    onEnter(auxValue);
                    setClicked(false);
                }
            }}
            value={auxValue}
            onChange={(e)=>{
                setAuxValue(e.target.value);
            }}
            onBlur={()=>{
                onEnter(auxValue);
                setClicked(false);
            }}
            ref={inputRef}
            />
        );
    }

    return (
        <p onClick={()=>{
            setClicked(true);
        }}
        >{value}
        </p>
    );
}

export default EditableText;