import {LinearProgress} from "@mui/material";
import {useCardWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/cardsPlugin.store";
import MembersTable from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/components/MembersTable";
import SlotsConfig from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/steps/SlotsConfig";
import CardsStep1 from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/steps/Step1";
import CardsStep2 from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/steps/Step2";
import {CardWithParticipants} from "@unigow/types/card";
import {UserType} from "@unigow/unigow-types";
import React, {useState} from "react";

type CardPluginStep = "basic" | "userparams" | "slots";

export interface EditCardProps {
    setStep: (step: CardPluginStep)=> void;
}

export default function EditCard(): React.ReactElement {
    const {selectedCard} = useCardWizardStore();
    const [step, setStep] = useState<CardPluginStep>("basic");

    const thisCard = selectedCard as CardWithParticipants;

    function convertReservationParticipants(
        cardConfig: {
            _id: UserType["_id"];
            name: UserType["name"];
            email: UserType["email"];
            phone: UserType["phone"];
            timetable: {
                start: string;
                end: string;
            }
        }[]
    ): (Pick<UserType, "name" | "email" | "phone"> & {
            timetable?: {
                start: Date;
                end: Date;
            }
        })[] {
        return cardConfig.map((participant)=>{
            return {
                name: participant.name,
                email: participant.email,
                phone: participant.phone,
                timetable: {
                    start: new Date(participant.timetable.start),
                    end: new Date(participant.timetable.end)
                }
            };
        });
    }

    function renderMembersTable(): React.ReactElement {
        if (!thisCard || !("_id" in thisCard)) return <></>;

        if (thisCard.type === "reservations") {
            return <MembersTable participants={convertReservationParticipants(thisCard.reservationConfig.bookedSlots)} type="reservations"/>;
        }

        return <MembersTable participants={thisCard.participantsInfo} type="default"/>;
    }

    return (
        <div className="flex flex-col gap-4">
            <LinearProgress className="bg-[#E0EDFF] rounded-lg" variant="determinate" value={step === "basic" ? 0 : 50}/>
            {step === "basic" && (
                <CardsStep1 setStep={setStep}/>
            )}
            {step === "userparams" && (
                <CardsStep2 setStep={setStep}/>
            )}
            {step === "slots" && (
                <SlotsConfig setStep={setStep}/>
            )}
            {renderMembersTable()}
        </div>
    );
}