import {Delete} from "@mui/icons-material";
import {IconButton, MenuItem} from "@mui/material";
import {api} from "@unigow/apis/requests";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import UnigowSelect from "@unigow/components/UnigowSelect/UnigowSelect";
import {useIframeWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/iframeWizard.store";
import {StepProps} from "@unigow/pages/Dashboard/pages/Iframes/tabs/Groupsplugin/sections/GroupConfig/GroupConfig";
import {useGroupWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/groupWizardStore";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {GroupPage} from "@unigow/types/group";
import {GroupsPluginType} from "@unigow/unigow-types";
import React, {Children, useState} from "react";
import {useQueryClient} from "react-query";
import {toast} from "react-toastify";

type GroupField = GroupPage["fields"][0] & {
    id: number
};

export default function Step1({setStep}: StepProps): React.ReactElement {
    const {clientInfo} = useDashboardStore();
    const {iframeData} = useIframeWizardStore();
    const {groupPageData, selectGroupPage, setGroupPageData} = useGroupWizardStore();

    const [alias, setAlias] = useState(groupPageData?.alias || "");
    const [fields, setFields] = useState<GroupField[]>(groupPageData?.fields.map((v, i)=>{
        return {...v, id:i};
    }) || []);

    const queryClient = useQueryClient();

    async function saveChanges(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        if (!clientInfo) return;

        e.preventDefault();

        if (alias === "") {
            toast.error("Debes seleccionar un nombre para el conjunto de grupos");
            return;
        }

        const group: Pick<GroupPage, "alias" | "fields" | "client"> = {
            client:clientInfo._id.toString(),
            alias,
            fields
        };

        let response: GroupsPluginType | undefined = undefined;

        if (groupPageData) {
            response = await api.patch<typeof group, GroupsPluginType>(`grouppages/${groupPageData._id}`, group);
        } else {
            response = await api.post<typeof group, GroupsPluginType>(`iframes/${iframeData?._id}/plugins/groups`, group);
        }

        if (response) {
            if (!groupPageData) {
                // Es un grupo nuevo, seleccionarlo
                selectGroupPage(response._id.toString());
            }

            setGroupPageData(response);
            // Invalidate data
            await queryClient.invalidateQueries(["groups", response._id]);
            setStep(2);
        }

    }

    return (
        <form className="flex flex-col gap-[15px] overflow-hidden" onSubmit={saveChanges}>
            <p className="font-light text-ug-base">Toma decisiones sobre cómo se verán tus grupos y mira en la vista previa </p>
            <div className="flex flex-col gap-2">
                <p className="font-semibold">Nombra a este conjunto de grupos</p>
                <p className="font-light text-ug-sm">Escribe un nombre que te permita identificarlo</p>
                <UnigowInput required value={alias} onChange={(e)=>setAlias(e.target.value)} placeholder="Por ejemplo: &quot;Grupo de grado&quot;"/>
            </div>
            <div className="flex flex-col gap-2">
                <p className="font-semibold">¿Qué campos quieres incluir en las tarjetas de los grupos?</p>
                <p className="font-light text-ug-sm">Además de la foto, los campos obligatorios para crear un grupo son el nombre y la descripción. Si quieres añadir más campos, haz click en el botón “Añadir campo”</p>
                <ul className="flex flex-col gap-3">
                    <li className="text-ug-sm font-semibold">Nombre</li>
                    <li className="text-ug-sm font-semibold">Descripción</li>
                    {Children.toArray(fields.map((field, i)=>(
                        <li className="flex items-end">
                            <div className="flex gap-2 items-start lg:items-center flex-col lg:flex-row w-full">
                                <div className="flex flex-col gap-2 w-full">
                                    <p className="font-semibold line-clamp-1">Nombre del campo</p>
                                    <UnigowInput required value={field.name} onChange={(e)=>{
                                        setFields([...fields.slice(0, i), {...field, name:e.target.value}, ...fields.slice(i + 1)]);
                                    }}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <p className="font-semibold line-clamp-1">Tipo de campo</p>
                                    <UnigowSelect required value={field.type} onChange={(e)=>{
                                        setFields([...fields.slice(0, i), {...field, type:e.target.value as GroupField["type"]}, ...fields.slice(i + 1)]);
                                    }}
                                    >
                                        <MenuItem value="shorttext">Etiqueta (poco texto)</MenuItem>
                                        <MenuItem value="longtext">Campo con título (texto largo)</MenuItem>
                                    </UnigowSelect>
                                </div>
                            </div>
                            <IconButton className="flex-shrink-0 text-red-700" onClick={()=>{
                                setFields([...fields.slice(0, i), ...fields.slice(i + 1)]);
                            }}
                            >
                                <Delete/>
                            </IconButton>
                        </li>
                    )))}
                    <li>
                        <UnigowButton variant="primary" type="button" onClick={()=>{
                            setFields([...fields, {
                                id:fields.length + 1,
                                name:"",
                                type:"shorttext"
                            }]);
                        }}
                        >Añadir campo
                        </UnigowButton>
                    </li>
                </ul>
            </div>
            <div className="flex gap-[10px] flex-col lg:flex-row">
                <UnigowButton type="button" variant="basic" onClick={()=>setStep(0)}>Cancelar</UnigowButton>
                <UnigowButton variant="secondary" type="submit">Continuar al siguiente paso</UnigowButton>
            </div>
        </form>
    );
}