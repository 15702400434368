import {useChatsPluginStore} from "../../stores/chatsPlugin.store";
import React, {useEffect} from "react";
import {useQuery} from "react-query";
import {useIframeWizardStore} from "../../stores/iframeWizard.store";
import {ChatsPluginAPI} from "@unigow/apis/iframe";
import PageContainer from "@unigow/components/PageContainer/PageContainer";
import {Tab, Tabs} from "@mui/material";
import ChatPluginConfig from "./sections/ChatPluginConfig/ChatPluginConfig";
import PopupConfig from "@unigow/pages/Dashboard/pages/Iframes/tabs/Chatsplugin/sections/PopupConfig/PopupConfig";

export default function ChatsPluginWizard(): React.ReactElement {
    const {iframeData} = useIframeWizardStore();
    const {setPluginData, configPage, setConfigPage, selectedPlugin, setStep, pluginData} = useChatsPluginStore();


    const {refetch} = useQuery(["pluginchats", iframeData?._id], async()=>{
        return ChatsPluginAPI.getIframeChatsPlugin({iframeId:iframeData!._id.toString()});
    }, {
        enabled: !!iframeData,
        onSuccess:(res)=>{
            if (res.length === 0) return;

            const [firstPlugin] = res;

            setPluginData(firstPlugin);
        }
    });

    useEffect(()=>{
        async function waitForPlugin(): Promise<void> {
            await refetch();

            setStep(2);
        }

        if (selectedPlugin) {
            void waitForPlugin();
        }
    }, [selectedPlugin, refetch, setStep]);

    return (
        <div className="flex flex-col gap-4">
            <PageContainer className="gap-[20px] items-start select-none w-fill p-0 lg:p-0">
                <div className="bg-white w-[calc(95%-2rem)] rounded-r-xl lg:px-8 px-4 flex gap-4 flex-col">
                    <Tabs value={configPage} onChange={(_, v)=>setConfigPage(v)}>
                        <Tab value="config" label="Configuración"/>
                        <Tab value="popups" label="Popups" disabled={!pluginData}/>
                    </Tabs>
                    <div className=" flex gap-8 flex-col lg:flex-row">
                        {configPage === "config" && (
                            <ChatPluginConfig/>
                        )}
                        {configPage === "popups" && (
                            <PopupConfig/>
                        )}
                    </div>
                </div>
            </PageContainer>
        </div>
    );
}