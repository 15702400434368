import React, {useState, useEffect} from "react";
import PageContainer from "@unigow/components/PageContainer/PageContainer";
import ChatsList from "../../../../components/ChatsList/ChatsList";
import {useNavigate, useSearchParams} from "react-router-dom";
import ChatRoom from "../../../../components/ChatRoom/ChatRoom";
import {Close, Info} from "@mui/icons-material";
import {useAuthStore} from "../../../../stores/authStore";
import {useQuery} from "react-query";
import {useTwilio} from "../../../../contexts/TwilioContext";
import {TwilioChat} from "../../../../types/chat";

import * as Sentry from "@sentry/react";
import {api} from "../../../../apis/requests";
import {IconButton, Tab, Tabs} from "@mui/material";
import {useDashboardStore} from "../../../../stores/dashboard/dashboardStore";
import {MentorAnswers} from "./components/MentorAnswers";
import {useChatsStore} from "@unigow/pages/Dashboard/pages/ChatsPage/stores/chatsStore";
import GroupRoom from "@unigow/components/ChatRoom/GroupRoom";

function ChatsPage(): React.ReactElement {
    const {userData} = useAuthStore();
    const {clientInfo} = useDashboardStore();
    const {setChats, getChats, getUnreadChats, getFavoriteChats, getGroupChats} = useChatsStore();
    const [searchParams] = useSearchParams();
    const [chatId, setChatId] = useState("");
    const [groupId, setGroupId] = useState("");
    const [chatType, setChatType] = useState<"all" | "unread" | "favorites" | "groups">("all");
    const [showRetos, setShowRetos] = useState(clientInfo?.questionary || false);
    const {twilioClient} = useTwilio();

    const {isLoading, isSuccess} = useQuery(["chats", userData, twilioClient?.connectionState], async()=>{
        const chatsRes = (await api.get<TwilioChat[]>("chats")).map((chat)=>{
            return {...chat, type:"chat"};
        });

        const groupsRes = (await api.get<TwilioChat[]>("groups?ambassador=true")).map((group)=>{
            return {...group, type:"group"};
        });

        const res = [...chatsRes, ...groupsRes] as TwilioChat[];

        await Promise.all(res.map(async(chat)=>{
            if (chat.type === "group" && !chat.accepted) return chat;

            // Set a timeout in case the conversation is not found
            try {
                const conversation = await twilioClient!.getConversationBySid(chat.SID);
                const allMessages = await conversation.getMessagesCount();
                const lastReadMessage = conversation.lastReadMessageIndex;
                const unread = await conversation.getUnreadMessagesCount();

                // Si existe lastReadMessage se puede acceder a los mensajes no leidos, imprimirlos, si no existe
                // simplemente se imprime el total de mensajes
                chat.unread = lastReadMessage !== null ? (unread || 0) : allMessages ;
                const lastMessage = await conversation?.getMessages(1);

                if (lastMessage.items.length > 0) {
                    chat.lastMessage = {
                        body:lastMessage?.items[0].body || "",
                        added:lastMessage.items[0].dateCreated
                    };
                }

                return chat;
            } catch (err) {
                console.log(err);
                Sentry.captureException(err);
                // Delete chat from array
                res.splice(res.indexOf(chat), 1);
            }
        }));

        return res.sort((a, b)=>{
            if (!a.lastMessage?.added || !b.lastMessage?.added) return 0;
            return b.lastMessage.added?.getTime() - a.lastMessage.added?.getTime();
        });
    }, {
        enabled:!!userData && !!twilioClient,
        refetchOnWindowFocus:true,
        refetchInterval:5 * 60 * 1000,
        onSuccess:(data)=>{
            setChats(data);
        }
    });

    useEffect(()=>{
        const currentChat = searchParams.get("chat");
        const currentGroup = searchParams.get("group");
        if (currentChat) {
            setChatId(currentChat);
            return;
        } else if (currentGroup) {
            setGroupId(currentGroup);
            return;
        }
        setChatId("");
        setGroupId("");
    }, [searchParams]);

    const navigate = useNavigate();

    // useEffect(()=>{
    //     if (!userData) return;

    //     const cookie = getCookie("whatsappwarn");

    //     if (cookie) return;

    //     if (!userData.phone || userData.phone === "") {
    //         toast.success(
    //             <div className="flex flex-col gap-2">
    //                 <p>¡Índicanos tu número de teléfono para poder enviarte notificaciones por whatsapp!</p>
    //                 <div className="flex flex-col gap-1">
    //                     <UnigowButton variant="secondary" onClick={()=>navigate("/account#phone")}>Ir a mi perfil</UnigowButton>
    //                 </div>
    //             </div>, {icon:<WhatsApp className="text-green-500"/>}
    //         );
    //         setCookie("whatsappwarn", false, 365);
    //         return;
    //     }
    // }, [userData, navigate]);

    function parseChats(): TwilioChat[] {
        switch (chatType) {
            case "unread":
                return getUnreadChats();
            case "favorites":
                return getFavoriteChats();
            case "groups":
                return getGroupChats();
            default:
                return getChats();
        }
    }

    return (
        <PageContainer className="gap-8 px-4 items-start">
            <div className="flex flex-col gap-3 items-start w-full">
                <h1>Contesta a las solicitudes de los usuarios</h1>
                <p className="text-ug-lg">¡Responde las dudas de los usuarios en base a tu experiencia real!</p>
            </div>
            <div className="flex flex-col gap-4 w-full">
                {showRetos && (
                    <div className="flex items-center justify-between bg-white rounded-sm p-2">
                        <div className="flex items-center gap-2">
                            <Info className="text-primary-500"/>
                            <p className="font-medium text-sm">Queremos conocerte un poco más. Accede a nuestra nueva sección de “Mi opinión”</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <a className="text-sm text-secondary-blue-400 font-semibold no-underline" href="/retos">IR A MI OPINIÓN</a>
                            <IconButton size="small" onClick={()=>{
                                setShowRetos(false);
                            }}
                            ><Close/>
                            </IconButton>
                        </div>
                    </div>
                )}
                <div className="flex gap-4 xl:flex-row flex-col">
                    <div className="w-full xl:w-3/4 shrink-0 pb-8">
                        {chatId && chatId !== "" ? (
                            <ChatRoom sid={chatId} goBack={()=>navigate("/chats")} dashboard/>
                        ) : groupId && groupId !== "" ? (
                            <GroupRoom sid={groupId} goBack={()=>navigate("/chats")} dashboard/>
                        ) : (
                            <div className="">
                                <Tabs value={chatType} onChange={(e, v)=>setChatType(v)} className="bg-white w-fit rounded-t-sm">
                                    <Tab label="Chats" value={"all"}/>
                                    <Tab label="No leídos" value={"unread"}/>
                                    <Tab label="Favoritos" value={"favorites"}/>
                                    <Tab label="Grupos" value={"groups"}/>
                                </Tabs>
                                <ChatsList dashboard type="twilio" goToChatPage={(sid: string, type: "chat" | "group")=>{
                                    return navigate(`/chats?${type}=${sid}`);
                                }} chats={parseChats()}
                                loading={isLoading || !isSuccess}
                                />
                            </div>
                        )}
                    </div>
                    {chatId && chatId !== "" && (
                        <MentorAnswers/>
                    )}
                </div>
            </div>
        </PageContainer>
    );
}

export default ChatsPage;