/* eslint-disable max-len */
import React from "react";
import {Helmet} from "react-helmet";

export default function Privacy(): React.ReactElement {
    return (
        <div>
            <Helmet>
                <title>Unigow - Política de privacidad</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div className="politica-privacidad p-10 md:p-16 lg:p-24 lg:pt-10 text-left">
                <h1 className=" text-4xl md:text-5xl font-bold text-primary">Política de privacidad</h1>
                <br></br>
                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2">
                    Agradecemos que esté en esta página, ya que significa que sus datos le importan, y quiere conocer tanto el destino de los mismos como quién los recoge.
                </h2>
                <p className="font-medium">
                    De conformidad con lo dispuesto en el Reglamento General (UE) Sobre Protección de Datos, los RESPONSABLES obrarán en todo momento siguiendo los principios de TRANSPARENCIA E INFORMACIÓN. Así, procederemos en adelante a facilitarle la información básica, como es la identidad del responsable del tratamiento, las finalidades del mismo y la posibilidad del interesado de ejercer sus derechos. Todo ello sin perjuicio de facilitarle una dirección de correo electrónico mediante la que podrá acceder a la restante información que desee: unigowbusiness@gmail.com.
                </p>

                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2">
                    Identidad del Responsable
                </h2>
                <p className="font-medium">
                    El responsable del tratamiento de los datos recabados por medio de este Sitio Web es:
                </p>
                <ul className="list-disc ml-12 md:ml-16 font-medium pt-1 pb-1">
                    <li> UNIGOW ENTERPRISE, S.L. </li>
                    <li> Domicilio: Calle Pensamiento nº 11 Leganés (28912), Madrid.</li>
                    <li> Correo electrónico: unigowbusiness@gmail.com</li>
                    <li> Teléfono: +34672421673</li>
                </ul>
                <p className="font-medium">
                    De ahora en adelante nos referiremos a usted, el Usuario, como el “INTERESADO”, y al Responsable del tratamiento, UNIGOW ENTERPRISE, S.L., como el “RESPONSABLE“ o “UNIGOW“.
                </p>
                <p className="font-medium">
                    Mediante la aceptación de la presente Política de Privacidad presta su consentimiento informado, expreso, libre e inequívoco para que los datos personales que proporcione a través de la página web https://unigow.com/ (en adelante SITIO WEB) sean tratados por el RESPONSABLE, con las finalidades mencionadas a continuación, y en base a los principios, derechos y obligaciones legalmente establecidos.
                </p>


                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2"> ¿Qué son los datos personales? </h2>
                <p className="font-medium"> Una pequeña aproximación es importante. Por ello, debe saber que, según el RGPD, los “datos personales” son toda información sobre una persona física identificada o identificable.  </p>
                <p className="font-medium"> En nuestro caso, la información personal que nos facilitan todos los usuarios cuando se registran en nuestro sitio web es la siguiente:</p>
                <ul className="list-disc ml-12 md:ml-16 font-medium pt-1 pb-1">
                    <li>	Nombre y apellidos </li>
                    <li> 	Correo electrónico</li>
                    <li> 	Foto de perfil (Opcional)</li>
                </ul>
                <p className="font-medium"> En el caso de usuarios que solicitan hablar con embajadores , la institución también puede solicitar información adicional, como número de teléfono, provincia natal, código postal y detalles de su interés en la institución. Para aquellos individuos que actúan como embajadores o representantes de una institución, se les pedirá que proporcionen información para que se pueda crear un perfil en la plataforma. Por ejemplo, esto puede incluir información como su función en la institución, año de estudio, sociedades e intereses, tipo de grado, si es un estudiante internacional o nacional, un mensaje personal y una foto de perfil. También podemos recopilar cualquier dato personal que envíe en la funcionalidad de chat. </p>
                <br/>
                <p className="font-medium"> La presente Política de Privacidad será válida únicamente para los datos de carácter personal obtenidos en el Sitio Web, no siendo aplicable para aquella información recabada por terceros en otras páginas web, incluso si éstas se encuentran enlazadas por el unigow.com. </p>
                <p className="font-medium"> Los datos recabados en el SITIO WEB son meramente identificativos, y su obtención tiene como fines exclusivos los mencionados en el presente documento. En ningún caso se recabarán datos de categoría especial, con la finalidad de identificar su ideología, afiliación sindical, religión, orientación sexual, creencias u origen racial o étnico. </p>
                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2">Principios </h2>
                <p className="font-medium">
                    De conformidad con el Reglamento General (UE) de Protección de Datos (RGPD) y la Ley Orgánica 3/2018, de
                    Protección de Datos Personales y Garantía de los Derechos Digitales, basaremos el tratamiento de sus datos en
                    los siguientes principios:{" "}
                </p>
                <h3 className="font-medium underline my-2">Principio de licitud, lealtad y transparencia</h3>
                <p className="font-medium">
                    Siempre vamos a requerir su consentimiento para el tratamiento de sus datos personales con uno o varios fines
                    específicos, de los cuales le informaremos previamente con absoluta transparencia. Dichos fines serán
                    determinados, explícitos y legítimos, y sus datos no serán tratados ulteriormente de manera incompatible con
                    dichos fines.{" "}
                </p>
                <h3 className="font-medium underline my-2">Principio de minimización de datos </h3>
                <p className="font-medium">
                    Solo vamos a solicitar los datos adecuados, pertinentes y estrictamente necesarios en relación con los fines
                    para los que los requerimos.
                </p>
                <p className="font-medium"> </p>
                <p className="font-medium"> </p> <h3 className="font-medium underline my-2">Principio de exactitud </h3>
                <p className="font-medium">
                    Recabaremos datos exactos, adoptando todas las medidas razonables para que se supriman o rectifiquen sin
                    dilación, la inexactitud de los datos personales, con respecto a los fines para los que se tratan, cuando los
                    datos sean inexactos. Recordar que la inexactitud de los datos, cuando sean obtenidos directamente del
                    INTERESADO, no será imputable al RESPONSABLE.{" "}
                </p>
                <p className="font-medium">
                    Como usuario, debe garantizar la exactitud y autenticidad de los datos personales facilitados, debiendo
                    aportar la información completa y correcta en los distintos formularios de captación de datos.{" "}
                </p>
                <p className="font-medium"> </p>{" "}
                <h3 className="font-medium underline my-2"> Principio de limitación del plazo de conservación</h3>
                <p className="font-medium">
                    Los datos serán mantenidos durante no más tiempo del necesario para los fines del tratamiento. En función a la
                    finalidad concreta, le informaremos del plazo de conservación correspondiente. En el caso de suscripciones,
                    revisaremos periódicamente listas y eliminaremos aquellos registros inactivos durante un tiempo considerable.
                    Dichos plazos estarán siempre condicionados a su decisión de ejercer sus derechos, como por ejemplo el de
                    supresión, mencionados posteriormente.{" "}
                </p>
                <p className="font-medium"> </p>
                <p className="font-medium"> </p>{" "}
                <h3 className="font-medium underline my-2">Principio de integridad y confidencialidad </h3>
                <p className="font-medium">
                    Sus datos serán tratados de tal manera que se garantice una seguridad adecuada de los datos personales y se
                    garantice su confidencialidad. Debe saber que tomamos todas las medidas encaminadas a evitar el acceso no
                    autorizado o uso indebido de los datos de nuestros usuarios por parte de terceros.{" "}
                </p>
                <p className="font-medium">
                    Sus datos estarán igualmente protegidos aún cuando haya finalizado su relación con nosotros y el SITIO WEB,
                    sin perjuicio de la eliminación de los mismos una vez pasado un periodo determinado de inactividad.{" "}
                </p>

                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2">Finalidad </h2>

                <p className="font-medium">
                    Tal y como se recoge en la normativa, se informa al USUARIO que, a través de los formularios de contacto o suscripciones se recaban datos, los cuales se almacenan en un fichero, con la siguiente finalidad:{" "}
                </p>
                <p className="font-medium">Asimismo, los datos se recaban con las siguientes finalidades: </p>
                <ul className="list-disc ml-12 md:ml-16 font-medium pt-1 pb-1">
                    <li> 	Creación del perfil del usuario en la plataforma.</li>
                    <li> 	Gestión del perfil.</li>
                    <li>    Suministro de contenidos en el blog.</li>
                    <li> 	Envío de notificaciones por interacciones con otros usuarios.</li>
                    <li> 	Cesión de los mismos a la empresa a los que pertenecen los embajadores.</li>
                    <li> 	Organización de eventos online u offline. </li>
                    <li> 	Analizar su actividad en nuestro servicio para mejorar Unigow. </li>
                    <li> 	Responder a sus consultas relacionadas con Unigow . </li>
                    <li> 	Podemos, en ocasiones y en beneficio propio, anonimizar y agregar sus datos. </li>
                </ul>
                <p className="font-medium">
                    Asimismo, en caso de que el INTERESADO preste su consentimiento, los datos se conservarán con las siguientes finalidades: envío de comunicaciones electrónicas, tales como: boletines (newsletters), nuevas entradas (posts), ofertas comerciales, así como otras comunicaciones que el RESPONSABLE entiende interesantes para sus USUARIOS.
                </p>
                <p className="font-medium">
                    Le recordamos que puede oponerse al envío de comunicaciones comerciales por cualquier vía y en cualquier
                    momento, remitiendo un correo electrónico a la siguiente dirección: unigowbusiness@gmail.com{" "}
                </p>
                <p className="font-medium">
                    Aun así, los campos marcados como de cumplimentación obligatoria son imprescindibles para alcanzar los fines
                    expresados.{" "}
                </p>
                <p className="font-medium">
                    Con ello manifestamos nuestro compromiso por mantener y garantizar las relaciones comerciales de forma segura
                    mediante la protección de los datos personales y garantizando el derecho a la privacidad de cada uno de los
                    usuarios de nuestro sitio web.{" "}
                </p>
                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2">Legitimación </h2>
                <p className="font-medium">
                    El RESPONSABLE está legitimado para tratar los datos del INTERESADO desde que este presta su consentimiento
                    expreso e inequívoco.{" "}
                </p>
                <p className="font-medium">
                    De conformidad con lo dispuesto en el artículo 4.11 del Reglamento (UE) 2016/679, se entiende por
                    consentimiento del INTERESADO toda manifestación de voluntad libre, específica, informada e inequívoca por la
                    que este acepta el tratamiento de datos personales que le conciernen.{" "}
                </p>
                <p className="font-medium">
                    La aceptación de la política de privacidad se entenderá a todos los efectos como la prestación de
                    CONSENTIMIENTO EXPRESO E INEQUÍVOCO del USUARIO al tratamiento de los datos de carácter personal en los
                    términos que se exponen en el presente documento, así como a la transferencia internacional de datos que se
                    produce exclusivamente debido a la ubicación física de las instalaciones de los proveedores de servicios y
                    encargados del tratamiento de datos. En ningún caso se realizará un uso diferente que la finalidad para los
                    que han sido recabados los datos.{" "}
                </p>
                <p className="font-medium">
                    El consentimiento es un proceso revocable. Por lo tanto, podrá ejercer sus derechos de acceso, rectificación,
                    limitación y suprimir los datos en unigowbusiness@gmail.com. En caso de revocación del consentimiento prestado
                    en un principio por parte del INTERESADO, ello no afectará a la licitud del tratamiento basado en el
                    consentimiento previo a su retirada.{" "}
                </p>
                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2">Menores de edad </h2>
                <p className="font-medium">
                    {" "}
                    En el supuesto de ser mayor de catorce años, podrá registrarse en el SITIO WEB sin necesidad del
                    consentimiento previo de sus padres o tutores. Bastará con su consentimiento para que nosotros podamos tratar
                    sus datos.{" "}
                </p>
                <p className="font-medium">¿Qué ocurre en el caso de que seas menor de 14 años? </p>
                <p className="font-medium">
                    En este supuesto, será condición obligatoria el consentimiento de sus padres o tutores legales para que
                    podamos tratar sus datos personales. El alcance del tratamiento estará condicionado al consentimiento prestado
                    por los titulares de la patria potestad o tutela.{" "}
                </p>
                <p className="font-medium">
                    {" "}
                    Advertencia: Si tiene menos de catorce años y no ha obtenido el consentimiento de sus padres, no puede
                    registrarse en el SITIO WEB por lo que procederemos a denegar su solicitud en caso de tener constancia de
                    ello.
                </p>
                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2">
                    Cumplimiento de la normativa de aplicación{" "}
                </h2>
                <p className="font-medium">
                    El RESPONSABLE cumple con la normativa vigente, y en concreto con el Reglamento General sobre Protección de Datos (RGPD) de la Unión Europea, y Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.
                </p>
                <p className="font-medium">
                    Asimismo, el RESPONSABLE informa que da cumplimiento a la Ley 34/2002 de 11 de julio, de Servicios de la
                    Sociedad de la Información y el Comercio Electrónico y le solicitará su consentimiento al INTERESADO para el
                    tratamiento de su correo electrónico con fines comerciales en cada momento.{" "}
                </p>
                <p className="font-medium">
                    En cumplimiento de lo establecido en la normativa, le informamos que los datos suministrados, así como
                    aquellos datos derivados de su navegación, podrán ser almacenados en los ficheros del RESPONSABLE y tratados
                    para la finalidad de atender su solicitud y el mantenimiento de la relación que se establezca en los
                    formularios que suscriba.{" "}
                </p>

                <p className="font-medium">
                    En caso de no autorizar el tratamiento de sus datos con la finalidad señalada anteriormente, el INTERESADO podrá ejercer su derecho de oposición al tratamiento de sus datos en los términos y condiciones previstos más adelante en el apartado “Ejercicio de Derechos”.
                </p>
                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2">Ejercicio de derechos </h2>
                <p className="font-medium">
                    Aquellas personas físicas que hayan facilitado sus datos a través de https://unigow.com/, podrán dirigirse al
                    RESPONSABLE con el fin de ejercitar gratuitamente sus derechos de acceso a sus datos, rectificación o
                    supresión, limitación y oposición respecto de los datos incorporados en sus ficheros.{" "}
                </p>
                <p className="font-medium">
                    {" "}
                    Así, los INTERESADOS podrán acceder a sus datos personales, así como a solicitar la rectificación de los datos
                    inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios
                    para los fines que fueron recogidos. En determinadas circunstancias, los interesados podrán solicitar la
                    limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la
                    defensa de reclamaciones.
                </p>
                <p className="font-medium">
                    En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán
                    oponerse al tratamiento de sus datos. El RESPONSABLE dejará de tratar los datos, salvo por motivos legítimos
                    imperiosos, o el ejercicio o la defensa de posibles reclamaciones.{" "}
                </p>
                <p className="font-medium">Menores de 14 años. </p>
                <p className="font-medium">
                    En cualquier caso, los titulares de la patria potestad podrán ejercitar en nombre y representación de los
                    menores de catorce años los derechos de acceso, rectificación, cancelación, oposición o cualesquiera otros que
                    pudieran corresponderles.{" "}
                </p>
                <p className="font-medium">
                    Cualquier detalle referente al ejercicio de alguno de estos derechos en concreto que no esté explicado en este
                    documento será comunicado al INTERESADO en el momento en el que contacte con nosotros con el objetivo de
                    ejercitar alguno de ellos.{" "}
                </p>
                <p className="font-medium">
                    El método más rápido y sencillo para ejercitar sus derechos sería enviarnos un correo electrónico a
                    unigowbusiness@gmail.com con la referencia “Protección de datos”, especificando los datos que desea obtener,
                    acreditando su identidad y los motivos de su solicitud. Cualquier información que necesite almacenar, en
                    virtud de una obligación legal o contractual, será bloqueada y sólo utilizada para dichos fines en lugar de
                    ser borrada.{" "}
                </p>
                <p className="font-medium">
                    Asimismo, el INTERESADO podrá ejercitar sus derechos mediante comunicación por escrito dirigida al RESPONSABLE
                    con la referencia “Protección de datos”, especificando los datos que desea, acreditando su identidad y los
                    motivos de su solicitud en la siguiente dirección:{" "}
                </p>
                <p className="font-medium">Calle Delicias, 21, 4ªA, 28045, Madrid.  </p>
                <p className="font-medium"> </p>
                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2"> Medidas de seguridad </h2>

                <p className="font-medium"> El RESPONSABLE le informa que tiene implantadas las medidas de seguridad de índole técnica y organizativas necesarias para garantizar la seguridad de sus datos de carácter personal y evitar su alteración, pérdida y tratamiento y/o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos, ya provengan de la acción humana o del medio físico o natural. Todo ello de conformidad con lo previsto en el RGPD. </p>
                <p className="font-medium"> Asimismo, el RESPONSABLE ha establecido medidas adicionales en orden a reforzar la confidencialidad e integridad de la información en su organización, manteniendo continuamente la supervisión, control y evaluación de los procesos para asegurar el respeto a la privacidad de los datos. </p>
                <p className="font-medium"> Igualmente, tal y como puede comprobar, el SITIO WEB dispone de certificado SSL por lo que se garantiza la seguridad de sus datos. </p>
                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2"> Links o enlaces externos</h2>
                <p className="font-medium"> Como un servicio a nuestros visitantes, en alguna ocasión podremos incluir hipervínculos a otros sitios que no son operados o controlados por el SITIO WEB. Por ello, no garantizamos, ni nos hacemos responsables de la licitud, fiabilidad, utilidad, veracidad y actualidad de los contenidos de tales sitios web o de sus prácticas de privacidad. Por favor, antes de proporcionar su información personal a estos sitios web ajenos, tenga en cuenta que sus políticas de privacidad pueden diferir de las nuestras. </p>
                <p className="font-medium"> El único objetivo de los enlaces es proporcionar al usuario la posibilidad de acceder a otros contenidos que consideramos que pueden ser de su interés, así como contratar servicios y productos, ofrecidos por terceras empresas, beneficiándose de descuentos por ser usuario de unigow.com.  </p>
                <p className="font-medium"> No obstante, recalcar que este UNIGOW no ofrece ni comercializa por sí ni por medio de terceros la información, contenidos y servicios disponibles en los sitios enlazados, ni aprueba, supervisa o controla en modo alguno los contenidos y servicios y cualquier material de cualquier naturaleza existente en los mismos. UNIGOW no se responsabiliza en ningún caso de las consecuencias que puedan derivarse del acceso por parte del INTERESADO a dichos enlaces externos.  </p>
                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2"> Modificación de la política de privacidad </h2>
                <p className="font-medium"> El RESPONSABLE se reserva el derecho a modificar su Política de Privacidad, de acuerdo a su propio criterio, motivado por un cambio legislativo, jurisprudencial o doctrinal de la Agencia Española de Protección de Datos. </p>
                <p className="font-medium"> Cualquier modificación de la Política de Privacidad será notificada a los INTERESADOS al menos diez días antes de su efectiva aplicación. El uso del SITIO WEB después de dichos cambios, implicará la aceptación de los mismos. </p>

                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2"> Cesión a terceros </h2>
                <p className="font-medium"> El RESPONSABLE solo cederá datos personales del INTERESADO a terceros cuando este preste su consentimiento expreso. En caso de que se vaya a producir una cesión, UNIGOW informará de los datos que se vayan a ceder y de las finalidades de tratamiento, con el fin de que el INTERESADO preste su consentimiento informado.  </p>
                <p className="font-medium"> Una vez el INTERESADO consienta la cesión, UNIGOW no será responsable del tratamiento que realicen los terceros cesionarios, ya que estos actúan como responsables del tratamiento, determinando los fines y los medios. Ello sin perjuicio del compromiso de dichos terceros a cumplir con la normativa en materia de Protección de Datos.  </p>
                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2">Intervención de terceros en el tratamiento </h2>
                <p className="font-medium"> El responsable del fichero de datos es UNIGOW ENTERPRISE, S.L. No obstante, a fin de mejorar nuestros servicios y optimizar la gestión y tratamiento de los datos de los INTERESADOS, existen encargados del tratamiento ajenos al citado responsable. </p>
                <p className="font-medium"> El RESPONSABLE ha contratado los servicios de Hosting a 10dencehispahard SL., (Identificado con la marca comercial Cdmon), con domicilio social en Calle Girona, 81 – 83 LOCAL 6, Malgrat de mar, 08380, Barcelona. Con C.I.F.: B-65739856. Se puede consultar la política de privacidad y demás aspectos legales de dicha compañía en el siguiente enlace: https://www.cdmon.com/es/contratos/aviso-legal-y-politica-de-privacidad-de-datos-20180525 </p>
                <p className="font-medium"> Adicionalmente, se han encargado los servicios de suscripción por correo electrónico y envío de newsletters a la compañía “Sendinblue” (Identificada por la marca comercial “Brevo”), pudiéndose consultar su política de privacidad en: https://www.brevo.com/legal/privacypolicy/</p>
                <p className="font-medium"> Para prestar servicios estrictamente necesarios para el desarrollo de nuestra actividad, https://unigow.com/ utiliza los siguientes prestadores bajo sus correspondientes condiciones de privacidad: </p>
                <ul className="list-disc ml-12 md:ml-16 font-medium pt-1 pb-1">
                    <li> -	Hosting: Cdmon</li>
                    <li> -	Plataforma web: Plataforma MERN</li>
                    <li> -	Servicios de Mensajería y envío de boletines: Brevo</li>
                    <li>-	Privacidad de fuentes de rastreo utilizadas en este sitio: Google (Analytics)</li>
                </ul>
                <p className="font-medium"> En el SITIO WEB también se estudian las preferencias de sus usuarios, sus características demográficas, sus patrones de tráfico, y otra información en conjunto para comprender mejor quiénes constituyen nuestra audiencia y qué es lo que necesita. El rastreo de las preferencias de nuestros usuarios también nos ayuda a mostrarle los avisos publicitarios más relevantes. </p>
                <p className="font-medium"> El usuario y, en general, cualquier persona física o jurídica, podrá establecer un hiperenlace o dispositivo técnico de enlace (por ejemplo, links o botones) desde su sitio web a https://unigow.com// (el “Hiperenlace“). El establecimiento del Hiperenlace no implica en ningún caso la existencia de relaciones entre https://unigow.com// y el propietario del sitio o de la página web en la que se establezca el Hiperenlace, ni la aceptación o aprobación por parte de https://unigow.com// de sus contenidos o servicios. En todo caso, https://unigow.com// se reserva el derecho de prohibir o inutilizar en cualquier momento cualquier Hiperenlace al SITIO WEB. </p>

                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2"> ¿No deseas recibir correos comerciales? </h2>
                <p className="font-medium"> De conformidad con lo dispuesto en la Ley 34/2002, de 11 de junio de Servicios de la Sociedad de la Información y del Comercio Electrónico, puede oponerse al uso de su información para fines publicitarios, investigaciones de mercado o desarrollo de encuestas de satisfacción en cualquier momento, así como revocar su consentimiento en cualquier momento (sin efecto retroactivo). Para ello, deberá enviar un correo electrónico a la dirección unigowbusiness@gmail.com.  </p>
                <p className="font-medium"> De acuerdo con la LSSICE, UNIGOW ENTERPRISE, S.L. no realiza prácticas de SPAM, por lo que no envía correos comerciales por vía electrónica que no hayan sido previamente solicitados o autorizados por el usuario. En consecuencia, en cada uno de los formularios existentes en la web, el usuario tiene la posibilidad de dar su consentimiento expreso para recibir el boletín, con independencia de la información comercial puntualmente solicitada. </p>
                <p className="font-medium"> Si ha recibido publicidad por correo electrónico una vez prestado su consentimiento para ello, podrá oponerse enviando un correo electrónico a la citada dirección. </p>

                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2"> Cumplimiento de la normativa de aplicación </h2>
                <p className="font-medium"> Formulario de registro: siempre tendrá la posibilidad de ir completando su perfil en Unigow con la información que considere, con el fin de mejorar su presencia en la plataforma. </p>
                <p className="font-medium"> Formularios de suscripción a contenidos: dentro de la web existen varios formularios para activar la suscripción (gestionada por Brevo) con la finalidad de enviar campañas de marketing por correo electrónico, gestión de suscripciones y envío de boletines o noticias. </p>
                <p className="font-medium"> Formulario de comentarios: La web incluye un formulario cuya finalidad es comentar los artículos y dar su opinión respetando la libertad de expresión. El usuario podrá publicar comentarios en los posts que se publiquen. Los datos personales introducidos en el formulario para insertar estos comentarios serán utilizados exclusivamente para moderarlos y publicarlos, recabando igualmente estos datos que se almacenarán en los servidores de Cdmon. </p>
                <p className="font-medium"> Formulario de contacto: Existe un formulario de contacto cuya finalidad es la respuesta de consultas, sugerencias o contacto profesional. En este caso se utilizará la dirección de correo electrónico para responder a las mismas y enviar la información que el usuario requiera a través de la web. Estos datos se almacenarán en los servidores de Cdmon y Brevo. </p>
                <p className="font-medium"> Cookies: Cuando el usuario se registra o navega en esta web, se almacenan «cookies». Puede consultar en cualquier momento la política de cookies para ampliar información sobre el uso de cookies y como desactivarlas. </p>
                <p className="font-medium"> Sistemas de rastreo: en este SITIO WEB, a través de Google (Analytics), también se estudian las preferencias de sus usuarios, sus características demográficas, sus patrones de tráfico, y demás información que, en conjunto, nos ayuda a comprender mejor quiénes constituyen su audiencia y qué es lo que esta necesita. El rastreo de las preferencias de nuestros usuarios también nos ayuda a mostrarle los avisos publicitarios más relevantes. Dicho rastreo se realiza sin recabar datos personales.  </p>
                <p className="font-medium"> Asimismo, informarle que para la captación de suscriptores y clientes utilizamos Facebook Ads, por lo que al generar un anuncio, se puede segmentar el público por la localización, datos demográficos, intereses, etc. Por ello, los datos obtenidos por esta plataforma, estarían sujetos a esta política de privacidad desde el momento en que el usuario deja sus datos para unirse al boletín de nuestra comunidad. </p>
                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2"> Redes Sociales </h2>
                <p className="font-medium"> En relación con la gestión de sus datos asociados a los perfiles sociales de unigow.com, el ejercicio de sus derechos dependerá de la funcionalidad de la red social y las posibilidades de acceso a la información de los perfiles de los usuarios. Con relación a los derechos de acceso y rectificación en el marco de estas plataformas, le alertamos que solo podrán satisfacerse por nuestra parte en relación con aquella información que se encuentre bajo el control de unigow.com. En este sentido, no nos hacemos responsables del tratamiento de datos que lleve a cabo cada red social, ni del ejercicio de sus derechos sobre los datos que estén siendo tratados por dichas plataformas.  </p>
                <p className="font-medium"> Además, podrá dejar de interactuar, seguir o recibir información de los perfiles sociales de unigow.com, eliminar los contenidos que dejen de interesarle o restringir con quien comparte sus conexiones, mediante los mecanismos estipulados en las diferentes redes sociales. </p>
                <p className="font-medium"> El usuario podrá acceder a las políticas de privacidad de cada Red Social, así como configurar su perfil para garantizar su privacidad. Este SITIO WEB anima a los usuarios a familiarizarse con las condiciones de uso de las distintas redes sociales antes de comenzar a usarlas. </p>
                <p className="font-medium"> En nuestro SITIO WEB le ofrecemos enlaces y servicios relacionados con las diferentes redes sociales en las que estamos presentes (ej. “Me gusta” de Facebook). Si es usted miembro de una red social y hace clic sobre el correspondiente enlace, el proveedor de la red social podrá enlazar sus datos de perfil con la información de su visita a nuestro SITIO WEB. </p>
                <p className="font-medium"> Por tanto, es conveniente que se informe sobre las políticas de privacidad de la respectiva red social, tanto si accede a una de nuestras páginas web con alguno de sus perfiles en redes sociales o comparte información a través de ellos. </p>
                <p className="font-medium"> Puede acceder en todo momento a las políticas de privacidad de las diferentes redes sociales, así como configurar su perfil para garantizar su privacidad. Les animamos a familiarizarse con las condiciones de uso de dichas redes sociales antes de comenzar a usarlas. </p>
                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2"> Reclamación ante las autoridades </h2>
                <p className="font-medium"> En caso de que el INTERESADO aprecie cualquier mala praxis o comportamiento ilícito por parte del RESPONSABLE, no habiendo podido llegar a una solución amistosa, este podrá reclamar ante la Agencia Española de Protección de Datos o la autoridad competente que corresponda.   </p>

                <h2 className="text-black text-2xl md:text-3xl font-bold pt-2 pb-2 my-2"> Cláusula final</h2>
                <p className="font-medium"> El INTERESADO declara haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptando y consintiendo el tratamiento de estos por parte del RESPONSABLE en la forma y para las finalidades indicadas en el presente documento. </p>
                <p className="font-medium"> Como le hemos comunicado a lo largo de las presentes políticas de privacidad, en cualquier momento podrá revocar su consentimiento y ejercitar sus derechos mediante los medios ya mencionados. </p>


            </div>
        </div>

    );
}
