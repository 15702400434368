import React, {useState, Fragment, useEffect, Children} from "react";
import {useIframe} from "../contexts/IframeContext";
import UnigowSelect from "../../../components/UnigowSelect/UnigowSelect";
import {useQuery} from "react-query";
import {api} from "../../../apis/requests";
import UserCard from "../components/UserCard";
import {orientatorAnsweredQuestions} from "../../../helpers/chat";
import {useCustomLocale} from "../../../contexts/CustomLocaleContext";
import {sortText} from "./../../../helpers/helper";
import {getCookie} from "../../../helpers/localstorage";
import UnigowButton from "../../../components/UnigowButton/UnigowButton";
import {MenuItem, Skeleton} from "@mui/material";
import {PublicOrientator} from "@unigow/types/orientators";
import {ChatsPluginWithClient} from "@unigow/types/iframe";

interface SearchProps {
    type: "staff" | "mentor";

    pluginData: ChatsPluginWithClient
}

function Search({type, pluginData}: SearchProps): React.ReactElement {
    // STORES
    const {searchType, setSearchType} = useIframe();
    const {t, ready:localeLoaded} = useCustomLocale();

    // STATES
    const [searchParams, setSearchParams] = useState<Record<string, string>>({});
    const [paramOptions, setParamOptions] = useState<Record<string, string[]>>({});

    // QUERIES
    const {data:orientators, isLoading, isSuccess} = useQuery(["orientators", searchParams, searchType, pluginData._id], async()=>{
        const params = Object.entries(searchParams).map(([k, v])=>{
            if (v) {
                return `${k}=${v}`;
            }
            return null;
        }).filter((x)=>x !== null);

        params.push(`type=${searchType}`);

        const res = (await api.get<PublicOrientator[]>(`pluginchats/${pluginData._id}/orientators?${params.join("&")}`)).filter((x)=>!!x.userInfo);

        if (res) {
            const newOptions: Record<string, string[]> = {};

            pluginData.settings.filterParams?.forEach((filter)=>{
                res.forEach((user)=>{
                    const {userInfo} = user;

                    if (!userInfo) return;

                    const userValue = userInfo.info.info[filter];

                    if (userValue) {
                        // Si el valor es un string
                        if (typeof userValue === "string") {
                            if (newOptions[filter]) {
                                if (!newOptions[filter].includes(userValue)) {
                                    newOptions[filter].push(userValue);
                                }
                            } else {
                                newOptions[filter] = [userValue];
                            }
                            return;
                        }
                        // Si el valor es un array
                        if (Array.isArray(userValue)) {
                            userValue.forEach((value)=>{
                                if (newOptions[filter]) {
                                    if (!newOptions[filter].includes(value)) {
                                        newOptions[filter].push(value);
                                    }
                                } else {
                                    newOptions[filter] = [value];
                                }
                            });
                            return;
                        }
                    }
                });
            });

            setParamOptions(newOptions);
        }

        if (pluginData.style.randomOrder) {
            return res.sort(()=>Math.random() - 0.5);
        }

        if (pluginData.style.priorityArray) {
            const {priorityArray} = pluginData.style;

            // Sort taking into account the positions of the priorityArray
            return res.sort((a, b)=>{
                if (!a.client || !b.client) return 0;

                const aIndex = priorityArray.map((x)=>x.toString()).indexOf(a.realClient?._id.toString() || a.client.toString());
                const bIndex = priorityArray.map((x)=>x.toString()).indexOf(b.realClient?._id.toString() || a.client.toString());

                if (aIndex === -1 && bIndex === -1) return 0;
                if (aIndex === -1) return 1;
                if (bIndex === -1) return -1;

                return aIndex - bIndex;
            });
        }

        return res;
    }, {enabled:!!pluginData && localeLoaded});

    // Get query from url search
    useEffect(()=>{
        if (!searchType) return;

        const urlParams = new URLSearchParams(window.location.search);

        const query = urlParams.get("query");
        const mainParam = pluginData.settings.mainField;

        if (!mainParam) return;

        if (query) return setSearchParams({[mainParam]: query});

        const mainQuery = getCookie("query");

        if (mainQuery) return setSearchParams({[mainParam]: mainQuery});

    }, [pluginData, searchType]);

    // useEffect(()=>{
    //     if (!userData) return;

    //     const cookie = getCookie("whatsappwarn");

    //     if (cookie) return;

    //     if (!userData.phone || userData.phone === "") {
    //         toast.success(
    //             <div className="flex flex-col gap-2">
    //                 <p>¡Índicanos tu número de teléfono para poder enviarte notificaciones por whatsapp!</p>
    //                 <div className="flex flex-col gap-1">
    //                     <UnigowButton variant="secondary" onClick={()=>setCurrentPage("profile")}>Ir a mi perfil</UnigowButton>
    //                 </div>
    //             </div>, {icon:<WhatsApp className="text-green-500"/>}
    //         );
    //         setCookie("whatsappwarn", false, 365);
    //         return;
    //     }
    // }, [userData, setCurrentPage]);

    // EFFECTS
    useEffect(()=>{
        // Use effect para cambiar el tamaño del iframe en caso de que se reciba un mensaje
        function startMessageListener(event: MessageEvent): void {
            if (event.data.filter) {
                const {type:mentorType, filter} = event.data as {type: "mentor" | "staff", filter: Record<string, string>};

                setSearchType(mentorType);

                setSearchParams(filter);
            }
        }

        window.addEventListener("message", startMessageListener);

        // Clean the listener
        return ()=>window.removeEventListener("message", startMessageListener);
    }, [setSearchType]);

    useEffect(()=>{
        if (!searchType) {
            setSearchType(type);

            setSearchParams({});
        }
    }, [type, setSearchType, searchType]);

    return (
        <div className="flex flex-col items-center w-11/12 lg:w-full mx-auto gap-6">
            {!!orientators && (
                <div className="flex justify-center gap-4 flex-wrap w-full">
                    {pluginData.settings.filterParams?.map((filter)=>{
                        if (paramOptions[filter]) {
                            return (
                                <UnigowSelect key={filter} className="w-10/12 lg:w-1/4 rounded-full border-primary-500 text-gray-cool-400 m-0"
                                    onChange={(e)=>{
                                        setSearchParams((prev)=>{
                                            return {...prev, [filter]:e.target.value};
                                        });
                                    }}
                                    value={searchParams[filter] || ""}
                                    label={`Filtrar por ${t(filter)}`}
                                    formControlSx={{
                                        "& .MuiInputBase-root": {
                                            borderRadius: "9999px!important"
                                        }
                                    }}
                                >
                                    {Object.values(searchParams).filter((x)=>x !== "").length !== 0 && (
                                        <MenuItem value=""><em>Borrar filtro</em></MenuItem>
                                    )}
                                    {Children.toArray(sortText(paramOptions[filter]).map((opt)=>(
                                        <MenuItem value={opt}>{opt}</MenuItem>
                                    )))}
                                </UnigowSelect>
                            );
                        }
                        return <Fragment key={filter}/>;
                    })}
                </div>)}
            {(isLoading || !isSuccess) ? (
                // ESQUELETO DE CARGA
                <div className="flex flex-wrap lg:flex-row flex-col gap-[20px] justify-center w-full">
                    {Children.toArray(Array(2).fill(
                        <div className="lg:w-[calc(50%-20px)] w-full">
                            <li className="flex flex-col pb-4">
                                <div className="flex flex-col pb-4 rounded-[10px] shadow-md gap-4">
                                    {/* Parte superior */}
                                    <div className="flex flex-row bg-primary-transparent-5 gap-4 py-[9px] px-4 items-center rounded-t-[10px]">
                                        <div className="w-[50px] h-[50px] flex">
                                            <Skeleton variant="circular" width={50} height={50}/>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <div className="flex gap-1 items-center">
                                                <Skeleton variant="text" width="9ch"/>
                                            </div>
                                            <p className="line-clamp-2"><Skeleton variant="text" width="20ch"/></p>
                                        </div>
                                    </div>

                                    <div className="flex flex-col mx-4 gap-3 justify-between h-full">
                                        <div className="flex flex-col gap-3">
                                            <ul className="flex flex-wrap gap-1">
                                                <li className="bg-gray-cool-100 rounded-full text-ug-base px-2"><Skeleton variant="text" width="5ch"/></li>
                                                <li className="bg-gray-cool-100 rounded-full text-ug-base px-2"><Skeleton variant="text" width="5ch"/></li>
                                                <li className="bg-gray-cool-100 rounded-full text-ug-base px-2"><Skeleton variant="text" width="5ch"/></li>
                                            </ul>

                                            <ul className="flex flex-col gap-3">
                                                <li className="flex flex-col gap-1">
                                                    <p className="uppercase text-ug-sm text-gray-cool-500"><Skeleton variant="text"/></p>
                                                    <p className="text-ug-base"><Skeleton variant="text"/></p>
                                                </li>
                                            </ul>

                                            <ul className="flex flex-col gap-3">
                                                <li className="flex flex-col gap-1">
                                                    <p className="uppercase text-ug-sm text-gray-cool-500"><Skeleton variant="text"/></p>
                                                    <p className="text-ug-base"><Skeleton variant="text"/></p>
                                                </li>
                                            </ul>

                                            <ul className="flex flex-col gap-3 h-fit justify-center">
                                                <Skeleton variant="rectangular" height="5ch"/>
                                            </ul>
                                        </div>
                                        <UnigowButton variant="primary"><Skeleton variant="text"/></UnigowButton>
                                    </div>
                                </div>
                            </li>
                        </div>
                    ))}
                </div>
            ) : (
                <ul className="flex flex-wrap gap-4 w-full">
                    {orientators?.map((orientator)=>{
                        const checkParams = orientatorAnsweredQuestions(pluginData.settings.mentorParams,
                            orientator.userInfo ? orientator.userInfo.info.info : undefined);

                        return (checkParams &&
                        <UserCard className="lg:w-[calc(50%-20px)] w-full" pluginData={pluginData} orientatorInfo={orientator} key={orientator._id.toString()}/>
                        );
                    })}
                </ul>
            )}
        </div>
    );
}

export default Search;