import React, {useState, FormEvent, useRef} from "react";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import UnigowSelect from "@unigow/components/UnigowSelect/UnigowSelect";
import {api} from "@unigow/apis/requests";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import {isHexColor, maxLength} from "class-validator";
import {toast} from "react-toastify";
import {MenuItem} from "@mui/material";
import {usePopupStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/popup.store";
import {PopupType} from "@unigow/unigow-types";

export default function Step2(): React.ReactElement {
    const {popupData, setStep, setPopupData} = usePopupStore();
    const [color, setColor] = useState((popupData && popupData.style) ? popupData?.style.mainColor : "#0034BB");
    const [font, setFont] = useState((popupData && popupData.style) ? popupData?.style.fontFamily : "Montserrat");
    const [size, setSize] = useState<"normal" | "small" | "big">((popupData && popupData.style) ? popupData?.style.size || "big" : "big");
    const [shortText, setShortText] = useState("Chatea con estudiantes reales");
    const [longText, setLongText] = useState("Descubre las experiencias reales de estudiantes. Resolverán todas tus dudas ;)");

    const colorPickerRef = useRef<HTMLInputElement>(null);

    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!isHexColor(color)) {
            toast.error("El color debe ser un color hexadecimal válido");
            return;
        }

        if (shortText === "" || longText === "") {
            toast.error("Los textos no pueden estar vacíos");
            return;
        }

        if (!maxLength(shortText, 30)) {
            toast.error("El texto corto no puede tener más de 30 caracteres");
            return;
        }

        const iframe: Partial<PopupType> = {
            style:{
                mainColor:color,
                fontFamily:font,
                shortText,
                longText,
                size
            }
        };

        if (!popupData) {
            setStep(0);
            return;
        }

        const response = await api.patch<Partial<PopupType>, PopupType>(`popups/${popupData._id}`, iframe);

        if (response) {
            setPopupData(response);
            setStep(0);
        }
    }

    return (
        <div className="flex flex-col gap-5">
            <p className="font-light text-ug-base">¡Estás a punto de terminar! Solo falta que pongas el color de tu organización
                y cambies algún detalle si lo ves necesario
            </p>
            <form className="flex flex-col gap-[15px]" onSubmit={handleSubmit}>
                <div className="flex flex-col gap-[10px]">
                    <p className="font-semibold">Texto de llamada a la acción PC</p>
                    <UnigowInput required value={longText} onChange={(e)=>setLongText(e.target.value)} placeholder="Descubre las experiencias reales de estudiantes. Resolverán todas tus dudas ;)"/>
                </div>
                <div className="flex flex-col gap-[10px]">
                    <p className="font-semibold">Texto de llamada a la acción Móvil</p>
                    <UnigowInput required value={shortText} onChange={(e)=>setShortText(e.target.value)} placeholder="Chatea con estudiantes reales"/>
                </div>
                <div className="flex gap-11 flex-wrap">
                    <div className="flex flex-col gap-[10px] lg:w-1/2 wr">
                        <p className="text-ug-base font-semibold">Color Principal</p>
                        <div className="flex gap-[10px]">
                            <UnigowInput value={color} onChange={(e)=>{
                                setColor(e.target.value);
                            }} className="lg:w-3/4 w-full"
                            onClick={()=>{
                                colorPickerRef.current?.click();
                            }}
                            />
                            <div onClick={()=>{
                                colorPickerRef.current?.click();
                            }} className="rounded w-9 h-auto relative border border-solid border-gray-cool-200" style={{backgroundColor:color}}
                            >
                                <input className="opacity-0" ref={colorPickerRef} type="color" onChange={(e)=>{
                                    setColor(e.target.value);
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[10px] lg:w-1/2">
                        <p className="text-ug-base font-semibold">Tamaño del popup</p>
                        <UnigowSelect value={size} onChange={(e)=>{
                            setSize(e.target.value as "normal" | "small" | "big");
                        }}
                        label="Tamaño del popup"
                        >
                            <MenuItem value="normal">Normal</MenuItem>
                            <MenuItem value="small">Pequeño</MenuItem>
                            <MenuItem value="big">Grande</MenuItem>
                        </UnigowSelect>
                    </div>
                    <div className="flex flex-col gap-[10px]">
                        <p className="text-ug-base font-semibold">Elige la fuente de texto</p>
                        <UnigowSelect value={font} style={{fontFamily:font}} onChange={(e)=>{
                            setFont(e.target.value);
                        }}
                        label="Tipo de fuente"
                        >
                            <MenuItem value="monospace" style={{fontFamily:"monospace"}}>Monospace</MenuItem>
                            <MenuItem value="Montserrat" style={{fontFamily:"Montserrat"}}>Montserrat</MenuItem>
                            <MenuItem value="Arial" style={{fontFamily:"Arial"}}>Arial</MenuItem>
                            <MenuItem value="Calibri" style={{fontFamily:"Calibri"}}>Calibri</MenuItem>ç
                            <MenuItem value={"\"Courier New\""} style={{fontFamily:"\"Courier New\""}}>Courier New</MenuItem>
                            <MenuItem value="Georgia" style={{fontFamily:"Georgia"}}>Georgia</MenuItem>
                            <MenuItem value={"\"Times New Roman\""} style={{fontFamily:"\"Times New Roman\""}}>Times New Roman</MenuItem>
                            <MenuItem value="Verdana" style={{fontFamily:"Verdana"}}>Verdana</MenuItem>
                            <MenuItem value="Noto Sans HK" style={{fontFamily:"Noto Sans HK"}}>Noto Sans HK</MenuItem>
                        </UnigowSelect>
                    </div>
                </div>
                <div className="flex gap-[10px] flex-col lg:flex-row">
                    {/* Error de que no hay mainColor */}
                    <UnigowButton variant="basic" onClick={()=>setStep(0)}>Cancelar</UnigowButton>
                    <UnigowButton variant="secondary" type="submit">Acabar configuración</UnigowButton>
                </div>
            </form>
        </div>
    );
}