import {KeyboardArrowDown} from "@mui/icons-material";
import {Box, Checkbox, Chip, FormControl, ListItemText, MenuItem, Select, SelectChangeEvent, SelectProps} from "@mui/material";
import React from "react";

interface UnigowMultipleSelectProps {
    options: string[],
    value: string[],
    handleFieldChange: (e: SelectChangeEvent<string[]>)=> void,
    placeholder: string
}

export default function UnigowMultipleSelect({options, value, handleFieldChange, placeholder, ...selectProps}:
UnigowMultipleSelectProps & SelectProps<string[]>): React.ReactElement {

    return (
        <FormControl>
            <Select
                {...selectProps}
                displayEmpty
                multiple={true}
                value={value}
                onChange={handleFieldChange}
                renderValue={(selected) => (
                    selected.length === 0 ? <em>{placeholder}</em> :
                        (
                            <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                                {selected.map((val) => (
                                    <Chip key={val} label={val} />
                                ))}
                            </Box>
                        )
                )}
                classes={{
                    select:"py-2 px-3 text-gray-cool-400 text-ug-lg font-light border border-solid border-gray-cool-300 rounded-lg backdrop-blur-sm"
                }}
                sx={{
                    "& fieldset": {border: "none"}
                }}
                MenuProps={{classes:{paper:"rounded-[10px] border-solid border-gray-cool-400 border"}}}
            >
                <MenuItem disabled value="" classes={{disabled:"opacity-100"}}>
                    <em className="text-gray-cool-500 font-light not-italic text-[14px] flex items-center gap-2">Elige entre estas opciones <KeyboardArrowDown sx={{width:20, height:18}}/></em>
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option} value={option}
                        classes={{selected:"bg-gray-cool-100"}}
                    >
                        <Checkbox checked={value.includes(option) || false} style={{color:"#000"}}/>
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}