import {DatePicker} from "@mui/x-date-pickers";
import {api} from "@unigow/apis/requests";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {useIframe} from "@unigow/pages/Iframe/contexts/IframeContext";
import {useIframeStore} from "@unigow/stores/iframeStore";
import {CardType} from "@unigow/unigow-types";
import dayjs, {Dayjs} from "dayjs";
import React, {Children, FormEvent, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {twMerge} from "tailwind-merge";

export default function SlotPicker(): React.ReactElement {
    const {setCurrentPage} = useIframe();
    const {pendingAction, setPendingAction} = useIframeStore();

    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
    const [userOffset, setUserOffset] = useState<number>(0);
    const [selectedSlot, setSelectedSlot] = useState<{start: Dayjs} | null>(null);

    useEffect(()=>{
        if (!pendingAction || !("reservationConfig" in pendingAction.data)) return;

        // Get hour difference between the timezone of the event and the local timezone, the timezone is in the format "America/Mexico_City"
        const eventOffset = dayjs().tz(pendingAction.data.reservationConfig?.timezone).utcOffset();
        const localOffset = dayjs().utcOffset();

        // Difference in minutes
        const offset = eventOffset - localOffset;

        setUserOffset(offset);
    }, [pendingAction]);

    if (!pendingAction || pendingAction.action !== "card" || !("reservationConfig" in pendingAction.data)) {
        return <></>;
    }

    const reservationAction = pendingAction as {action: "card", data: CardType};

    function getDaySlots(date: Dayjs, slotConfig: CardType["reservationConfig"], maxParticipants: number): number[] {
        if (!slotConfig || !reservationAction) return [];

        const {slotDuration} = slotConfig;

        const day = date.day();

        // Get all the slots for the day
        const weekSlots = Object.values(slotConfig?.slots)[day];

        // Get the booked slots for the day
        const dayBooked = (slotConfig.bookedSlots || []).filter((bookedDay)=>{
            const bookedDateWithOffset = dayjs(bookedDay.start).add(userOffset, "minute");

            return bookedDateWithOffset.date() === date.date() && bookedDateWithOffset.month() === date.month();
        })
        // We have an array with the booked slots for the day, get the start and end of each slot in iso.
            .map((bookedDay)=>{
            // Apply the offset to the start and end
                const start = dayjs(bookedDay.start).toISOString();
                const end = dayjs(bookedDay.end).toISOString();

                return {start, end, slots:maxParticipants};
            })
            // If there are multiple bookings for the same slot (same start) reduce the slots
            .reduce((acc, current)=>{
                const index = acc.findIndex((booked)=>booked.start === current.start);

                if (index !== -1) {
                    acc[index].slots -= 1;
                } else {
                    current.slots -= 1;
                    acc.push(current);
                }

                return acc;
            }, [] as {start: string, end: string, slots: number}[]);

        // Generate all posible slots for the day using weekSlots and slotDuration
        const slots: number[] = [];

        weekSlots.forEach((slot)=>{
            // Convert to minutes the start and end
            const start = (parseInt(slot.start.split(":")[0]) * 60) + parseInt(slot.start.split(":")[1]) - userOffset;

            const end = (parseInt(slot.end.split(":")[0]) * 60) + parseInt(slot.end.split(":")[1]) - userOffset;

            // Generate all the slots for the day
            for (let i = start; i < end; i += slotDuration) {
                // Add only if the hour is in the future from now
                if (i < (dayjs().hour() * 60) + dayjs().minute()) continue;

                slots.push(i);
            }
        });

        // Remove the booked slots
        dayBooked.forEach((booked)=>{
            const start = (new Date(booked.start).getHours() * 60) + new Date(booked.start).getMinutes();
            const end = (new Date(booked.end).getHours() * 60) + new Date(booked.end).getMinutes();

            for (let i = start; i < end; i += slotDuration) {
                let index = slots.indexOf(i);

                if (index === -1) {
                    index = slots.indexOf(i + (24 * 60));
                }

                if (index === -1) {
                    index = slots.indexOf(i - (24 * 60));
                }

                if (index !== -1) {
                    if (booked.slots === 0) {
                        slots.splice(index, 1);
                    }
                }
            }
        });

        return slots;
    }

    function isDayAvailable(date: Dayjs, slotConfig: CardType["reservationConfig"], maxParticipants: number): boolean {
        if (!slotConfig) return false;

        // Only check one month in advance
        if (date.diff(new Date(), "month") > 1) return false;

        // Check if the day is in the weekSlots, use the indexes of the weekslots
        const day = date.day();
        if (Object.values(slotConfig.slots)[day].length === 0) return false;

        // Check if the day is in the bookedDays, bookedDays has a field called start which is a Date object
        const dayBooked = (slotConfig.bookedSlots || []).find((bookedDay)=>new Date(bookedDay.start).getDate() === date.date() &&
        new Date(bookedDay.start).getMonth() === date.month());

        if (dayBooked) {
            const daySlots = getDaySlots(date, slotConfig, maxParticipants);

            return daySlots.length > 0;
        }

        return true;
    }

    function disableDates(date: Dayjs): boolean {
        const {reservationConfig, maxParticipants} = reservationAction.data;

        if (!reservationConfig) return true;

        return !isDayAvailable(date, reservationConfig, maxParticipants);
    }

    function getSlots(): number[] {
        if (!selectedDate || !reservationAction || !("reservationConfig" in reservationAction.data)) return [];

        const slots = getDaySlots(selectedDate, reservationAction.data.reservationConfig, reservationAction.data.maxParticipants);

        if (userOffset > 0) {
            // Add slots from the prev day
            const nextDay = selectedDate.add(1, "day");
            const nextSlots = getDaySlots(nextDay, reservationAction.data.reservationConfig, reservationAction.data.maxParticipants);

            // Add only the slots that are superior than 1440 minutes
            nextSlots.forEach((slot)=>{
                if (slot < 0) {
                    // Add the the beginning of the array
                    slots.push(slot + (24 * 60));
                }
            });
        } else if (userOffset < 0) {
            // Add slots from the next day
            const previousDay = selectedDate.subtract(1, "day");
            const previousSlots = getDaySlots(previousDay, reservationAction.data.reservationConfig, reservationAction.data.maxParticipants);

            // Add only the slots that are inferior than 0 minutes
            previousSlots.reverse().forEach((slot)=>{
                if (slot > 1440) {
                    // Add the the end of the array
                    slots.unshift(slot - (24 * 60));
                }
            });
        }

        return slots.filter((slot)=>slot >= 0 && slot < 1440);
    }

    function isSlotSelected({start}: {start: Dayjs}): boolean {
        if (!selectedSlot) return false;

        // Compare only the hours and minutes
        return selectedSlot.start.hour() === start.hour() && selectedSlot.start.minute() === start.minute();
    }

    async function pickDate(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!selectedDate || !selectedSlot || !reservationAction) {
            toast.error("Debes seleccionar una fecha y una hora");
            return;
        }

        const newBooking = {
            start: selectedSlot.start.toISOString(),
            end: selectedSlot.start.add(reservationAction.data.reservationConfig!.slotDuration, "minute").toISOString()
        };

        const response = await api.post(`cards/${reservationAction.data._id}/book`, newBooking);

        if (response) {
            toast.success("Reserva realizada con éxito");
            setCurrentPage(`plugin-${reservationAction.data.plugin}`);
        } else {
            toast.error("No se pudo realizar la reserva");
        }
        setPendingAction(undefined);
    }

    return (
        <form className="w-10/12 mx-auto flex flex-col gap-4" onSubmit={pickDate}>
            <h2>Elige la fecha y la hora de la reserva</h2>
            <div className="flex flex-col gap-[10px]">
                <p className="text-ug-lg font-semibold">Elige el día de la reserva</p>
                <DatePicker value={selectedDate} disablePast shouldDisableDate={disableDates} onChange={(v)=>{
                    setSelectedDate(v);
                }}
                />
            </div>
            {selectedDate && (
                <div className="flex flex-col gap-2">
                    <p className="text-ug-lg font-semibold">Elige la hora de la reserva</p>
                    <ul className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                        {Children.toArray(getSlots().map((slot)=>{
                            const hours = Math.floor(slot / 60);
                            const minutes = slot % 60;

                            const start = dayjs().hour(hours).minute(minutes).format("HH:mm");
                            const end = dayjs().hour(hours).minute(minutes + reservationAction.data.reservationConfig!.slotDuration).format("HH:mm");

                            // Get utc time using user timezone
                            const startDateAdjustedToOffset = selectedDate.hour(hours).minute(minutes);

                            return (
                                <li className="flex gap-2 items-center">
                                    <UnigowButton type="button" className={twMerge("w-[25ch]")} variant={isSlotSelected({start:startDateAdjustedToOffset}) ? "secondary" : "basic"} onClick={()=>{
                                        setSelectedSlot({start: startDateAdjustedToOffset});
                                    }}
                                    >
                                        {start} - {end}
                                    </UnigowButton>
                                </li>
                            );
                        }
                        ))}
                    </ul>
                </div>
            )}
            <UnigowButton variant="primary">Confirmar la reserva</UnigowButton>
        </form>
    );
}