import React, {FormEvent, Fragment, useState} from "react";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {AddAPhoto, Edit} from "@mui/icons-material";
import {LandingEditorProps} from "../LandingCreator";
import {api} from "@unigow/apis/requests";
import {toast} from "react-toastify";
import ImageUploader from "@unigow/components/ImageUploader/ImageUploader";
import PopupWindow from "@unigow/components/PopupWindow/PopupWindow";
import UnigowSelect from "@unigow/components/UnigowSelect/UnigowSelect";
import {MenuItem} from "@mui/material";
import {LandingType} from "@unigow/unigow-types";

function EditLogo(props: LandingEditorProps): React.ReactElement {
    const {landingInfo, modifyLandingData} = props;
    const [open, setOpen] = useState(false);
    const [logo, setLogo] = useState(landingInfo?.logo || "");
    const [size, setSize] = useState<"normal" | "big" | "small">(landingInfo?.logoSize || "normal");

    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        if (!logo || !landingInfo) return;

        const res = await api.patch<Partial<LandingType>, LandingType>(`landing/${landingInfo._id}`, {logo, logoSize:size});

        if (res) {
            setOpen(false);
            toast.success("Cambios guardados con éxito");
            modifyLandingData("logo", logo);
        }
    }

    return (
        <Fragment>
            <UnigowButton className="py-0 px-3" variant="primary" onClick={()=>setOpen(true)}>
                <Edit className="w-[18px]"/>
            </UnigowButton>
            <PopupWindow fullWidth open={open} closePopup={()=>setOpen(false)} title="Edita el logo de la Landing Page">
                <form onSubmit={handleSubmit} className="flex flex-col gap-4 items-center">
                    <ImageUploader firebaseFolder="landingLogos" firebaseSufix="landinglogo" buttonText="Guardar Logo"
                        variableSetter={setLogo}
                        name={landingInfo!._id.toString()}
                    >
                        {logo ? (
                            <div className="flex items-center gap-2 py-4">
                                <p className="text-ug-base font-semibold">Logo actual:</p>
                                <img src={logo} alt="Logo" className="w-[100px]"/>
                            </div>
                        ) : (
                            <div className="border border-solid border-gray-cool-300 h-[62px] w-[180px] px-2 flex items-center justify-center">
                                <AddAPhoto className="text-gray-cool-400 w-7 h-7"/>
                            </div>
                        )}
                    </ImageUploader>

                    <UnigowSelect
                        className="w-full"
                        required
                        value={size}
                        onChange={(e)=>setSize(e.target.value as "normal" | "big" | "small")}
                    >
                        <MenuItem value="small">
                            Pequeño
                        </MenuItem>
                        <MenuItem value="normal">
                            Normal
                        </MenuItem>
                        <MenuItem value="big">
                            Grande
                        </MenuItem>
                    </UnigowSelect>
                    <UnigowButton disabled={logo === ""} variant="secondary">Guardar ajustes del logo</UnigowButton>
                </form>
            </PopupWindow>
        </Fragment>
    );
}

export default EditLogo;