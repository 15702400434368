import React from "react";
import ImageUploader from "../../../../../components/ImageUploader/ImageUploader";
import {ReactComponent as Avatar} from "@unigow/assets/avatarGrey.svg";
import {useAuthStore} from "../../../../../stores/authStore";

interface MandoryProfilePicProps {
    profPicSetter: (v: React.SetStateAction<string>)=> void
}

export default function MandatoryProfilePic(props: MandoryProfilePicProps): React.ReactElement {
    const {userData} = useAuthStore();
    const {profPicSetter} = props;

    return (
        <div className="flex flex-col gap-3 items-center px-12">
            <div className="flex flex-col text-[18px] items-center">
                <h3 className="font-semibold">Último paso del registro:</h3>
                <p>Añade tu foto de perfil</p>
            </div>
            {userData && (
                <ImageUploader variableSetter={profPicSetter} buttonText="Guardar foto de perfil"
                    firebaseFolder="profPics" firebaseSufix="profPic" aspectRatio={1} rounded
                    name={userData.email}
                >
                    <div className="cursor-pointer overflow-hidden w-fit">
                        <Avatar className="w-[200px] h-[200px]"/>
                    </div>
                </ImageUploader>
            )}
            <p className="italic font-light text-ug-base">Haz click en el icono para subir una foto</p>
        </div>
    );
}