import React, {useState, FormEvent} from "react";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {api} from "@unigow/apis/requests";
import {isURL} from "class-validator";
import {toast} from "react-toastify";
import {usePopupStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/popup.store";
import {PopupType} from "@unigow/unigow-types";
import {useChatsPluginStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/chatsPlugin.store";

function Step1(): React.ReactElement {
    const {setStep, popupData, setPopupData} = usePopupStore();
    const {pluginData} = useChatsPluginStore();

    const [link, setLink] = useState<string>(popupData?.settings.redirectLink || "");

    async function saveChanges(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!isURL(link, {require_protocol:true})) {
            toast.error("El link a la página debe ser una URL válida.");
            return;
        }

        const popup: Pick<PopupType, "settings"> & {type: string} = {
            settings:{
                redirectLink:link
            },
            type:"chats"
        };

        let response: PopupType | undefined = undefined;

        if (popupData) {
            response = await api.patch<Pick<PopupType, "settings">, PopupType>(`popups/${popupData._id}`, popup);
        } else {
            // Aquí va el id del iframe
            response = await api.post<Pick<PopupType, "settings"> & {type: string}, PopupType>(`iframes/${pluginData?.iframe}/plugins/${pluginData?._id}/popups`, popup);
        }

        if (response) {
            setPopupData(response);
            setStep(2);
        }
    }

    return (
        <form className="flex flex-col gap-[15px] overflow-hidden" onSubmit={saveChanges}>
            <p className="font-light text-ug-base">Este popup se verá en la parte inferior derecha de una página de tu web. En la sección derecha puedes ver una simulación de cómo se ve</p>
            <div className="flex flex-col gap-[10px]">
                <p className="font-semibold">Link a la página en la que está tu Chat peer-to-peer</p>
                <UnigowInput required value={link} onChange={(e)=>setLink(e.target.value)} placeholder="Copia el link de la web donde está tu Chat peer-to-peer"/>
            </div>
            <div className="flex gap-[10px] flex-col lg:flex-row">
                <UnigowButton type="button" variant="basic" onClick={()=>setStep(0)}>Cancelar</UnigowButton>
                <UnigowButton variant="secondary" type="submit">Continuar</UnigowButton>
            </div>
        </form>
    );
}

export default Step1;