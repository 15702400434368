import React, {useState} from "react";
import {api} from "@unigow/apis/requests";
import PopupWindow from "@unigow/components/PopupWindow/PopupWindow";
import {Param} from "@unigow/types/param";
import {useQuery, useQueryClient} from "react-query";
import {Autocomplete} from "@mui/material";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {FullGroup} from "@unigow/types/group";
import {UnigowHttpError} from "@unigow/types/error";
import {toast} from "react-toastify";
import {useGroupWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/groupWizardStore";
import {ChatsPluginAPI} from "@unigow/apis/iframe";
import {ChatsPluginType, ClientType} from "@unigow/unigow-types";

interface MentorSelectorProps {
    open: boolean;
    setOpen: (open: boolean)=> void;
}

export default function ExistingMentorSelector({open, setOpen}: MentorSelectorProps): React.ReactElement {
    const queryClient = useQueryClient();

    const {selectedGroup} = useGroupWizardStore();
    const group = selectedGroup as FullGroup;
    const [selectedPlugin, setSelectedPlugin] = useState<(ChatsPluginType & {clientInfo: Pick<ClientType, "_id" | "alias" | "name">}) | null>(null);
    const [mainField, setMainField] = useState<string[]>([]);

    const {data:chatPlugins = []} = useQuery("chatplugins", async()=>{
        return ChatsPluginAPI.getAllChatsPlugins();
    });

    const {data:mainOptions = []} = useQuery(["parameters", selectedPlugin], async()=>{
        const url = `parameters/${selectedPlugin!.client}/parameterInfo`;

        const clientParams = await api.get<Param[]>(url);

        const mainParam = selectedPlugin!.settings.mainField;

        return clientParams.find((param)=>param.alias === mainParam)?.options || [];
    }, {enabled:!!selectedPlugin});

    async function startBatch(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!selectedPlugin || mainField.length === 0) return;

        try {
            const response = await api.post<{plugin: string, filterField: string | undefined, fields: string[]}, {status: string}>(`groups/${group._id}/invite`, {
                plugin: selectedPlugin._id.toString(),
                fields: mainField,
                filterField:selectedPlugin.settings.mainField
            });

            if (response) {
                toast.success("Embajadores invitados con éxito");
                setOpen(false);
                // Invalidate groups data
                await queryClient.invalidateQueries(["groups", group.groupPage]);
            }
        } catch (err) {
            const error = err as UnigowHttpError;

            if (error.errorCode === "group/full") {
                toast.error("No hay suficiente espacio en el grupo");
            }
        }
    }

    return (
        <PopupWindow title="Aprovecha los embajadores existentes en Unigow" open={open} onClose={()=>{
            setOpen(false);
            setSelectedPlugin(null);
        }}
        closePopup={()=>setOpen(false)}
        >
            <form className="flex flex-col gap-[15px] w-full" onSubmit={startBatch}>
                <div className="flex flex-col gap-[15px] w-full">
                    <div className="flex flex-col gap-[5px]">
                        <h3 className="text-primary-500">Selecciona los embajadores que quieres</h3>
                    </div>
                    <div className="flex lg:items-center gap-4 justify-between flex-col lg:flex-row">
                        <p className="text-ug-lg font-semibold">Proyecto de Institución educativa</p>
                        <Autocomplete className="lg:w-2/5 shrink-0" options={chatPlugins}
                            renderInput={(params)=>(
                                <UnigowInput required {...params} size="small" label="Proyecto"/>
                            )}
                            getOptionLabel={(option)=>`${option.clientInfo.name} - ${option.alias}`}
                            value={selectedPlugin}
                            onChange={(_, value)=>setSelectedPlugin(value)}
                        />
                    </div>
                    <div className="flex lg:items-center gap-4 justify-between flex-col lg:flex-row">
                        <p className="text-ug-lg font-semibold">Escoge embajadores de las titulaciones disponibles</p>
                        <Autocomplete multiple autoHighlight className="lg:w-2/5 shrink-0" options={["Todas"].concat(mainOptions)}
                            renderInput={(params)=>(
                                <UnigowInput required={mainField.length === 0} {...params} size="small" label="Campo principal"/>
                            )}
                            value={mainField}
                            onChange={(_, value)=>setMainField(value)}
                        />
                    </div>
                </div>
                <div>
                    <UnigowButton type="submit" className="px-8" variant="secondary">Invitar a estos embajadores</UnigowButton>
                </div>
            </form>
        </PopupWindow>
    );
}
