import React, {FormEvent, useState} from "react";
import {Checkbox} from "@mui/material";
import ParameterChooser from "@unigow/pages/Dashboard/components/ParameterChooser/ParameterChooser";
import ParameterFields from "@unigow/pages/Dashboard/components/ParameterChooser/ParameterFields";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import {EditCardProps} from "@unigow/pages/Dashboard/pages/Iframes/tabs/Cardsplugin/sections/CreateCards/EditCard";
import {CardType} from "@unigow/unigow-types";
import {useCardWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/cardsPlugin.store";
import {CardsPluginAPI} from "@unigow/apis/iframe";
import {toast} from "react-toastify";

export default function CardsStep2({setStep}: EditCardProps): React.ReactElement {
    // STORES
    const {selectedCard, modifyCard, selectCard} = useCardWizardStore();
    const {clientParams} = useDashboardStore();

    const thisCard = selectedCard as CardType;

    // STATES
    const [subStep, setSubStep] = useState(0);
    const [phoneNeeded, setPhoneNeeded] = useState(thisCard.settings.userNeedsPhone || false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>(thisCard.settings.userParams || []);

    // FUNCTIONS
    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        const iframe: Partial<CardType> = {
            settings:{
                userParams:selectedOptions,
                userNeedsPhone:phoneNeeded
            }
        };

        if (!thisCard) {
            setStep("basic");
            return;
        }

        const response = await CardsPluginAPI.updateCard({cardId:thisCard._id.toString(), data:iframe});

        if (response) {
            modifyCard("settings", response.settings);
            setSubStep(1);
        }
    }

    return (
        <div className="overflow-hidden">
            {subStep === 0 ? (
                <form className="flex flex-col gap-[15px] " onSubmit={handleSubmit}>
                    <h2>Personaliza el registro de los usuarios</h2>
                    <p className="font-light text-ug-base">Es hora de pensar en qué datos quieres pedirle al usuario antes de que pueda inscribirse en la actividad. El nombre y correo son obligatorios.</p>
                    <p className="text-ug-base font-semibold">Nombre *</p>
                    <p className="text-ug-base font-semibold">Correo electrónico *</p>
                    <div className="flex items-center gap-2">
                        <Checkbox
                            style={{color:"#000"}}
                            id="neededphone"
                            className="p-0"
                            checked={phoneNeeded}
                            onChange={(e)=>setPhoneNeeded(e.target.checked)}
                        />
                        <label htmlFor="neededphone" className="text-ug-base font-semibold">Pedir número de teléfono</label>
                    </div>
                    <ParameterChooser selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}
                        goBack={()=>setStep("basic")}
                    />
                </form>
            ) : (
                <div className="flex flex-col gap-[15px] ">
                    <h2>Revisa el formato de cada campo</h2>
                    <p className="font-light text-ug-base">El usuario al registrarse deberá seleccionar una opción dentro de cada categoría. Asegúrate de que las opciones son correctas.</p>

                    <ParameterFields selectedOptions={clientParams.filter((x)=>selectedOptions.includes(x.alias))}
                        setSubStep={setSubStep} goNext={()=>{
                            toast.success("Tarjeta actualizada correctamente");
                            selectCard(undefined);
                        }}
                    />
                </div>
            )}

        </div>
    );
}