import React, {HTMLAttributes, useState} from "react";
import {twMerge} from "tailwind-merge";
import ChatButton from "./components/ChatButton";
import {ReactComponent as Avatar} from "@unigow/assets/avatarGrey.svg";
import UnigowButton from "../../../components/UnigowButton/UnigowButton";
import {useCustomLocale} from "../../../contexts/CustomLocaleContext";
import AprovalButton from "./components/AprovalButton";
import VerifiedBadge from "./components/VerifiedBadge";
import FreeField from "./components/FreeField";
import {printAdditionalInfo} from "../../../helpers/helper";
import {useAuthStore} from "../../../stores/authStore";
import {PublicOrientator} from "@unigow/types/orientators";
import {ChatsPluginWithClient} from "@unigow/types/iframe";

interface UserCardProps {
    orientatorInfo: PublicOrientator;
    color?: string;
    pluginData: ChatsPluginWithClient
}

export default function UserCard({orientatorInfo, className, pluginData,
    ...divProps}: UserCardProps & HTMLAttributes<HTMLLIElement>): React.ReactElement {
    // STORES
    const {t, getType, getInputType} = useCustomLocale();
    const {userData} = useAuthStore();

    // Search the main field of the orientator in this iframe
    const mainParam = orientatorInfo.userInfo.info.info[pluginData?.settings.mainField || "main"];

    const orientatorParams = Object.entries(orientatorInfo.userInfo.info.info).map(([k, v]) => ({
        alias: k,
        // Si el valor es un array, unirlo para que cada valor tenga un # al principio
        value: printAdditionalInfo(v)
    }))
        // Comprueba que el parámetro no es el principal y que está en los parámetros visibles (o no hay parámetros visibles)
        .filter((x)=>x.alias !== "main" && (pluginData?.settings.visibleParams?.length === 0 || pluginData?.settings.visibleParams?.includes(x.alias)))
        // Comprueba que el parametro está entre los parámetros del mentor y no es el principal
        .filter((x)=>(pluginData?.settings.mentorParams || []).includes(x.alias) && x.alias !== pluginData?.settings.mainField)
        .sort((a, b)=>t(a.alias).localeCompare(t(b.alias)));

    const chipParams: {
        alias: string,
        value: string
    }[] = [];

    const defaultParams: {
        alias: string,
        value: string,
        type: "specific" | "free" | "multi"
    }[] = [];

    orientatorParams.forEach((x)=>{
        const type = getType(x.alias);
        const inputType = getInputType(x.alias);

        if (inputType === "checkbox") {
            defaultParams.push({
                ...x,
                type: "specific"
            });
            return;
        }

        switch (type) {
            case "general":{
                chipParams.push(x);
                break;
            }
            case "specific":{
                defaultParams.push({
                    ...x,
                    type: "specific"
                });
                break;
            }
            case "free":{
                defaultParams.push({
                    ...x,
                    type: "free"
                });
                break;
            }
        }
    });

    function renderButton(): React.ReactElement {
        switch (pluginData?.settings.contact) {
            case "chat":{
                return <ChatButton orientatorInfo={orientatorInfo} variant="primary">Quiero chatear con {orientatorInfo.userInfo.name}</ChatButton>;
            }
            default:{
                return <AprovalButton pluginData={pluginData} orientatorInfo={orientatorInfo} variant="primary">Quiero contactar con {orientatorInfo.userInfo.name}</AprovalButton>;
            }
        }
    }

    const clientLogo = orientatorInfo.realClient?.logo || pluginData.clientInfo.logo || undefined;
    const clientName = orientatorInfo.realClient?.name || undefined;

    const [showLogo, setShowLogo] = useState<boolean>((pluginData.style.showLogos && !!clientLogo) || false);

    function renderOption(param: {alias: string, value: string, type: "specific" | "free" | "multi"}): React.ReactElement {
        switch (param.type) {
            case "specific":{
                return (
                    <li className="flex flex-col gap-1" key={param.alias}>
                        <p className="uppercase text-ug-sm text-gray-cool-500">{t(param.alias)}</p>
                        <p className="text-ug-base">{param.value}</p>
                    </li>
                );
            }
            case "free":{
                return (
                    <FreeField key={param.alias} param={param} lines={2.2}/>
                );
            }
            case "multi":{
                return (
                    <FreeField key={param.alias} param={param} lines={1}/>
                );
            }
        }
    }


    return (
        <li {...divProps} className={twMerge("flex flex-col pb-4", className)}>
            <div className="flex flex-col pb-4 rounded-[10px] shadow-md gap-4 bg-white">
                {/* Parte superior */}
                <div className="flex flex-row bg-primary-transparent-5 gap-4 py-[9px] px-4 items-center rounded-t-[10px]" onClick={()=>{
                    if (!pluginData.style.showLogos) return;
                    setShowLogo((prev)=>!prev);
                }}
                >
                    <div className="w-[50px] h-[50px] flex flex-shrink-0">
                        {orientatorInfo.userInfo.profilePic ? (
                            <img className="rounded-full w-full" src={orientatorInfo.userInfo.profilePic} alt="" />
                        ) : (
                            <Avatar/>
                        )}
                    </div>
                    <div className="flex flex-col justify-center shrink-0" style={{minHeight:"3lh", width:`calc(100% - ${showLogo ? "200" : "50"}px)`}}>
                        <div className="flex gap-1 items-center">
                            <p className="text-ug-lg font-semibold">{orientatorInfo.userInfo.name}</p>
                            <VerifiedBadge/>
                        </div>
                        <p className="line-clamp-2">{printAdditionalInfo(mainParam)}</p>
                    </div>
                    {showLogo && (
                        <div className="block">
                            <img className="h-auto w-full rounded-sm" src={clientLogo} alt={`Logo de ${clientName}`} />
                        </div>
                    )}
                </div>

                {/* Parte inferior */}
                <div className="flex flex-col mx-4 gap-3 justify-between h-full">
                    {/* Propiedades generales */}
                    <div className="flex flex-col gap-3">
                        {chipParams.length > 0 && (
                            <ul className="flex flex-wrap gap-1">
                                {chipParams.map((param)=>(
                                    <li className="bg-gray-cool-100 rounded-full text-ug-base px-2" key={param.alias}>{param.value}</li>
                                ))}
                            </ul>
                        )}

                        <ul className="flex flex-col gap-3">
                            {defaultParams.map(renderOption)}
                        </ul >
                    </div>
                    {orientatorInfo.userInfo._id === userData?._id ? (
                        <UnigowButton disabled variant="primary">¡Eres Tú!</UnigowButton>
                    ) : renderButton()}
                </div>
            </div>
        </li>
    );
}