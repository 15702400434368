import React, {useState, FormEvent, useEffect} from "react";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {ReactComponent as ChatIcon} from "@unigow/assets/chatIcon.svg";
import {EmailOutlined, WhatsApp} from "@mui/icons-material";
import {twMerge} from "tailwind-merge";
import {toast} from "react-toastify";
import {useChatsPluginStore} from "../../../../../stores/chatsPlugin.store";
import {ChatsPluginAPI} from "@unigow/apis/iframe";
import {useIframeWizardStore} from "../../../../../stores/iframeWizard.store";
import {CreateChatsPluginReq} from "@unigow/unigow-types";
import {useNavigate} from "react-router-dom";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";

function Step1(): React.ReactElement {
    const navigate = useNavigate();

    // STORES
    const {iframeData} = useIframeWizardStore();
    const {clientInfo} = useDashboardStore();
    const {setStep, pluginData, addNewPlugin, selectPlugin, setPluginData} = useChatsPluginStore();

    // STATES
    const [alias, setAlias] = useState<string>("");
    // const [privacy, setPrivacy] = useState<"public" | "private">(iframeData ? iframeData.settings.private ? "private" : "public" : "public");
    const [contact, setContact] = useState<string>("");

    useEffect(()=>{
        if (!pluginData) return;

        setAlias(pluginData.alias);
        setContact(pluginData.settings.contact);
    }, [pluginData]);

    // FUNCTIONS
    async function saveChanges(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!clientInfo) return;

        if (!iframeData) {
            navigate("/iframes");
            return;
        }

        if (!contact) {
            toast.error("Debes seleccionar una vía de contacto");
            return;
        }

        const newChatsPlugin: CreateChatsPluginReq = {
            alias:alias.toLowerCase(),
            settings:{
                private:false,
                contact
            }
        };

        if (pluginData) {
            // Existing plugin
            const response = await ChatsPluginAPI.updateChatsPlugin({pluginId:pluginData._id.toString(), data:newChatsPlugin});

            if (!response) {
                toast.error("Error al actualizar el Chat peer-to-peer");
                return;
            }

            setPluginData(response);

            setStep(2);

            return;
        }

        // New plugin
        const response = await ChatsPluginAPI.createChatsPlugin({iframe:iframeData._id.toString(), data:newChatsPlugin});

        if (!response) {
            toast.error("Error al crear el Chat peer-to-peer");
            return;
        }

        addNewPlugin({
            alias,
            _id:response.pluginId
        });

        selectPlugin(response.pluginId.toString());
    }

    return (
        <form className="flex flex-col gap-[15px] overflow-hidden" onSubmit={saveChanges}>
            <p className="font-light text-ug-base">Escoge la vía de contacto y define un alias para identificar el Chat peer-to-peer</p>
            <div className="flex flex-col gap-[10px]">
                <p className="font-semibold">Escoge la vía de contacto entre usuarios y embajadores</p>
                <div className="flex gap-[10px] flex-col lg:flex-row">
                    <div className={twMerge("px-[5px] cursor-pointer py-1 border border-solid border-gray-cool-400 rounded-[10px] justify-center flex flex-col items-center", contact === "chat" ? "border-primary-500 bg-primary-transparent-10" : "")}
                        onClick={()=>setContact("chat")}
                    >
                        <ChatIcon/>
                        <p className="text-ug-sm">Chat Unigow</p>
                    </div>
                    <div className={twMerge("px-[5px] cursor-pointer py-1 border border-solid border-gray-cool-400 rounded-[10px] justify-center flex flex-col items-center", contact === "email" ? "border-primary-500 bg-primary-transparent-10" : "")}
                        onClick={()=>setContact("email")}
                    >
                        <EmailOutlined color="primary"/>
                        <p className="text-ug-sm">Correo Electrónico</p>
                    </div>
                    <div className={twMerge("px-[5px] cursor-pointer py-1 border border-solid border-gray-cool-400 rounded-[10px] justify-center flex flex-col items-center", contact === "whatsapp" ? "border-primary-500 bg-primary-transparent-10" : "")}
                        onClick={()=>setContact("whatsapp")}
                    >
                        <WhatsApp className="text-[#40C351]"/>
                        <p className="text-ug-sm">Whatsapp</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-[10px]">
                <p className="font-semibold">&quot;Alias&quot; de tu Chat peer-to-peer</p>
                <UnigowInput required value={alias} onChange={(e)=>setAlias(e.target.value)} placeholder="Define un alias para identificar el Chat peer-to-peer"/>
            </div>
            {/* <div className="">
                <RadioGroup value={privacy} onChange={(e, v) => setPrivacy(v as "private" | "public")} className="gap-[2px]">
                    <FormControlLabel value="public" control={<Radio size="small" className="py-1"/>} label={
                        <Typography className="font-semibold text-ug-base" fontFamily="Montserrat">Conector público</Typography>
                    }
                    />
                    <FormControlLabel value="private" control={<Radio size="small" className="py-1"/>} label={
                        <div className="flex gap-[10px] items-center">
                            <Typography className="font-semibold text-ug-base flex-shrink-0" fontFamily="Montserrat">Conector privado</Typography>
                        </div>
                    }
                    />
                </RadioGroup>
                <p className="text-ug-sm text-gray-400 flex-shrink">Se suele utilizar para comunidades privadas como Alumni</p>
            </div> */}
            <div className="flex gap-[10px] flex-col lg:flex-row">
                <UnigowButton type="button" variant="basic" onClick={()=>setStep(0)}>Cancelar</UnigowButton>
                <UnigowButton variant="secondary" type="submit">Continuar al siguiente paso</UnigowButton>
            </div>
        </form>
    );
}

export default Step1;