import {FullGroup, NewGroup} from "@unigow/types/group";
import {GroupsPluginType} from "@unigow/unigow-types";
import {create} from "zustand";

type GroupsStore = {
    selectedGroupPage: string | undefined;

    selectGroupPage: (groupId: string | undefined)=> void;

    groupPageData: GroupsPluginType | undefined;

    setGroupPageData: (group: GroupsPluginType | undefined)=> void;

    modifyGroupPageData: <K extends keyof GroupsPluginType>(property: K, value: GroupsPluginType[K])=> void;

    groupPageGroups: FullGroup[];

    setGroupPageGroups: (groups: FullGroup[])=> void;

    selectedGroup: NewGroup | FullGroup | undefined;

    setSelectedGroup: (group: NewGroup | FullGroup | undefined)=> void;

    configPage: "config" | "groups";

    setConfigPage: (page: "config" | "groups")=> void;

    resetPluginData: ()=> void;
};

export const useGroupWizardStore = create<GroupsStore>((set, get) => ({
    selectedGroupPage: undefined,

    selectGroupPage: (groupId) => {
        if (groupId === "temp") {
            set({groupPageData: undefined});
        }
        set({selectedGroupPage: groupId});
    },

    groupPageData: undefined,

    setGroupPageData: (group) => {
        set({groupPageData: group});
    },

    modifyGroupPageData: (property, value) => {
        const {groupPageData} = get();
        if (!groupPageData) return;

        const updatedGroupPageData = {
            ...groupPageData,
            [property]: value
        };

        set({groupPageData: updatedGroupPageData});
    },

    groupPageGroups: [],

    setGroupPageGroups: (groups) => {
        set({groupPageGroups: groups});
    },

    selectedGroup: undefined,

    setSelectedGroup: (group) => {
        set({selectedGroup: group});
    },

    configPage: "config",

    setConfigPage: (page) => {
        set({configPage: page});
    },

    resetPluginData: () => {
        set({selectedGroupPage: undefined, groupPageData: undefined, groupPageGroups: [], selectedGroup: undefined});
    }
}));