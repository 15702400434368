import React, {Children} from "react";
import PopupWindow from "../../../../../components/PopupWindow/PopupWindow";
import {Lead} from "../../../../../types/log";

interface UserHistoryProps {
    user: Lead["user"] | undefined,
    clearUser: ()=> void
}

export function UserHistory({user, clearUser}: UserHistoryProps): React.ReactElement {
    return (
        <PopupWindow open={!!user} title={`Más información sobre ${ user?.name}`} onClose={clearUser}>
            <div className="flex flex-col gap-2">
                <h3>Ha interactuado con los embajadores de</h3>
                {!!user && (
                    <ul className="flex flex-col gap-2">
                        {Children.toArray(user.history.map((item)=>(
                            <li className="text-gray-cool-400 font-semibold">{item}</li>
                        )))}
                    </ul>
                )}
            </div>
        </PopupWindow>
    );
}