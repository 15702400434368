import React from "react";
import {twMerge} from "tailwind-merge";
import {ReactComponent as Unicoin} from "@unigow/assets/unicoin.svg";

interface RetoPriceProps {
    completed?: boolean;
    quantity: number;
}

export function RetoPrice({completed, quantity}: RetoPriceProps): React.ReactElement {
    return (
        <div className={twMerge("rounded-full px-2 py-1 text-sm font-semibold flex items-center gap-1", completed ? "bg-[#7FFFD1]" : "bg-[#F3F4F6]")}>
            Premio de {quantity} <Unicoin width={20} height={20}/>
        </div>
    );
}