// import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import React, {FormEvent, useEffect, useState} from "react";
import PageContainer from "../../../../components/PageContainer/PageContainer";
// import {ExpandMore, Info} from "@mui/icons-material";
import UnigowButton from "../../../../components/UnigowButton/UnigowButton";
import {useQuery} from "react-query";
import {api} from "../../../../apis/requests";
import ParamQuestions from "../../../../components/ParamQuestions/ParamQuestions";
import {validateField} from "../../../../helpers/helper";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {CreateOrientator} from "../../../../types/user";
import {useAuthStore} from "../../../../stores/authStore";
import {useDashboardStore} from "../../../../stores/dashboard/dashboardStore";
import {orientatorAnsweredQuestions} from "../../../../helpers/chat";
import {ChatIframe} from "@unigow/types/iframe";
import {ParameterType} from "@unigow/unigow-types";

export default function SuperIframe(): React.ReactElement {
    // MISCELLANEOUS
    const navigate = useNavigate();
    // Extraer el id del iframe de la query string
    const searchPams = new URLSearchParams(window.location.search);
    const iframe = searchPams.get("iframe");
    const notification = searchPams.get("notid");

    // STORES
    const {userData} = useAuthStore();
    const {selectedClient} = useDashboardStore();

    // STATES
    const [formData, setFormData] = useState<Record<string, string | string[]>>({});

    const handleFieldChange = (fieldName: string, value: string | string[] | undefined): void => {
        if (!value) {
            // Remove the field from the formData
            const newFormData = {...formData};
            delete newFormData[fieldName];
            setFormData(newFormData);
            return;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value
        }));
    };

    const {data:iframeData} = useQuery(["iframe-data", iframe], async()=>{
        return api.get<ChatIframe>(`iframes/${iframe}/data`);
    }, {enabled:!!iframe});

    const {data:paramsData = []} = useQuery(["parameters", iframe], async()=>{
        const params = await api.get<ParameterType[]>(`parameters/${iframeData!.client}/parameterInfo`);

        const {mentorParams} = iframeData!.settings;

        if (!mentorParams) return [];

        return params.filter((param)=>mentorParams.includes(param.alias));
    }, {enabled:!!iframeData});

    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        const createUserInfo: {
            info: Record<string, string | string[]>
        } = {
            info:formData
        };
        // Search for all the elements in formData, search those whose param has property validation and
        // validate with regex the expressión in that field
        // Travel formData Record with key and value
        try {
            validateField(formData, paramsData);
        } catch (err) {
            const error = err as Error;
            toast.error(error.message);
            return;
        }

        try {
            const newOrientator: CreateOrientator = {
                realClient:selectedClient
            };

            const res = await api.post<{info: Record<string, string | string[]>}, {info: Record<string, string>}>("userinfo", createUserInfo);

            try {
                await api.post<CreateOrientator, CreateOrientator>(`iframes/${iframe}/orientators`, newOrientator);
            } catch {
                // Do nothing
            }

            if (res) {
                await api.post(`notifications/${notification}/complete`, {});
                toast.success("Registrado con éxito");
                navigate("/chats");
            }
        } catch {
            navigate("/chats");
        }
    }

    // EFFECTS
    useEffect(()=>{
        async function createNewOrientator(): Promise<void> {
            const newOrientator: CreateOrientator = {
                realClient:selectedClient
            };

            try {
                await api.post<CreateOrientator, CreateOrientator>(`iframes/${iframe}/orientators`, newOrientator);
                await api.post(`notifications/${notification}/complete`, {});
                toast.success("Registrado con éxito");
                navigate("/chats");
            } catch {
                navigate("/chats");
            }
        }

        if (!userData || !iframeData) return;

        if (userData.additionalInfo) {
            if (orientatorAnsweredQuestions(iframeData?.settings.mentorParams, userData.additionalInfo)) {
                void createNewOrientator();
            }
        }
    }, [userData, iframeData, iframe, selectedClient, navigate, notification]);

    // EFFECTS
    useEffect(()=>{
        if (userData) {
            if (userData.additionalInfo) {
                setFormData(userData.additionalInfo);
            }
        }
    }, [userData]);

    return (
        <PageContainer className="px-4 items-start" style={{alignItems:"unset"}}>
            <div className="flex flex-col gap-3 items-start w-full">
                <h1>Necesitamos conocerte un poco más</h1>
            </div>
            {/* <div>
                <Accordion
                    className="rounded-xl my-4 py-2 shadow-none"
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore className="text-primary-500"/>}
                        aria-controls="more-info"
                    >
                        <div className="flex items-center gap-2">
                            <Info className="text-primary-500"/>
                            <p className="text-lg font-semibold">¿En qué consistirá este proyecto?</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            El proyecto consiste en desarrollar una plataforma de comunicación entre estudiantes y orientadores,
                            para que los estudiantes puedan resolver sus dudas sobre la universidad, la carrera o cualquier
                            otra duda que tengan sobre su futuro profesional.
                        </p>
                    </AccordionDetails>
                </Accordion>
            </div> */}
            <div className="bg-white p-[30px] gap-[30px] flex flex-col lg:w-5/6 rounded-[10px]">
                <form className="flex flex-col gap-4 lg:w-8/12" onSubmit={handleSubmit}>
                    <ParamQuestions params={paramsData} formData={formData}
                        handleFieldChange={handleFieldChange} className="flex flex-col gap-4"
                    />
                    <div>
                        <UnigowButton variant="secondary">Guardar datos</UnigowButton>
                    </div>
                </form>
            </div>
        </PageContainer>
    );
}