import {api} from "@unigow/apis/requests";
import GroupCard from "@unigow/components/GroupCard/GroupCard";
import JoinGroupButton from "../components/components/JoinGroupButton";
import {DisplayGroup} from "@unigow/types/group";
import React, {Children} from "react";
import {useQuery} from "react-query";
import {GroupsPluginType, IframeType} from "@unigow/unigow-types";

interface GroupsSearchProps {
    pluginData: GroupsPluginType,
    iframeData: IframeType
}

export default function GroupsSearch({pluginData, iframeData}: GroupsSearchProps): React.ReactElement {
    const {data = []} = useQuery(["groups", pluginData._id], ()=>{
        return api.get<DisplayGroup[]>(`grouppages/${pluginData._id}/groups`);
    }, {enabled:!!pluginData._id});

    return (
        <div className="flex flex-col items-center w-11/12 lg:w-full mx-auto gap-7">
            <ul className="flex flex-wrap gap-4 w-full">
                {Children.toArray((data).map((group)=>(
                    <li className="w-full md:w-[calc(50%-1rem)] relative">
                        <GroupCard color={iframeData.style.mainColor} groupInfo={group} ActionButton={(
                            <JoinGroupButton currGroup={group}/>
                        )}
                        />
                    </li>
                )
                ))}
            </ul>
        </div>
    );
}