import {CardWithParticipants} from "@unigow/types/card";
import {CardsPluginType} from "@unigow/unigow-types";
import {create} from "zustand";

type CardsStore = {
    selectedCardPage: string | undefined;

    selectCardPage: (cardId: string | undefined)=> void;

    cardPageData: CardsPluginType | undefined;

    setCardPageData: (card: CardsPluginType | undefined)=> void;

    modifyCardPageData: <K extends keyof CardsPluginType>(property: K, value: CardsPluginType[K])=> void;

    cardPageCards: CardWithParticipants[] | undefined;

    setCardPageCards: (Cards: CardWithParticipants[])=> void;

    selectedCard: Pick<CardWithParticipants, "name"> | CardWithParticipants | undefined;

    selectCard: (card: Pick<CardWithParticipants, "name"> | CardWithParticipants | undefined)=> void;

    modifyCard: <K extends keyof CardWithParticipants>(property: K, value: CardWithParticipants[K])=> void;

    configPage: "config" | "cards";

    setConfigPage: (page: "config" | "cards")=> void;

    resetPluginData: ()=> void;
};

export const useCardWizardStore = create<CardsStore>((set, get) => ({
    selectedCardPage: undefined,

    selectCardPage: (cardId) => {
        if (cardId === "temp") {
            set({cardPageData: undefined});
        }
        set({selectedCardPage: cardId});
    },

    cardPageData: undefined,

    setCardPageData: (card) => {
        set({cardPageData: card});
    },

    modifyCardPageData: (property, value) => {
        const {cardPageData} = get();
        if (!cardPageData) return;

        const updatedCardPageData = {
            ...cardPageData,
            [property]: value
        };

        set({cardPageData: updatedCardPageData});
    },

    cardPageCards: undefined,
    setCardPageCards: (Cards) => {
        set({cardPageCards: Cards});
    },

    selectedCard: undefined,
    selectCard: (card) => {
        set({selectedCard: card});
    },
    modifyCard: (property, value) => {
        const {selectedCard} = get();
        if (!selectedCard) return;

        const updatedCard = {
            ...selectedCard,
            [property]: value
        };

        set({selectedCard: updatedCard});
    },

    configPage: "config",
    setConfigPage: (page) => {
        set({configPage: page});
    },

    resetPluginData: () => {
        set({selectedCardPage: undefined, cardPageData: undefined, cardPageCards: undefined, selectedCard: undefined});
    }
}));