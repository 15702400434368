import {Check} from "@mui/icons-material";
import React from "react";
import {formatTime} from "../../../../../helpers/helper";
import {twMerge} from "tailwind-merge";

interface WhatsappMsgProps {
    message: {
        sender: "unigow" | "user",
        body: string,
        sentDate: Date
    }
}

export function WhatsappMsg({message}: WhatsappMsgProps): React.ReactElement {
    const {sender, body, sentDate} = message;

    const positionClass = sender === "unigow" ? "justify-end" : "";
    const designClass = sender === "unigow" ? "bg-primary-transparent-10 rounded-l-xl" : "bg-gray-cool-100 rounded-r-xl";

    return (
        <li id="message" className={`w-full flex relative ${positionClass} group`}>
            <div className={`${designClass} px-[37px] py-[15px] rounded-t-xl text-left leading-6 w-fit max-w-[50vw] break-words gap-3 flex flex-col`}>
                <p className={twMerge("text-ug-lg text-black", sender === "unigow" ? "text-right" : "text-left")}>{body}</p>
                <div className="w-full flex justify-between gap-4">
                    {sender === "unigow" && (
                        <div className="flex justify-end w-full">
                            <p className="text-gray-cool-400 text-ug-base flex items-center gap-1">
                                <Check className="text-primary-500"/>
                                {formatTime(sentDate || new Date(), "short")}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </li>
    );
}