import {Accordion, AccordionDetails, AccordionSummary, TextareaAutosize} from "@mui/material";
import React, {Children, useState} from "react";

import {ReactComponent as CheckIcon} from "@unigow/assets/checked.svg";
import {ReactComponent as LoadingIcon} from "@unigow/assets/staticLoading.svg";
import {RetoObject, UserAnswer} from "@unigow/types/retos";
import {RetoPrice} from "./RetoPrice";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {api} from "@unigow/apis/requests";
import {toast} from "react-toastify";
import {useDashboardStore} from "../../../../../stores/dashboard/dashboardStore";

interface RetoProps {
    reto: RetoObject;
    defaultOpen?: boolean;
}

export function Reto({reto, defaultOpen}: RetoProps): React.ReactElement {
    const {title, description, answered, questions} = reto;
    const {clientInfo} = useDashboardStore();
    const [open, setOpen] = useState(defaultOpen || false);
    const [finished, setFinished] = useState(answered);
    const [answers, setAnswers] = useState<Record<string, string>>({});

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        // Create the answers object
        const answersArray: UserAnswer[] = Object.entries(answers).map(([alias, answer])=>({
            alias,
            answer
        }));

        const response = await api.post<{answers: UserAnswer[]}, unknown>(`retosanswers/${reto._id}`, {answers:answersArray});

        if (response) {
            toast.success("Respuestas guardadas correctamente");
            setOpen(false);
            setFinished(true);
        }
    }

    return (
        <Accordion expanded={open} onChange={()=>{
            if (finished) return;

            setOpen((prev)=>!prev);
        }}
        >
            <AccordionSummary>
                <div className="flex items-center gap-4">
                    <div>
                        {finished ? (
                            <CheckIcon/>
                        ) : (
                            <LoadingIcon className="text-gray-cool-400"/>
                        )}
                    </div>
                    <div className="flex gap-2 items-center">
                        <h2 className="flex-shrink-0">{title}</h2>
                        {clientInfo?.incentives && (
                            <RetoPrice completed={finished} quantity={reto.award}/>
                        )}
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className="flex flex-col gap-4">
                    <p>{description}</p>
                    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                        {Children.toArray(questions.map((question)=>(
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-1">
                                    <h3>{question.question}</h3>
                                    <p className="text-sm text-gray-500">{question.description}</p>
                                </div>
                                <TextareaAutosize required className="border-gray-300 rounded-xl text-gray-cool-500 text-ug-lg" placeholder={question.placeholder} minRows={question.lines || 1} onChange={(e)=>{
                                    const questionAlias = question.alias;
                                    const {value} = e.target;

                                    if (value === "") {
                                        // Remove the alias from the answers object
                                        const newAnswers = {...answers};

                                        delete newAnswers[questionAlias];

                                        setAnswers(newAnswers);

                                        return;
                                    }

                                    setAnswers((prevAnswers)=>({
                                        ...prevAnswers,
                                        [questionAlias]: value
                                    }));
                                }}
                                />
                            </div>
                        )))}
                        <div>
                            <UnigowButton variant="secondary">Guardar respuestas</UnigowButton>
                        </div>
                    </form>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}