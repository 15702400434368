import {LinearProgress, MenuItem} from "@mui/material";
import React, {useState, useEffect} from "react";
import UnigowSelect from "../../../../components/UnigowSelect/UnigowSelect";
import UnigowButton from "../../../../components/UnigowButton/UnigowButton";
import UnigowInput from "../../../../components/UnigowInput/UnigowInput";
import {toast} from "react-toastify";
import PopupWindow from "../../../../components/PopupWindow/PopupWindow";
import {useSearchParams} from "react-router-dom";
import * as Sentry from "@sentry/react";
import useUnigowStore from "../../../../stores/unigowStore";

function Support(): React.ReactElement {
    const {support, closeSupport} = useUnigowStore();
    const [URLSearchParams, setURLSearchParams] = useSearchParams();
    const [questionType, setQuestionType] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [percent, setPercent] = useState(0);
    const [sending, setSending] = useState(false);

    useEffect(()=>{
        setQuestionType(URLSearchParams.get("type") || "");
    }, [URLSearchParams]);

    function closeSupportPopup(): void {
        setURLSearchParams();
        closeSupport();
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        setSending(true);
        e.preventDefault();
        setPercent(33);

        try {
            setPercent(50);
            // Función para correo transaccional
            toast.success("Hemos recibido el ticket con éxito, responderemos lo antes posible", {className:"unigow-toast"});
            setPercent(100);
        } catch (error) {
            Sentry.captureException(error);
            setPercent(100);
            toast.error("Ha habido un error, prueba más tarde", {autoClose: 4000, className:"unigow-toast"});
        }
        setURLSearchParams();
        setSending(false);
        closeSupportPopup();
    }

    return (
        <PopupWindow title="Soporte de Unigow" open={support} onClose={closeSupportPopup} closePopup={closeSupport}>
            <form className="w-full flex justify-center flex-col gap-4" onSubmit={handleSubmit} onKeyDown={(e)=>{
                if (e.key === "Enter" && !e.shiftKey) {
                    void handleSubmit(e);
                }
            }}
            >
                <div className="flex items-center lg:gap-2 flex-col lg:flex-row">
                    <label htmlFor="type" className="text-primary w-32">Consulta: </label>
                    <UnigowSelect className="shrink-0 grow" required id="type" value={questionType} onChange={(e)=>setQuestionType(e.target.value)} label="Tipo de consulta">
                        <MenuItem value="" disabled>-- Elige un tipo de consulta --</MenuItem>
                        <MenuItem value="section">Solicitar sección personalizada</MenuItem>
                        <MenuItem value="platform">Funcionamiento Plataforma</MenuItem>
                        <MenuItem value="error">Reportar Error</MenuItem>
                        <MenuItem value="sugerencias">Dar Sugerencia</MenuItem>
                        <MenuItem value="feedback">Feedback :)</MenuItem>
                        <MenuItem value="eliminar">Eliminar cuenta :/</MenuItem>
                        <MenuItem value="other">Otro</MenuItem>
                    </UnigowSelect>
                </div>
                <div className="flex items-center gap-2 flex-col lg:flex-row">
                    <label htmlFor="subject" className="text-secondary w-32">Asunto: </label>
                    <UnigowInput required className="shrink-0 grow" id="subject" type="text" value={subject} onChange={(e)=>setSubject(e.target.value)}/>
                </div>
                <div className="flex items-center gap-2 flex-col lg:flex-row w-full">
                    <label htmlFor="subject" className="text-secondary w-32">Descripción: </label>
                    <textarea required className="rounded-lg border-secondary border-2 shrink-0 grow" value={description} onChange={(e)=>setDescription(e.target.value)}
                        onKeyDown={(e)=>{
                            if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                            }
                        }}
                    />
                </div>
                <p className="text-xs text-primary">Tip: Pulsa la tecla &quot;shift&quot; junto con &quot;Enter&quot; para añadir una nueva línea.</p>
                <UnigowButton variant="secondary">Enviar consulta</UnigowButton>
                {sending && <LinearProgress value={percent} color="secondary" />}
            </form>
        </PopupWindow>
    );
}

export default Support;