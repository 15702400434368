import React, {FormEvent, Fragment, useEffect, useState} from "react";
import PopupWindow from "@unigow/components/PopupWindow/PopupWindow";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {Edit} from "@mui/icons-material";
import {LandingEditorProps} from "../LandingCreator";
import {api} from "@unigow/apis/requests";
import {toast} from "react-toastify";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import {LandingType} from "@unigow/unigow-types";

function EditSubtitle(props: LandingEditorProps): React.ReactElement {
    const {landingInfo, modifyLandingData} = props;
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState("");

    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!landingInfo) return;

        const res = await api.patch<Partial<LandingType>, LandingType>(`landing/${landingInfo._id}`, {description});

        if (res) {
            toast.success("Cambios guardados con éxito");
            modifyLandingData("description", description);
            setOpen(false);
        }
    }

    useEffect(()=>{
        if (landingInfo && landingInfo.description) {
            setDescription(landingInfo.description);
        }
    }, [landingInfo]);

    return (
        <Fragment>
            <UnigowButton className="py-0 px-3 flex items-center gap-2" variant="primary" onClick={()=>setOpen(true)}>
                <Edit className="w-[18px]"/>
            </UnigowButton>
            <PopupWindow fullWidth open={open} closePopup={()=>setOpen(false)} title="Edita el subtítulo de la Landing Page">
                <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
                    <div className="flex flex-col gap-[10px]">
                        <p className="text-ug-base font-semibold">Subtítulo</p>
                        <UnigowInput value={description} onChange={(e)=>setDescription(e.target.value)}/>
                    </div>
                    <UnigowButton disabled={description === ""} variant="secondary">Guardar Subtítulo</UnigowButton>
                </form>
            </PopupWindow>
        </Fragment>
    );
}

export default EditSubtitle;