import React, {useEffect, useState} from "react";
import {ReactComponent as UnigowLogo} from "../../assets/unigow-black.svg";
import {LinearProgress} from "@mui/material";
import UnigowButton from "../../components/UnigowButton/UnigowButton";
import {useDashboardStore} from "../../stores/dashboard/dashboardStore";
import {useNavigate} from "react-router-dom";
import {useAuthStore} from "../../stores/authStore";
import {orientatorAnsweredQuestions} from "../../helpers/chat";
import UserForm from "@unigow/pages/Iframe/pages/Form/UserForm";

export default function OrientatorRegister(): React.ReactElement {
    // MISCELLANEOUS
    const navigate = useNavigate();

    // STORES
    const {availableChatPlugins} = useDashboardStore();
    const {userData} = useAuthStore();
    const [progress, setProgress] = useState(66);

    // EFFECTS
    useEffect(()=>{
        if (!availableChatPlugins.length) {
            // La página se ha recargado y ha perdido el contexto, volver al dashboard
            navigate("/");
        }
    }, [availableChatPlugins, navigate]);

    useEffect(()=>{
        if (!availableChatPlugins) return;

        const allParams = availableChatPlugins.map((plugin)=>plugin.settings.mentorParams).flat()
            .filter((param, index, self)=>self.findIndex((p)=>p === param) === index);

        const mentorAnsweredQuestions = orientatorAnsweredQuestions(allParams, userData?.additionalInfo);

        if (!mentorAnsweredQuestions || !userData?.phone || userData.phone === "") return;
        setProgress(100);

        // Wait half a second
        setTimeout(()=>{
            navigate("/");
        }, 500);
    }, [availableChatPlugins, userData, navigate]);

    return (
        <div className="absolute inset-0 bg-gradient-to-b from-black from-[65%] to-primary-500">
            <div className="absolute max-h-[85%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white lg:w-1/3 w-[90%] rounded-2xl py-8 lg:px-28 px-4 flex flex-col gap-4 shadow-ug-xl items-center overflow-y-auto">
                <div className="flex justify-center">
                    <UnigowLogo />
                </div>
                <div className="flex justify-center">
                    <h2>Regístrate como Embajador</h2>
                </div>
                <div className="w-full">
                    <LinearProgress value={progress} variant="determinate"
                        className="h-[6px] rounded-full"
                        sx={{
                            background: "#E0EDFF",
                            "> span": {background: "linear-gradient(107deg, #8CD2FD 0%, #394DE7 33.85%, #0028C6 58.33%, #6AC0F4 100%)", borderRadius:"9999px"}
                        }}
                    />
                </div>
                <UserForm neededParams={availableChatPlugins.map((plugin)=>plugin.settings.mentorParams).flat()
                    .filter((param, index, self)=>self.findIndex((p)=>p === param) === index) || []} SubmitButton={
                    (
                        <div className="w-full">
                            <UnigowButton className="w-full" type="submit" variant="secondary">Guardar Cambios</UnigowButton>
                        </div>
                    )
                } registerPage requirePhone
                />
            </div>
        </div>
    );
}