import React, {useEffect, useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import {twMerge} from "tailwind-merge";

import "@unigow/styles/stepsstyles.css";
import {toast} from "react-toastify";
import CardContainer from "@unigow/components/GroupCard/components/CardContainer";
import CardTop from "@unigow/components/GroupCard/components/CardTop";
import CardBottom from "@unigow/components/GroupCard/components/CardBottom";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {useGroupWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/groupWizardStore";
import Step1 from "@unigow/pages/Dashboard/pages/Iframes/tabs/Groupsplugin/sections/GroupConfig/components/Step1";
import Step2 from "@unigow/pages/Dashboard/pages/Iframes/tabs/Groupsplugin/sections/GroupConfig/components/Step2";
import {useIframeWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/iframeWizard.store";

export interface StepProps {
    setStep: (step: number)=> void;
    step: number;
}

export default function GroupConfig(): React.ReactElement {
    const {iframeData} = useIframeWizardStore();
    const {groupPageData, groupPageGroups, setConfigPage} = useGroupWizardStore();
    const [step, setStep] = useState(0);

    const step1Ref = useRef<HTMLDivElement>(null);
    const step2Ref = useRef<HTMLDivElement>(null);

    useEffect(()=>{
        if (!groupPageData && step === 0) {
            setStep(1);
        }
    }, [groupPageData, step]);

    useEffect(()=>{
        // If grouppage changes or the component is unmounted, reset the step
        return ()=>{
            setStep(0);
        };
    }, [groupPageData]);

    function changeStep(newStep: number): void {
        if (newStep >= 2) {
            if (!groupPageData || !groupPageData.alias || groupPageData.alias === "") {
                toast.error("Debes seleccionar un nombre para el conjunto de grupos");
                return;
            }

            setStep(2);
        }

        setStep(newStep);
    }

    return (
        <div className="flex gap-8 flex-col lg:flex-row w-full">
            <div className="flex flex-col lg:w-[55%] w-full shrink-0 gap-[14px] pr-4">
                <div className={twMerge("flex flex-col rounded-[10px] border border-solid border-gray-cool-400 px-[26px] py-2 gap-1", step !== 1 ? "pb-2 cursor-pointer" : "pb-4")}>
                    <h2 className={step !== 1 ? "text-gray-cool-500" : ""} onClick={()=>changeStep(1)}>1. Elegir la configuración de los grupos</h2>
                    <CSSTransition nodeRef={step1Ref} classNames="wizardstep" timeout={500} in={step === 1} unmountOnExit>
                        <div ref={step1Ref}>
                            <Step1 setStep={setStep} step={step}/>
                        </div>
                    </CSSTransition>
                </div>
                <div className={twMerge("flex flex-col rounded-[10px] border border-solid border-gray-cool-400 px-[26px] py-2 gap-1", step !== 2 ? "pb-2 cursor-pointer" : "pb-4")}>
                    {step !== 2 && (
                        <h2 className={step !== 4 ? "text-gray-cool-500" : ""} onClick={()=>changeStep(2)}>2. Definir los datos que se piden al usuario</h2>
                    )}
                    <CSSTransition nodeRef={step2Ref} classNames="wizardstep" timeout={500} in={step === 2} unmountOnExit>
                        <div ref={step2Ref}>
                            <Step2 setStep={setStep} step={step}/>
                        </div>
                    </CSSTransition>
                </div>
            </div>
            <div className="flex items-center text-left flex-grow flex-col">
                <div className="flex justify-between w-full items-center mb-2">
                    <p className="text-xs font-semibold">Vista previa</p>
                    {groupPageData?.alias && (
                        <UnigowButton className="py-0 text-xs" variant="basic" onClick={()=>{
                            setConfigPage("groups");
                        }}
                        >Editar o crear grupos
                        </UnigowButton>
                    )}
                </div>
                <div className="w-full">
                    {groupPageGroups && groupPageGroups.length > 0 ? (
                        <CardContainer>
                            <CardTop color={iframeData?.style.mainColor} name={groupPageGroups[0].name}
                                iconUrl={groupPageGroups[0].iconUrl} subtitle="Número de participantes"
                            />

                            <CardBottom description={groupPageGroups[0].description} fields={groupPageGroups[0].fields}/>

                            <div className="px-4">
                                <UnigowButton buttonType="button" ref={(node)=>{
                                    if (node) {
                                        node.style.setProperty("background-color", iframeData?.style.mainColor || "", "important");
                                    }
                                }} className={"w-full"} variant="primary"
                                >Quiero unirme al grupo
                                </UnigowButton>
                            </div>
                        </CardContainer>
                    ) : (
                        <CardContainer>
                            <CardTop name="Título del grupo" iconUrl="" subtitle="Número de participantes"/>

                            <CardBottom description="Aquí podrás escribir una descripción atractiva para animar a que las personas entren" fields={[]}/>

                            <div className="px-4">
                                <UnigowButton className="w-full" variant="primary">Quiero unirme al grupo</UnigowButton>
                            </div>
                        </CardContainer>
                    )}
                </div>
            </div>
        </div>
    );
}