import React, {useEffect} from "react";
import UnigowButton from "../../../../components/UnigowButton/UnigowButton";
import {useIframe} from "../../contexts/IframeContext";
import {CreateAproval, CreateChat} from "../../../../types/chat";
import {createAprovalHelper, createChatHelper, joinGroupHelper, userAnsweredQuestions} from "../../../../helpers/chat";
import {getLocalStorage, getLocalStorageObj} from "../../../../helpers/localstorage";
import {Checkbox} from "@mui/material";
import UserForm from "./UserForm";
import {useIframeStore} from "@unigow/stores/iframeStore";
import {useAuthStore} from "@unigow/stores/authStore";
import {joinActivityHelper} from "@unigow/helpers/cards";
import {useCustomLocale} from "@unigow/contexts/CustomLocaleContext";

export default function IframeQuestions(): React.ReactElement {
    // STORES
    const {getRequiredParams} = useCustomLocale();
    const {setCurrentPage} = useIframe();
    const {userData} = useAuthStore();
    const {iframeData, setLoading, pendingAction, setPendingAction} = useIframeStore();

    useEffect(()=>{
        async function createChat(createChatDto: CreateChat, goToChat: (sid: string)=> void): Promise<void> {
            setLoading(true);
            await createChatHelper(createChatDto, goToChat, ()=>setPendingAction(undefined));
            setLoading(false);
        }

        async function createAproval(createAprovalDto: CreateAproval): Promise<void> {
            await createAprovalHelper(createAprovalDto, ()=>setPendingAction(undefined));
        }

        if (!iframeData || !userData || !pendingAction) return;
        // Si el usuario no ha respondido las preguntas se queda en esta página

        const {action, data, ...extraData} = pendingAction;

        const privacyAcceptedRaw = getLocalStorageObj<string[]>("privacyAccepted");

        const unansweredQuestions = userAnsweredQuestions(getRequiredParams, userData.minor,  extraData.userParams, userData.additionalInfo);

        if (unansweredQuestions.length > 0 || (extraData.needsPhone && (!userData.phone || userData.phone === "")) || (!!extraData.privacy && (!privacyAcceptedRaw ||
            !privacyAcceptedRaw.includes(extraData.privacy.clientId)))) return;

        switch (action) {
            case "chat":{
                void createChat(data, (sid: string)=>setCurrentPage(`chat-${sid}`));
                break;
            }
            case "aproval":{
                void createAproval(data);
                break;
            }
            case "card":{
                void joinActivityHelper(data, setCurrentPage, ()=>setPendingAction(undefined));
                break;
            }
            case "group":{
                void joinGroupHelper(data, (v)=>setCurrentPage(`group-${v}`));
                break;
            }
        }
        return;
    }, [iframeData, pendingAction, userData, setCurrentPage, setLoading, setPendingAction, getRequiredParams]);

    function renderButton(): React.ReactElement {
        if (!!iframeData && !!pendingAction?.privacy) {
            return (
                <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-4">
                        <Checkbox
                            id="privacy"
                            required
                            className="p-0"
                            onChange={(e)=>{
                                // If checked add the client id to the accepted array in local storage
                                if (e.target.checked) {
                                    if (!pendingAction.privacy) return;
                                    const privacyAccepted = getLocalStorageObj<string[]>("privacyAccepted") || [];

                                    if (!privacyAccepted.includes(pendingAction.privacy.clientId.toString())) {
                                        privacyAccepted.push(pendingAction.privacy.clientId.toString());
                                    }

                                    localStorage.setItem("privacyAccepted", JSON.stringify(privacyAccepted));
                                } else {
                                    if (!pendingAction.privacy) return;
                                    const privacyAccepted = getLocalStorageObj<string[]>("privacyAccepted") || [];

                                    if (privacyAccepted.includes(pendingAction.privacy.clientId.toString())) {
                                        privacyAccepted.splice(privacyAccepted.indexOf(pendingAction.privacy.clientId.toString()), 1);
                                    }

                                    localStorage.setItem("privacyAccepted", JSON.stringify(privacyAccepted));

                                }
                            }}
                        />
                        <label htmlFor="privacy" className="text-ug-sm select-none">
                            Entiendo que mis datos personales pueden ser procesados por {pendingAction.privacy.clientName} de acuerdo con su
                            <a rel="noopener noreferrer" target="_blank" className="underline font-medium text-black" href={pendingAction.privacy.privacyLink}> Política de Privacidad</a>.
                        </label>
                    </div>
                    <UnigowButton variant="primary">{getLocalStorage("pendingChat") ? "Acceder al chat" : "Finalizar registro"}</UnigowButton>
                </div>
            );
        }
        return (
            <UnigowButton variant="primary">{getLocalStorage("pendingChat") ? "Acceder al chat" : "Finalizar registro"}</UnigowButton>
        );
    }

    if (!pendingAction) {
        return <></>;
    }

    return (
        <div className="w-10/12 mx-auto flex flex-col gap-4">
            <h3>Cuéntanos un poco más sobre ti</h3>
            <UserForm neededParams={pendingAction.userParams} requirePhone={pendingAction.needsPhone} className="flex flex-col gap-4" SubmitButton={renderButton()} registerPage/>
        </div>
    );
}