import React, {HTMLAttributes, useState} from "react";
import {twMerge} from "tailwind-merge";

interface ReadMoreProps {
    text: string
    characters?: number
}

export function ReadMore({text, characters = 36, ...textProps}: ReadMoreProps & HTMLAttributes<HTMLParagraphElement>): React.ReactElement {
    const [isExpanded, setIsExpanded] = useState(false);
    const itCanOverflow = text.length > characters;

    // Get first characters of the text
    const beginText = itCanOverflow ? text.substring(0, characters) : text;

    return (
        <p {...textProps} className={twMerge(textProps.className)} style={{wordBreak:"break-word"}}>
            {beginText}
            {itCanOverflow && (
                <>
                    {!isExpanded && <span>... </span>}
                    <span
                        className={`${!isExpanded && "hidden"}`}
                        aria-hidden={!isExpanded}
                    >
                        {text.substring(characters)}
                    </span>
                    <span
                        className="cursor-pointer text-gray-cool-500 text-xs hover:underline ml-2"
                        role="button"
                        tabIndex={0}
                        aria-expanded={isExpanded}
                        aria-controls={textProps.id || "read-more"}
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {isExpanded ? "Mostrar menos" : "Mostrar más"}
                    </span>
                </>
            )}
        </p>
    );
}