import {api} from "@unigow/apis/requests";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import BasicPage from "@unigow/pages/Functional/BasicPage";
import React, {FormEvent, useState} from "react";
import {useSearchParams} from "react-router-dom";

export default function CancelActivity(): React.ReactElement {
    const [searchParams] = useSearchParams();

    const [result, setResult] = useState<"success" | "error" | "cancelled" | null>(null);

    const activityId = searchParams.get("activity");
    const userId = searchParams.get("user");
    const activityName = searchParams.get("name");
    const cancelToken = searchParams.get("token");
    const slotId = searchParams.get("slot");

    async function leaveActivity(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        const body = {
            cancelToken,
            user: userId,
            slotId: slotId ? slotId : undefined
        };

        await api.post<typeof body, {message: string}>(`cards/${activityId}/cancel`, body).catch(()=>{
            setResult("error");
        }).then((d)=>{
            if (!d) return;
            setResult("success");
        });
    }

    function renderMessage(): string {
        switch (result!) {
            case "success": return "Tu reserva ha sido cancelada con éxito";
            case "error": return "Ha ocurrido un error al cancelar tu reserva";
            case "cancelled": return "Acción cancelada";
        }
    }

    return (
        <BasicPage>
            {!result ? (
                <form className="flex flex-col rounded-md p-6 border border-solid border-gray-cool-200 w-10/12 sm:w-[480px] gap-4" onSubmit={leaveActivity}>
                    <div>
                        <h3 className="text-lg font-semibold">Estás a punto de cancelar tu inscripción a: <br/> {activityName}</h3>
                        <p className="text-sm text-gray-cool-500">Una vez que hayas cancelado, se liberará tu plaza</p>
                    </div>

                    <div className="gap-2 md:gap-4 flex flex-col-reverse md:grid grid-cols-4">
                        <UnigowButton className="col-span-1" variant="basic" type="button" onClick={()=>{
                            setResult("cancelled");
                        }}
                        >Cancelar
                        </UnigowButton>
                        <UnigowButton className="col-span-3" variant="primary" type="submit">Confirmo que quiero cancelar mi inscripción</UnigowButton>
                    </div>
                </form>
            ) : (
                <p><span className="font-semibold">{renderMessage()}</span>, puedes cerrar esta pestaña</p>
            )}
        </BasicPage>
    );
}