import React, {Fragment, useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {formatBytes} from "../../helpers/helper";
import PopupWindow from "../PopupWindow/PopupWindow";
import {Crop, PercentCrop, centerCrop, makeAspectCrop, ReactCrop, PixelCrop} from "react-image-crop";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "@unigow/firebase";
import UnigowButton from "../UnigowButton/UnigowButton";
import {LinearProgress} from "@mui/material";
import "react-image-crop/dist/ReactCrop.css";
import * as Sentry from "@sentry/react";

interface ImageUploaderProps {
    children: React.ReactNode,
    variableSetter: (v: React.SetStateAction<string>)=> void,
    firebaseSufix: string,
    firebaseFolder: string,
    name?: string;
    aspectRatio?: number,
    buttonText: string,
    rounded?: boolean
}

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
): PercentCrop {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 95
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

export default function ImageUploader({children, variableSetter, firebaseSufix, firebaseFolder,
    aspectRatio, buttonText, rounded, name}: ImageUploaderProps): React.ReactElement {
    // REFS
    const fileUploadPopup = useRef<HTMLInputElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);

    // STATES
    const [cachedImage, setCachedImage] = useState("");
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [crop, setCrop] = useState<Crop>();
    const [uploading, setUploading] = useState(false);
    const [percent, setPercent] = useState(0);
    const [fileType, setFileType] = useState("image/jpeg");

    // FUNCTIONS
    function activatePopup(): void {
        fileUploadPopup.current?.click();
    }

    function handleImageSelection(e: React.ChangeEvent<HTMLInputElement>): void {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > 10240 * 1024) {
                toast.error(`El archivo debe pesar menos de ${formatBytes(10240 * 1024)}`);
                return;
            }
            if (e.target.files[0]) {
                // Check the file type is an image
                if (!e.target.files[0].type.includes("image")) {
                    toast.error("El archivo debe ser una imagen");
                    return;
                }
                setFileType(e.target.files[0].type);
                setCachedImage(URL.createObjectURL(e.target.files[0]));
            } else {
                setCachedImage("");
            }
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>): void {
        const {width, height} = e.currentTarget;
        const defaultAspectCrop = centerAspectCrop(width, height, aspectRatio || rounded ? 1 : 16 / 9);
        setCrop(defaultAspectCrop);
        setCompletedCrop({x:0, y:0, unit:"px", width, height});
    }

    async function uploadToFirebase(): Promise<void> {
        const image = imgRef.current;
        const previewCanvas = previewCanvasRef.current;

        if (!image || !previewCanvas || !completedCrop) {
            throw new Error("Crop canvas does not exist");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const offscreen = document.createElement("canvas");
        offscreen.width = completedCrop.width * scaleX;
        offscreen.height = completedCrop.height * scaleY;

        const ctx = offscreen.getContext("2d");

        if (!ctx) {
            throw new Error("No 2d context");
        }

        // Get file extension

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height
        );
        try {
            const blob = await new Promise<Blob>((resolve, reject) => {
                offscreen.toBlob((blobAux) => {
                    if (blobAux) {
                        resolve(blobAux);
                    } else {
                        reject(new Error("Error al recortar la imagen."));
                    }
                }, fileType);
            });

            // Generate name based on timestamp + random string (16 characters in total)
            const fileName = name || Date.now().toString() + Math.random().toString(16).substring(2, 8);

            const profileRef = ref(storage, `${firebaseFolder}/${fileName}-${firebaseSufix}.webp`);
            const uploadTask = uploadBytesResumable(profileRef, blob);
            setUploading(true);
            uploadTask.on(
                "state_changed",
                (snapshot)=>{
                    setPercent(Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                (err) => console.log(err),
                async()=>{
                    try {
                        const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
                        if (variableSetter) {
                            variableSetter(imageUrl);
                        }
                        setCachedImage("");
                        setCompletedCrop(undefined);
                        setUploading(false);
                        setPercent(0);
                        if (fileUploadPopup.current) {
                            fileUploadPopup.current.value = "";
                            fileUploadPopup.current.files = null;
                        }
                    } catch (e) {
                        Sentry.captureException(e);
                        console.log(e);
                    }
                }
            );
        } catch (err) {
            Sentry.captureException(err);
            setCachedImage("");
            setCompletedCrop(undefined);
            setUploading(false);
            setPercent(0);
            if (fileUploadPopup.current) {
                fileUploadPopup.current.value = "";
                fileUploadPopup.current.files = null;
            }
        }

        return;
    }

    // EFFECTS
    useEffect(()=>{
        if (
            completedCrop?.width &&
    completedCrop?.height &&
    imgRef.current &&
    previewCanvasRef.current
        ) {
            const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
            const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
            previewCanvasRef.current.width = completedCrop.width;
            previewCanvasRef.current.height = completedCrop.height;

            const ctx = previewCanvasRef.current.getContext("2d");

            if (!ctx) return;

            ctx.drawImage(
                imgRef.current,
                completedCrop.x * scaleX,
                completedCrop.y * scaleY,
                completedCrop.width * scaleX,
                completedCrop.height * scaleY,
                0,
                0,
                completedCrop.width,
                completedCrop.height
            );
        }
    }, [completedCrop]);

    return (
        <Fragment>
            <div onClick={activatePopup} className="w-fit hover:cursor-pointer">
                {children}
                <input type="file" className="bg-secondary hidden" ref={fileUploadPopup} accept="image/*" onChange={handleImageSelection}/>
                {!!completedCrop && (
                    <div>
                        <canvas
                            hidden
                            ref={previewCanvasRef}
                            style={{
                                border: "1px solid black",
                                objectFit: "contain",
                                width: completedCrop.width,
                                height: completedCrop.height
                            }}
                        />
                    </div>
                )}
            </div>
            <PopupWindow title="Subir Imagen" closePopup={()=>{
                setCachedImage("");
                setCompletedCrop(undefined);
                setUploading(false);
                setPercent(0);
                if (fileUploadPopup.current) {
                    fileUploadPopup.current.value = "";
                    fileUploadPopup.current.files = null;
                }
            }} open={cachedImage !== ""} className="p-4" onClose={()=>{
                setCachedImage("");
                setCompletedCrop(undefined);
                setUploading(false);
                setPercent(0);
                if (fileUploadPopup.current) {
                    fileUploadPopup.current.value = "";
                    fileUploadPopup.current.files = null;
                }
            }}
            >
                <div className="p-8 flex gap-4 flex-col items-center">
                    <h3 className="text-[18px] font-semibold">Arrastra la imagen para ajustarla</h3>
                    <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)} aspect={aspectRatio} minHeight={50}
                        className="relative" circularCrop={rounded}
                    >
                        <img ref={imgRef} src={cachedImage} onLoad={onImageLoad}/>
                    </ReactCrop>
                    {uploading && <LinearProgress variant="determinate" value={percent} color="secondary" />}
                    <UnigowButton type="button" variant="primary" onClick={uploadToFirebase}>
                        {buttonText}
                    </UnigowButton>
                </div>
            </PopupWindow>
        </Fragment>
    );
}