import {api} from "@unigow/apis/requests";
import {CardType} from "@unigow/unigow-types";
import {toast} from "react-toastify";

export async function joinActivityHelper(cardInfo: CardType, goToPage: (p: string)=> void, resetPendingAction: ()=> void): Promise<void> {
    if (cardInfo.type === "reservations") {
        // Se gestiona todo en la página de selección de slot
        goToPage("slotpick");
        return;
    }

    try {
        resetPendingAction();
        await api.post<void, {message: string}>(`cards/${cardInfo._id}/join`);

        toast.success("Te has inscrito correctamente");

        goToPage(`plugin-${cardInfo.plugin}`);
    } catch (err) {
        toast.error("Ya estás inscrito en ese evento");
    }
}