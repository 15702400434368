import {Warning} from "@mui/icons-material";
import {Tab, Tabs, Tooltip} from "@mui/material";
import PageContainer from "@unigow/components/PageContainer/PageContainer";
import {useGroupWizardStore} from "../../stores/groupWizardStore";
import React from "react";
import {useQuery} from "react-query";
import {thereIsIncompleteGroup} from "./helpers/groupHelper";
import GroupConfig from "./sections/GroupConfig/GroupConfig";
import Groups from "./sections/CreateGroups/Groups";
import {useIframeWizardStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/iframeWizard.store";
import {GroupsPluginAPI} from "@unigow/apis/iframe";

export default function GroupPageWizard(): React.ReactElement {
    const {iframeData} = useIframeWizardStore();
    const {setGroupPageData, selectGroupPage, groupPageData, configPage, setConfigPage, groupPageGroups} = useGroupWizardStore();

    useQuery(["plugingroups", iframeData?._id], async()=>{
        return GroupsPluginAPI.getIframeGroupsPlugin({iframeId:iframeData!._id.toString()});
    }, {
        enabled: !!iframeData,
        onSuccess:(res)=>{
            if (res.length === 0) return;

            const [firstPlugin] = res;

            setGroupPageData(firstPlugin);
            selectGroupPage(firstPlugin._id.toString());
        }
    });

    function isOptionEnabled(option: number): boolean {
        if (option >= 1) {
            return !!groupPageData?.alias;
        }
        return true;
    }

    return (
        <div className="flex flex-col gap-4">
            <PageContainer className="gap-[20px] items-start select-none w-fill p-0 lg:p-0">
                <div className="bg-white w-[calc(90%-2rem)] rounded-r-xl lg:px-8 py-4 px-4 flex gap-4 flex-col">
                    <Tabs value={configPage} onChange={(_, v)=>setConfigPage(v)}>
                        <Tab value="config" label="Configuración"/>
                        <Tab value="groups" label={
                            <div className="flex items-center gap-2">
                                <span>Activación de grupos</span>
                                {thereIsIncompleteGroup(groupPageGroups) && (
                                    <Tooltip title="Hay grupos incompletos">
                                        <Warning/>
                                    </Tooltip>
                                )}
                            </div>
                        } disabled={!isOptionEnabled(1)}
                        />
                    </Tabs>
                    <div className=" flex gap-8 flex-col lg:flex-row">
                        {configPage === "config" && (
                            <GroupConfig/>
                        )}
                        {configPage === "groups" && (
                            <Groups/>
                        )}
                    </div>
                </div>
            </PageContainer>
        </div>
    );
}