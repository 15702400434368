import {create} from "zustand";
import {Notification} from "../../types/notifications";
import {getLocalStorage, setLocalStorage} from "../../helpers/localstorage";
import {ChatsPluginType, ParameterType} from "@unigow/unigow-types";
import {ClientWithPermissions} from "@unigow/types/clients";

interface DashboardState {
    // Clientes disponibles para acceder en el dashboard
    availableClients: {
        _id: string;
        name: string;
    }[];

    // Función para obtener los clientes disponibles
    setAvailableClients: (clients: {_id: string, name: string}[])=> void;

    // --- VARIABLES DE MANAGERS --- //
    // Iframes disponibles para acceder en el dashboard
    availableIframes: {alias: string, _id: string}[] | undefined;

    // Función para obtener los iframes disponibles
    setAvailableIframes: (iframes: {alias: string, _id: string}[])=> void;

    // Función para modificar un iframe concreto del array de iframes disponibles
    modifyIframe: (id: string, iframe: {alias: string, _id: string}, newiframe?: boolean)=> void;

    // Función para añadir iframe vacío
    addIframe: ()=> void;

    // Id del cliente seleccionado
    selectedClient: string | undefined;

    // Función para seleccionar un cliente
    selectClient: (clientId: string)=> void;

    // Información del cliente seleccionado
    clientInfo: ClientWithPermissions | undefined;

    // Función para definir la información del cliente seleccionado
    setClientInfo: (client: ClientWithPermissions)=> void;

    // Función para modificar la información del cliente seleccionado
    modifyClientInfo: <K extends keyof ClientWithPermissions>(property: K, value: ClientWithPermissions[K])=> void;

    // Estado de carga del cliente
    clientLoading: boolean;

    // Función para modificar el estado de carga del cliente
    setClientLoading: (loading: boolean)=> void;

    // --- VARIABLES DE ORIENTADORES --- //
    // plugins disponibles para acceder en el dashboard
    availableChatPlugins: ChatsPluginType[];

    // Función para obtener los plugins disponibles
    setAvailableChatPlugins: (plugins: ChatsPluginType[])=> void;

    // Id del iframe seleccionado
    selectedPlugin: string | undefined;

    // Función para seleccionar un iframe
    selectPlugin: (pluginId: string)=> void;

    // Información del cliente seleccionado
    pluginInfo: ChatsPluginType | undefined;

    // Función para modificar la información del cliente seleccionado
    setPluginInfo: (plugin: ChatsPluginType)=> void;

    // Balance del orientador
    balance: number;

    // Modificar balance del orientador
    setBalance: (balance: number)=> void;

    // Notificaciones para el orientador
    notifications: Notification[];

    // Función para modificar las notificaciones del orientador
    setNotifications: (notifications: Notification[])=> void;

    // Función para borrar las notificaciones
    clearNotifications: ()=> void;

    // Parámetros de la información del cliente seleccionado
    clientParams: ParameterType[];

    // Función para modificar los parámetros de la información del cliente seleccionado
    setClientParams: (params: ParameterType[])=> void;

    // Functión para modificar las opciones de un parámetro
    modifyClientParamOptions: (paramId: string, options: string[])=> void;
}

export const useDashboardStore = create<DashboardState>()(
    // persist(
    (set, get)=>({
        availableClients:[],
        setAvailableClients:(clients)=>set({availableClients:clients.sort((a, b)=>a.name.localeCompare(b.name))}),

        availableIframes:undefined,
        setAvailableIframes:(iframes)=>{
            set({availableIframes:iframes.sort((a, b)=>a.alias.localeCompare(b.alias))});
        },
        modifyIframe:(id, iframe, newIframe)=>{
            const {availableIframes} = get();

            if (!availableIframes) return;

            const iframeId = newIframe ? "temp" : id;

            const updatedIframes = availableIframes.map((ifr)=>{
                if (ifr._id === iframeId) {
                    return iframe;
                }

                return ifr;
            });

            set({availableIframes:updatedIframes});
        },
        addIframe:()=>{
            const {availableIframes} = get();

            if (!availableIframes) return;

            // Si ya existe no añadimos otro
            if (availableIframes.filter((ifr)=>ifr._id.startsWith("temp")).length > 0) return;

            const newIframe: {alias: string, _id: string} = {
                _id:"temp",
                alias:`Chat peer-to-peer ${  availableIframes.length + 1}`
            };

            set({availableIframes:[...availableIframes, newIframe]});
        },

        selectedClient:undefined,
        selectClient:(clientId)=>{
            // Check if the client exists in availableClients
            const clientExists = get().availableClients.find((client)=>client._id === clientId);

            if (!clientExists) return;

            setLocalStorage("lastClient", clientId);

            set({selectedClient:clientId});
        },

        clientInfo:undefined,
        setClientInfo:(client)=>{
            set({clientInfo:client});
        },

        modifyClientInfo:(property, value)=>{
            const {clientInfo} = get();
            if (!clientInfo) return;

            const updatedClientInfo = {
                ...clientInfo,
                [property]: value
            };

            set({clientInfo:updatedClientInfo});
        },

        clientLoading:true,
        setClientLoading:(loading)=>set({clientLoading:loading}),

        availableChatPlugins:[],
        setAvailableChatPlugins:(plugins)=>{
            set({availableChatPlugins:plugins.sort((a, b)=>a.alias.localeCompare(b.alias))});
        },

        selectedPlugin:undefined,
        selectPlugin:(pluginId)=>{
            set({selectedPlugin:pluginId});
        },

        pluginInfo:undefined,
        setPluginInfo:(plugin)=>{
            set({pluginInfo:plugin});
        },

        balance:0,
        setBalance:(balance)=>set({balance}),

        notifications:[],
        setNotifications:(notifications)=>set({notifications}),
        clearNotifications:()=>{
            const {notifications} = get();
            // Get the try counter object of the notification ids from cookies
            const tryCounter = JSON.parse(getLocalStorage("tryCounter") || "{}") as Record<string, number>;

            // Increase the try counter for every notification id
            notifications.forEach((notification)=>tryCounter[notification._id] = (tryCounter[notification._id] || 0) + 1);

            // Set the try counter object in cookies
            setLocalStorage("tryCounter", JSON.stringify(tryCounter));

            // Clear set notifications to an empty array
            set({notifications:[]});
        },

        clientParams:[],
        setClientParams:(params)=>{
            set({clientParams:params});
        },
        modifyClientParamOptions:(alias, options)=>{
            const {clientParams} = get();
            if (!clientParams) return;

            // Modify the object clientParams in order to trigger a re-render
            const updatedClientParams = clientParams.map((param)=>{
                if (param.alias === alias) {
                    return {...param, options:options};
                }

                return param;
            });

            set({clientParams:updatedClientParams});
        }
    })
    // ,{
    //     name:"dashboard-storage",
    //     storage:createJSONStorage(()=>sessionStorage)
    // }
    // )
);