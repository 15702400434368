import React, {Children, FormEvent, useEffect, useState} from "react";
import {MenuItem, Select} from "@mui/material";
import {useChatsPluginStore} from "@unigow/pages/Dashboard/pages/Iframes/stores/chatsPlugin.store";
import ParameterChooser from "@unigow/pages/Dashboard/components/ParameterChooser/ParameterChooser";
import ParameterFields from "@unigow/pages/Dashboard/components/ParameterChooser/ParameterFields";
import {ChatsPluginAPI} from "@unigow/apis/iframe";
import {UpdateChatsPluginReq} from "@unigow/unigow-types";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";

function Step2(): React.ReactElement {
    // STORES
    const {clientParams} = useDashboardStore();
    const {setStep, step, pluginData, modifyPluginData} = useChatsPluginStore();

    // STATES
    const [subStep, setSubStep] = useState(0);
    const [mainField, setMainField] = useState("");
    const [selectedOptions, setSelectedOptions] = useState<string[]>(pluginData?.settings.mentorParams || []);

    useEffect(()=>{
        if (!pluginData || !pluginData.settings.mainField) return;

        setMainField(pluginData.settings.mainField);
    }, [pluginData]);

    // FUNCTIONS
    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        const chatsPlugin: UpdateChatsPluginReq = {
            settings:{
                mainField,
                mentorParams:selectedOptions,
                visibleParams:selectedOptions
            }
        };

        if (!pluginData) {
            setStep(0);
            return;
        }

        const response = await ChatsPluginAPI.updateChatsPlugin({pluginId:pluginData._id.toString(), data:chatsPlugin});

        if (response) {
            modifyPluginData("settings", response.settings);
            setSubStep(1);
        }
    }

    return (
        <div className="overflow-hidden">
            {subStep === 0 ? (
                <form className="flex flex-col gap-[15px] " onSubmit={handleSubmit}>
                    {step === 2 && (
                        <h2>2.1 Configurar categorías del embajador</h2>
                    )}
                    <p className="font-light text-ug-base">Estos son los datos que los embajadores tendrán que añadir para aparecer en la herramienta. El nombre, el email y la contraseña son campos que se pide por defecto al embajador.</p>
                    <p className="text-ug-base font-semibold">Nombre *</p>
                    <div className="flex justify-between items-center">
                        <p className="text-ug-base font-semibold">Categoría Principal *</p>
                        <Select required value={mainField} onChange={(e)=>{
                            setMainField(e.target.value);
                            // Add the field to the selected options if it's not already there
                            if (!selectedOptions.includes(e.target.value)) {
                                setSelectedOptions([...selectedOptions, e.target.value]);
                            }
                        }} size="small" className="w-[16ch]"
                        >
                            {Children.toArray(clientParams.map((param)=>(
                                <MenuItem value={param.alias}>{param.name}</MenuItem>
                            )))}
                        </Select>
                    </div>
                    <ParameterChooser selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}
                        goBack={()=>setStep(0)} mentor
                    />
                </form>
            ) : (
                <div className="flex flex-col gap-[15px] ">
                    {step === 2 && (
                        <h2>2.2 Revisa las opciones de cada categoría</h2>
                    )}
                    <p className="font-light text-ug-base">El embajador al registrarse deberá seleccionar una opción dentro de cada categoría. Asegúrate de que las opciones son correctas.</p>

                    <ParameterFields selectedOptions={clientParams.filter((x)=>selectedOptions.includes(x.alias))}
                        setSubStep={setSubStep} goNext={()=>{
                            setStep(3);
                        }}
                    />
                </div>
            )}

        </div>
    );
}

export default Step2;