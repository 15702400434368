import React, {useState} from "react";
import PopupWindow from "@unigow/components/PopupWindow/PopupWindow";
import {Autocomplete} from "@mui/material";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {useQuery} from "react-query";
import {api} from "@unigow/apis/requests";
import {Param} from "@unigow/types/param";
import {toast} from "react-toastify";
import {useDashboardStore} from "@unigow/stores/dashboard/dashboardStore";
import UnigowInput from "@unigow/components/UnigowInput/UnigowInput";
import {ChatsPluginAPI} from "@unigow/apis/iframe";
import {ChatsPluginType, ClientType} from "@unigow/unigow-types";

interface MentorSelectorProps {
    open: boolean;
    setOpen: (open: boolean)=> void;
}

export default function MentorSelector({open, setOpen}: MentorSelectorProps): React.ReactElement {
    // STORES
    const {selectedClient} = useDashboardStore();

    const [selectedIframe, setSelectedIframe] = useState<(ChatsPluginType & {clientInfo: Pick<ClientType, "_id" | "alias" | "name">}) | null>(null);
    const [mainField, setMainField] = useState<string[]>([]);
    const [selectedTarget, setSelectedTarget] = useState<string | null>(null);

    const {data:chatPlugins = []} = useQuery(["chatplugins"], async()=>{
        return ChatsPluginAPI.getAllChatsPlugins();
    }, {
        enabled: !!selectedClient
    });

    const {data:mainOptions = []} = useQuery(["parameters", selectedIframe], async()=>{
        const url = `parameters/${selectedIframe!.client}/parameterInfo`;

        const clientParams = await api.get<Param[]>(url);

        const mainParam = selectedIframe!.settings.mainField;

        return clientParams.find((param)=>param.alias === mainParam)?.options || [];
    }, {enabled:!!selectedIframe});

    async function startBatch(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!selectedIframe || mainField.length === 0 || !selectedTarget) return;

        const response = await api.post<{plugin: string, mainField: string[]}, {count: number}>(`pluginchats/${selectedTarget}/orientators/batch`, {
            plugin:selectedIframe._id.toString(),
            mainField
        });

        if (response) {
            toast.success(`Se han replicado ${response.count} embajadores`);
            setOpen(false);
        }
    }

    return (
        <PopupWindow title="Aprovecha los embajadores existentes en Unigow" open={open} onClose={()=>{
            setOpen(false);
            setSelectedIframe(null);
        }}
        closePopup={()=>setOpen(false)}
        >
            <form className="flex flex-col gap-[15px] w-full" onSubmit={startBatch}>
                <div className="flex flex-col gap-[15px] w-full">
                    <div className="flex flex-col gap-[5px]">
                        <h3 className="text-primary-500">Selecciona el proyecto al que importar los embajadores</h3>
                    </div>
                    <div className="flex lg:items-center gap-4 justify-between flex-col lg:flex-row">
                        <p className="text-ug-lg font-semibold">Proyecto de tu institución</p>
                        <Autocomplete className="lg:w-2/5 shrink-0" options={chatPlugins.filter((iframe)=>iframe.client.toString() === selectedClient)}
                            renderInput={(params)=>(
                                <UnigowInput required {...params} size="small" label="Proyecto"/>
                            )}
                            getOptionLabel={(option)=>option.alias}
                            isOptionEqualToValue={(option, value)=>option._id.toString() === value._id.toString()}
                            onChange={(_, value)=>setSelectedTarget(value?._id.toString() || "")}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-[15px] w-full">
                    <div className="flex flex-col gap-[5px]">
                        <h3 className="text-primary-500">Selecciona los embajadores que quieres</h3>
                    </div>
                    <div className="flex lg:items-center gap-4 justify-between flex-col lg:flex-row">
                        <p className="text-ug-lg font-semibold">Proyecto de Institución educativa</p>
                        <Autocomplete className="lg:w-2/5 shrink-0" options={chatPlugins}
                            renderInput={(params)=>(
                                <UnigowInput required {...params} size="small" label="Proyecto"/>
                            )}
                            getOptionLabel={(option)=>`${option.clientInfo.name} - ${option.alias}`}
                            value={selectedIframe}
                            onChange={(_, value)=>setSelectedIframe(value)}
                        />
                    </div>
                    <div className="flex lg:items-center gap-4 justify-between flex-col lg:flex-row">
                        <p className="text-ug-lg font-semibold">Escoge embajadores de las titulaciones disponibles</p>
                        <Autocomplete multiple autoHighlight className="lg:w-2/5 shrink-0" options={["Todas"].concat(mainOptions)}
                            renderInput={(params)=>(
                                <UnigowInput required={mainField.length === 0} {...params} size="small" label="Campo principal"/>
                            )}
                            value={mainField}
                            onChange={(_, value)=>setMainField(value)}
                        />
                    </div>
                </div>
                <div>
                    <UnigowButton type="submit" className="px-8" variant="secondary">Replicar a estos embajadores</UnigowButton>
                </div>
            </form>
        </PopupWindow>
    );
}