import React from "react";

import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

interface UnigowTableProps {
    headerTitles: string[];
    elementCount: number;
    children: React.ReactNode
}

export function UnigowTable({headerTitles, children, elementCount}: UnigowTableProps): React.ReactElement {
    return (
        <Paper sx={{width: "100%", overflow: "hidden", boxShadow:"none"}}>
            <TableContainer component={Paper} sx={{maxHeight: 700}}>
                <div className="px-4 py-2 text-gray-cool-500">
                    <p>{elementCount} elementos</p>
                </div>
                <Table stickyHeader={true} className=" border-0 border-t border-gray-cool-200 border-solid">
                    <TableHead sx={{whiteSpace:"nowrap"}}>
                        <TableRow>
                            {headerTitles.map((name)=>(
                                <TableCell key={name}>
                                    <p className="text-primary-500 font-semibold text-ug-base">{name}</p>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {children}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}