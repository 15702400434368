import {TextareaAutosize} from "@mui/material";
import {api} from "@unigow/apis/requests";
import UnigowButton from "@unigow/components/UnigowButton/UnigowButton";
import {RetoObject, UserAnswer} from "@unigow/types/retos";
import React, {Children, useState} from "react";
import {useQuery} from "react-query";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {ReactComponent as UnigowLogo} from "../../assets/unigow-black.svg";

export default function FillReto(): React.ReactElement {
    const navigate = useNavigate();

    const {retoId} = useParams();

    const {data} = useQuery(["reto", retoId], async()=>{
        return api.get<RetoObject>(`retos/${retoId}`);
    });

    const [answers, setAnswers] = useState<Record<string, string>>({});

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        // Create the answers object
        const answersArray: UserAnswer[] = Object.entries(answers).map(([alias, answer])=>({
            alias,
            answer
        }));

        const response = await api.post<{answers: UserAnswer[]}, unknown>(`retosanswers/${retoId}`, {answers:answersArray});

        if (response) {
            toast.success("Respuestas guardadas correctamente");
            navigate("/");
        }
    }

    if (!data) return <></>;

    return (
        <div className="absolute inset-0 bg-gradient-to-b from-black from-[65%] to-primary-500">
            <div className="absolute max-h-[85%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white lg:w-1/3 w-[90%] rounded-2xl py-8 lg:px-28 px-4 flex flex-col gap-4 shadow-ug-xl items-center [overflow:overlay]">
                <div className="flex justify-center">
                    <UnigowLogo />
                </div>
                <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                    {Children.toArray(data.questions.map((question)=>(
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col gap-1">
                                <h3>{question.question}</h3>
                                <p className="text-sm text-gray-500">{question.description}</p>
                            </div>
                            <TextareaAutosize required className="border-gray-300 rounded-xl text-gray-cool-500 text-ug-lg" placeholder={question.placeholder} minRows={question.lines || 1} onChange={(e)=>{
                                const questionAlias = question.alias;
                                const {value} = e.target;

                                if (value === "") {
                                    // Remove the alias from the answers object
                                    const newAnswers = {...answers};

                                    delete newAnswers[questionAlias];

                                    setAnswers(newAnswers);

                                    return;
                                }

                                setAnswers((prevAnswers)=>({
                                    ...prevAnswers,
                                    [questionAlias]: value
                                }));
                            }}
                            />
                        </div>
                    )))}
                    <div>
                        <UnigowButton variant="secondary">Guardar respuestas</UnigowButton>
                    </div>
                </form>
            </div>
        </div>
    );
}