import React from "react";
import UnigowButton from "../../../../components/UnigowButton/UnigowButton";
import {useSearchParams} from "react-router-dom";
import useUnigowStore from "../../../../stores/unigowStore";
import {useDashboardStore} from "../../../../stores/dashboard/dashboardStore";
import {UnigowMultipleAutocomplete} from "@unigow/components/UnigowAutocomplete/UnigowAutocomplete";

interface ParameterChooserProps {
    selectedOptions: string[];
    setSelectedOptions: (options: string[])=> void;
    goBack: ()=> void;
    mentor?: boolean
}

function ParameterChooser({selectedOptions, setSelectedOptions, goBack}: ParameterChooserProps): React.ReactElement {
    // MISCELLANEOUS
    const [,setURLSearchParams] = useSearchParams();

    // STORES
    const {openSupport} = useUnigowStore();
    const {clientParams} = useDashboardStore();

    return (
        <div className="flex flex-col gap-[10px]">
            <p className="text-ug-base font-semibold"><span className="text-gray-cool-300">(OPCIONAL)</span> Añade una o varias categorías adicionales</p>
            <UnigowMultipleAutocomplete
                options={clientParams.map((option: {alias: string; name: string}) => option.alias)}
                getOptionLabel={(option) => {
                    const found = clientParams.find((p)=>p.alias === option);
                    const minorSuffix = found?.minors ? " (para menor)" : "";
                    return `${found?.name}${minorSuffix}`;
                }}
                value={selectedOptions}
                onChange={(event, value) => {
                    if (value) {
                        setSelectedOptions(value);
                    }
                }}
                placeholder="Buscar categoría"
                className="w-full"
            />
            <p className="text-primary-500 underline cursor-pointer text-ug-sm py-1" onClick={()=>{
                setURLSearchParams({type:"section"});
                openSupport();
            }}
            >Quiero añadir una sección personalizada
            </p>
            <div className="flex gap-[10px] flex-col lg:flex-row">
                <UnigowButton variant="basic" type="button" onClick={goBack}>Cancelar</UnigowButton>
                <UnigowButton variant="primary" type="submit">Continuar</UnigowButton>
            </div>
        </div>
    );
}

export default ParameterChooser;