import React, {AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef} from "react";
import {twMerge} from "tailwind-merge";

export type UnigowButtonProps =
  {
      children: React.ReactNode;
      variant: "primary" | "secondary" | "cancel" | "basic" | "business";
  } &
  (
      ({buttonType?: "button"} & ButtonHTMLAttributes<HTMLButtonElement>) |
      ({buttonType: "anchor"} & AnchorHTMLAttributes<HTMLAnchorElement>)
  )
  ;

const UnigowButton = forwardRef<HTMLButtonElement, UnigowButtonProps>(
    ({children, variant, className, buttonType = "button", ...buttonProps}, ref) => {

        let variantClasses = "";

        switch (variant) {
            case "primary":
                variantClasses = "bg-primary-500 text-white";
                break;
            case "secondary":
                variantClasses = "bg-secondary-blue-500 text-white";
                break;
            case "cancel":
                variantClasses = "bg-red-500 hover:bg-red-600 text-white";
                break;
            case "basic":
                variantClasses = "border border-solid border-gray-cool-300";
                break;
            case "business":
                variantClasses = "bg-business-500 text-white";
                break;
        }

        if (buttonType === "anchor") {
            const {...extraProps} = buttonProps as AnchorHTMLAttributes<HTMLAnchorElement>;
            return (
                <a {...extraProps} className={twMerge("px-6 py-2 text-center no-underline rounded-full border-0 text-ug-base font-semibold cursor-pointer", variantClasses, className)} target="_blank" rel="noopener noreferrer">{children}</a>
            );
        }

        const {...extraProps} = buttonProps as ButtonHTMLAttributes<HTMLButtonElement>;

        return (
            <button style={variant === "basic" ? {backgroundColor:"white"} : {}} ref={ref} {...extraProps} className={twMerge("px-6 py-2 rounded-full border-0 text-ug-base font-semibold cursor-pointer", variantClasses, extraProps.disabled ? "cursor-default bg-secondary-blue-300" : "disabled:cursor-default disabled:bg-secondary-blue-300", className)}>{children}</button>
        );
    });

export default UnigowButton;